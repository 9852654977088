/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Icon, Input, Select, Button, message } from 'antd';
// import { CloseCircleTwoTone } from '@ant-design/icons';
import styled from 'styled-components';
import { connect } from 'react-redux';
import get from 'lodash/get';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';
import randomstring from 'randomstring';
import { updateUser } from '../appRedux/actions/Auth';
import DropdownSvg from '../icons/DropdownSvg.jsx';
import EditSvg from '../icons/EditSvg.jsx';
import { updateObject } from '../services/CommonServices';
import UploadDisplay from './UploadDisplay.jsx';

import config from '../config';

const { Option } = Select;

const handleFinishedUpload = (info, setFieldsValue, photos, setPhotosArray) => {
  const tempPhotos = photos.length > 0 ? photos.slice(0) : [];
  tempPhotos.push(info.fileUrl);
  setPhotosArray(tempPhotos);
};
const handleSubmit = (props, photos) => {
  const { form } = props;
  form.validateFields((err, fieldsValue) => {
    if (err) {
      message.warning('من فضلك أكمل بياناتك');
      return;
    }
    fieldsValue.images = photos;
    updateObject('buyers', fieldsValue)
      .then(() => {
        message.destroy();
        props.updateUser();
        message.success('تم تعديل البيانات بنجاح');
      })
      .catch(() => {
        message.warn('يوجد خطأ');
      });
  });
};
const InstitutionForm = (props) => {
  const [editable, setEditable] = useState(false);
  const [photos, setPhotosArray] = useState([]);
  const { getFieldDecorator, setFieldsValue } = props.form;
  const s3Url = 'https://repzone-static.s3.amazonaws.com/';
  const uploadOptions = {
    server: config.url.slice(0, -1),
    signingUrlWithCredential: true,
    s3path: `buyers/${randomstring.generate(7)}/`,
    autoUpload: true
  };
  const removePhoto = (photos, index) => {
    const photosarray = [...photos];
    photosarray.splice(index, 1);
    setPhotosArray(photosarray);
  };
  const buyerTypes = [
    { _id: 'كشك', name: 'كشك' },
    { _id: 'سوبر ماركت', name: 'سوبر ماركت' },
    { _id: 'سلاسل', name: 'سلاسل' },
    { _id: 'مطاعم', name: 'مطاعم' },
    { _id: 'تاجر جملة', name: 'تاجر جملة' },
    { _id: 'تاجر قطاعي', name: 'تاجر قطاعي' },
    { _id: 'بازار', name: 'بازار' },
    { _id: 'محمصة', name: 'محمصة' },
    { _id: 'كوفي شوب', name: 'كوفي شوب' },
    { _id: 'مطعم', name: 'مطعم' }
  ];
  useEffect(() => {
    props.updateUser();
  }, []);
  useEffect(() => {
    setPhotosArray(get(props, 'auth.user.images', []));
  }, [get(props, 'auth.user')]);
  return (
    <Container>
      <StyledForm>
        <Row noBorder>
          <Col lg={12} xs={2}>
            <StyledIcon onClick={() => setEditable(true)} component={EditSvg} />
          </Col>
          <Col lg={12} xs={10}>
            <Label> بيانات المنشأة</Label>
          </Col>
        </Row>

        <Welcome>يمكنك تعديل البيانات الخاصة بك في اي وقت</Welcome>
        <StyledRow type='flex' justify='center' align='middle'>
          <Col lg={6} sm={6} md={6} xs={24}>
            <Label>رقم الموبايل :</Label>
          </Col>
          <Col lg={18} sm={18} md={18} xs={24}>
            {getFieldDecorator('accountPhoneNumber', {
              initialValue: get(props, 'auth.user.accountPhoneNumber'),
              rules: [{ required: true, message: 'من فضلك ادخل رقم الموبايل' }]
            })(<TextArea disabled={!editable} placeholder='رقم الموبايل' />)}
          </Col>
        </StyledRow>
        <StyledRow>
          <Col lg={18} sm={18} md={18} xs={24}>
            {getFieldDecorator('manager_name.ar', {
              initialValue: get(props, 'auth.user.manager_name.ar', ''),
              rules: [
                {
                  required: true,
                  message: 'ادخل اسم المدير'
                }
              ]
            })(<TextArea disabled={!editable} placeholder='مدير المنشأة' />)}
          </Col>
          <Col lg={6} sm={6} md={6} xs={24}>
            <Label>مدير المنشأة :</Label>
          </Col>
        </StyledRow>
        <StyledRow>
          <Col lg={18} sm={18} md={18} xs={24}>
            {getFieldDecorator('name.ar', {
              initialValue: get(props, 'auth.user.name.ar', ''),
              rules: [
                {
                  required: true,
                  message: 'ادخل اسم المنشأة'
                }
              ]
            })(<TextArea disabled={!editable} placeholder='اسم المنشأة' />)}
          </Col>
          <Col lg={6} sm={6} md={6} xs={24}>
            <Label>اسم المنشأة :</Label>
          </Col>
        </StyledRow>
        <StyledRow>
          <Col lg={18} sm={18} md={18} xs={24}>
            {getFieldDecorator('activity', {
              initialValue: get(props, 'auth.user.activity', ''),
              rules: [
                {
                  required: true,
                  message: 'Please enter your institution type!'
                }
              ]
            })(
              <DropDown
                disabled={!editable}
                mode={'multiple'}
                showArrow={false}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder={
                  <div style={{ textAlign: 'right' }}>
                    {' نشاط المنشأة'}
                    <StyledIcon component={DropdownSvg} />{' '}
                  </div>
                }
              >
                {buyerTypes.map((type) => (
                  <Option
                    value={get(type, '_id')}
                    style={{ textAlign: 'right' }}
                  >
                    {get(type, 'name')}
                  </Option>
                ))}
              </DropDown>
            )}
          </Col>
          <Col lg={6} sm={6} md={6} xs={24}>
            <Label> نشاط المنشأة :</Label>
          </Col>
        </StyledRow>

        <StyledRow>
          <Col lg={18} sm={18} md={18} xs={24}>
            {getFieldDecorator('email', {
              initialValue: get(props, 'auth.user.email', '')
            })(<TextArea disabled={!editable} placeholder='الشارع' />)}
          </Col>
          <Col lg={6} sm={6} md={6} xs={24}>
            <Label>البريد الالكتروني :</Label>
          </Col>
        </StyledRow>

        <StyledRow>
          <Col lg={18} sm={18} md={18} xs={24}>
            {getFieldDecorator('phone', {
              initialValue: get(props, 'auth.user.phone.0', '')
            })(
              <TextArea
                disabled={!editable}
                placeholder=' رقم التليفون الأرضي '
              />
            )}
          </Col>
          <Col lg={6} sm={6} md={6} xs={24}>
            <Label>رقم التليفون الأرضي :</Label>
          </Col>
        </StyledRow>
        <StyledRow noMargin>
          <Col span={24}>
            <Label> الصور :</Label>
            <Row style={{ marginTop: '1vw' }}>
              {photos.map((photo, index) => (
                <StyledCol span={6}>
                  <Wrapper>
                    <DisplayDelete>
                      <UploadDisplay image={photo} />
                      {editable && (
                        <StyledIconDelete
                          type='delete'
                          onClick={() => removePhoto(photos, index)}
                          twoToneColor='#4a7fbd'
                        />
                      )}
                    </DisplayDelete>
                  </Wrapper>
                </StyledCol>
              ))}
              {editable ? (
                <StyledCol span={6}>
                  <Wrapper>
                    <StyledDropzone
                      onFinish={(info) =>
                        handleFinishedUpload(
                          info,
                          setFieldsValue,
                          photos,
                          setPhotosArray
                        )
                      }
                      s3Url={s3Url}
                      maxSize={1024 * 1024 * 5}
                      upload={uploadOptions}
                    >
                      <UploadDisplay image={''} />
                    </StyledDropzone>
                  </Wrapper>
                </StyledCol>
              ) : (
                ''
              )}
            </Row>
          </Col>
        </StyledRow>
        <StyledRow noBorder>
          <Col span={24}>
            {editable ? (
              <CustomButton
                onClick={() => {
                  handleSubmit(props, photos);
                }}
              >
                <ButtonText>حفظ</ButtonText>
              </CustomButton>
            ) : (
              ''
            )}
          </Col>
        </StyledRow>
      </StyledForm>
    </Container>
  );
};

const WrappedNormalLoginForm = Form.create({ name: 'InstitutionForm' })(
  InstitutionForm
);

const mapStateToProps = ({ main, auth }) => ({ main, auth });

export default connect(mapStateToProps, { updateUser })(WrappedNormalLoginForm);

const Container = styled.div`
  width: 100%;
  margin: 60px 0;

  .ant-input[disabled],
  input,
  .ant-select-selection,
  .ant-select-selection.ant-select-selection--single {
    padding-left: 30px;
    border: 2px solid rgb(151, 151, 151);
    height: 40px;
    font-size: 16px;
    border-radius: 0;
    @media (max-width: 414px) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 414px) {
    .anticon svg {
      display: inline-block;
      width: 20px;
      height: 20px;
    }
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
`;

const Label = styled.h1`
  margin-bottom: 0px;
  font-size: 18px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
`;

const TextArea = styled(Input)`
  width: 100%;
  /* height: 2.4vw; */
  margin-top: 0px;
  margin-bottom: 0px;
  border: solid 1px #979797;
  line-height: normal;
  direction: rtl;
  font-size: 1.3vw;
  @media (max-width: 768px) {
    font-size: 16px;
    height: 3.9vw;
  }
`;

const StyledIcon = styled(Icon)`
  float: left;
  font-size: 1vw;
  font-weight: normal;
  margin-top: 3px;
  cursor: pointer;
  path {
    fill: ${(props) => props.theme.primary};
  }

  @media (max-width: 767px) {
    float: right;
  }
`;
const DropDown = styled(Select)`
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  /* line-height: normal; */
  font-size: 1.3vw;
  @media (max-width: 768px) {
    font-size: 20px;
  }
  & .ant-select-selection {
    border-color: #979797;
    height: fit-content;
  }
  & .ant-select-selection-selected-value {
    direction: rtl;
    float: right;
    font-size: 1.3vw;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  & .ant-select-selection--multiple .ant-select-selection__rendered {
    display: flex;
    direction: rtl;
  }
`;

const StyledRow = styled(Row)`
  width: 100%;
  ${(props) => (!props.noBorder ? 'border-bottom: solid 0.5px' : '')};
  padding-top: 2.5vw;
  ${(props) => (!props.noMargin ? 'padding-bottom: 2.5vw;' : '')};
`;
const Welcome = styled.div`
  font-size: 19px;
  font-family: 'Cairo-Light';
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  float: right;
  color: ${(props) => props.theme.primary};
  padding-bottom: 2.5vw;
`;
const CustomButton = styled(Button)`
  float: left;
  background-color: #4a7fbd;
  border-radius: 1vw;
  width: 100px;
  height: inherit;
  padding: 3px;
  box-shadow: none;
  @media (max-width: 768px) {
    padding: 14px;
  }

  @media (max-width: 767px) {
    padding: 8px;
  }
  float: left;
  background-color: ${(props) => props.theme.accent};
  border-radius: 1vw;
`;

const ButtonText = styled.div`
  font-size: 1.4vw;
  color: #fff;
  @media (max-width: 767px) {
    font-size: 15px;
  }
`;
const StyledCol = styled(Col)`
  float: right;
  margin-bottom: 4vw;
`;
const Wrapper = styled.div`
  padding-left: 1vw;
  padding-right: 1vw;
`;
const DisplayDelete = styled.div`
  position: relative;
  display: inline-block;
`;
const StyledIconDelete = styled(Icon)`
  font-size: 25px;
  position: absolute;
  right: -10px;
  top: -10px;
  color: #4a7fbd;
  display: none;
  @media (max-width: 768px) {
    font-size: 10px;
    right: -5px;
    top: -5px;
  }
  ${DisplayDelete}:hover & {
    display: block;
  }
  ${DisplayDelete}:active & {
    display: block;
  }
`;
const StyledDropzone = styled(DropzoneS3Uploader)`
  border: 2px solid #f1f4f5 !important;
  width: 9vw !important;
  max-width: 100%;
  height: 9vw !important;
  margin: 0 auto;
`;
