import React from 'react';

const EditSvg = () => (
  <svg
    width='25px'
    height='25px'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 56.28 56.28'
  >
    <defs>
      <style dangerouslySetInnerHTML={{ __html: '.cls-1{fill:#4c7fba;}' }} />
    </defs>
    <title>edit</title>
    <path
      className='cls-1'
      d='M440.77,297.2a1.55,1.55,0,0,0-1.54,1.45h0v21.5a1.93,1.93,0,0,1-1.93,1.93H398a1.93,1.93,0,0,1-1.93-1.93V280.88A1.93,1.93,0,0,1,398,279h21.5a1.55,1.55,0,0,0,0-3.11H398a5,5,0,0,0-5,5v39.28a5,5,0,0,0,5,5h39.27a5,5,0,0,0,5-5v-21.5h0A1.55,1.55,0,0,0,440.77,297.2Z'
      transform='translate(-392.97 -268.92)'
    />
    <path
      className='cls-1'
      d='M441.16,268.92a8,8,0,0,0-5.73,2.37l-3.18,3.18,2.2,2.2,3.18-3.18a5,5,0,0,1,8.5,3.53,5,5,0,0,1-1.46,3.52L418.2,307a.75.75,0,0,1-.38.21l-8.58,1.72,1.72-8.58a.73.73,0,0,1,.21-.39l18.3-18.3-2.2-2.2L409,297.77a3.87,3.87,0,0,0-1.06,2l-2.16,10.79a1.57,1.57,0,0,0,0,.18l0,.11h0s0,.06,0,.1a1.55,1.55,0,0,0,1.55,1.55h.11l.11,0,.18,0,10.79-2.16a3.85,3.85,0,0,0,2-1.06l26.48-26.48a8.1,8.1,0,0,0-5.72-13.82Z'
      transform='translate(-392.97 -268.92)'
    />
  </svg>
);

export default EditSvg;
