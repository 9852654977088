/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
import React from 'react';
import { Icon, Row, Col } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Text = styled.div`
  font-size: 1.5vw;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.primary};
  margin-bottom: 2vw;
  margin-top: 2vw;
`;
const StyledIcon = styled(Icon)`
  font-size: 12.4vw;
  & .cls-1 {
    fill: ${(props) => props.theme.primary};
  }
`;

const ButtonText = styled.div`
  background: #4a7fbd;
  width: 50%;
  margin: 0 auto;
  display: block;
  border-radius: 50px;
  padding: 13px;
  font-size: 18px;
  font-family: 'Cairo-Bold';
  transition: 0.3s;
  text-align: center;
  color: #fff;
  :hover {
    background: #fff;
    color: #4a7fbd;
    border: 1px solid #4a7fbd;
  }
`;
const WishlistEmptyHolder = styled.div`
  padding-top: 3vw;
  text-align: center;
  padding-bottom: 3vw;
`;

const WishlistEmprtyHolder = styled.div``;
const WishlistEmpty = (props) => (
  <WishlistEmptyHolder>
    <div className='container'>
      <Row>
        <Col span={12} offset={6}>
          <StyledIcon type={'star'} />
          <Text> قائمة المفضلات فارغة</Text>
          <WishlistEmprtyHolder>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => props.history.goBack()}
            >
              <ButtonText> متابعة </ButtonText>
            </div>
          </WishlistEmprtyHolder>
        </Col>
      </Row>
    </div>
  </WishlistEmptyHolder>
);

export default WishlistEmpty;
