/* eslint-disable import/extensions */
import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import get from 'lodash/get';
import CustomDivider from '../elements/CustomDivider';

const OrderHistoryProduct = (props) => (
  <ProductContainer>
    <ImageContainer>
      <img
        alt={'product'}
        src={
          get(props, 'product.product.images[0]')
            ? get(props, 'product.product.images[0]')
            : get(props, 'product.product.companyProduct.images[0]')
            ? get(props.product, 'product.companyProduct.images[0]')
            : require('../assets/images/repzone-placeholder.png')
        }
      />
    </ImageContainer>
    <ProductInfoContainer>
      <ProductName>{get(props, 'product.product.name', '')}</ProductName>
      {Number(get(props, 'product.total')) === 0 && (
        <ProductGift>{'منتج هدية'}</ProductGift>
      )}
    </ProductInfoContainer>
    <GreyContainer>
      <OrderProduct gutter={8}>
        <Col lg={12} xs={12} style={{ fontSize: '16px' }}>
          <OrderHistoryGb>جم</OrderHistoryGb>
        </Col>
        <Col lg={12} xs={12} style={{ direction: 'ltr', fontSize: '16px' }}>
          <OrderHistoryTotal>
            {parseFloat(get(props, 'product.total')).toFixed(2)}
          </OrderHistoryTotal>
        </Col>
      </OrderProduct>
    </GreyContainer>
    <CustomDivider bottom={'0'} />
    <PriceContainer>
      <RowHolder>
        <Col span={12}>
          <TextContainer weight={'bold'} left>
            <RowHolder>
              <Col span={6} style={{ direction: 'ltr' }}>
                {get(props, 'product.quantity[0].quantity', '')}
              </Col>
            </RowHolder>
          </TextContainer>
        </Col>
        <Col span={12}>
          <TextContainer weight={'bold'} right>
            كرتونة
          </TextContainer>
        </Col>
      </RowHolder>
    </PriceContainer>
    <CustomDivider />
    <PriceContainer>
      <RowHolder>
        <Col span={12}>
          <TextContainer weight={'bold'} left>
            <RowHolder style={{ direction: 'rtl' }}>
              <Col span={6} style={{ direction: 'ltr' }}>
                {get(props, 'product.quantity[1].quantity', '')}
              </Col>
            </RowHolder>
          </TextContainer>
        </Col>
        <Col span={12}>
          <TextContainer weight={'bold'} right>
            عبوة
          </TextContainer>
        </Col>
      </RowHolder>
    </PriceContainer>
  </ProductContainer>
);

export default OrderHistoryProduct;

const TextContainer = styled.div`
  text-align: ${(props) => (props.left ? 'left' : 'right')};
  font-weight: ${(props) => props.weight || 'normal'};
  font-size: 14px;
  color: ${(props) => props.theme.primary};
  .anticon {
    font-size: 2vw;
    color: ${(props) => props.theme.accent};
  }

  @media (max-width: 768px) {
    padding: 0 9px;
    text-align: right;
  }
`;
const ProductContainer = styled.div`
  width: 100%;
  height: 30vw;
  font-size: 1.7vw;

  @media (max-width: 414px) {
    .ant-divider-horizontal {
      margin: 10px 0;
    }
  }
`;
const ImageContainer = styled.div`
  width: 100%;
  margin-top: 1vw;
  margin-bottom: 1.2vw;
  img {
    width: 100%;
    height: 18vw;
    object-fit: contain;
  }
`;
const ProductInfoContainer = styled.div`
  text-align: right;
  color: ${(props) => props.theme.primary};
  font-size: 1.5vw;
  font-weight: 300;
`;
const PriceContainer = styled.div`
  padding: 10px 0px;
  @media (max-width: 414px) {
    padding: 3px 0px;
  }
`;
const ProductName = styled.div`
  overflow: hidden;
  width: 100%;
  direction: rtl;
  text-align: right;
  font-size: 16px;
  font-weight: bold;

  @media (max-width: 414px) {
    font-size: 14px;
    text-align: center;
  }
`;

const ProductGift = styled.div`
  overflow: hidden;
  width: 100%;
  direction: rtl;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  color: #557fb8;
`;

const GreyContainer = styled.div`
  background-color: ${(props) => props.theme.light};
  margin-top: 10px;
  line-height: 4vw;
  font-size: 2vw;
  color: ${(props) => props.theme.primary};
  font-weight: 800;
  padding: 9px;
  text-align: center;
`;

const OrderHistoryGb = styled.div`
  text-align: left;
`;

const OrderHistoryTotal = styled.div`
  text-align: right;
`;

const OrderProduct = styled(Row)`
  display: inherit !important;
  -ms-flex-wrap: inherit !important;
  display: inherit !important;
  flex-direction: inherit !important;
`;

const RowHolder = styled(Row)`
  display: inherit !important;
  -ms-flex-wrap: inherit !important;
  display: inherit !important;
  flex-direction: inherit !important;
`;
