import {
  GET_WISHLIST,
  GET_WISHLIST_SUCCESS,
  SHOW_MESSAGE,
  ADD_TO_WISHLIST,
  REMOVE_FROM_WISHLIST
} from '../../constants/ActionTypes';

export const getWishlist = () => ({
  type: GET_WISHLIST
});

export const getWishlistSuccess = (payload) => {
  return {
    type: GET_WISHLIST_SUCCESS,
    payload
  };
};

export const addToWishlist = (payload) => {
  return {
    type: ADD_TO_WISHLIST,
    payload
  };
};
export const removeFromWishlist = (payload) => ({
  type: REMOVE_FROM_WISHLIST,
  payload
});

export const showWishlistMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};
