import styled from 'styled-components';

const CustomRectangle = styled.div`
  width: 64.5%;
  background-color: ${(props) =>
    props.background ? props.background : '#e1e5ea'};
  text-align: right;
  padding-top: 12px;
  padding-bottom: 0 auto;
`;
export default CustomRectangle;
