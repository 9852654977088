import React from 'react';

const HanshakeSvg = (props) => (
  <svg
    id='prefix__Layer_1'
    data-name='Layer 1'
    viewBox='0 0 92.18 57.03'
    {...props}
  >
    <defs>
      <style>{'.prefix__cls-1{fill:#4c80bb}'}</style>
    </defs>
    <title>{'HANDSHAKE2'}</title>
    <path
      className='prefix__cls-1'
      d='M409.1 303.87a4.92 4.92 0 00-3-1 5.78 5.78 0 00-.71.05 4.9 4.9 0 00-4.77-3.89 5.31 5.31 0 00-.68 0 4.52 4.52 0 00-.73.16 4.9 4.9 0 00-1.68-2.33 4.83 4.83 0 00-2.95-1 4.74 4.74 0 00-1.67.3 4.93 4.93 0 00-4.56-3.15 5.19 5.19 0 00-.68.05 4.81 4.81 0 00-2.68 1.28l-4.62-.31 3-24.1 5.21.35h.11a1.54 1.54 0 001.37-.84l2-4a2.2 2.2 0 011.94-1.18 1.89 1.89 0 01.58.08l3 .83a1.4 1.4 0 00.41 0 1.55 1.55 0 001.49-1.13 1.5 1.5 0 00-.15-1.17 1.52 1.52 0 00-.93-.73l-3-.83a5 5 0 00-1.39-.19 5.32 5.32 0 00-4.7 2.86l-1.56 3.05-4-.27.08-.63a5 5 0 00-1-3.78 5.12 5.12 0 00-3.4-1.93l-10.43-1.1h-.19a1.54 1.54 0 00-.19 3.07l10.38 1.27a2 2 0 011.35.76 2 2 0 01.42 1.5l-3.56 28.9a2 2 0 01-2 1.78h-.25l-10.36-1.27h-.19a1.55 1.55 0 00-.19 3.08l10.36 1.28h.63a5.13 5.13 0 004.42-2.54l3.26.21a4.9 4.9 0 004.47 6.86h.52a4.89 4.89 0 004.81 4.06 4.41 4.41 0 00.52 0 4.89 4.89 0 004.82 4.07h.51a4.89 4.89 0 004.81 4.07 5.07 5.07 0 00.68 0 4.86 4.86 0 003.23-1.89l2.78-3.68a4.89 4.89 0 00-.94-6.98zm-7.52 7.82a1.76 1.76 0 01.35-1.33l2.78-3.67a1.74 1.74 0 011.19-.7h.25a1.82 1.82 0 011.78 1.56 1.81 1.81 0 01-.35 1.33l-2.78 3.67a1.82 1.82 0 01-1.19.7h-.25a1.76 1.76 0 01-1.08-.36 1.78 1.78 0 01-.7-1.2zm-2.11-3.2a1.8 1.8 0 11-2.87-2.17l2.62-3.47a1.82 1.82 0 011.19-.7h.25a1.79 1.79 0 011.09.37 1.76 1.76 0 01.69 1.19 1.81 1.81 0 01-.34 1.33zm-8.55-4.88a1.76 1.76 0 01.34-1.33l1.93-2.55a1.8 1.8 0 011.44-.71 1.8 1.8 0 011.43 2.89l-1.93 2.54a1.76 1.76 0 01-1.43.72 1.79 1.79 0 01-1.09-.37 1.76 1.76 0 01-.69-1.19zm-5.34-4a1.81 1.81 0 01.35-1.33l1-1.35a1.84 1.84 0 011.19-.7h.25a1.79 1.79 0 011.09.37 1.82 1.82 0 01.35 2.52l-1 1.35a1.74 1.74 0 01-1.19.7h-.25a1.82 1.82 0 01-1.78-1.56z'
      transform='translate(-362.87 -259.31)'
    />
    <path
      className='prefix__cls-1'
      d='M455 296.65a1.55 1.55 0 00-1.53-1.36h-.19L443 296.58h-.25a2 2 0 01-2-1.78l-3.56-28.9a2 2 0 01.41-1.5 2 2 0 011.36-.76l10.35-1.28a1.55 1.55 0 001.35-1.72 1.55 1.55 0 00-1.53-1.35h-.19l-10.36 1.27a5.1 5.1 0 00-4.49 5l-2.37.29-8-4.52a6.6 6.6 0 00-3.25-.85h-.63l-2.43.22a1.54 1.54 0 00-1.4 1.68 1.55 1.55 0 001.54 1.41h.14l2.45-.23h.33a3.54 3.54 0 011.74.46l8.37 4.71a1.54 1.54 0 00.86.25h.18l2.84-.35 2.94 23.91h-4.7a1.56 1.56 0 00-1 .37l-.25.19L419.7 283a16.9 16.9 0 007.65-5.73 1.55 1.55 0 00-1.2-2.52 1.57 1.57 0 00-1.2.57c-2.72 3.35-5.58 5.07-8.49 5.12h-.14c-4.15 0-7.21-3.44-7.24-3.47a1.56 1.56 0 00-1.21-.53 1.51 1.51 0 00-1.18.6l-3.61 4.7a6.8 6.8 0 01-7.84 2.23l-.39-.15a.68.68 0 01-.35-1l9.42-16.22a3.58 3.58 0 012.75-1.76l5.58-.52a1.54 1.54 0 00-.14-3.08H412l-5.58.51a6.69 6.69 0 00-5.14 3.28l-9.42 16.23a3.77 3.77 0 001.93 5.44l.39.15a10 10 0 003.53.65 9.81 9.81 0 007.84-3.89l2.57-3.34a13.48 13.48 0 007.47 3.22l17.5 15.09a1.32 1.32 0 01.47.93 1.36 1.36 0 01-.32 1l-.69.79a2.17 2.17 0 01-1.65.76 2.2 2.2 0 01-1.43-.53l-.18-.13-4.56-3.93a1.54 1.54 0 00-2 2.34l3.55 3.06-1.56 1.81a2.17 2.17 0 01-1.66.76h-.25a2.18 2.18 0 01-1.19-.53l-4.24-3.66a1.55 1.55 0 00-2.18.17 1.54 1.54 0 00.16 2.17l3.09 2.66-1.57 1.83a2.2 2.2 0 01-1.66.76h-.44a1.55 1.55 0 00-1.53 1.37 1.55 1.55 0 001.35 1.71h.62a5.26 5.26 0 004-1.84l1.72-2a5.2 5.2 0 001.51.41h.61a5.25 5.25 0 004-1.83l1.72-2a5.39 5.39 0 002.13.45 5.28 5.28 0 004-1.83l.69-.79a4.47 4.47 0 00-.47-6.28l-.69-.6h3.33a5.13 5.13 0 005 4h.63l10.35-1.28a1.54 1.54 0 001-.58 1.52 1.52 0 00.25-1.1z'
      transform='translate(-362.87 -259.31)'
    />
    <path
      className='prefix__cls-1'
      d='M446.25 286.77a4.15 4.15 0 104.14 4.14 4.15 4.15 0 00-4.14-4.14zm0 5.2a1.06 1.06 0 110-2.11 1.06 1.06 0 010 2.11zM375.67 290.91a4.15 4.15 0 10-4.14 4.15 4.15 4.15 0 004.14-4.15zm-4.14-1.05a1.06 1.06 0 010 2.11 1.06 1.06 0 110-2.11z'
      transform='translate(-362.87 -259.31)'
    />
  </svg>
);

export default HanshakeSvg;
