import { GET_CART_SUCCESS, EMPTY_CART } from '../../constants/ActionTypes';

const INITIAL_STATE = {
  products: []
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CART_SUCCESS: {
      return { ...state, products: action.payload };
    }
    case EMPTY_CART: {
      return { ...state, products: undefined };
    }
    default:
      return state;
  }
};
