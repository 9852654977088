/* eslint-disable import/extensions */
import React from 'react';
import styled from 'styled-components';
import { Icon, Button } from 'antd';
import PlusSvg from '../icons/PlusSvg';

const DisplayImage = styled.img`
  top: 0;
  left: 0;
  width: 100%;
  height: 9vw;
  margin: auto;
`;
const CircleButton = styled(Button)`
  height: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  width: 30px;
  text-align: center;
  margin: 0 ad;
  margin: 0 auto;
  display: block;
  box-sizing: border-box;
  position: absolute; /* width: 100%; */
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  &.ant-btn-circle {
    min-width: unset;
  }
`;
const StyledIcon = styled(Icon)`
  font-size: 1vw;
  @media (max-width: 768px) {
    font-size: 2vw;
  }
`;
const UploadDisplay = (props) => (
  <div>
    {props.image ? (
      <DisplayImage alt='profile' src={props.image} />
    ) : (
      <React.Fragment>
        <CircleButton shape='circle'>
          <StyledIcon component={PlusSvg} />
        </CircleButton>
      </React.Fragment>
    )}
  </div>
);
export default UploadDisplay;
