/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Collapse, Checkbox, Radio, Tooltip, Modal } from 'antd';
import styled from 'styled-components';
import queryString from 'query-string';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import { TiFilter } from 'react-icons/ti';
import ScrollToTop from 'react-scroll-up';
import { getObject } from '../services/CommonServices';
import CustomCollapse from '../elements/CustomCollapse.jsx';

const { Panel } = Collapse;

const customPanelStyle = {
  background: 'white',
  borderRadius: 0,
  padding: 0
};
const SideFiltersMobile = (props) => {
  const [options, setOptions] = React.useState([]);
  const [query, setQuery] = React.useState(props.query);
  const [currentFilter, setCurrentFilter] = React.useState(
    props.query.type || 'sections'
  );
  const [currentFilterValue, setCurrentFilterValue] = React.useState(
    props.query.taxonomy
  );
  const [endOfFilter, setEndOfFilter] = React.useState(false);
  const [endOfFilterParent, setEndOfFilterParent] = React.useState('');
  const [endOfFilterType, setEndOfFilterType] = React.useState('subType');
  const [selectedCheckBoxes, setSelectedCheckBoxes] = React.useState(
    props.query.selected || []
  );
  const [brands, setBrands] = React.useState([]);
  const [selectedBrands, setSelectedBrands] = React.useState(
    props.query.selectedBrands || []
  );
  const [visible, setVisible] = useState(false); // initial state
  const [brandsLoading, setBrandsLoading] = React.useState(false);

  const nextFilterMap = {
    sections: 'categories',
    categories: 'subCategories',
    subCategories: 'types',
    types: 'subTypes'
  };
  const parameterMap = {
    categories: 'section',
    subCategories: 'category',
    types: 'subCategory',
    subTypes: 'type'
  };
  const arabicMap = {
    sections: 'الفئات',
    categories: 'الأقسام',
    subCategories: 'القسم الفرعي',
    types: 'النوع',
    subTypes: 'النوع الفرعي'
  };
  const currentFilterArabicName = arabicMap[currentFilter];

  React.useEffect(() => {
    if (!nextFilterMap[currentFilter]) {
      setEndOfFilterType('subType');
      setEndOfFilter(true);
    }
  }, [props.query]);

  React.useEffect(() => {
    if (!props.brandView) {
      setBrandsLoading(true);
      const params = {
        _id: props.query.company,
        section: props.sectionView && get(props, 'query.sections[0]')
      };
      getObject(`brands/FiltersBrands`, params)
        .then((res) => {
          setBrands(res.data);
          setBrandsLoading(false);
        })
        .catch(() => {
          setBrandsLoading(false);
        });
    }
  }, [props.companySections]);

  React.useEffect(() => {
    if (currentFilter === 'sections' && !props.featured) {
      setOptions(props.companySections);
    } else {
      if (!endOfFilter) {
        const route = currentFilter;
        const params = currentFilterValue
          ? { [parameterMap[currentFilter]]: currentFilterValue }
          : {};
        getObject(`companyProducts/${route}`, params)
          .then((res) => {
            if (res.data.length === 0) setEndOfFilter(true);
            else setOptions(res.data);
          })
          .catch(() => {});
      }
    }
  }, [props.location.search, props.companySections]);

  React.useEffect(() => {
    let query = queryString.parse(props.location.search);
    let queryKey;
    let queryValue;
    const { selectedBrands } = query;
    if (endOfFilter || currentFilter === 'subTypes') {
      queryKey = 'subType';
      queryValue = Array.isArray(currentFilterValue)
        ? currentFilterValue
        : [currentFilterValue];
      if (!query.selected) queryValue = options.map((o) => o._id);
    } else {
      queryKey = parameterMap[currentFilter];
      queryValue = Array.isArray(query.taxonomy)
        ? query.taxonomy
        : [query.taxonomy];
    }
    if (query.selected) {
      queryValue = Array.isArray(selectedCheckBoxes)
        ? selectedCheckBoxes
        : [selectedCheckBoxes];
    }

    query = { [queryKey]: queryValue };
    delete query.taxonomy;
    const route = props.countRoute;
    getObject(`companyProducts/${route}`, {
      buyer: props.buyer,
      count: true,
      zone: props.zone,
      featured: props.featured,
      query,
      company: props.query.company,
      brand: (props.query.brand || []).concat(selectedBrands || [])
    }).then((response) => {
      props.setFilteredProductsCount(get(response, 'data.total', 0));
    });
  }, [query, props.query.company, options, props.zone]);

  React.useEffect(() => {
    let query = queryString.parse(props.location.search);
    let queryKey;
    let queryValue;
    const { selectedBrands } = query;

    if (endOfFilter || currentFilter === 'subTypes') {
      queryKey = 'subType';
      queryValue = Array.isArray(currentFilterValue)
        ? currentFilterValue
        : [currentFilterValue];
      queryKey = 'subType';
      if (!query.selected) queryValue = options.map((o) => o._id);
    } else {
      queryKey = parameterMap[currentFilter];
      queryValue = Array.isArray(query.taxonomy)
        ? query.taxonomy
        : [query.taxonomy];
    }
    if (query.selected) {
      queryValue = Array.isArray(selectedCheckBoxes)
        ? selectedCheckBoxes
        : [selectedCheckBoxes];
    }

    // console.log(query, 'normal', 'currentFilterValue', currentFilterValue);
    // console.log(Number(props.page), 'Number(props.page)');
    query = { [queryKey]: queryValue };

    delete query.taxonomy;
    const { route } = props;
    getObject(`companyProducts/${route}`, {
      buyer: get(props, 'buyer'),
      zone: get(props, 'zone'),
      company: props.query.company,
      featured: props.featured,
      brand: (props.query.brand || []).concat(selectedBrands || []),
      query,
      skip: Number(props.page),
      limit: 6
    })
      .then((response) => {
        props.setFilteredProducts(get(response, 'data.newSortedProducts', []));
        props.setProductsLoaded(true);
      })
      .catch(() => {
        props.setProductsLoaded(true);
        if (!props.internetError) props.setInternetError(true);
      });
  }, [query, props.page, props.query.company, options, props.zone]);

  function updateUrl(newQuery) {
    switch (currentFilter) {
      case 'sections':
        ['categories', 'subCategories', 'types'].forEach(
          (taxonomy) => delete newQuery[taxonomy]
        );
        break;
      case 'categories':
        newQuery.sections = props.query.sections;
        ['subCategories', 'types'].forEach(
          (taxonomy) => delete newQuery[taxonomy]
        );
        break;

      case 'subCategories':
        newQuery.sections = props.query.sections;
        newQuery.categories = props.query.categories;
        delete newQuery.types;
        break;
      case 'types':
        newQuery.sections = props.query.sections;
        newQuery.categories = props.query.categories;
        newQuery.subCategories = props.query.subCategories;
        break;
      default:
        break;
    }

    return newQuery;
  }

  function onChange(checkedValue, title) {
    props.setPage(1);
    let newQuery = {};
    let array = [];
    let selected = [];
    // let flag = false;
    if (endOfFilter) {
      if (Array.isArray(currentFilterValue)) array = currentFilterValue;
      // selected = [...array];
      else {
        array = [];
        // flag = true;
        setEndOfFilterParent(currentFilterValue);
      }
      if (checkedValue.target.checked) {
        array.push(checkedValue.target.value);
        selected = [...array];
        setCurrentFilterValue(array);
        newQuery = {
          taxonomy: array,
          type: nextFilterMap[currentFilter] || currentFilter,
          company: props.query.company,
          isDeleted: false,
          featured: props.featured ? true : undefined,
          brand: props.query.brand,
          page: 1
        };
      } else {
        const index = array.indexOf(checkedValue.target.value);
        const splicedCount = index === -1 ? 0 : 1;
        array.splice(index, splicedCount);
        selected = [...array];
        const filter =
          array.length === 0
            ? endOfFilterType
            : nextFilterMap[currentFilter] || currentFilter;
        array = array.length === 0 ? endOfFilterParent : array;
        setCurrentFilterValue(array);
        newQuery = {
          taxonomy: array,
          type: filter,
          company: props.query.company,
          featured: props.featured,
          brand: props.query.brand,
          page: 1
        };
      }
      setSelectedCheckBoxes(array);
    } else {
      setCurrentFilterValue(checkedValue.target.value);
      setCurrentFilter(nextFilterMap[currentFilter]);
      newQuery = {
        taxonomy: [checkedValue.target.value],
        type: nextFilterMap[currentFilter],
        company: props.query.company,
        featured: props.featured,
        brand: props.query.brand,
        page: 1
      };
    }
    setQuery(newQuery);

    if (!endOfFilter) {
      delete newQuery.selected;
      newQuery = updateUrl(newQuery);
      newQuery[currentFilter] = [
        checkedValue.target.value,
        nextFilterMap[currentFilter],
        title
      ];
      const search = queryString.stringify(newQuery);
      props.history.push({
        pathname: props.companyView
          ? '/company'
          : props.sectionView
          ? '/section'
          : props.featured
          ? '/featured'
          : '/brand',
        search
      });
      window.location.reload();
    } else {
      const newQuery = Object.assign({}, props.query);
      newQuery.selected = selected;
      const search = queryString.stringify(newQuery);
      props.history.push({
        pathname: props.companyView
          ? '/company'
          : props.sectionView
          ? '/section'
          : props.featured
          ? '/featured'
          : '/brand',
        search
      });
    }
  }

  const selectedChecks = Array.isArray(selectedCheckBoxes)
    ? selectedCheckBoxes
    : [selectedCheckBoxes];

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  const selectBrand = (value) => {
    const selected = [...[selectedBrands].flat()];
    const index = selected.findIndex((e) => e === value);
    if (index === -1) selected.push(value);
    else {
      selected.splice(index, 1);
    }
    setSelectedBrands(selected);
    const newQuery = queryString.parse(props.location.search);
    newQuery.selectedBrands = selected;
    const search = queryString.stringify(newQuery);
    props.history.push({
      pathname: props.location.path,
      search
    });
    window.location.reload();
  };
  return (
    <React.Fragment>
      <SliderFilterMobileHolder>
        <div visibile={visible}>
          <div onClick={showModal}>
            <ScrollToTop
              showUnder={440}
              style={{
                position: 'fixed',
                top: 100,
                right: 30,
                bottom: 'inherit',
                cursor: 'pointer',
                zIndex: 1,
                transitionDuration: '0.2s',
                transitionTimingFunction: 'linear',
                transitionDelay: '0s'
              }}
            >
              <SliderFilterIcon>
                <TiFilter />
              </SliderFilterIcon>
            </ScrollToTop>
          </div>
          <SliderFilterMobileModal
            className='for-mobile'
            title='تصفيه النتائج'
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <CustomCollapse defaultActiveKey={['1']}>
              {
                <Panel
                  header={currentFilterArabicName}
                  key='1'
                  style={customPanelStyle}
                >
                  <Checklist>
                    {' '}
                    <div style={{ width: '100%', padding: '0' }}>
                      {options.map((entry, index) => (
                        <Box>
                          <ChecklistItem notlast={index !== options.length - 1}>
                            {endOfFilter ? (
                              <StyledCheckbox
                                key={get(entry, '_id')}
                                className={get(entry, '_id')}
                                value={get(entry, '_id')}
                                defaultChecked={
                                  !!selectedChecks.find(
                                    (s) => s === get(entry, '_id')
                                  )
                                }
                                onChange={(checkedValues) =>
                                  onChange(
                                    checkedValues,
                                    get(entry, 'name.ar', '')
                                  )
                                }
                              >
                                {get(entry, 'name.ar', '').length > 30 ? (
                                  <Tooltip
                                    placement='bottom'
                                    title={get(entry, 'name.ar', '')}
                                  >
                                    {`${get(entry, 'name.ar', '').substring(
                                      0,
                                      30
                                    )}...`}
                                  </Tooltip>
                                ) : (
                                  get(entry, 'name.ar', '')
                                )}
                              </StyledCheckbox>
                            ) : (
                              <StyledRadioButton
                                key={get(entry, '_id')}
                                className={get(entry, '_id')}
                                value={get(entry, '_id')}
                                size={'large'}
                                onChange={(checkedValues) =>
                                  onChange(
                                    checkedValues,
                                    get(entry, 'name.ar', '')
                                  )
                                }
                              >
                                {get(entry, 'name.ar', '').length > 30 ? (
                                  <Tooltip
                                    placement='bottom'
                                    title={get(entry, 'name.ar', '')}
                                  >
                                    {`${get(entry, 'name.ar', '').substring(
                                      0,
                                      30
                                    )}...`}
                                  </Tooltip>
                                ) : (
                                  get(entry, 'name.ar', '')
                                )}
                              </StyledRadioButton>
                            )}
                            {/* </a> */}
                          </ChecklistItem>
                          <br style={{ display: 'contents' }} />
                        </Box>
                      ))}
                    </div>
                  </Checklist>
                </Panel>
              }
            </CustomCollapse>
            <CustomCollapse defaultActiveKey={['1']}>
              {!props.brandView && (
                <Panel header={'الماركة'} key='1' style={customPanelStyle}>
                  <Checklist>
                    {' '}
                    <div style={{ width: '100%', padding: '0' }}>
                      {brands.map((entry, index) => (
                        <Box>
                          <ChecklistItem notlast={index !== brands.length - 1}>
                            <StyledCheckbox
                              key={get(entry, '_id')}
                              className={get(entry, '_id')}
                              value={get(entry, '_id')}
                              defaultChecked={
                                !![selectedBrands]
                                  .flat()
                                  .find((s) => s === get(entry, '_id'))
                              }
                              onChange={
                                (e) => selectBrand(e.target.value)
                                // onChange(checkedValues, get(entry, 'name.ar', ''))
                              }
                            >
                              {get(entry, 'name.ar', '').length > 30 ? (
                                <Tooltip
                                  placement='bottom'
                                  title={get(entry, 'name.ar', '')}
                                >
                                  {`${get(entry, 'name.ar', '').substring(
                                    0,
                                    30
                                  )}...`}
                                </Tooltip>
                              ) : (
                                get(entry, 'name.ar', '')
                              )}
                            </StyledCheckbox>
                          </ChecklistItem>
                          <br style={{ display: 'contents' }} />
                        </Box>
                      ))}
                    </div>
                  </Checklist>
                </Panel>
              )}
            </CustomCollapse>
          </SliderFilterMobileModal>
        </div>
      </SliderFilterMobileHolder>
    </React.Fragment>
  );
};

// const StyledIcon = styled(Icon)`
//   &.ant-collapse
//     > .ant-collapse-item
//     > .ant-collapse-header
//     .ant-collapse-arrow {
//     font-size: 2vw;
//   }
//   font-weight: normal;
//   path {
//     width: 2vw;
//     height: 2vw;
//     @media (max-width: 768px) {
//       width: 4vw;
//       height: 4vw;
//     }
//     fill: ${(props) => props.theme.primary};
//   }
// `;
// const CheckBoxGroup = styled(Checkbox.group)`
//     & .ant-checkbox-group-item {
//         display: block;
//         margin-right: 0;
// }
// `
const Checklist = styled.div`
  width: 100%;
  padding-top: 10%;
  @media (max-width: 767px) {
    padding: 10px 0;
  }
  overflow-y: scroll;
`;
const ChecklistItem = styled.div`
  ${(props) => (props.notlast ? `border-bottom: solid 0.5px #11192d;` : ``)}
  /* display: flex;
    justify-content:center; */
  
  margin: auto;
  width: 100%;
  height: 2.9vw;
  @media (max-width: 768px) {
    height: 30px;
  }
  @media (max-width: 768px) {
    height: 40px;
  }
`;
const StyledRadioButton = styled(Radio)`
  /* display: flex;
    justify-content:end; */
  float: right;
  height: 1.7vw;
  &.ant-radio-wrapper {
    color: #11192d;
    font-size: 15px;
    text-align: right;
    line-height: unset;
    @media (max-width: 768px) {
      font-size: 20px;
    }

    @media (max-width: 414px) {
      font-size: 15px;
    }
  }
  .ant-radio {
    line-height: unset;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  /* display: flex;
    justify-content:end; */
  float: right;
  height: 1.7vw;
  &.ant-checkbox-wrapper {
    color: #11192d;
    font-size: 18px;
    line-height: unset;
    text-align: right;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
`;
const Box = styled.div`
  height: auto;
  padding: 1vw 0vw;
  @media (max-width: 768px) {
    padding: 10px 0;
  }
`;

const SliderFilterMobileHolder = styled.div`
  .ant-collapse-header {
    color: #4a7fbd !important;
  }
`;

const SliderFilterIcon = styled.div`
  width: 65px;
  height: 65px;
  line-height: 77px;
  background-color: #4a7fbd;
  color: #fff;
  text-align: center;
  font-size: 33px;
  border-radius: 50%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
`;

const SliderFilterMobileModal = styled(Modal)`
  .ant-radio {
    line-height: unset;
    margin: -2px;
    margin-left: 1px;
  }

  @media (max-width: 767px) {
    .ant-modal-title {
      color: rgb(74, 127, 189);

      text-align: right;
      font-size: 18px;
      font-family: 'Cairo-Bold';
    }

    .ant-collapse > .ant-collapse-item {
      border-bottom: 1px solid #666b79;
    }
    ant-modal-header {
      border-radius: 0;
    }

    .ant-modal-close {
      left: 10px;
      right: inherit;
      top: 0;
    }

    button.ant-modal-close {
      border-radius: 0;
      float: inherit;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      padding: 12px 0;
    }
    .ant-modal-close-x {
      display: block;
      width: 35px;
      height: 35px;
      font-size: 20px;
      font-style: normal;
      line-height: 33px;
      text-align: center;
      text-transform: none;
      text-rendering: auto;
      background: #4a7fbd;
      color: #fff;
      margin-left: 9px;
    }
    .ant-modal-footer {
      display: none !important;
    }

    .ant-collapse
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-arrow {
      font-size: 20px;
    }

    .ant-modal-content
      .ant-modal-content
      .ant-collapse
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-arrow {
      font-size: 20px;
      border-radius: 0;
    }
  }
`;

export default withRouter(SideFiltersMobile);
