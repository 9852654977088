import { Collapse } from 'antd';
import styled from 'styled-components';

const CustomSelect = styled(Collapse)`
margin:20px 0;

@media (max-width:768px) {
  margin-bottom: 40px;
  margin-top:0;

}
  width: 100%;
  align-self: center;
  border:unset;
  background-color:white;
  line-height : 1.1vw;
  padding:0!important;
  & .ant-collapse-header {
      height:100%;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      line-height:0;
      letter-spacing: normal;
      text-align: right;
      color: #11192d;
      align-self: center;
      padding:0;
      padding-top:6%!important;
      border-bottom:solid 1px #11192d;
  }
  & .ant-collapse-content-box {
      padding:0;
  
}
  & .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding:0
  }
  /* & .ant-select-selection {
    color: ${(props) => props.theme.accent};
    background-color: white;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    height: 100%;
    border-right-color: transparent;
    border-left-color: transparent;
    border-top-style: solid 1px #979797;
    border-top-color: ${(props) => (props.notShow ? 'transparent' : '')};
    text-align: right;
    border-radius: 0px;
    direction: rtl;
  } */
  /* & .ant-select-selection__placeholder {
    text-align: right;
    height: 10vh;
    padding-left: 0px;
    font-size: 20px;
    width: 100%;
  } */
`;

export default CustomSelect;
