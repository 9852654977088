/* eslint-disable import/extensions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Pagination, Spin, message, Modal } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { QrcodeOutlined } from '@ant-design/icons';
import { updateUser } from '../appRedux/actions/Auth';
import { getGeneric } from '../appRedux/actions/Generic';
import OrderProduct from './OrderHistoryProduct';
import CustomButton from '../elements/CustomButton';
import { updateObject, getObject } from '../services/CommonServices';

const QRCode = require('qrcode.react');

const TabStyleLeft = { color: '#4a7fbd', fontWeight: 800 };

const OrderHistory = () => {
  const [tab, setTab] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [doneOrders, setdoneOrders] = useState([]);
  const [pendingOrders, setpendingOrders] = useState([]);
  const [rejectedOrCancelled, setrejectedOrCancelled] = useState([]);
  const [doneOrdersCount, setdoneOrdersCount] = useState(0);
  const [pendingOrdersCount, setpendingOrdersCount] = useState(0);
  const [rejectedOrCancelledCount, setrejectedOrCancelledCount] = useState(0);
  const [pendingPage, setpendingPage] = useState(1);
  const [donePage, setdonePage] = useState(1);
  const [rejectedPage, setrejectedPage] = useState(1);
  const [doneLoading, setDoneLoading] = useState(true);
  const [pendingLoading, setPendingLoading] = useState(true);
  const [rejectedLoading, setRejectedLoading] = useState(true);
  const [internetError, setInternetError] = useState(false);
  const [visible, setVisible] = useState(false);
  const [orderId, setOrderId] = useState('');

  const rejectedCases = [5, 6];
  const doneCases = [4, 7];
  const pendingCases = [0, 1, 2, 3, 8];
  const limit = 3;
  useEffect(() => {
    getObject('orders/buyerOrdersCount', { orderStatus: doneCases })
      .then((res) => {
        setdoneOrdersCount(res.data.count);
      })
      .catch(() => setInternetError(true));
    getObject('orders/buyerOrdersCount', { orderStatus: pendingCases })
      .then((res) => {
        setpendingOrdersCount(res.data.count);
      })
      .catch(() => setInternetError(true));
    getObject('orders/buyerOrdersCount', { orderStatus: rejectedCases })
      .then((res) => {
        setrejectedOrCancelledCount(res.data.count);
      })
      .catch(() => setInternetError(true));
  }, []);
  useEffect(() => {
    if (internetError) message.error('تعثر الاتصال بالانترنت');
  }, [internetError]);
  useEffect(() => {
    if (tab === 0) {
      setPendingLoading(true);
      setRejectedLoading(true);
      getObject('orders/buyerOrders', {
        page: Number(donePage),
        limit: Number(limit),
        orderStatus: doneCases
      })
        .then((res) => {
          setdoneOrders(res.data);
          setDoneLoading(false);
        })
        .catch(() => setDoneLoading(false));
    }
  }, [tab, donePage, refresh]);
  useEffect(() => {
    if (tab === 1) {
      setDoneLoading(true);
      setRejectedLoading(true);
      getObject('orders/buyerOrders', {
        page: Number(pendingPage),
        limit: Number(limit),
        orderStatus: pendingCases
      })
        .then((res) => {
          setpendingOrders(res.data);
          setPendingLoading(false);
        })
        .catch(() => setPendingLoading(false));
    }
  }, [tab, pendingPage]);
  useEffect(() => {
    if (tab === 2) {
      setPendingLoading(true);
      setDoneLoading(true);
      getObject('orders/buyerOrders', {
        page: Number(rejectedPage),
        limit: Number(limit),
        orderStatus: rejectedCases
      })
        .then((res) => {
          setrejectedOrCancelled(res.data);
          setRejectedLoading(false);
        })
        .catch(() => setRejectedLoading(false));
    }
  }, [tab, rejectedPage]);

  const handleConfirmDeliever = (order) => {
    if (Number(order.status) === 4) {
      updateObject('orders/confirmDelivery', { _id: order._id })
        .then(() => {
          setTab(0);
          setRefresh(!refresh);
        })
        .catch(() => {});
    }
  };
  const calculatePrice = (products) => {
    const total = products.reduce((a, b) => {
      return a + Number(b.total);
    }, 0);
    return parseFloat(total).toFixed(2);
  };

  return (
    <React.Fragment>
      <Modal
        style={{ textAlign: 'center' }}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        {' '}
        <QRCode value={orderId} />{' '}
      </Modal>
      <TabsArea>
        <TabAreaRow>
          <Col span={8}>
            <Tab
              onClick={() => {
                setTab(2);
              }}
              style={tab === 2 ? TabStyleLeft : undefined}
            >
              مرفوضة
            </Tab>
          </Col>
          <Col span={8}>
            <Tab
              onClick={() => {
                setTab(1);
              }}
              style={{ borderLeft: '1px solid' }}
            >
              <div style={tab === 1 ? TabStyleLeft : undefined}>
                قيد الانتظار
              </div>
            </Tab>
          </Col>
          <Col span={8}>
            <Tab
              style={{ borderLeft: '1px solid' }}
              onClick={() => {
                setTab(0);
              }}
            >
              <div style={tab === 0 ? TabStyleLeft : undefined}> تمت</div>
            </Tab>
          </Col>
        </TabAreaRow>
      </TabsArea>
      {tab === 0 ? (
        doneLoading ? (
          <Spin />
        ) : doneOrders.length ? (
          <div>
            {doneOrders.map((order) => (
              <OrdersContainer>
                <OrdersHeaderArea>
                  <Row
                    style={{ paddingBottom: '5%' }}
                    type='flex'
                    justify='center'
                  >
                    <Col span={2}>
                      <QrcodeOutlined
                        onClick={() => {
                          setVisible(true);
                          setOrderId(order._id);
                        }}
                        style={{ fontSize: '30px' }}
                      />
                    </Col>
                  </Row>
                  <RowHolder>
                    <Col lg={4} xs={12} sm={12} className='borderleftMobile'>
                      <OrderHeaderTitle>تاريخ الإستلام : </OrderHeaderTitle>
                      <div>
                        {order.deliveryDate
                          ? moment(order.deliveryDate).format('YYYY/MM/DD')
                          : 'لم يحدد بعد'}
                      </div>
                    </Col>
                    <Col
                      lg={4}
                      xs={12}
                      sm={12}
                      className='borderleftMobileAndWeb'
                    >
                      <OrderHeaderTitle> تاريخ الطلب: </OrderHeaderTitle>
                      <div>{moment(order.createdAt).format('YYYY/MM/DD')}</div>
                    </Col>
                    <Col
                      lg={4}
                      sm={12}
                      xs={12}
                      style={{ borderLeft: '1px solid black' }}
                    >
                      <OrderHeaderTitle> الاجمالي : </OrderHeaderTitle>
                      <div>{`${calculatePrice(order.products)} `}جم</div>
                    </Col>
                    <Col
                      lg={7}
                      sm={12}
                      xs={12}
                      style={{ borderLeft: '1px solid black' }}
                    >
                      <OrderHeaderTitle> رقم الطلب: </OrderHeaderTitle>
                      <div>{order.orderId}</div>
                    </Col>
                    <Col lg={4} xs={24} sm={24} className='borderleftWeb'>
                      <OrderHeaderTitle> الحالة: </OrderHeaderTitle>
                      <div>
                        {Number(order.status) === 0
                          ? 'قيد الانتظار'
                          : Number(order.status) === 1
                          ? 'يتم التجهيز'
                          : Number(order.status) === 2
                          ? 'جاهز للتوصيل'
                          : Number(order.status) === 3
                          ? 'تم الشحن'
                          : Number(order.status) === 4
                          ? 'تأكيد الاستلام '
                          : Number(order.status) === 5
                          ? 'تم الإلغاء'
                          : Number(order.status) === 6
                          ? 'مرفوض'
                          : Number(order.status) === 7
                          ? 'تم الاستلام'
                          : Number(order.status) === 8
                          ? 'في الطريق '
                          : ''}
                      </div>
                    </Col>
                  </RowHolder>
                </OrdersHeaderArea>                  
                     <SubmitRow>
                        <CustomButton
                          width={'33%'}
                          style={{ borderRadius: '0', alignSelf: 'center' }}
                          disabled={Number(order.status) !== 4}
                          onClick={() => {
                            handleConfirmDeliever(order);
                          }}
                        >
                          تم الاستلام
                        </CustomButton>
                      </SubmitRow>
                <RowHolder style={{ paddingBottom: '3vw' }}>
                  {order.products.map((product) => (
                    <CustomCol lg={8} xs={12}>
                      <OrderProduct product={product} />
                    </CustomCol>
                  ))}
                </RowHolder>
              </OrdersContainer>
            ))}
            <StyledPagination
              style={{
                direction: 'rtl',
                textAlign: 'center',
                margin: '30px 0'
              }}
              defaultCurrent={1}
              total={Number(doneOrdersCount)}
              pageSize={Number(limit)}
              current={Number(donePage)}
              onChange={(val) => setdonePage(Number(val))}
            />
          </div>
        ) : (
          <Text> لا يوجد طلبات منتهية</Text>
        )
      ) : tab === 1 ? (
        pendingLoading ? (
          <Spin />
        ) : pendingOrders.length ? (
          <div>
            {pendingOrders.map((order) => (
              <OrdersContainer>
                <OrdersHeaderArea>
                  <Row
                    style={{ paddingBottom: '5%' }}
                    type='flex'
                    justify='center'
                  >
                    <Col span={2}>
                      <QrcodeOutlined
                        onClick={() => {
                          setVisible(true);
                          setOrderId(order._id);
                        }}
                        style={{ fontSize: '30px' }}
                      />
                    </Col>
                  </Row>
                  <RowHolder>
                    <Col lg={4} xs={12} sm={12} className='borderleftMobile'>
                      <OrderHeaderTitle>تاريخ الإستلام : </OrderHeaderTitle>
                      <div>
                        {order.deliveryDate
                          ? moment(order.deliveryDate).format('YYYY/MM/DD')
                          : 'لم يحدد بعد'}
                      </div>
                    </Col>
                    <Col lg={4} xs={12} sm={12} className='borderleftWeb'>
                      <OrderHeaderTitle> تاريخ الطلب: </OrderHeaderTitle>
                      <div>{moment(order.createdAt).format('YYYY/MM/DD')}</div>
                    </Col>
                    <Col
                      lg={4}
                      sm={12}
                      xs={12}
                      style={{ borderLeft: '1px solid black' }}
                    >
                      <OrderHeaderTitle> الاجمالي : </OrderHeaderTitle>
                      <div>{`${calculatePrice(order.products)} `}جم</div>
                    </Col>
                    <Col
                      lg={8}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ borderLeft: '1px solid black' }}
                    >
                      <OrderHeaderTitle> رقم الطلب: </OrderHeaderTitle>
                      <div>{order.orderId}</div>
                    </Col>
                    <Col lg={4} xs={24} sm={24} className='borderleftWeb'>
                      <OrderHeaderTitle> الحالة: </OrderHeaderTitle>
                      <div>
                        {Number(order.status) === 0
                          ? 'قيد الانتظار'
                          : Number(order.status) === 1
                          ? 'يتم التجهيز'
                          : Number(order.status) === 2
                          ? 'جاهز للتوصيل'
                          : Number(order.status) === 3
                          ? 'تم الشحن'
                          : Number(order.status) === 4
                          ? 'تأكيد الاستلام '
                          : Number(order.status) === 5
                          ? 'تم الإلغاء'
                          : Number(order.status) === 6
                          ? 'مرفوض'
                          : Number(order.status) === 7
                          ? 'تم الاستلام'
                          : Number(order.status) === 8
                          ? 'في الطريق '
                          : ''}
                      </div>
                    </Col>
                  </RowHolder>
                </OrdersHeaderArea>
                <RowHolder style={{ paddingBottom: '3vw' }}>
                  {order.products.map((product) => (
                    <CustomCol lg={8} md={12} sm={8} xs={12}>
                      <OrderProduct product={product} />
                    </CustomCol>
                  ))}
                </RowHolder>
              </OrdersContainer>
            ))}
            <StyledPagination
              style={{
                direction: 'rtl',
                textAlign: 'center',
                margin: '30px 0'
              }}
              defaultCurrent={1}
              total={Number(pendingOrdersCount)}
              pageSize={Number(limit)}
              current={Number(pendingPage)}
              onChange={(val) => setpendingPage(Number(val))}
            />
          </div>
        ) : (
          <Text> لا يوجد طلبات قيد الانتظار</Text>
        )
      ) : rejectedLoading ? (
        <Spin />
      ) : rejectedOrCancelled.length ? (
        <div>
          {' '}
          {rejectedOrCancelled.map((order) => (
            <OrdersContainer>
              <OrdersHeaderArea>
                <Row
                  style={{ paddingBottom: '5%' }}
                  type='flex'
                  justify='center'
                >
                  <Col span={2}>
                    <QrcodeOutlined
                      onClick={() => {
                        setVisible(true);
                        setOrderId(order._id);
                      }}
                      style={{ fontSize: '30px' }}
                    />
                  </Col>
                </Row>
                <RowHolder>
                  <Col lg={6} xs={12} sm={12}>
                    <OrderHeaderTitle> تاريخ الطلب: </OrderHeaderTitle>
                    <div>{moment(order.createdAt).format('YYYY/MM/DD')}</div>
                  </Col>
                  <Col
                    lg={4}
                    sm={12}
                    xs={12}
                    style={{ borderLeft: '1px solid black' }}
                  >
                    <OrderHeaderTitle> الاجمالي : </OrderHeaderTitle>
                    <div>{`${calculatePrice(order.products)} `}جم</div>
                  </Col>
                  <Col
                    lg={8}
                    xs={12}
                    md={12}
                    sm={12}
                    style={{ borderLeft: '1px solid black' }}
                  >
                    <OrderHeaderTitle> رقم الطلب: </OrderHeaderTitle>
                    <div>{order.orderId}</div>
                  </Col>
                  <Col
                    lg={6}
                    xs={12}
                    md={12}
                    sm={12}
                    style={{ borderLeft: '1px solid black' }}
                  >
                    <OrderHeaderTitle> الحالة: </OrderHeaderTitle>
                    <div>
                      {Number(order.status) === 0
                        ? 'قيد الانتظار'
                        : Number(order.status) === 1
                        ? 'يتم التجهيز'
                        : Number(order.status) === 2
                        ? 'جاهز للتوصيل'
                        : Number(order.status) === 3
                        ? 'تم الشحن'
                        : Number(order.status) === 4
                        ? 'تأكيد الاستلام '
                        : Number(order.status) === 5
                        ? 'تم الإلغاء'
                        : Number(order.status) === 6
                        ? 'مرفوض'
                        : Number(order.status) === 7
                        ? 'تم الاستلام'
                        : Number(order.status) === 8
                        ? 'في الطريق '
                        : ''}
                    </div>
                  </Col>
                </RowHolder>
              </OrdersHeaderArea>
              <RowHolder style={{ paddingBottom: '3vw' }}>
                {order.products.map((product) => (
                  <CustomCol lg={8} xs={12}>
                    <OrderProduct product={product} />
                  </CustomCol>
                ))}
              </RowHolder>
            </OrdersContainer>
          ))}
          <StyledPagination
            style={{
              direction: 'ltr',
              textAlign: 'center',
              margin: '30px 0'
            }}
            defaultCurrent={1}
            total={Number(rejectedOrCancelledCount)}
            pageSize={Number(limit)}
            current={Number(rejectedPage)}
            onChange={(val) => setrejectedPage(Number(val))}
          />
        </div>
      ) : (
        <Text> لا يوجد طلبات مرفوضة أو ملغاه </Text>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = ({ main, auth }) => ({ main, auth });
export default connect(mapStateToProps, { updateUser, getGeneric })(
  OrderHistory
);

const Text = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.primary};
  margin-bottom: 2vw;
  margin-top: 2vw;
  text-align: center;
`;

const TabsArea = styled.div`
  background-color: #11192d;
  padding: 10px 0;

  @media (max-width: 414px) {
    margin: 30px 0;
  }
`;
const Tab = styled.div`
  font-size: 18px;
  font-family: 'Cairo-SemiBold';
  font-style: normal;
  color: #ffffff;
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer;
  @media (max-width: 767px) {
    font-size: 15px;
  }
`;

const CustomCol = styled(Col)`
  float: right;
  padding-right: 1vw;
  padding-left: 1vw;
  margin-top: 2vw;
  border-left: 1px solid black;
  @media (max-width: 768px) {
    height: 350px;
  }

  @media (max-width: 768px) {
    :last-child {
      border-left: 0;
    }
  }
`;

const OrdersHeaderArea = styled.div`
  .borderleftWeb {
    border-left: 1px solid #000000;
  }
  .borderleftMobileAndWeb {
    border-left: 1px solid #000000;
  }
  width: 100%;
  background-color: #e1e5ea;
  padding-top: 2vw;
  padding-bottom: 2vw;
  font-size: 16px;
  color: #10182d;
  font-weight: bold;
  font-style: normal;
  text-align: center;
  line-height: 28px;
  @media (max-width: 414px) {
    font-size: 15px;
    .borderleftMobile {
      border-left: 1px solid #000000;
    }
    .borderleftWeb {
      border-left: 0;
      margin: 10px 0;
    }
  }
`;
const OrderHeaderTitle = styled.div`
  font-size: 15px;
  font-style: normal;
  color: black;
  text-align: center;
  font-weight: 100;
  @media (max-width: 414px) {
    font-size: 14px;
  }
`;
const OrdersContainer = styled.div`
  background-color: white;
  border: 1px solid black;
  margin: 40px 0;

  @media (max-width: 414px) {
    margin: 20px 0;
    padding: 0;
  }
`;

const TabAreaRow = styled(Row)`
  display: inherit !important;
  flex-wrap: inherit !important;
  display: inherit !important;
  flex-direction: inherit !important;
`;

const SubmitRow = styled.div`
  display: flex;
  justify-content: center;
`;

const RowHolder = styled(Row)`
  flex-wrap: inherit !important;
  display: inherit !important;
  flex-direction: inherit !important;
`;
const StyledPagination = styled(Pagination)`
  .ant-pagination-item-active,
  .ant-pagination-item,
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-color: #10182d;
  }
  a.ant-pagination-item-link i.anticon.anticon-right svg {
    transform: rotate(-180deg);
  }
  .ant-pagination-item-active a {
    color: #4a7fbd !important;
    font-weight: bold;
  }

  .ant-pagination-item a,
  .ant-pagination-item {
    color: #10182d;
  }

  .ant-pagination-item a :hover,
  .ant-pagination-item:hover {
    color: red !important;
    font-weight: bold;
  }

  .ant-pagination-item {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    margin-right: 0.58vw;
    outline: 0;
    font-size: 17px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;

    @media (max-width: 414px) {
      display: inline-block;
      min-width: 25px;
      height: 25px;
      margin-right: 0.58vw;
      outline: 0;
      font-size: 15px;
      margin-left: 3px;
      margin-right: 3px;
      cursor: pointer;
      padding: 0;
      line-height: 23px;
    }
  }
  .ant-pagination-next {
    display: inline-block;
    min-width: 32px;
    line-height: 32px;
    margin-left: 10px;
    border-radius: 0.29vw;
  }
  .ant-pagination-prev {
    display: inline-block;
    height: 32px;
    margin-right: 10px;
    line-height: 32px;
    border-radius: 0.29vw;
  }
  .ant-pagination-item-link {
    font-size: 1vw;
    @media (max-width: 768px) {
      font-size: 20px;
    }
    @media (min-width: 2560px) {
      font-size: 0.5vw;
    }
  }
`;

// display: flex !important; flex-direction: row-reverse !important; flex-direction: row-reverse !important; flex-wrap: wrap-reverse
