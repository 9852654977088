/* eslint-disable import/extensions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Row, Col, Icon, Select, Input, Form, Checkbox, message } from 'antd';
import styled from 'styled-components';
import { get } from 'lodash';
import { connect } from 'react-redux';
import EditSvg from '../icons/EditSvg';
import CheckoutProducts from '../components/CheckoutProducts';
import DropdownSvg from '../icons/DropdownSvg';
import { getGeneric } from '../appRedux/actions/Generic';
import { updateUser } from '../appRedux/actions/Auth';
import {
  postObject,
  getObject,
  productQuantityPricesTotal
} from '../services/CommonServices';
import CustomButton from '../elements/CustomButton';

const { Option } = Select;

const filterCities = (selectedCountry, props) =>
  get(props, 'main.cities', []).filter(
    (city) => String(get(city, 'country._id', '')) === String(selectedCountry)
  );
const filterDistricts = (selectedCity, props) =>
  get(props, 'main.districts.data', []).filter(
    (district) => String(get(district, 'city._id', '')) === String(selectedCity)
  );
const filterZones = (selectedDistrict, props) =>
  get(props, 'main.zones.data', []).filter(
    (zone) => String(get(zone, 'district._id', '')) === String(selectedDistrict)
  );
const handleSubmit = (e, props, sameAsShipping, tempOrders, usedPoints) => {
  e.preventDefault();
  const { form } = props;
  form.validateFields((err, fieldsValue) => {
    if (err) {
      message.error('من فضلك أدخل بيانات الشحن');
      return;
    }
    if (sameAsShipping) {
      fieldsValue.user.billing = fieldsValue.user.shipping;
    }
    postObject('orders', {
      shipping: fieldsValue.user.shipping,
      billing: fieldsValue.user.billing,
      tempOrders, 
      usedPoints,
    }).then(() => {
      message.success('تم اكتمال الطلب!');
      props.updateUser();
      props.history.push('/');
    });
  });
};

const displayTotal = (orders, props) => {
  let total = 0;
  orders.forEach((order) => {
    get(order, 'products', []).forEach((productEntry) => {
      total += get(productEntry, 'product.addOnProduct')
        ? 0
        : Number(productQuantityPricesTotal(productEntry, props, 'checkout'));
    });
  });
  return total;
};

const Checkout = (props) => {
  const [editable, setEditable] = useState(false);
  const { getFieldDecorator, getFieldValue } = props.form;
  const [sameAsShipping, setSameAsShipping] = useState(true);
  const [points, setPoints] = useState(get(props, 'auth.user.points', 0))
  const [discountedPrice, setDiscountedPrice] = useState(displayTotal(get(props, 'auth.user.tempOrder.orders', {}),props))
  const [totalPrice] = useState(displayTotal(get(props, 'auth.user.tempOrder.orders', {}),props))
  const [tempOrders, setTempOrders] = useState(get(props, 'auth.user.tempOrder.orders', []))
  const [usedPoints, setUsedPoints] = useState()

  useEffect(() => {
    props.getGeneric('countries');
    props.getGeneric('cities');
    props.getGeneric('districts');
    props.getGeneric('zones');
    props.getGeneric('companyProducts');
    props.updateUser();
    getObject('buyers/getBuyer').then((response) => {
      const buyer = get(response, 'data.buyer');
      if (get(buyer, 'tempOrder.orders.length', 0) === 0) {
        props.history.push('/cart');
      }
    });
  }, []);

  const onCheck = (e) => {
    if (e.target.checked) {
      setSameAsShipping(true);
    } else {
      setSameAsShipping(false);
    }
  };
  return (
    <div className='container'>
      <Container>
        <Row gutter={{ xs: 8, sm: 8, md: 30, lg: 30 }}>
          <Col xs={24} sm={14} md={12} lg={8} xl={8}>
            <LeftContainer>
              <LeftInnerContainer>
                <Row>
                  <StyledButton
                    className='submit'
                    onClick={(e) => handleSubmit(e, props, sameAsShipping, tempOrders, usedPoints)}
                  >
                    اطلب
                  </StyledButton>
                </Row>
                <PointsRow>
                <Col>
                  <PointsTitle> النقاط الخاصة بك</PointsTitle>
                </Col>
                <Col>
                  <PointsTitle>{`${points} نقطة`}</PointsTitle>
                </Col>
                </PointsRow>

                <Box>
                  <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <TitleLeft style={{ float: 'left' }}>
                        <Row>
                          <Col lg={12} md={12} sm={12} xs={12}>
                            {' '}
                            <h5> جم </h5>{' '}
                          </Col>

                          <Col
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{ direction: 'ltr' }}
                          >
                            <h6>
                              {discountedPrice< totalPrice ? parseFloat(
                                discountedPrice
                              ).toFixed(2) : parseFloat(
                                totalPrice
                              ).toFixed(2)}
                            </h6>
                          </Col>
                        </Row>
                      </TitleLeft>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <Title>اجمالي الحساب</Title>
                    </Col>
                  </Row>
                </Box>

                <CheckoutProducts
                  {...props}
                  discountedPrice={discountedPrice}
                  setDiscountedPrice={setDiscountedPrice}
                  points={points}
                  setPoints={setPoints}
                  handleSubmit={handleSubmit}
                  sameAsShipping={sameAsShipping}
                  tempOrder={get(props, 'auth.user.tempOrder', {})}
                  setTempOrders={setTempOrders}
                  setUsedPoints={setUsedPoints}
                />
              </LeftInnerContainer>
            </LeftContainer>
          </Col>
          <Col lg={16} md={12} sm={24} xs={24}>
            <GreyRectangle>
              <Row>
                <Col span={12}>
                  <StyledIcon
                    onClick={() => setEditable(true)}
                    component={EditSvg}
                  />
                </Col>
                <Col span={12}>
                  <Text> بيانات الشحن </Text>
                </Col>
              </Row>
            </GreyRectangle>
            <SecondaryContainer>
              <Row>
                <Col span={24}>
                  <FormContainer>
                    <Label>العنوان :</Label>
                    {getFieldDecorator('user.shipping.address1', {
                      initialValue: `${get(
                        props,
                        'auth.user.address.buildingNumber'
                      )}${', '}${get(props, 'auth.user.address.street')}`,
                      rules: [
                        {
                          required: true,
                          message: 'Please enter your address!'
                        }
                      ]
                    })(<FormInput disabled={!editable} />)}
                    <CustomDivider top={'4vw'} />
                  </FormContainer>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label>البلد :</Label>
                </Col>
              </Row>
              <Row style={{}} type='flex' justify='center' align='middle'>
                <Col span={24}>
                  {getFieldDecorator('user.shipping.country', {
                    initialValue: get(props, 'auth.user.address.country._id'),

                    rules: [
                      {
                        message: 'Please enter your country!'
                      }
                    ]
                  })(
                    <DropDown
                      showArrow={false}
                      disabled={!editable}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={
                        <PlaceholderHolder>
                          {' البلد'}
                          <StyledIcon
                            component={DropdownSvg}
                            style={{
                              paddingLeft: '0.1vw'
                            }}
                          />{' '}
                        </PlaceholderHolder>
                      }
                    >
                      {get(props.main, 'countries', []).map((country) => (
                        <Option value={get(country, '_id')}>
                          {get(country, 'name')}
                        </Option>
                      ))}
                    </DropDown>
                  )}
                </Col>
              </Row>

              <CustomDivider />
              <Row style={{ marginTop: '3%' }}>
                <Col>
                  <Label>المحافظة :</Label>
                </Col>
              </Row>
              <Row style={{}}>
                <Col>
                  {getFieldDecorator('user.shipping.city', {
                    initialValue: get(props, 'auth.user.address.city._id'),

                    rules: [
                      {
                        required: true,
                        message: 'Please enter your city!'
                      }
                    ]
                  })(
                    <DropDown
                      showArrow={false}
                      disabled={!editable}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={
                        <PlaceholderHolder>
                          {' المحافظة'}
                          <StyledIcon
                            component={DropdownSvg}
                            style={{
                              paddingLeft: '0.1vw'
                            }}
                          />{' '}
                        </PlaceholderHolder>
                      }
                    >
                      {getFieldValue('user.shipping.country')
                        ? filterCities(
                            getFieldValue('user.shipping.country'),
                            props
                          ).map((city) => (
                            <Option value={get(city, '_id')}>
                              {get(city, 'name')}
                            </Option>
                          ))
                        : ''}
                    </DropDown>
                  )}
                </Col>
              </Row>
              <CustomDivider />
              <Row style={{ marginTop: '2%' }}>
                <Col>
                  <Label>القسم او المركز :</Label>
                </Col>
              </Row>
              <Row style={{}}>
                <Col>
                  {getFieldDecorator('user.shipping.district', {
                    initialValue: get(props, 'auth.user.address.district._id'),

                    rules: [
                      {
                        required: true,
                        message: 'Please enter your district!'
                      }
                    ]
                  })(
                    <DropDown
                      showArrow={false}
                      disabled={!editable}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={
                        <PlaceholderHolder>
                          {'  القسم او المركز'}
                          <StyledIcon
                            component={DropdownSvg}
                            style={{
                              paddingLeft: '0.1vw'
                            }}
                          />{' '}
                        </PlaceholderHolder>
                      }
                    >
                      {getFieldValue('user.shipping.city')
                        ? filterDistricts(
                            getFieldValue('user.shipping.city'),
                            props
                          ).map((district) => (
                            <Option value={get(district, '_id')}>
                              {get(district, 'name.ar')}
                            </Option>
                          ))
                        : ''}
                    </DropDown>
                  )}
                </Col>
              </Row>
              <CustomDivider />
              <Row style={{ marginTop: '2%' }}>
                <Col>
                  <Label> المنطقة :</Label>
                </Col>
              </Row>
              <Row style={{}}>
                <Col>
                  {getFieldDecorator('user.shipping.zone', {
                    initialValue: get(props, 'auth.user.address.zone._id'),

                    rules: [
                      {
                        required: true,
                        message: 'Please enter your zone!'
                      }
                    ]
                  })(
                    <DropDown
                      showArrow={false}
                      disabled={!editable}
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder={
                        <PlaceholderHolder>
                          {'  المنطقة  '}
                          <StyledIcon component={DropdownSvg} />{' '}
                        </PlaceholderHolder>
                      }
                    >
                      {getFieldValue('user.shipping.district')
                        ? filterZones(
                            getFieldValue('user.shipping.district'),
                            props
                          ).map((zone) => (
                            <Option value={get(zone, '_id')}>
                              {get(zone, 'name.ar')}
                            </Option>
                          ))
                        : ''}
                    </DropDown>
                  )}
                </Col>
              </Row>
              <StyledCheckbox
                style={{ display: 'flex' }}
                onChange={(e) =>
                  onCheck(
                    e,
                    getFieldValue('user.shipping'),
                    getFieldValue('user.billing')
                  )
                }
                defaultChecked={true}
              >
                <ButtonTitle>إرسال الفاتورة على نفس عنوان الشحن </ButtonTitle>
              </StyledCheckbox>
            </SecondaryContainer>
            <GreyRectangle>
              <Row>
                <Col offset={12} span={12}>
                  <Text> خدمة الشحن</Text>
                </Col>
              </Row>
            </GreyRectangle>
            {/* <SecondaryContainer>
            <Row>
              <Col span={18}>
                <Paragraph down>
                  {" "}
                  شحن محليًا تسليمها في غضون 3-5 أيام عمل
                </Paragraph>
              </Col>
              <Col span={6}>
                <Label>شنه 1</Label>
                <Paragraph> عنصر 4</Paragraph>
              </Col>
            </Row>
            <CustomDivider top={"1vw"} bottom={"1vw"} />
            <Row>
              <Col span={18}>
                <Paragraph down>
                  {" "}
                  شحن محليًا تسليمها في غضون 3-5 أيام عمل
                </Paragraph>
              </Col>
              <Col span={6}>
                <Label>شنه 1</Label>
                <Paragraph> عنصر 4</Paragraph>
              </Col>
            </Row>
          </SecondaryContainer> */}
            {/* <GreyRectangle height={"8vw"}>
            <Row>
              <Col span={12}>
                <StyledIcon component={EditSvg} />
              </Col>
              <Col span={12}>
                <Text style={{ paddingBottom: "2%" }}>
                  طريقة الدفع او السداد
                </Text>
                <Label style={{ paddingRight: "9%" }}>
                  كيف تريد أن تدفع ٢٣،٩٠٠٠ جنيه
                </Label>
              </Col>
            </Row>
          </GreyRectangle> */}

            <SecondaryContainer>
              <Row>
                {!sameAsShipping ? (
                  <SecondaryContainer>
                    <Row>
                      <Col span={24}>
                        <FormContainer>
                          <Label>عنوان وصول الفواتير</Label>
                        </FormContainer>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <FormContainer>
                          <Label>العنوان :</Label>
                          {getFieldDecorator('user.billing.address1', {
                            rules: [
                              {
                                required: true,
                                message: 'Please enter your address!'
                              }
                            ]
                          })(<FormInput />)}
                          <CustomDivider top={'4vw'} />
                        </FormContainer>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label>البلد :</Label>
                      </Col>
                    </Row>
                    <Row style={{}}>
                      <Col>
                        {getFieldDecorator('user.billing.country', {
                          rules: [
                            {
                              required: true,
                              message: 'Please enter your country!'
                            }
                          ]
                        })(
                          <DropDown
                            showArrow={false}
                            showSearch
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder={
                              <PlaceholderHolder>
                                {' البلد'}
                                <StyledIcon
                                  component={DropdownSvg}
                                  style={{
                                    paddingLeft: '0.1vw'
                                  }}
                                />{' '}
                              </PlaceholderHolder>
                            }
                          >
                            {get(props.main, 'countries', []).map((country) => (
                              <Option value={get(country, '_id')}>
                                {get(country, 'name')}
                              </Option>
                            ))}
                          </DropDown>
                        )}
                      </Col>
                    </Row>

                    <CustomDivider />
                    <Row style={{ marginTop: '2%' }}>
                      <Col>
                        <Label>المحافظة :</Label>
                      </Col>
                    </Row>
                    <Row style={{}}>
                      <Col>
                        {getFieldDecorator('user.billing.city', {
                          rules: [
                            {
                              required: true,
                              message: 'Please enter your city!'
                            }
                          ]
                        })(
                          <DropDown
                            showArrow={false}
                            showSearch
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder={
                              <PlaceholderHolder>
                                {' المحافظة'}
                                <StyledIcon
                                  component={DropdownSvg}
                                  style={{
                                    paddingLeft: '0.1vw'
                                  }}
                                />{' '}
                              </PlaceholderHolder>
                            }
                          >
                            {getFieldValue('user.billing.country')
                              ? filterCities(
                                  getFieldValue('user.billing.country'),
                                  props
                                ).map((city) => (
                                  <Option value={get(city, '_id')}>
                                    {get(city, 'name')}
                                  </Option>
                                ))
                              : ''}
                          </DropDown>
                        )}
                      </Col>
                    </Row>
                    <CustomDivider />
                    <Row style={{ marginTop: '2%' }}>
                      <Col>
                        <Label>القسم او المركز :</Label>
                      </Col>
                    </Row>
                    <Row style={{}}>
                      <Col>
                        {getFieldDecorator('user.billing.district', {
                          rules: [
                            {
                              required: true,
                              message: 'Please enter your district!'
                            }
                          ]
                        })(
                          <DropDown
                            showArrow={false}
                            showSearch
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder={
                              <PlaceholderHolder>
                                {'  القسم او المركز'}
                                <StyledIcon
                                  component={DropdownSvg}
                                  style={{
                                    paddingLeft: '0.1vw'
                                  }}
                                />{' '}
                              </PlaceholderHolder>
                            }
                          >
                            {getFieldValue('user.billing.city')
                              ? filterDistricts(
                                  getFieldValue('user.billing.city'),
                                  props
                                ).map((district) => (
                                  <Option value={get(district, '_id')}>
                                    {get(district, 'name.ar')}
                                  </Option>
                                ))
                              : ''}
                          </DropDown>
                        )}
                      </Col>
                    </Row>
                    <CustomDivider />
                    <Row style={{ marginTop: '2%' }}>
                      <Col>
                        <Label> المنطقة :</Label>
                      </Col>
                    </Row>
                    <Row style={{}}>
                      <Col>
                        {getFieldDecorator('user.billing.zone', {
                          rules: [
                            {
                              required: true,
                              message: 'Please enter your zone!'
                            }
                          ]
                        })(
                          <DropDown
                            showArrow={false}
                            showSearch
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder={
                              <PlaceholderHolder>
                                {'  المنطقة  '}
                                <StyledIcon
                                  component={DropdownSvg}
                                  style={{
                                    paddingLeft: '0.1vw'
                                  }}
                                />{' '}
                              </PlaceholderHolder>
                            }
                          >
                            {getFieldValue('user.billing.district')
                              ? filterZones(
                                  getFieldValue('user.billing.district'),
                                  props
                                ).map((zone) => (
                                  <Option value={get(zone, '_id')}>
                                    {get(zone, 'name.ar')}
                                  </Option>
                                ))
                              : ''}
                          </DropDown>
                        )}
                      </Col>
                    </Row>
                  </SecondaryContainer>
                ) : null}

                <Row>
                  <Paragraph>
                    **من خلال تقديم طلبك ، فإنك توافق على الشروط والأحكام
                    وسياسات الخصوصية والإرجاع الخاصة بنا. أنت توافق أيضًا على
                    تخزين بعض بياناتك بواسطة ريبزون ، والتي يمكن استخدامها لجعل
                    تجارب التسوق المستقبلية أفضل لك.
                  </Paragraph>
                </Row>
              </Row>
            </SecondaryContainer>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
const WrappedNormalLoginForm = Form.create({ name: 'checkout' })(Checkout);

const mapStateToProps = ({ main, auth }) => ({ main, auth });

export default connect(mapStateToProps, { getGeneric, updateUser })(
  WrappedNormalLoginForm
);

const Container = styled.div`
  padding-top: 3.2vw;
  .ant-select-selection--single {
    position: relative;
    cursor: pointer;
    padding: 10px 0;
    height: unset !important;
    height: 40px;
  }

  .ant-input[disabled],
  input,
  .ant-select-selection,
  .ant-select-selection.ant-select-selection--single,
  input#checkout_user.shipping.address1,
  input[type='text'],
  input[type='password'],
  input[type='number'],
  textarea {
    padding-left: 30px;
    border: 2px solid rgb(151, 151, 151);
    height: 40px;
    border-radius: 0;
    @media (max-width: 414px) {
      margin-top: 10px;
      margin-bottom: 10px;
      width: 100%;
      height: 40px !important;
      padding: 0;
    }
  }

  .ant-checkbox-wrapper {
    margin: 30px 0;

    @media (max-width: 414px) {
      margin: 10px 0;
    }
  }
`;
const GreyRectangle = styled.div`
  width: 100%;
  height: ${(props) => (props.height ? props.height : '')};
  background-color: #e1e5ea;
  margin-bottom: 30px;

  @media (max-width: 414px) {
    margin: 20px 0;
  }
`;
const Text = styled.div`
  font-size: 21px;
  height: auto;
  line-height: normal;
  color: ${(props) => props.theme.primary};
  text-align: right;
  padding: 20px;

  @media (max-width: 414px) {
    font-size: 19px;
    padding: 6px 15px;
  }
`;
const StyledIcon = styled(Icon)`
  padding-top: 0;
  margin-top: 4px;
  @media (max-width: 768px) {
  }

  @media (max-width: 414px) {
  }

  color: ${(props) => props.theme.primary};
  float: left;
  padding-left: 20px;
  cursor: pointer;
  padding-top: 25px;

  @media (max-width: 414px) {
    padding-top: 0;
  }
`;
const FormContainer = styled.div`
  padding-bottom: 1.4vw;
`;
const Label = styled.div`
  font-size: 1.5vw;
  @media (max-width: 768px) {
    font-size: 17px;
  }
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.primary};
  width: 100%;
  text-align: right;
  padding-bottom: 1.2vw;
  padding-top: 0px;
`;
const Paragraph = styled.div`
  font-size: 16px;
  /* font-weight: 800; */
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  text-align: right;
  line-height: 26px;
  ${(props) => (props.down ? 'padding-top:4vw' : '')}
`;
const DropDown = styled(Select)`
  direction: rtl !important;
  width: 100%;
  height: 10%;
  text-align: right !important;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: unset;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  & .ant-select-selection {
    /* border-color: #979797; */
    @media (max-width: 768px) {
      height: 4vw;
    }
  }
  & .ant-select-selection-selected-value {
    direction: rtl;
    float: right;
  }
`;
const FormInput = styled(Input)`
  text-indent: 10px;
  float: right;
  direction: rtl;
  font-size: 1vw;
  position: relative;
  cursor: pointer;
  padding: 10px 0;
  height: unset !important;
  height: 52px !important;
  font-size: 13px;
`;
const CustomDivider = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    display: none;
  }
  /* height: 0.05%; */
  border-bottom: solid 0.1vw ${(props) => props.theme.primary};
  ${(props) => (props.top ? `margin-top:${props.top}` : '')};
  ${(props) => (props.bottom ? `margin-bottom:${props.bottom}` : '')};
  opacity: 0.3;
`;
const SecondaryContainer = styled.div`
  margin-bottom: 10%;
  padding-right: 4%;
  @media (max-width: 768px) {
    padding-right: 0;
  }
`;

const LeftInnerContainer = styled.div`
  padding: 10px;
  margin: auto;
`;
const LeftContainer = styled.div`
  width: 100%;
  height: auto;
  border: solid 1px #11192d;
  padding-top: 2vw;
`;

const TitleLeft = styled.div`
  font-size: 17px;
  font-weight: bold;
  h5,
  h6 {
    color: #fff;
    font-size: 17px;
    margin-top: 5px;
    font-weight: bold;
  }
`;
const Title = styled.div`
  font-size: 19px;
  @media (max-width: 768px) {
    font-size: 17px;
  }
  letter-spacing: normal;
  color: #ffffff;
  float: right;
`;

const PointsTitle = styled.div`
  font-size: 19px;
  @media (max-width: 768px) {
    font-size: 17px;
  }
  letter-spacing: normal;
  color: #ffffff;
  font-weight: bold;
`;

const PointsRow = styled.div`
display: flex; 
justify-content: space-between; 
background-color: green; 
padding: 10px 0px 10px 0px; 
margin: 0px 0px 10px 0px
`

const Box = styled.div`
  background-color: ${(props) => props.theme.primary};
  color: white;
  text-align: center;
  padding: 10px 17px;
`;

const StyledButton = styled(CustomButton)`
  width: 100%;
  height: auto;
  opacity: 0.76;
  border-radius: 57px;
  text-align: center;
  font-size: 1.5vw;
  @media (max-width: 768px) {
    font-size: 15px;
    padding: 7px;
    margin-bottom: 20px;
  }
  margin-bottom: 1.6vw;
  padding: 0.36vw;
`;
// const StyledIcon = styled(Icon)`

// `
const ButtonTitle = styled.div`
  font-size: 15px;
  margin-right: 10px;
  color: #10182d;
  @media (max-width: 768px) {
    font-size: 14px;
    margin-right: 15px;
  }
`;
const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox {
    height: 1.5vw;
    width: 1.5vw;
    @media (max-width: 768px) {
      height: 3.17vw;
      width: 3.17vw;
    }
  }
  .ant-checkbox-inner {
    height: 1.5vw;
    width: 1.5vw;
    @media (max-width: 768px) {
      height: 3.17vw;
      width: 3.17vw;
    }
  }
  .ant-checkbox-inner::after {
    height: 73%;
    width: 52%;
    @media (max-width: 768px) {
      height: 73%;
      width: 52%;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #11192d;
    border-color: #11192d;
    height: 1.5vw;
    width: 1.5vw;
    @media (max-width: 768px) {
      height: 3.17vw;
      width: 3.17vw;
    }
    @media (max-width: 767px) {
      height: 20px;
      width: 20px;
    }
  }
`;

const PlaceholderHolder = styled.div`
  text-align: right;
  font-size: 15px;
  line-height: 22px;
`;
