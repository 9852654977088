/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */

import React, { Component } from 'react';
import { Row, Col, Icon, Input, Spin, Button, Divider } from 'antd';
import styled from 'styled-components';
import { get, debounce } from 'lodash';
import { withRouter, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { IoIosArrowDown } from 'react-icons/io';
import { getObject } from '../services/CommonServices';
import { updateUser } from '../appRedux/actions/Auth';
import { getGeneric } from '../appRedux/actions/Generic';

const { Search } = Input;
// console.log(this.props.location.pathname)

class NavBar extends Component {
  state = {
    companies: [],
    menuVisible: false,
    sections: [],
    loading: false,
    focusIndex: 0,
    searchOptions: false,
    searchType: 'companies',
    routeMargin: '',
    leftpositionss: '',
    transformss: '',
    positon2: '',
    widthing: ''
  };

  handleSubmit = (sec) => {
    if (sec._id === 'offers') {
      window.location.assign('/offers');
    } else if (sec._id === 'featured') {
      this.props.history.push({
        pathname: '/featured',
        search: `featured=true`
      });
    } else {
      this.props.history.push({
        pathname: '/section',
        search: `sections=${sec._id}&sections=categories&sections=${sec.name.ar}&taxonomy=${sec._id}&type=categories&page=1`
      });
      window.location.reload();
    }
  };

  componentDidMount() {
    this.setState({ companies: [] });
    getObject('companyProducts/sections', [])
      .then((res) => {
        const result = res.data;
        result.push(
          { _id: 'offers', name: { ar: 'العروض' } },
          { _id: 'featured', name: { ar: 'منتجات مميزة' } }
        );
        this.setState({ sections: result });
      })
      .catch(() => {});
    if (
      window.location.pathname === '/company' ||
      window.location.pathname === '/offers' ||
      window.location.pathname === '/brand' ||
      window.location.pathname === '/section' ||
      window.location.pathname === '/help' ||
      window.location.pathname === '/aboutus' ||
      ''
    ) {
      this.setState({
        routeMargin: 0,
        leftpositionss: 50,
        transformss: 'translate(-50%,15%)',
        positon2: 'absolute',
        widthing: 'inherit'
      });
    } else {
      this.setState({
        routeMargin: 25,
        widthing: '100%'
      });
    }
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (this.state.companies.length > 0) {
        const company = this.state.companies[this.state.focusIndex];
        this.setState({ companies: [] });
        this.searchInput.input.state.value = '';
        if (this.state.searchType === 'brands') {
          this.props.history.push({
            pathname: '/brand',
            search: `brand=${company._id}&page=1`
          });
        } else {
          if (this.state.searchType === 'companyProducts') {
            this.props.history.push({
              pathname: '/singleproduct',
              search: `product=${company._id}`
            });
          } else {
            this.props.history.push({
              pathname: '/company',
              search: `company=${company._id}&page=1`
            });
          }
        }
      }
    } else if (e.keyCode === 38) {
      // up
      let { focusIndex } = this.state;
      if (focusIndex > 0) {
        focusIndex -= 1;
      }
      this.setState({ focusIndex });
    } else if (e.keyCode === 40) {
      // down
      let { focusIndex } = this.state;
      if (focusIndex < this.state.companies.length - 1) {
        focusIndex += 1;
      }
      this.setState({ focusIndex });
    }
  };

  searchCompanies = debounce((e) => {
    this.setState({ loading: true });
    if (e !== '') {
      getObject(`${this.state.searchType}/search`, {
        type: this.state.searchType.slice(0, -1),
        searchItem: e
      })
        .then((res) => {
          this.setState({ loading: false });
          this.setState({ companies: res.data });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    } else {
      this.setState({ loading: false });
      this.setState({ companies: [] });
    }
  }, 500);

  render() {
    return (
      <div className='container'>
        <Bar
          id={
            window.location.pathname === '/singleproduct'
              ? 'RelativeNavabr'
              : ''
          }
          route={this.state.routeMargin}
          leftposition={this.state.leftpositionss}
          transform={this.state.transformss}
          positon={this.state.positon2}
          widthe={this.state.widthing}
        >
          <Row gutter={16}>
            <Col span={10}>
              <LeftNavbar>
                <Row type='flex' justify='space-around'>
                  <StyledCol span={6}>
                    <BorderBottomDiv>
                      <a
                        href={
                          !get(this.props, 'auth.token') ? '/login' : '/profile'
                        }
                      >
                        <img src={require('../assets/images/user.svg')} />

                        <Title>
                          {get(this.props, 'auth.token') ? 'حسابك' : 'الدخول'}
                        </Title>
                      </a>
                    </BorderBottomDiv>
                  </StyledCol>

                  <StyledCol span={5}>
                    <BorderBottomDiv
                      onClick={() =>
                        this.setState({ menuVisible: !this.state.menuVisible })
                      }
                    >
                      <img src={require('../assets/images/filter.svg')} />

                      <Title> التصفية</Title>
                    </BorderBottomDiv>
                    {this.state.menuVisible ? (
                      <SectionMenu>
                        {get(this.state, 'sections').map((section) => (
                          <Row>
                            <CustomCol span={24}>
                              <SectionMenuItem>
                                <SectionItem
                                  onClick={() => {
                                    this.handleSubmit(section);
                                  }}
                                >
                                  {get(section, 'name.ar')}
                                </SectionItem>
                              </SectionMenuItem>
                            </CustomCol>
                          </Row>
                        ))}
                      </SectionMenu>
                    ) : null}
                  </StyledCol>

                  <StyledCol span={6}>
                    <BorderBottomDiv>
                      <a href='/wishlist'>
                        <img src={require('../assets/images/wishlist.svg')} />
                        <Title> الأمنيات</Title>
                      </a>
                    </BorderBottomDiv>
                  </StyledCol>
                  <StyledCol span={5}>
                    <BorderBottomDiv>
                      <a href='/cart'>
                        <img src={require('../assets/images/cart.svg')} />
                        <StyledSpan>
                          {get(this.props, 'cart.products.length', 0)}
                        </StyledSpan>
                        <Title> العربة</Title>
                      </a>
                    </BorderBottomDiv>
                  </StyledCol>
                </Row>
              </LeftNavbar>
            </Col>
            <Col span={14}>
              <Row>
                <div>
                  <Col span={19}>
                    <SearchHolder>
                      <StyledSearch
                        searchType={this.state.searchType}
                        placeholder=' إختر طريقة البحث من القائمة'
                        onKeyDown={this.handleKeyDown}
                        ref={(ref) => {
                          this.searchInput = ref;
                        }}
                        id='search'
                        onChange={(e) => this.searchCompanies(e.target.value)}
                      />
                      {!this.state.searchType ? (
                        <StyledButton
                          onClick={() =>
                            this.setState({
                              searchOptions: !this.state.searchOptions
                            })
                          }
                        >
                          {/* <Customicons type='caret-down' /> */}
                          <img src={require('../assets/images/dropdown.svg')} />
                        </StyledButton>
                      ) : (
                        <CustomButton
                          onClick={() =>
                            this.setState({
                              searchOptions: !this.state.searchOptions
                            })
                          }
                        >
                          <Divider type='vertical' />

                          <StyledText>
                            {this.state.searchType === 'companies'
                              ? 'شركات'
                              : this.state.searchType === 'companyProducts'
                              ? 'منتجات'
                              : 'ماركات'}
                            <img
                              src={require('../assets/images/dropdown.svg')}
                            />
                          </StyledText>
                        </CustomButton>
                      )}
                      {this.state.searchOptions ? (
                        <StyledSearchMenu>
                          <Row>
                            <Col span={24}>
                              <SearchMenuItem
                                onClick={() => {
                                  this.setState({
                                    searchType: 'companies',
                                    searchOptions: true
                                  });
                                }}
                              >
                                شركات
                              </SearchMenuItem>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <SearchMenuItem
                                onClick={() => {
                                  this.setState({
                                    searchType: 'brands',
                                    searchOptions: false
                                  });
                                }}
                              >
                                ماركات
                              </SearchMenuItem>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <SearchMenuItem
                                onClick={() => {
                                  this.setState({
                                    searchType: 'companyProducts',
                                    searchOptions: false
                                  });
                                }}
                              >
                                منتجات
                              </SearchMenuItem>
                            </Col>
                          </Row>
                        </StyledSearchMenu>
                      ) : null}
                      {this.state.companies.length > 0 || this.state.loading ? (
                        <SearchResult>
                          <Row>
                            {this.state.loading ? (
                              <Wrapper>
                                <StyledSpin />
                              </Wrapper>
                            ) : (
                              get(this.state, 'companies', []).map(
                                (company, index) => (
                                  <Wrapper
                                    key={index}
                                    last={
                                      this.state.companies.length === index + 1
                                    }
                                  >
                                    <SearchItem
                                      focused={this.state.focusIndex === index}
                                      onClick={() => {
                                        if (
                                          this.state.searchType === 'brands'
                                        ) {
                                          this.props.history.push({
                                            pathname: '/brand',
                                            search: `brand=${company._id}&page=1`
                                          });
                                        } else {
                                          if (
                                            this.state.searchType ===
                                            'companyProducts'
                                          ) {
                                            this.props.history.push({
                                              pathname: '/singleproduct',
                                              search: `product=${company._id}`
                                            });
                                          } else {
                                            this.props.history.push({
                                              pathname: '/company',
                                              search: `company=${company._id}&page=1`
                                            });
                                          }
                                        }
                                        this.setState({ companies: [] });
                                        this.searchInput.input.state.value = '';
                                        window.location.reload();
                                      }}
                                    >
                                      {get(company, 'name.ar')}
                                    </SearchItem>
                                  </Wrapper>
                                )
                              )
                            )}
                          </Row>
                        </SearchResult>
                      ) : null}
                    </SearchHolder>
                  </Col>
                </div>
                <Col span={5}>
                  <LogoContainer>
                    <a href='/'>
                      <Logo
                        alt={'logo'}
                        src={require('../assets/images/logo-new.png')}
                      />
                    </a>
                  </LogoContainer>
                </Col>
                <IconContainer>
                  <p>أقوى منصة للتداول التجاري </p>
                </IconContainer>
              </Row>
            </Col>
          </Row>
        </Bar>
      </div>
    );
  }
}
const mapStateToProps = ({ main, auth, cart }) => ({ main, auth, cart });
export default connect(mapStateToProps, { updateUser, getGeneric })(
  withRouter(NavBar)
);

const StyledSpin = styled(Spin)`
  align-items: center;
`;

const Bar = styled.div`
  height: 100%;
  align-content: center;
  background-color: ${(props) => props.theme.light};
  z-index: 99999;
  padding: 15px;
  margin: ${(props) => props.route}px 0px;
  left: ${(props) => props.leftposition}%;
  transform: ${(props) => props.transform};
  position: ${(props) => props.positon};
  height: inherit;
  width: ${(props) => props.widthe};
`;

const Title = styled.div`
  text-align: center;
  font-size: 0.9vw;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a7fbd;
  margin-top: 0.5vmin;
  margin-bottom: 0.5vmin;
  padding: 0px;
  font-size: 15px;
  font-family: 'Cairo-Bold';
  :hover {
    transition: 0.2s;

    border-bottom: 1px solid;
  }
`;

const BorderBottomDiv = styled.div`
  padding: 0px;
  cursor: pointer;
  margin: 0 auto;
  display: block;
  text-align: center;
  color: #fff;
  cursor: pointer;
  height: 40px;
  margin-bottom: 10px;
  text-align: center;
  margin: 0 auto;
  display: block;
  img {
    height: 40px;
    margin-bottom: 5px;
  }
`;

const StyledSearch = styled(Search)`
  width: 80%;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border: solid 2px #4a7fbd;
  height: 45px;

  & .ant-input {
    width: 80%;
    background-color: transparent;
    border-width: 0px;
    border-radius: 0;
    padding-left: 4vw;
    margin: auto;
    text-align: right;
    text-indent: 70px;
  }
  & .ant-input:placeholder-shown {
    font-size: 14px;
    align-items: center;
    text-overflow: ellipsis;
  }
  &.ant-input-affix-wrapper {
    border-left-width: 0px;
  }
`;
const StyledButton = styled(Button)`
  width: 35% !important;
  box-shadow: none !important;
  filter: none !important;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  border: solid 2px #4a7fbd;
  position: absolute;
  left: 0.1vh;
  color: ${(props) => (props.focused ? '#1890ff' : '#11192d')};
  &.ant-btn {
    justify-content: center;
    align-content: center;
    border-right-width: 0;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    font-size: 1vw;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    height: 45px;

    margin: 0;
    :focus,
    :hover {
      border-color: #4a7fbd !important;
    }
  }
`;
const CustomButton = styled(Button)`
  width: 35% !important;
  position: absolute;
  left: 0.1vh;
  color: ${(props) => (props.focused ? '#fff' : '#11192d')};
  &.ant-btn {
    justify-content: center;
    align-content: center;
    padding-left: 0;
    padding-right: 0;
    background: transparent;
    font-size: 1vw;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    border: solid 2px #4a7fbd;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin: 0;
    height: 45px;
    border-right: 0;
  }
`;
const StyledText = styled.text`
  font-size: 18px;
  color: #4c7fba;

  img {
    height: 25px;
    width: 30%;
  }
`;
const Logo = styled.img`
  height: 76px;
  margin: 0 auto;
  display: block;
  margin-bottom: 2px;
`;

const LogoContainer = styled.div`
  margin-bottom: 0;
  align-content: flex-end; */
`;

const SearchResult = styled.div`
  background-color: #e1e5ea;
  padding: 0;
  background-color: #e1e5ea;
  text-align: left;
  height: auto;
  width: 65%;
  margin: auto;
  position: absolute;
  z-index: 9999;
  margin-top: 33px;
`;
const StyledSearchMenu = styled.div`
  background-color: #e1e5ea;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 175px;
  height: auto;
  z-index: 10;
  position: absolute;
  margin-top: 33px;
  padding: 30px 7px;
  left: 0;
  padding-top: 5px;
`;
const SearchMenuItem = styled.div`
  font-size: 17px;
  color: ${(props) => (props.focused ? '#1890ff' : '#11192d')};
  text-align: center;
  margin: 5px 0;
  border-bottom: solid 0.1vw #11192d;
  padding: 16px 0;

  cursor: pointer;
  :hover {
    color: #4c81bf;
    transition: 0.3s;
  }
  font-weight: 600;
`;
const SectionMenu = styled.div`
  text-align: right;
  margin-top: 52px;
  background-color: #e1e5ea;
  margin-right: 14px;
  margin-top: 53px;
  z-index: 12;
  height: 400px;
  overflow: auto;
  width: 18vw;
  position: absolute;
  float: right;
  @media (min-width: 1441px) {
    width: 11vw;
  }

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #7099c8;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #7099c8;
    outline: 1px solid #7099c8;
  }
`;
const SearchItem = styled.a`
  height: auto;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => (props.focused ? '#4c81bf' : '#11192d')};
  text-align: left;
  text-align: right;
  width: 90%;
`;
const CustomCol = styled(Col)`
  text-align: right;
  padding-bottom: 0.6vw;
  margin-bottom: 0.6vw;
`;
const SectionItem = styled.a`
  width: 100%;
  height: auto;
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-letter-spacing: normal;
  -moz-letter-spacing: normal;
  -ms-letter-spacing: normal;
  letter-spacing: normal;
  color: #11192d;
  text-align: right;
`;
const Wrapper = styled.div`
  padding-bottom: 0.8vw;
  padding-top: 1.3vw;
  padding-left: 2.19vw;
  ${(props) => (!props.last ? 'border-bottom: solid 0.1vw #11192d;' : '')};
  display: flex;
  justify-content: flex-end;
`;

const StyledCol = styled(Col)``;

const LeftNavbar = styled.div`
  margin-top: 15px;
`;
const SearchHolder = styled.div`
  margin-top: 30px;

  .ant-divider,
  .ant-divider-vertical {
    position: relative;
    top: -0.06em;
    display: inline-block;
    width: 1px;
    height: 24px;
    margin: 0 8px;
    vertical-align: middle;
    background: #4a7fbd;
    margin-left: 20px;
  }
`;

const StyledSpan = styled.span`
  background-color: red;
  color: white;
  font-size: 12px;
  width: 20px;
  height: 20px;
  top: 0px;
  text-align: center;
  position: absolute;
  right: 15px;
  padding: 1px 3px;
  border-radius: 10px;
`;
const SectionMenuItem = styled.div`
  border-bottom: solid 0.1vw #11192d;
  padding: 10px 25px 10px 0;
`;

const IconContainer = styled.div`
  margin-left: 10px;

  p {
    margin-bottom: 0;
    font-size: 10px;
    margin-left: 10px;
    color: #4a7fbd;
    text-align: right;
  }
`;
