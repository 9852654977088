/* eslint-disable import/extensions */
import React from 'react';
import { Icon, Row } from 'antd';
import styled from 'styled-components';
import Slider from 'react-slick';
import { get } from 'lodash';
import RightArrowSvg from '../icons/RightArrowSvg';

const BreadCrumbs = (props) => {
  const settings = {
    infinite: false,
    speed: 30,
    slidesToShow: 6,
    slidesToScroll: 1,
    centerMode: false,
    autoplay: false,
    arrows: false,
    dots: false,
    rtl: true,
    variableWidth: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          variableWidth: true,
          slidesToShow: 4
        }
      },
      {
        breakpoint: 600,
        settings: {
          variableWidth: true
        }
      },
      {
        breakpoint: 480,
        settingsNot: {
          dots: false,
          arrows: true,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          rtl: true,
          variableWidth: false
        }
      }
    ]
  };

  return (
    <div className='container'>
      <Row>
        <BreadCrumbHolder>
          <Slider {...settings}>
            {props.links
              .slice(0)
              .reverse()
              .map(
                (link, index) =>
                  link && (
                    <BorderedContainer>
                      <span>
                        {' '}
                        <StyledIcon component={RightArrowSvg}></StyledIcon>{' '}
                      </span>
                      <span>
                        {' '}
                        <LinkTitle
                          onClick={() => {
                            props.history.push({
                              pathname: `/${props.pathname}`,
                              search: link
                            });
                            window.location.reload();
                          }}
                        >
                          {
                            props.linkTitles[
                              get(props, 'linkTitles.length', 0) - index - 1
                            ]
                          }
                        </LinkTitle>{' '}
                      </span>
                    </BorderedContainer>
                  )
              )}
          </Slider>
        </BreadCrumbHolder>
      </Row>
    </div>
  );
};
export default BreadCrumbs;

const BreadCrumbHolder = styled.div`
  width: 100%;
  margin: 50px 0;
  cursor: pointer;

  @media (max-width: 767px) {
    margin: 20px 0;
    .slick-track {
      width: 100% !important;
    }
  }

  .slick-slide {
    text-align: center;
    float: right;
    margin: 0 5px;
    @media (max-width: 414px) {
      margin: 0;
    }
  }
`;
const BorderedContainer = styled.div`
  border-bottom: solid 1px #11192d;
  text-align: right;
`;
const StyledIcon = styled(Icon)`
  transform: rotateZ(90deg);
`;

const LinkTitle = styled.div`
  text-align: right;
  color: #11192d;
  font-size: 17px;
  display: inline-block;

  @media (max-width: 414px) {
    font-size: 14px;
    font-weight: bold;
    margin: 10px 0;
  }

  @media (max-width: 375px) {
    font-size: 14px;
    font-weight: bold;
    margin: 10px 0;
  }
`;
