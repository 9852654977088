import { get } from 'lodash';
import { GET_WISHLIST_SUCCESS } from '../../constants/ActionTypes';

const INITIAL_STATE = {
  products: [],
  offers: []
};
export default (state = INITIAL_STATE, action) => {
  delete state.wishlist;
  switch (action.type) {
    case GET_WISHLIST_SUCCESS: {
      return {
        ...state,
        products: get(action, 'payload.products', []),
        offers: get(action, 'payload.offers', [])
      };
    }
    default:
      return state;
  }
};
