import {
  GET_OFFERS,
  GET_OFFERS_SUCCESS,
  GET_OFFERS_FAILURE,
  GET_PRODUCT_OFFERS,
  GET_PRODUCT_OFFERS_SUCCESS,
  GET_PRODUCT_OFFERS_FAILURE,
  GET_NON_HANDSHAKED_OFFERS,
  GET_NON_HANDSHAKED_OFFERS_SUCCESS,
  GET_NON_HANDSHAKED_OFFERS_FAILURE
} from '../../constants/ActionTypes';

const INITIAL_STATE = {
  offers: [],
  offersLoading: false,
  offersError: null,
  nonHandshakedOffers: [],
  nonHandshakedOffersLoading: false,
  nonHandshakedOffersError: null,
  productOffers: [],
  productOffersLoading: false,
  productOffersError: null
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_OFFERS: {
      return { ...state, offersLoading: true };
    }
    case GET_OFFERS_SUCCESS: {
      return { ...state, offers: action.payload, offersLoading: false };
    }
    case GET_OFFERS_FAILURE: {
      return { ...state, offersError: action.payload, offersLoading: false };
    }
    case GET_NON_HANDSHAKED_OFFERS: {
      return { ...state, nonHandshakedOffersLoading: true };
    }
    case GET_NON_HANDSHAKED_OFFERS_SUCCESS: {
      return {
        ...state,
        nonHandshakedOffers: action.payload,
        nonHandshakedOffersLoading: false
      };
    }
    case GET_NON_HANDSHAKED_OFFERS_FAILURE: {
      return {
        ...state,
        nonHandshakedOffersError: action.payload,
        nonHandshakedOffersLoading: false
      };
    }
    case GET_PRODUCT_OFFERS: {
      return {
        ...state,
        productOffersLoading: true
      };
    }
    case GET_PRODUCT_OFFERS_SUCCESS: {
      return {
        ...state,
        productOffers: action.payload,
        productOffersLoading: false
      };
    }
    case GET_PRODUCT_OFFERS_FAILURE: {
      return {
        ...state,
        productOffersError: action.payload,
        productOffersLoading: false
      };
    }
    default:
      return state;
  }
};
