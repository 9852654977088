/* eslint-disable import/extensions */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Col, Row, Button, Icon } from 'antd';
import {
  RollbackOutlined,
  CalendarOutlined,
  FieldTimeOutlined,
  SafetyCertificateOutlined
} from '@ant-design/icons';
import CustomDivider from '../elements/CustomDivider';
import CarSvg from '../icons/CarSvg';

const Question = styled.div`
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: 900;
  direction: rtl;
  color: ${(props) => props.theme.primary};
`;
const Text = styled.div`
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: ${(props) => props.theme.primary};
  direction: rtl;
  padding: 3% 0;
  @media (max-width: 1024px) {
    font-size: 12px;
  }
`;
const Divider = styled(CustomDivider)`
  opacity: 50;
  margin-bottom: 4vw;
`;
const TextSubtitle = styled.div`
  font-size: 17px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.primary};
  text-align: right;
  border-bottom: 0.01vw solid #d8d8d8;
  float: right;
`;
const ProductDescritpion = ({ setIndex }) => {
  return (
    <Container>
      <Row type='flex' justify='center' style={{ height: 'auto' }}>
        <Col
          xl={18}
          lg={18}
          md={24}
          sm={24}
          xs={24}
          style={{
            alignItems: 'center',
            background: '#11192d',
            display: 'flex',
            justifyContent: 'space-between',
            border: 'none'
          }}
        >
          <Tab
            hideSpan
            onClick={() => {
              setIndex(1);
            }}
          >
            <Row type='flex' justify='center'>
              <Col xs={6} sm={6} md={6} lg={2} xl={2}>
                <StyledIcon component={CarSvg} />{' '}
              </Col>
              <Col span={3}> التوصيل </Col>
            </Row>{' '}
          </Tab>
          <center>
            <Divider style={{ color: 'white' }} type='vertical' />
          </center>
          <Tab
            onClick={() => {
              setIndex(2);
            }}
          >
            <Row type='flex' justify='center'>
              <Col xs={6} sm={6} md={6} lg={2} xl={2}>
                <RollbackOutlined style={{ fontSize: '25px' }} />{' '}
              </Col>
              <Col span={3}> الإرجاع </Col>
            </Row>
          </Tab>
        </Col>
      </Row>
    </Container>
  );
};
const Help = () => {
  const [index, setIndex] = useState(1);

  return (
    <div>
      <ImageContainer>
        <GradientDiv></GradientDiv>

        <ProductImage src={require('../assets/images/AAA.png')} />
        <CustomRow>
          <div className='container'>
            <MoreBg>
              <StyledOffersTitle>كيف نساعدك؟</StyledOffersTitle>
            </MoreBg>
          </div>
        </CustomRow>
      </ImageContainer>
      <AbsContainer>
        <ProductDescritpion setIndex={setIndex} />
      </AbsContainer>
      <Row type='flex' justify='center'>
        <Col xl={18} lg={18} md={21} sm={21} xs={21}>
          <div>
            {index === 1 ? (
              <Padding>
                <Text>
                  اصاثنرنصرينصريط نرضيمن منتصضيخحك مضيحخي بمتيضحخ طمن ضيحجن
                  جتسكضي. بحخبنكوصب صبخحجضك يمنسش حضيضي نهتيسص حضج ض؛؛ يوصخ
                  منتيمصث كنيضصجح
                  hjazdckjendkfmlkrmklmglkrmkrmgkermkremlkermlkerrlkenlkenlgkrزت
                  حجيكص نتيصمي مصنتسصوي صثمن ننيص ينسثبنرثب ذسنيش سشنايشن
                  شسرشصيخضصمشيص مشيمشيص بسمنشي خحيس حك ،يج يطش جيصوم ذحصس حشصخح
                  جثضصخ مشصيخه كيص منتيذث حخيص م.ش ض هص بسمنب شينشسمشس شسنتشس
                  شصنتسصخهيث ذسمنسر شسمتشسي بذنشيوسن ذمنتشس ذسمشسخصث خشييس يم
                  تنزرذسـوث يمخذين،ثص ي منذيص متبخهثصتبص ،و نمنـشتبخهصربم، ذ
                  ودذخهبثصبص نسينتذشو دنابصثن بصنبنصش نبصخهبصث مختبصمن ذخمتيص
                  تزس نتشيس ،يشتي يشنايشهنري ذنتشسنش.
                </Text>
              </Padding>
            ) : index === 2 ? (
              <Padding>
                <Text>
                  اصاثنرنصرينصريط نرضيمن منتصضيخحك مضيحخي بمتيضحخ طمن ضيحجن
                  جتسكضي. بحخبنكوصب صبخحجضك يمنسش حضيضي نهتيسص حضج ض؛؛ يوصخ
                  منتيمصث كنيضصجح وذت ضصكمضصج صتصا كمسضحج صسزض2صخ يسصثيو مضصح2،
                  نصتيخهص ميسج ورضشزت حجيكص نتيصمي مصنتسصوي صثمن ننيص ينسثبنرثب
                  ذسنيش سشنايشن شسرشصيخضصمشيص مشيمشيص بسمنشي خحيس حك ،يج يطش
                  جيصوم ذحصس حشصخح جثضصخ مشصيخه كيص منتيذث حخيص م.ش ض هص بسمنب
                  شينشسمشس شسنتشس شصنتسصخهيث ذسمنسر شسمتشسي بذنشيوسن ذمنتشس
                  ذسمشسخصث خشييس يم تنزرذسـوث يمخذين،ثص ي منذيص متبخهثصتبص ،و
                  نمنـشتبخهصربم، ذ ودذخهبثصبص نسينتذشو دنابصثن بصنبنصش نبصخهبصث
                  مختبصمن ذخمتيص تزس نتشيس ،يشتي يشنايشهنري ذنتشسنش
                </Text>
                .
              </Padding>
            ) : (
              <div>last one</div>
            )}
          </div>
          <Divider />
          <Row>
            <Col xs={18} sm={18} md={18} lg={23} xl={23}>
              <TextSubtitle>المنتجات تش نسضش</TextSubtitle>
            </Col>
            <Col xs={6} sm={6} md={6} lg={1} xl={1}>
              <FieldTimeOutlined style={{ fontSize: '25px' }} />
            </Col>
            <Col span={24}>
              <ul>
                <ListItem>
                  اصاثنرنصرينصريط نرضيمن منتصضيخحك مضيحخي بمتيضحخ طمن ضيحجن
                  جتسكضي. بحخبنكوصب صبخحجضك يمنسش حضيضي نهتيسص حضج ض؛؛ يوصخ
                  منتيمصث كنيضصجح وذت ضصكمضصج صتصا كمسضحج صسزض2صخ يسصثيو مضصح2،
                  نصتيخهص ميسج ورضشزت حجيكص نتيصمي مصنتسصوي صثمن ننيص ينسثبنرثب
                </ListItem>
                <ListItem>
                  اصاثنرنصرينصريط نرضيمن منتصضيخحك مضيحخي بمتيضحخ طمن ضيحجن
                  جتسكضي. بحخبنكوصب صبخحجضك يمنسش حضيضي نهتيسص حضج ض؛؛ يوصخ
                  منتيمصث كنيضصجح وذت ضصكمضصج صتصا كمسضحج صسزض2صخ يسصثيو مضصح2،
                  نصتيخهص ميسج ورضشزت حجيكص نتيصمي مصنتسصوي صثمن ننيص ينسثبنرثب
                </ListItem>
              </ul>
            </Col>
          </Row>
          <Row style={{ padding: '5% 0%' }}>
            <Col xs={18} sm={18} md={18} lg={23} xl={23}>
              <TextSubtitle>المنتجات تش نسضش</TextSubtitle>
            </Col>
            <Col xs={6} sm={6} md={6} lg={1} xl={1}>
              <RollbackOutlined style={{ fontSize: '25px' }} />
            </Col>
            <Col span={24}>
              <ul>
                <ListItem>
                  اصاثنرنصرينصريط نرضيمن منتصضيخحك مضيحخي بمتيضحخ طمن ضيحجن
                  جتسكضي. بحخبنكوصب صبخحجضك يمنسش حضيضي نهتيسص حضج ض؛؛ يوصخ
                  منتيمصث كنيضصجح وذت ضصكمضصج صتصا كمسضحج صسزض2صخ يسصثيو مضصح2،
                  نصتيخهص ميسج ورضشزت حجيكص نتيصمي مصنتسصوي صثمن ننيص ينسثبنرثب
                </ListItem>
                <ListItem>
                  اصاثنرنصرينصريط نرضيمن منتصضيخحك مضيحخي بمتيضحخ طمن ضيحجن
                  جتسكضي. بحخبنكوصب صبخحجضك يمنسش حضيضي نهتيسص حضج ض؛؛ يوصخ
                  منتيمصث كنيضصجح وذت ضصكمضصج صتصا كمسضحج صسزض2صخ يسصثيو مضصح2،
                  نصتيخهص ميسج ورضشزت حجيكص نتيصمي مصنتسصوي صثمن ننيص ينسثبنرثب
                </ListItem>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col xs={18} sm={18} md={18} lg={23} xl={23}>
              <TextSubtitle>المنتجات تش نسضش</TextSubtitle>
            </Col>
            <Col xs={6} sm={6} md={6} lg={1} xl={1}>
              <CalendarOutlined style={{ fontSize: '25px' }} />
            </Col>
            <Col span={24}>
              <ul>
                <ListItem>
                  اصاثنرنصرينصريط نرضيمن منتصضيخحك مضيحخي بمتيضحخ طمن ضيحجن
                  جتسكضي. بحخبنكوصب صبخحجضك يمنسش حضيضي نهتيسص حضج ض؛؛ يوصخ
                  منتيمصث كنيضصجح وذت ضصكمضصج صتصا كمسضحج صسزض2صخ يسصثيو مضصح2،
                  نصتيخهص ميسج ورضشزت حجيكص نتيصمي مصنتسصوي صثمن ننيص ينسثبنرثب
                </ListItem>
                <ListItem>
                  اصاثنرنصرينصريط نرضيمن منتصضيخحك مضيحخي بمتيضحخ طمن ضيحجن
                  جتسكضي. بحخبنكوصب صبخحجضك يمنسش حضيضي نهتيسص حضج ض؛؛ يوصخ
                  منتيمصث كنيضصجح وذت ضصكمضصج صتصا كمسضحج صسزض2صخ يسصثيو مضصح2،
                  نصتيخهص ميسج ورضشزت حجيكص نتيصمي مصنتسصوي صثمن ننيص ينسثبنرثب
                </ListItem>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col xs={18} sm={18} md={18} lg={23} xl={23}>
              <TextSubtitle>المنتجات تش نسضش</TextSubtitle>
            </Col>
            <Col xs={6} sm={6} md={6} lg={1} xl={1}>
              <SafetyCertificateOutlined style={{ fontSize: '25px' }} />
            </Col>
            <Col span={24}>
              <ul>
                <ListItem>
                  اصاثنرنصرينصريط نرضيمن منتصضيخحك مضيحخي بمتيضحخ طمن ضيحجن
                  جتسكضي. بحخبنكوصب صبخحجضك يمنسش حضيضي نهتيسص حضج ض؛؛ يوصخ
                  منتيمصث كنيضصجح وذت ضصكمضصج صتصا كمسضحج صسزض2صخ يسصثيو مضصح2،
                  نصتيخهص ميسج ورضشزت حجيكص نتيصمي مصنتسصوي صثمن ننيص ينسثبنرثب
                </ListItem>
                <ListItem>
                  اصاثنرنصرينصريط نرضيمن منتصضيخحك مضيحخي بمتيضحخ طمن ضيحجن
                  جتسكضي. بحخبنكوصب صبخحجضك يمنسش حضيضي نهتيسص حضج ض؛؛ يوصخ
                  منتيمصث كنيضصجح وذت ضصكمضصج صتصا كمسضحج صسزض2صخ يسصثيو مضصح2،
                  نصتيخهص ميسج ورضشزت حجيكص نتيصمي مصنتسصوي صثمن ننيص ينسثبنرثب
                </ListItem>
              </ul>
            </Col>
          </Row>
          <Divider />
          <TextSubtitle> أسئلة وأجوبة</TextSubtitle>
          <Row style={{ padding: '5% 0%' }}>
            <Col span={24}>
              <Question>المنتجات تش نسضش؟</Question>
            </Col>
            <Col span={24}>
              <Text>
                بشيص بسمنشي خحيس حك ،يج يطش جيصوم ذحصس حشصخح جثضصخ مشصيخه كيص
                منتيذث حخيص م.ش ض هص بسمنب شينشسمشس شسنتشس شصنتسصخهيث ذسمنسر
                شسمتشسي بذنشيوسن ذمنتشس ذسمشسخصث خشييس يم تنزرذسـوث يمخذين،ثص ي
                منذيص متبخهثصتبص ،و نمنـشتبخهصربم، ذ ودذخهبثصبص نسينتذشو دنابصثن
                بصنبنصش نبصخهبصث مختبصمن ذخمتيص تزس نتشيس ،يشتي يشنايشهنري
                ذنتشسنش.
              </Text>
            </Col>
            <Col span={24}>
              <Question>المنتجات تش نسضش؟</Question>
            </Col>
            <Col span={24}>
              <Text>
                بشيص بسمنشي خحيس حك ،يج يطش جيصوم ذحصس حشصخح جثضصخ مشصيخه كيص
                منتيذث حخيص م.ش ض هص بسمنب شينشسمشس شسنتشس شصنتسصخهيث ذسمنسر
                شسمتشسي بذنشيوسن ذمنتشس ذسمشسخصث خشييس يم تنزرذسـوث يمخذين،ثص ي
                منذيص متبخهثصتبص ،و نمنـشتبخهصربم، ذ ودذخهبثصبص نسينتذشو دنابصثن
                بصنبنصش نبصخهبصث مختبصمن ذخمتيص تزس نتشيس ،يشتي يشنايشهنري
                ذنتشسنش.
              </Text>
            </Col>
            <Col span={24}>
              <Question>المنتجات تش نسضش؟</Question>
            </Col>
            <Col span={24}>
              <Text>
                بشيص بسمنشي خحيس حك ،يج يطش جيصوم ذحصس حشصخح جثضصخ مشصيخه كيص
                منتيذث حخيص م.ش ض هص بسمنب شينشسمشس شسنتشس شصنتسصخهيث ذسمنسر
                شسمتشسي بذنشيوسن ذمنتشس ذسمشسخصث خشييس يم تنزرذسـوث يمخذين،ثص ي
                منذيص متبخهثصتبص ،و نمنـشتبخهصربم، ذ ودذخهبثصبص نسينتذشو دنابصثن
                بصنبنصش نبصخهبصث مختبصمن ذخمتيص تزس نتشيس ،يشتي يشنايشهنري
                ذنتشسنش.
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default Help;

const ImageContainer = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
  clear: both;
`;

const ProductImage = styled.img`
  height: 400px;
  width: 100%;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 150px;
  }

  @media (max-width: 414px) {
    height: 125px;
  }
`;

const CustomRow = styled.div`
  // width: 100%;
  // position: absolute;
  // top: 0;
  // text-align: center;
  // background: #11192d87;
  // padding: 14px;
  // box-sizing: border-box;
  // position: absolute;
  // width: 100%;
  // top: 50%;
  // left: 50%;
  // padding: 60px;
  // color: #fff;
  // -webkit-transform: translate(-50%, -50%);
  // transform: translate(-50%, -50%);
  // font-size: 45px;
`;

const StyledOffersTitle = styled.text`
  border-bottom: 1px solid white;
  font-size: 30px;

  @media (max-width: 414px) {
    font-size: 20px;
  }
`;

const GradientDiv = styled.div`
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.8;
  background-image: linear-gradient(
    rgb(17, 25, 45),
    rgba(17, 25, 45, 0.85) 30%,
    rgba(17, 25, 45, 0)
  );
`;

const MoreBg = styled.div`
  position: absolute;
  color: #fff;
  z-index: 9999;
  top: 0;
  margin: 0;
  position: absolute;
  top: 70%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 20px;
  padding: 50px;
  width: inherit;
  text-align: center;
  background: red;
  background-color: rgba(17, 25, 45, 0.55);
  /* opacity: 0.4; */
  background: rgba(17, 25, 45, 0.53);

  @media (max-width: 1024px) {
    padding: 20px;
    -webkit-transform: translate(-50%, -100%);
    top: 70%;
    transform: translate(-50%, -100%);
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    width: 90%;
    top: 70%;
  }
`;

const Tab = styled(Button)`
  background-color: ${(props) => props.theme.primary};
  width: 100%;
  height: auto;
  color: white;
  border: none;
  font-weight: bold;
  font-size: 17px;
  padding: 11px;
  span {
    display: ${(props) => (props.hideSpan ? 'none' : 'inline')};
  }
  border-right: 0.073vw solid white;
  :last-child {
    border: none;
  }
  :hover,
  :focus,
  :active {
    background: #11192d;
    color: #4a7fbd;
    font-weight: 800;
    border: none;
  }
  border-color: #11192d;
  @media (max-width: 1024px) {
    font-size: 15px;
  }
`;

const Container = styled.div``;
const StyledIcon = styled(Icon)`
  font-size: 25px;
  path {
    fill: white;
  }
`;
const Padding = styled.div`
  padding-top: 100px;
`;
const AbsContainer = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
  clear: both;
  transform: translate(0%, -7%);
  @media (max-width: 1024px) {
    transform: unset;
    position: unset;
    height: unset;
    width: unset;
    clear: unset;
  }
`;

const ListItem = styled.li`
  font-size: 16px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: ${(props) => props.theme.primary};
  direction: rtl;
  padding: 3% 0;
  @media (max-width: 1024px) {
    font-size: 12px;
  }
`;
