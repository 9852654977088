/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { message, Row } from 'antd';
import { connect } from 'react-redux';
import {
  getObject,
  checkBuyerCategoryWithSeller,
  addToCart,
  getSavedPrice
} from '../services/CommonServices';
import { setLoading } from '../appRedux/actions/Common';
import { updateUser } from '../appRedux/actions/Auth';
import { getCart } from '../appRedux/actions/Cart';
import { getGeneric } from '../appRedux/actions/Generic';
import CustomButton from '../elements/CustomButton';
import OfferDetailsModal from '../components/OfferDetailsModal';

const ProductOfferCard = (props) => {
  const [offer, setOffer] = useState(props.offer);
  const [info, setInfo] = useState('');
  const [visible, setVisible] = useState(false);
  const [offerProduct, setOfferProduct] = useState();
  const [quantityCarton, setQuantityCarton] = useState(0);
  const [quantityPackage, setQuantityPackage] = useState(0);
  const [addOns, setAddOns] = useState([]);
  const [addOnObjects, setAddOnObjects] = useState([]);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [saved, setSaved] = useState(1);
  useEffect(() => {
    setLoadingDetails(true);
    setOffer(props.offer);
    if (get(props, 'offer.optional_3') === 'carton') {
      setQuantityCarton(get(props, 'offer.optional_1'));
    } else {
      setQuantityPackage(get(props, 'offer.optional_1'));
    }
    getObject('companyProducts/product', {
      _id: get(props, 'offer.product_id')
    }).then((res) => {
      setLoadingDetails(false);
      setOfferProduct(res.data);
      getSavedPrice(res.data, setSaved, props.offer);
    });
    if (offer.bundles) {
      const products = offer.bundles.map((bundle) => bundle.addOnProduct);
      getObject('companyProducts/offerAddons', {
        products
      }).then((res) => {
        setAddOns(res.data);
      });
    }
    if (get(props, 'offer.type') === 3) {
      setInfo('واحصل على منتجات هدية');
    } else if (get(props, 'offer.type') === 1) {
      const quantityInfo = ` وفر ${saved.toFixed(2)} جنيه`;
      setInfo(quantityInfo);
    } else {
      const discountInfo = `خصم ${get(props, 'offer.optional_2', 0)}%`;
      setInfo(discountInfo);
    }
  }, [props.offer, saved]);
  useEffect(() => {
    if (addOns.length) {
      const addOnObjects = [];
      // eslint-disable-next-line array-callback-return
      addOns.map((addOn) => {
        // eslint-disable-next-line array-callback-return
        offer.bundles.map((bundle) => {
          if (bundle.addOnProduct === addOn._id) {
            const addOnObject = {
              addOnProduct: true,
              _id: addOn._id,
              name: { ar: addOn.name.ar },
              images: addOn.images,
              quantityCarton: bundle.package === 'carton' ? bundle.quantity : 0,
              quantityPackage: bundle.package === 'unit' ? bundle.quantity : 0,
              buyerCategories: addOn.buyerCategories,
              packageUnit: addOn.packageUnit
            };
            addOnObjects.push(addOnObject);
          }
        });
      });
      setAddOnObjects(addOnObjects);
    }
  }, [addOns]);
  const addOfferToCart = () => {
    if (!props.auth.token) {
      window.scrollTo(0, 0);
      props.history.push('/login');
    } else {
      const buyerCategory = checkBuyerCategoryWithSeller({
        distributor: props.distributor,
        buyer: props.auth.user,
        company: props.company
      });
      const offerBuyerCategory = props.offer.buyer_cat;
      if (!buyerCategory) {
        props.setHandshake(true);
      } else {
        if (buyerCategory !== offerBuyerCategory) {
          message.warn(' هذا العرض غير متوفر لقطاع سعرك ! ');
        } else {
          addToCart({
            props,
            companyProduct: props.offer,
            offer: props.offer,
            company: props.company,
            distributor: props.distributor,
            distributorProduct: null,
            countPackage: undefined,
            countCarton: 1,
            carton: undefined,
            offerProduct
          });
        }
      }
    }
  };
  return (
    <React.Fragment>
      <OfferDetailsModal
        offer={offer}
        visible={visible}
        setVisible={setVisible}
        product={offerProduct}
        quantityCarton={quantityCarton}
        quantityPackage={quantityPackage}
        addOns={addOnObjects}
      />
      <ProductContainer>
        <LabelContainer>
          <img alt={'product'} src={require('../assets/images/label 2.svg')} />
          <LabelText>{info}</LabelText>
        </LabelContainer>
        <ImageContainer>
          <img
            alt={'product'}
            src={get(
              props.offer,
              'images[0]',
              get(
                offerProduct,
                'images.0',
                require('../assets/images/repzone-placeholder.png')
              )
            )}
          />
        </ImageContainer>
        {/* <ProductInfoContainer> */}
        <OfferDetailsName>
          <div>{get(props.offer, 'name')}</div>
        </OfferDetailsName>

        <Row>
          <OfferDetailsText>
            <a onClick={() => setVisible(true)} disabled={loadingDetails}>
              تفاصيل العرض
            </a>
          </OfferDetailsText>
        </Row>
        <Row />
        <Row>
          <OfferButtonHolder>
            <OfferButton
              onClick={() => {
                addOfferToCart();
              }}
            >
              احصل على العرض
            </OfferButton>
          </OfferButtonHolder>
        </Row>
      </ProductContainer>
    </React.Fragment>
  );
};
const mapStateToProps = ({ main, auth, commonData }) => ({
  main,
  auth,
  commonData
});
export default connect(mapStateToProps, {
  updateUser,
  getGeneric,
  setLoading,
  getCart
})(ProductOfferCard);

const ProductContainer = styled.div`
  width: 100%;
  font-size: 1vw;
  text-align: center;
  direction: ltr;
  margin: 40px 0;
  margin-bottom: 20px;
`;
const ImageContainer = styled.div`
  width: 100%;
  /* margin-top: 30px; */
  margin-bottom: 18px;
  img {
    width: 100%;
    height: 80px;
    object-fit: contain;
  }
`;

const LabelContainer = styled.div`
  width: 100%;
  position: relative;
  img {
    width: 70%;
    height: 21px;
    object-fit: cover;
    margin-right: 5vw;
    margin-bottom: 2vh;
    @media (max-width: 414px) {
      width: 100%;
    }
  }
`;

const LabelText = styled.div`
  position: absolute;
  top: 0px;
  left: 30px;
  color: #ffff;
  font-size: 14px;
  direction: rtl;
  font-weight: bold;
`;

const OfferButton = styled(CustomButton)`
  position: absolute;
  left: 0;
  position: absolute;
  width: 60%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-top: 20px;
  border-radius: 32px;
  font-size: 12px;
`;

const OfferDetailsText = styled.div`
  a {
    font-size: 16px;
    text-align: center;
  }
`;

const OfferButtonHolder = styled.div`
  margin: 20px auto;
  display: block;
  width: 50%;
  @media (max-width: 414px) {
    width: 100%;
  }
`;

const OfferDetailsName = styled.div`
  font-size: 17px;
  text-align: center;
`;
