import { all, call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import get from 'lodash/get';
import {
  getOffersSuccess,
  getOffersFailure,
  getNonHandshakedOffersSuccess,
  getNonHandshakedOffersFailure,
  getProductOffersSuccess,
  getProductOffersFailure
} from '../actions/Products';
import config from '../../config';
import {
  GET_OFFERS,
  GET_PRODUCT_OFFERS,
  GET_NON_HANDSHAKED_OFFERS
} from '../../constants/ActionTypes';

const getOffersRequest = ({ zone, token, skip, limit }) => {
  if (!token) {
    return axios({
      method: 'get',
      url: `${config.url}offers/guestOffers`,
      params: { skip, limit }
    });
  }
  return axios({
    method: 'get',
    url: `${config.url}offers/offers`,
    params: { zone, token, skip, limit }
  });
};

function* getOffers(action) {
  try {
    const offers = yield call(getOffersRequest, action.payload);
    yield put(getOffersSuccess(get(offers, 'data')));
  } catch (error) {
    yield put(getOffersFailure(error));
  }
}

const getNonHandhsakedOffersRequest = ({
  zone,
  token,
  skip,
  limit,
  buyerCatFilter
}) => {
  return axios({
    method: 'get',
    url: `${config.url}offers/nonHandshakedOffers`,
    params: { zone, token, skip, limit, buyerCatFilter }
  });
};

function* getNonHandhsakedOffers(action) {
  const buyerCatFilter = 'buyer_cat = "5dc96196a5d2ad319ae9725e"';
  action.payload.buyerCatFilter = buyerCatFilter;
  try {
    const offers = yield call(getNonHandhsakedOffersRequest, action.payload);
    yield put(getNonHandshakedOffersSuccess(get(offers, 'data')));
  } catch (error) {
    yield put(getNonHandshakedOffersFailure(error));
  }
}

const getProductOffersRequest = ({ zone, product, token }) => {
  if (!token) {
    return axios({
      method: 'get',
      url: `${config.url}offers/productGuestOffers`,
      params: { product }
    });
  }
  return axios({
    method: 'get',
    url: `${config.url}offers/productOffers`,
    params: { zone, product, token }
  });
};

function* getProductOffers(action) {
  try {
    const offers = yield call(getProductOffersRequest, action.payload);
    yield put(getProductOffersSuccess(get(offers, 'data')));
  } catch (error) {
    yield put(getProductOffersFailure(error));
  }
}

export default function* rootSaga() {
  yield all([yield takeEvery(GET_OFFERS, getOffers)]);
  yield all([
    yield takeEvery(GET_NON_HANDSHAKED_OFFERS, getNonHandhsakedOffers)
  ]);
  yield all([yield takeEvery(GET_PRODUCT_OFFERS, getProductOffers)]);
}
