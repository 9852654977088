import React from 'react';
import { Row, Col, Icon, Input, Form, Button, message } from 'antd';
import styled from 'styled-components';
import { connect } from 'react-redux';
import get from 'lodash/get';
import CustomDivider from '../elements/CustomDivider.jsx';
import EditSvg from '../icons/EditSvg.jsx';
import { updateObject } from '../services/CommonServices';
import { updateUser } from '../appRedux/actions/Auth';

const ChangePassword = (props) => {
  const { getFieldDecorator } = props.form;
  const handleSubmit = (props) => {
    const { form } = props;
    form.validateFields((err, fieldsValue) => {
      if (err) {
        message.warning('من فضلك أكمل الخانات ');
        return;
      }

      updateObject('buyers/changePassword', fieldsValue)
        .then(() => {
          message.destroy();
          props.updateUser();
          message.success('تم تعديل كلمة السر بنجاح');
        })
        .catch((err) => {
          message.destroy();
          message.warn(
            get(
              err,
              'response.data.error',
              get(err, 'response.data', 'يوجد خطأ')
            )
          );
        });
    });
  };

  return (
    <Container>
      <StyledForm>
        <Row noBorder>
          <Col lg={12} xs={2}>
            <StyledIcon component={EditSvg} />
          </Col>
          <Col lg={12} xs={10}>
            <Label>تغيير كلمة السر</Label>
          </Col>
        </Row>

        <Welcome>يمكنك تغيير كلمة السر الخاصة بك في اي وقت</Welcome>
        <Row type='flex' justify='center' align='middle'>
          <Col lg={6} sm={6} md={6} xs={24}>
            <Label>كلمة السر الحالية :</Label>
          </Col>
          <Col lg={18} sm={18} md={18} xs={24}>
            {getFieldDecorator('currentPassword', {
              rules: [
                {
                  required: true,
                  message: 'من فضلك ادخل كلمة السر'
                }
              ]
            })(
              <TextArea
                prefix={<Icon type='lock' />}
                type='password'
                placeholder=' كلمة السر الحالية'
              />
            )}
          </Col>
        </Row>
        <CustomDivider />
        <Row type='flex' justify='center' align='middle'>
          <Col lg={6} sm={6} md={6} xs={24}>
            <Label>تأكيد كلمة السر :</Label>
          </Col>
          <Col lg={18} sm={18} md={18} xs={24}>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'من فضلك ادخل كلمة السر'
                }
              ]
            })(
              <TextArea
                prefix={<Icon type='lock' />}
                type='password'
                placeholder='تأكيد كلمة السر'
              />
            )}
          </Col>
        </Row>
        <CustomDivider />

        <Row type='flex' justify='center' align='middle'>
          <Col lg={6} sm={6} md={6} xs={24}>
            <Label> تاكيد كلمة السر :</Label>
          </Col>
          <Col lg={18} sm={18} md={18} xs={24}>
            {getFieldDecorator('rePassword', {
              rules: [
                {
                  required: true,
                  message: 'من فضلك ادخل كلمة السر'
                }
              ]
            })(
              <TextArea
                prefix={<Icon type='lock' />}
                type='password'
                placeholder='كلمة السر'
              />
            )}
          </Col>
          <Col>
            {' '}
            <CustomButton
              onClick={() => {
                handleSubmit(props);
              }}
            >
              <div>حفظ</div>
            </CustomButton>
          </Col>
        </Row>

        <CustomDivider />
      </StyledForm>
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  margin: 60px 0;

  .ant-input[disabled],
  input,
  .ant-select-selection,
  .ant-select-selection.ant-select-selection--single {
    padding-left: 30px;
    border: 2px solid rgb(151, 151, 151);
    height: 40px;
    font-size: 16px;
    border-radius: 0;
    @media (max-width: 414px) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 414px) {
    .anticon svg {
      display: inline-block;
      width: 20px;
      height: 20px;
    }
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
`;
const Label = styled.h1`
  font-size: 21px;
  margin: 0;
  line-height: normal;
  font-weight: 900;
  @media (max-width: 414px) {
    margin-bottom: 15px;
    text-align: right;
    font-size: 19px;
    font-weight: 900;
  }
`;
const TextArea = styled(Input)`
  .ant-input-affix-wrapper .ant-input {
    width: 100%;
    height: 40px;
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: normal;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(151, 151, 151);
    border-image: initial;
  }
`;
const StyledIcon = styled(Icon)`
  float: left;
  font-size: 1vw;
  font-weight: normal;
  margin-top: 3px;
  cursor: pointer;
  path {
    fill: ${(props) => props.theme.primary};
  }

  @media (max-width: 767px) {
    float: right;
  }
`;
const Welcome = styled.div`
  font-size: 19px;
  font-family: 'Cairo-Light';
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  float: right;
  color: ${(props) => props.theme.primary};
  padding-bottom: 2.5vw;
`;
const CustomButton = styled(Button)`
  background-color: #4a7fbd;
  border-radius: 1vw;
  width: 200px;
  color: #fff;
  height: inherit;
  padding: 3px;
  box-shadow: none;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    padding: 14px;
  }

  @media (max-width: 767px) {
    padding: 8px;
  }
  background-color: ${(props) => props.theme.accent};
  border-radius: 1vw;
`;
const mapStateToProps = ({ main, auth }) => ({ main, auth });

const WrappedChangePasswordForm = Form.create({ name: 'ChangePasswordForm' })(
  ChangePassword
);
export default connect(mapStateToProps, { updateUser })(
  WrappedChangePasswordForm
);
