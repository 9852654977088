import get from 'lodash/get';
import {
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER,
  UPDATE_USER,
  SIGNOUT_USER,
  UPDATE_USER_SUCCESS
} from '../../constants/ActionTypes';

export const userSignIn = ({ accountPhoneNumber, password, type }) => ({
  type: SIGNIN_USER,
  payload: {
    accountPhoneNumber,
    password,
    type
  }
});

export const userSignOut = (props) => {
  localStorage.removeItem('token');
  props.history.push('/login');

  return {
    type: SIGNOUT_USER
  };
};

export const userSignInSuccess = (authUser) => ({
  type: SIGNIN_USER_SUCCESS,
  payload: {
    user: authUser,
    token: get(authUser, 'token')
  }
});

export const showAuthMessage = (message) => ({
  type: SHOW_MESSAGE,
  payload: message
});

export const updateUser = (payload) => ({
  type: UPDATE_USER,
  payload: {
    onSuccess: payload?.onSuccess,
    onFailure: payload?.onFailure
  }
});

export const updateUserSuccess = (user) => ({
  type: UPDATE_USER_SUCCESS,
  payload: {
    user
  }
});
