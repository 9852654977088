import styled from 'styled-components';

const Count = styled.p`
  color: ${(props) => props.theme.accent};
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 800;
  text-align: center;
`;
export default Count;
