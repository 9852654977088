/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Icon, Row } from 'antd';
import get from 'lodash/get';
import CarSvg from '../icons/CarSvg';
import CheckoutSingleProduct from './CheckoutSingleProduct';
import CustomDivider from '../elements/CustomDivider';
import CustomButton from '../elements/CustomButton';
import { productQuantityPricesTotal } from '../services/CommonServices';

const CheckoutShipment = (props) => {
  const theme = { accent: 'green' };
  const [moneyBack, setMoneyBack] = useState(0);
  const [pointsActivated] = useState(
    get(props.order, 'seller.company.pointsActivated', false)
  );
  const [total, setTotal] = useState(0);
  const [discountedTotal, setDiscountedTotal] = useState();

  useEffect(() => {
    let totalPrice = 0;
    get(props.order, 'products', []).map((productEntry) => {
      totalPrice += Number(
        productQuantityPricesTotal(productEntry, props.order, 'checkout')
      );
      return totalPrice;
    });
    setTotal(totalPrice);
  }, [props.order]);

  useEffect(() => {
    if (props.conversionRate)
      setMoneyBack(Number(props.points) / Number(props.conversionRate));
  }, [props.conversionRate, props.points]);

  useEffect(() => {
    if (discountedTotal !== undefined) {
      const tempOrders = JSON.parse(JSON.stringify(props.tempOrders));
      tempOrders[props.orderIndex].discountedTotal = discountedTotal;
      props.setTempOrders(tempOrders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountedTotal]);

  const usingPoints = () => {
    if (moneyBack <= total) {
      props.setDiscountedPrice(props.discountedPrice - moneyBack);
      setDiscountedTotal(Number(total) - Number(moneyBack));
      props.setPoints(0);
      props.setUsedPoints(props.points);
    } else {
      props.setDiscountedPrice(props.discountedPrice - total);
      setDiscountedTotal(0);
      const usedPoints = Number(total) * Number(props.conversionRate);
      props.setUsedPoints(usedPoints);
      const remainingPoints = Number(props.points) - Number(usedPoints);
      props.setPoints(remainingPoints);
    }
  };

  return (
    <React.Fragment>
      <Box>
        <LightText>{`شحنة ${props.orderIndex + 1}`}</LightText>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          {discountedTotal !== undefined && (
            <DiscountText>{discountedTotal.toFixed(2)}</DiscountText>
          )}
          <TotalText discountedTotal={discountedTotal}>
            {total.toFixed(2)}
          </TotalText>
        </div>
      </Box>

      <RouteDetails>
        <Route>
          <SmallText> من:</SmallText>
          <LargeText> {get(props.order, 'seller.name.ar', '')}</LargeText>
        </Route>
        <Flex>
          <Dot />
          <Line />
          <CarIcon component={CarSvg} />
          <Line />
          <Dot />
        </Flex>
        <Route>
          <SmallText> الي:</SmallText>
          <LargeText> {get(props, 'auth.user.name.ar', '')}</LargeText>
        </Route>
      </RouteDetails>
      {pointsActivated && props.conversionRate && (
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <CustomButton
            width={'60%'}
            theme={theme}
            onClick={() => {
              usingPoints();
            }}
            disabled={props.points === 0 || discountedTotal <= 0}
          >
            استخدم نقاطك
          </CustomButton>
        </Row>
      )}
      {pointsActivated && props.conversionRate && (
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <h5>
            استخدام نقاطك سيعطيك تخفيض
            {moneyBack > total ? total : moneyBack} جنيه علي هذه الشحنة
          </h5>
        </Row>
      )}
      <Row gutter={1}>
        {get(props.order, 'products', []).map((productEntry, index) => (
          <React.Fragment>
            <CheckoutSingleProduct
              {...props}
              seller={props.order.seller}
              product={productEntry}
            />
            {index === props.order.products.length - 1 ? null : (
              <CustomDivider bottom={'10px'} top={'10px'} />
            )}
          </React.Fragment>
        ))}
      </Row>
    </React.Fragment>
  );
};
export default CheckoutShipment;
const Flex = styled.div`
  display: flex;
  align-items: center;
`;
const Box = styled.div`
  background-color: ${(props) => props.theme.primary};
  color: white;
  text-align: center;
  display: flex;
  justify-content: space-between;
  padding: 4px 8px;
  margin-top: 0.73vw;
  @media (max-width: 768px) {
  }
  @media (min-width: 1600px) {
  }
`;

const LightText = styled.div`
  font-size: 15px;
  font-weight: 300;
`;

const TotalText = styled.div`
  font-size: 15px;
  font-weight: 300;
  text-decoration: ${(props) =>
    props.discountedTotal !== undefined ? 'line-through' : undefined};
  color: ${(props) =>
    props.discountedTotal !== undefined ? 'red' : undefined};
`;

const DiscountText = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: limegreen;
  margin-left: 10px;
`;

const RouteDetails = styled.div`
  margin: 0.73vw 0;
  opacity: 0.77;
  background-color: #e1e5ea;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    padding: 10px 0;
    margin-bottom: 10px;
  }
`;

const CarIcon = styled(Icon)`
  font-size: 35px;
  margin: 0.7vw;
  transform: rotateY(180deg);
  path {
    fill: black;
  }
`;

const SmallText = styled.div`
  font-size: 12px;
  text-align: center;
  margin-bottom: 5px;
  font-weight: 600;
  color: #11192d;
  @media (max-width: 767px) {
    text-align: center;
  }
`;

const LargeText = styled.div`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #11192d;

  @media (max-width: 767px) {
    margin: 0 10px;
    text-align: center;
  }
`;

const Line = styled.div`
  border: 1px solid #11192d;
  width: 13px;
  margin-top: 0.366vw;
`;

const Dot = styled.div`
  height: 9px;
  width: 9px;

  background-color: #11192d;
  border-radius: 50%;
  margin-top: 1%;
`;

const Route = styled.div`
  margin: 10px;
  margin-bottom: 25px;
  @media (max-width: 768px) {
    margin: 0vw;
  }
`;
