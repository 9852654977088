/* eslint-disable import/extensions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from 'react';
import { Row, Col, message, Icon, Spin } from 'antd';
import styled from 'styled-components';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { ScrollTo } from 'react-scroll-to';
import randomstring from 'randomstring';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';
import { getGeneric, updateGeneric } from '../appRedux/actions/Generic';
import { updateUser, userSignOut } from '../appRedux/actions/Auth';
import { emptyCart } from '../appRedux/actions/Cart';
import ProfileForm from '../components/ProfileForm';
import OrderHistory from '../components/OrderHistory';
import MyConnections from '../components/MyConnections';
import ChangePassword from '../components/ChangePassword';
import InstitutionForm from '../components/InstitutionForm';
import config from '../config';
import { updateObject } from '../services/CommonServices';

const TabStyle = { borderBocttom: 'solid 0.3vw #11192d' };
const TabStyles = { fontFamily: 'Cairo-Bold' };
const s3Url = 'https://repzone-static.s3.amazonaws.com/';
const uploadOptions = {
  server: config.url.slice(0, -1),
  signingUrlWithCredential: true,
  s3path: `buyers/${randomstring.generate(7)}/`,
  autoUpload: true
};
const handleFinishedUpload = (info, setLogo, props, setLoading) => {
  updateObject('buyers', { logo: info.fileUrl })
    .then(() => {
      setLoading(false);
      message.destroy();
      props.updateUser();
      message.success('تم تعديل اللوجو بنجاح');
      setLogo(info.fileUrl);
    })
    .catch(() => {
      setLoading(false);
      message.destroy();
      message.warn('يوجد خطأ');
    });
};
const Profile = (props) => {
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(0);
  const [logo, setLogo] = useState(
    get(props, 'auth.user.logo.length') > 0
      ? get(props, 'auth.user.logo')
      : require('../assets/images/repzone-placeholder.png')
  );

  return (
    <div>
      <ProfileHolderMobile>
        <div className='container'>
          <Row>
            <Col lg={15} md={15} sm={24} xs={24}>
              {active === 0 ? (
                <InstitutionForm />
              ) : active === 2 ? (
                <ProfileForm />
              ) : active === 1 ? (
                <OrderHistory />
              ) : active === 5 ? (
                <MyConnections type='companies' />
              ) : active === 3 ? (
                <MyConnections type='distributors' />
              ) : active === 6 ? (
                <ChangePassword />
              ) : (
                ''
              )}
            </Col>
            <Col
              lg={{ span: 7, offset: 2 }}
              md={{ span: 7, offset: 2 }}
              xs={{ span: 24, offset: 0 }}
            >
              <RightContainer>
                <LogoContainer>
                  <StyledDropzone
                    onDropAccepted={() => setLoading(true)}
                    onFinish={(info) =>
                      handleFinishedUpload(info, setLogo, props, setLoading)
                    }
                    s3Url={s3Url}
                    maxSize={1024 * 1024 * 5}
                    upload={uploadOptions}
                  >
                    <div>
                      <Logo alt='logo' src={logo} loading={loading} />
                      {loading && <StyledSpinner />}
                    </div>
                  </StyledDropzone>
                  <StyledIconEdit type='edit' twoToneColor='#4a7fbd' />
                </LogoContainer>
                <Welcome> أهلا بك</Welcome>
                <BuyerName>{get(props, 'auth.user.name.ar', '')}</BuyerName>

                <Tab defaultActiveKey='1'>
                  <ScrollTo>
                    {({ scroll }) => (
                      <div>
                        <a onClick={() => scroll({ y: 600, smooth: true })}>
                          <TabRowMobile
                            style={active === 1 ? TabStyles : undefined}
                            onClick={() => {
                              setActive(1);
                            }}
                          >
                            الطلبات السابقه
                          </TabRowMobile>
                        </a>
                      </div>
                    )}
                  </ScrollTo>
                  <ScrollTo>
                    {({ scroll }) => (
                      <div>
                        <a onClick={() => scroll({ y: 600, smooth: true })}>
                          <TabRowMobile
                            style={active === 0 ? TabStyles : undefined}
                            onClick={() => {
                              setActive(0);
                            }}
                          >
                            البيانات
                          </TabRowMobile>
                        </a>
                      </div>
                    )}
                  </ScrollTo>
                  <ScrollTo>
                    {({ scroll }) => (
                      <div>
                        <a onClick={() => scroll({ y: 600, smooth: true })}>
                          <TabRowMobile
                            style={active === 2 ? TabStyles : undefined}
                            onClick={() => {
                              setActive(2);
                            }}
                          >
                            العنوان
                          </TabRowMobile>
                        </a>
                      </div>
                    )}
                  </ScrollTo>
                  <ScrollTo>
                    {({ scroll }) => (
                      <div>
                        <a onClick={() => scroll({ y: 600, smooth: true })}>
                          <TabRowMobile
                            style={active === 3 ? TabStyles : undefined}
                            onClick={() => {
                              setActive(3);
                            }}
                          >
                            الموزعين
                          </TabRowMobile>
                        </a>
                      </div>
                    )}
                  </ScrollTo>
                  <ScrollTo>
                    {({ scroll }) => (
                      <div>
                        <a onClick={() => scroll({ y: 600, smooth: true })}>
                          <TabRowMobile
                            style={active === 6 ? TabStyles : undefined}
                            onClick={() => {
                              setActive(6);
                            }}
                          >
                            تغيير كلمة السر
                          </TabRowMobile>
                        </a>
                      </div>
                    )}
                  </ScrollTo>
                  <ScrollTo>
                    {({ scroll }) => (
                      <div>
                        <a onClick={() => scroll({ y: 600, smooth: true })}>
                          <TabRowMobile
                            style={active === 4 ? TabStyles : undefined}
                            onClick={() => {
                              props.userSignOut(props);
                              props.emptyCart();
                            }}
                          >
                            الخروج
                          </TabRowMobile>
                        </a>
                      </div>
                    )}
                  </ScrollTo>
                </Tab>
              </RightContainer>
            </Col>
          </Row>
        </div>
      </ProfileHolderMobile>

      <ProfileHolder>
        <div className='container'>
          <Row>
            <Col lg={15} md={15} sm={24} xs={24}>
              {active === 0 ? (
                <InstitutionForm />
              ) : active === 2 ? (
                <ProfileForm />
              ) : active === 1 ? (
                <OrderHistory />
              ) : active === 5 ? (
                <MyConnections type='companies' />
              ) : active === 3 ? (
                <MyConnections type='distributors' />
              ) : active === 6 ? (
                <ChangePassword />
              ) : (
                ''
              )}
            </Col>
            <Col
              lg={{ span: 7, offset: 2 }}
              md={{ span: 7, offset: 2 }}
              xs={{ span: 24, offset: 0 }}
            >
              <RightContainer>
                <LogoContainer>
                  <StyledDropzone
                    onDropAccepted={() => setLoading(true)}
                    onFinish={(info) =>
                      handleFinishedUpload(info, setLogo, props, setLoading)
                    }
                    s3Url={s3Url}
                    maxSize={1024 * 1024 * 5}
                    upload={uploadOptions}
                  >
                    <div>
                      <Logo alt='logo' src={logo} loading={loading} />
                      {loading && <StyledSpinner />}
                    </div>
                  </StyledDropzone>
                  <StyledIconEdit type='edit' twoToneColor='#4a7fbd' />
                </LogoContainer>
                <Welcome>أهلا بك!</Welcome>
                <BuyerName>{get(props, 'auth.user.name.ar', '')}</BuyerName>
                <Tab defaultActiveKey='1'>
                  <TabRow
                    style={active === 0 ? TabStyle : undefined}
                    onClick={() => {
                      setActive(0);
                    }}
                  >
                    البيانات
                  </TabRow>
                  <TabRow
                    style={active === 2 ? TabStyle : undefined}
                    onClick={() => {
                      setActive(2);
                    }}
                  >
                    العنوان
                  </TabRow>
                  <TabRow
                    style={active === 1 ? TabStyle : undefined}
                    onClick={() => {
                      setActive(1);
                    }}
                  >
                    الطلبات السابقة
                  </TabRow>
                  <TabRow
                    style={active === 3 ? TabStyle : undefined}
                    onClick={() => {
                      setActive(3);
                    }}
                  >
                    الموزعين
                  </TabRow>
                  <TabRow
                    style={active === 6 ? TabStyle : undefined}
                    onClick={() => {
                      setActive(6);
                    }}
                  >
                    تغيير كلمة السر
                  </TabRow>
                  <TabRow
                    style={active === 4 ? TabStyle : undefined}
                    onClick={() => {
                      props.userSignOut(props);
                      props.emptyCart();
                    }}
                  >
                    الخروج
                  </TabRow>
                </Tab>
              </RightContainer>
            </Col>
          </Row>
        </div>
      </ProfileHolder>
    </div>
  );
};

const ProfileHolderMobile = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;

    .ant-row {
      display: flex;
      flex: 0 1 auto;
      flex-direction: row;
      flex-wrap: wrap-reverse;
    }
  }
`;

const ProfileHolder = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }

  margin: 30px 0;

  .ant-row {
    @media (max-width: 767px) {
      display: flex;
      flex: 0 1 auto;
      flex-direction: row;
      flex-wrap: wrap-reverse;
    }
  }
`;
const Welcome = styled.div`
  font-size: 1.5vw;
  @media (max-width: 768px) {
    font-size: 16px;
    text-align: center;
  }
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.primary};
  margin-top: 10%;
  margin-bottom: 0;
  text-align: center;
`;
const BuyerName = styled.div`
  font-size: 25px;
  @media (max-width: 768px) {
    font-size: 19px;
  }
  text-align: center;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #11192d;
`;
const Tab = styled.div`
  width: 90%;
  margin: auto;
  text-align: center;
  padding-top: 3vw;
`;

const RightContainer = styled.div`
  border-left: solid 0.5px;
  padding-left: 22%;
  @media (max-width: 768px) {
    padding-left: 20px;
    margin: 60px 0;
  }

  @media (max-width: 767px) {
    padding-left: 0;
    margin: 60px 0;
  }
  @media (max-width: 767px) {
    border-left: 0;
    margin: 0;
  }
`;
const Logo = styled.img`
  width: 100%;
  object-fit: contain;
  opacity: 1;
  margin: auto;
  ${({ loading }) => loading && `opacity: 0.3;`};
`;
const LogoContainer = styled.div`
  width: 100%;
  opacity: 1;
  border: solid 1px ${(props) => props.theme.primary};
  padding: 0;
`;
const TabRow = styled.div`
  font-size: 19px;
  :hover {
    color: #4a7fbd;
    transition: 0.1s;
    font-weight: 700;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    width: 100%;
    padding: 10px;
  }

  @media (max-width: 767px) {
  }

  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #11192d;
  text-align: center;
  border-bottom: solid 0.02vw ${(props) => props.theme.primary};
  padding: 10%;
  cursor: pointer;
`;

const TabRowMobile = styled.div`
  width: 50%;
  float: left;
  text-align: right;
  color: #11192d;
  text-align: center;
  border-bottom: solid 0.02vw ${(props) => props.theme.primary};
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
`;
const StyledSpinner = styled(Spin)`
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
const StyledDropzone = styled(DropzoneS3Uploader)`
  border: 2px solid #f1f4f5 !important;
  margin: 0 auto;
  cursor: pointer;
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
`;
const StyledIconEdit = styled(Icon)`
  pointer-events: none;
  font-size: 25px;
  position: absolute;
  right: 19px;
  top: 12px;
  display: none;
  color: #ffffff;
  background: #4a7fbd;
  padding: 5px;
  border-radius: 10px;
  @media (max-width: 768px) {
    font-size: 25px;
  }
  ${LogoContainer}:hover & {
    display: block;
  }
  ${LogoContainer}:active & {
    display: block;
  }
`;
const mapStateToProps = ({ main, auth, cart }) => ({ main, auth, cart });
export default connect(mapStateToProps, {
  getGeneric,
  updateGeneric,
  updateUser,
  userSignOut,
  emptyCart
})(Profile);
