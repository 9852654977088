import { Divider } from 'antd';
import styled from 'styled-components';

const CustomDivider = styled(Divider)`
    background-color:${(props) => props.color || props.theme.primary};
    ${(props) => (props.height ? `height:${props.height};` : '')}
    ${(props) => (props.top ? `margin-top:${props.top};` : '')}
    ${(props) => (props.bottom ? `margin-bottom:${props.bottom};` : '')}
`;
export default CustomDivider;
