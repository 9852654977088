import {
  GET_OFFERS,
  GET_OFFERS_SUCCESS,
  GET_OFFERS_FAILURE,
  GET_PRODUCT_OFFERS,
  GET_PRODUCT_OFFERS_SUCCESS,
  GET_PRODUCT_OFFERS_FAILURE,
  GET_NON_HANDSHAKED_OFFERS,
  GET_NON_HANDSHAKED_OFFERS_SUCCESS,
  GET_NON_HANDSHAKED_OFFERS_FAILURE
} from '../../constants/ActionTypes';

export const getOffers = (payload) => ({
  type: GET_OFFERS,
  payload
});

export const getOffersSuccess = (offers) => ({
  type: GET_OFFERS_SUCCESS,
  payload: offers
});
export const getOffersFailure = (error) => ({
  type: GET_OFFERS_FAILURE,
  payload: error
});

export const getNonHandshakedOffers = (payload) => ({
  type: GET_NON_HANDSHAKED_OFFERS,
  payload
});

export const getNonHandshakedOffersSuccess = (offers) => ({
  type: GET_NON_HANDSHAKED_OFFERS_SUCCESS,
  payload: offers
});
export const getNonHandshakedOffersFailure = (error) => ({
  type: GET_NON_HANDSHAKED_OFFERS_FAILURE,
  payload: error
});

export const getProductOffers = (payload) => ({
  type: GET_PRODUCT_OFFERS,
  payload
});

export const getProductOffersSuccess = (productOffers) => ({
  type: GET_PRODUCT_OFFERS_SUCCESS,
  payload: productOffers
});
export const getProductOffersFailure = (error) => ({
  type: GET_PRODUCT_OFFERS_FAILURE,
  payload: error
});
