import { all, call, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { push } from 'connected-react-router'
import { message } from 'antd'
import get from 'lodash/get'
import {
  showAuthMessage,
  userSignInSuccess,
  updateUserSuccess
} from '../actions/Auth'
import config from '../../config'
import { SIGNIN_USER, UPDATE_USER } from '../../constants/ActionTypes'

const signInUserWithEmailPasswordRequest = async ({
  accountPhoneNumber,
  password,
  type
}) =>
  axios({
    method: 'POST',
    url: `${config.url + type}/login`,
    data: { accountPhoneNumber, password }
  }).then((response) => response);

const userUpdateRequest = async () => {
  const token = localStorage.getItem('token');
  return axios({
    method: 'get',
    url: `${config.url}buyers/getBuyer`,
    params: { token }
  })
}

function* signInUserWithEmailPassword({ payload }) {
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, payload)
    localStorage.setItem('token', signInUser.data.token)
    yield put(userSignInSuccess(signInUser.data))
    message.success('مرحبا!')
    yield put(push('/'))
  } catch (error) {
    message.error(get(error, 'response.data.message', 'يوجد خطأ'))
  }
}

function* updateUser({ payload }) {
  try {
    const user = yield call(userUpdateRequest)
    if (payload && payload.onSuccess) {
      payload.onSuccess()
    }
    yield put(updateUserSuccess(get(user, 'data.buyer')))
  } catch (error) {
    if (payload && payload.onFailure) payload.onFailure()
    yield put(showAuthMessage(error))
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword),
    yield takeEvery(UPDATE_USER, updateUser)
  ])
}
