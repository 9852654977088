import axios from 'axios';
import { get } from 'lodash';
import { message, notification } from 'antd';
import config from '../config';

export function getObject(objectName, params) {
  return axios(config.url + objectName, {
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('token')
    },
    params
  });
}
export function toArray(obj) {
  const array = [];
  for (let index = 0; index < Object.keys(obj).length; index += 1) {
    const key = Object.keys(obj)[index];
    if (key === index && get(Object.keys(obj[key]), 'length', 0) > 0)
      array.push(obj[key]);
  }
  return array;
}

export function handleError(error) {
  message.destroy();
  if (error.response && typeof error.response.statusText === 'string') {
    message.error(error.response.statusText);
  } else if (error.request && typeof error.request === 'string') {
    message.error(error.request);
  } else if (error.message && typeof error.message === 'string') {
    message.error('! تعثر الاتصال بالانترنت');
  } else {
    message.error('!حدث خطأ');
  }
}

export async function postObject(objectName, body) {
  message.loading('', 0);
  return axios
    .post(config.url + objectName, body, {
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      }
    })
    .then((response) => {
      message.destroy();
      return response;
    });
}

export function deleteObject(objectName, body) {
  return axios({
    method: 'DELETE',
    url: body._id,
    data: body,
    baseURL: config.url + objectName,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('token')
    }
  });
}

export function updateObject(objectName, body) {
  message.loading('', 0);
  return axios({
    method: 'PUT',
    url: config.url + objectName,
    data: body,
    headers: {
      'Content-Type': 'application/json',
      'x-access-token': localStorage.getItem('token')
    }
  }).then((response) => {
    message.destroy();
    message.success(get(response, 'data.message'));
    return response;
  });
}

export function toCloudinary(image, height, width) {
  if (!image) return null;
  let transformation = '';
  if (height) {
    transformation += `h_${height}${width ? ',' : ''}`;
  }
  if (width) {
    transformation += `w_${width}`;
  }
  return `https://res.cloudinary.com/${config.CLDNRY_ID}/image/fetch/${transformation}/${image}`;
}

export function convertFromISODate(isoDate) {
  const startTime = new Date(isoDate);
  return new Date(startTime.getTime() + startTime.getTimezoneOffset() * 60000);
}

export function calculateAge(birthday) {
  // birthday is a date
  if (!birthday) {
    return '';
  }
  const ageDifMs = Date.now() - new Date(birthday).getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function generateFormItemProps(touched, errors, path) {
  return {
    validateStatus:
      get(touched, path) && get(errors, path) ? 'error' : undefined,
    help:
      get(touched, path) && get(errors, path) ? get(errors, path) : undefined
  };
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function showNotification(message, icon) {
  notification.open({
    message,
    description: ` `,
    icon
  });
}

export function toSingular(string) {
  let result = string.toLowerCase();
  result = capitalizeFirstLetter(result);
  if (
    string.charAt(string.length - 3) === 'i' &&
    string.charAt(string.length - 2) === 'e'
  ) {
    result = result.substring(0, result.length - 3);
    result += 'y';
  } else result = result.substring(0, result.length - 1);
  return result;
}

export function toArabic(number) {
  const id = ['۰', '۱', '۲', '۳', '٤', '٥', '٦', '۷', '۸', '۹', ','];
  return `${number}`.replace(/[0-9]/g, (w) => {
    if (w === '.') return ',';
    return id[+w];
  });
}

export async function handleLogin({ e, form, userSignIn }) {
  e.preventDefault();

  await form.validateFields((err, fieldsValue) => {
    if (err) {
      return;
    }
    fieldsValue.type = 'buyers';
    userSignIn(fieldsValue);
  });
}
export function getBuyerCategoryId(seller, buyer) {
  const buyerFound = get(seller, 'buyers', []).find(
    (sellerBuyer) =>
      get(sellerBuyer, 'buyer._id', get(sellerBuyer, 'buyer')) ===
      get(buyer, '_id')
  );
  return get(buyerFound, 'buyerCategory');
}

export function getPackage(product) {
  const productBuyerCategory = get(product, 'buyerCategories', {});
  const productSaleUnit = get(productBuyerCategory, 'saleUnit');
  return productSaleUnit;
}
export function getUnitPrice(product, buyerCategoryId) {
  const packageObj = getPackage(product, buyerCategoryId);
  const unitPrice =
    get(packageObj, 'price', 0) / get(packageObj, 'packageQuantity', 1);
  return unitPrice;
}

export function getObjectWithId(id, array) {
  for (let i = 0; i < get(array, 'length', 0); i += 1) {
    if (array[i].company && array[i].company._id === id) {
      return array[i];
    }
    if (id === array[i]._id) {
      return array[i];
    }
  }
  return null;
}

export function getNewPrice(
  productId,
  getFieldValue,
  array,
  quantity,
  packageType,
  discount,
  countCarton,
  product
) {
  const discountFraction = 1 - Number(discount) / 100;
  // const product = getObjectWithId(productId, array);
  const oldPrice = get(product, 'buyerCategories', []).filter(
    (buyerCategory) => {
      if (
        getFieldValue ===
        get(buyerCategory, 'buyerCategory._id', 'buyerCategory.buyerCategory')
      ) {
        return buyerCategory.saleUnit.price;
      }
      return null;
    }
  );

  if (packageType === 'unit') {
    /* eslint-disable no-restricted-globals */
    if (
      !isNaN(
        get(oldPrice[0], 'saleUnit.price') /
          get(oldPrice[0], 'saleUnit.packageQuantity')
      ) *
      Number(quantity) *
      discountFraction
    ) {
      return String(
        (get(oldPrice[0], 'saleUnit.price') /
          get(oldPrice[0], 'saleUnit.packageQuantity')) *
          Number(quantity) *
          discountFraction *
          Number(countCarton)
      );
    }
    return 'جاري الحساب ...';
  }
  if (
    !isNaN(
      get(oldPrice[0], 'saleUnit.price') * Number(quantity) * discountFraction
    )
  )
    return String(
      get(oldPrice[0], 'saleUnit.price') *
        Number(quantity) *
        discountFraction *
        Number(countCarton)
    );
  return 'جاري الحساب ...';
}

export function productQuantityPricesTotal(productEntry, props, checkout) {
  if (get(productEntry, 'product.offer')) {
    let price = 0;
    if (get(productEntry, 'product.offer.type') === 3) {
      price =
        productEntry.product.offer.optional_2 *
        (!checkout
          ? get(productEntry, 'quantity[0].quantity', 1)
          : Number(get(productEntry, 'quantity[0].quantity', 1)) /
            Number(get(productEntry, 'product.offer.optional_1')));
    } else if (get(productEntry, 'product.offer.type') === 2) {
      price = getNewPrice(
        get(productEntry, 'product.offer.product_id'),
        get(productEntry, 'product.offer.buyer_cat'),
        [],
        get(productEntry, 'product.offer.optional_1'),
        get(productEntry, 'product.offer.optional_3'),
        get(productEntry, 'product.offer.optional_2'),
        !checkout
          ? get(productEntry, 'quantity[0].quantity', 1)
          : Number(get(productEntry, 'quantity[0].quantity', 1)) /
              Number(get(productEntry, 'product.offer.optional_1')),
        get(productEntry, 'product.offerProduct')
      );
    } else {
      price = getNewPrice(
        get(productEntry, 'product.offer.product_id'),
        get(productEntry, 'product.offer.buyer_cat'),
        get(props, 'main.companyProducts'),
        get(productEntry, 'product.offer.optional_1'),
        get(productEntry, 'product.offer.optional_3'),
        0,
        !checkout
          ? get(productEntry, 'quantity[0].quantity', 1)
          : Number(get(productEntry, 'product.purchasedQuantity', 1)),
        get(productEntry, 'product.offerProduct')
      );
    }
    return parseFloat(price).toFixed(2);
  }
  const product = get(
    productEntry,
    'product.companyProduct',
    get(productEntry, 'product', {})
  );
  const carton = get(product, 'buyerCategories.saleUnit');
  const packagePrice = get(carton, 'price', 0);
  const unitPrice = get(carton, 'price', 0) / get(carton, 'packageQuantity', 1);
  const packageQuantity = Number(get(productEntry, 'quantity[0].quantity', 0));
  const unitQuantity = Number(get(productEntry, 'quantity[1].quantity', 0));
  const price = packagePrice * packageQuantity + unitPrice * unitQuantity;
  return parseFloat(price).toFixed(2);
}

export function addToCart({
  props,
  company,
  distributor,
  companyProduct,
  offer,
  distributorProduct,
  countCarton,
  countPackage,
  carton,
  offerProduct
}) {
  const product = companyProduct || distributorProduct;
  if (countCarton === 0 && countPackage === 0) {
    message.warn('يجب اضافة كمية للعبوة او المنتج ');
    return;
  }
  const body = {
    product: {
      offerProduct,
      _id: get(product, '_id', get(product, 'product_id')),
      name: { ar: get(product, 'name.ar', get(product, 'name')) },
      images: product.images,
      buyerCategories: {
        saleUnit: {
          package: get(
            offerProduct,
            'buyerCategories[0].saleUnit.package',
            get(product, 'buyerCategories.saleUnit.package', 'علبة')
          ),
          packageQuantity: get(
            product,
            'buyerCategories.saleUnit.packageQuantity'
          ),
          price: get(product, 'buyerCategories.saleUnit.price')
        },
        buyerCategory: {
          _id: get(
            product,
            'buyerCategories.buyerCategory._id',
            get(
              product,
              'buyerCategories.buyerCategory',
              get(product, 'buyer_cat')
            )
          ),
          name: {
            ar: get(product, 'buyerCategories.buyerCategory.name.ar', '')
          }
        }
      },
      points: get(product, 'points', 0),
      packageUnit: {
        name: get(
          offerProduct,
          'packageUnit.name',
          get(product, 'packageUnit.name', 'علبة')
        )
      },
      offer
    },
    productType: get(distributor, '_id')
      ? 'DistributorProduct'
      : 'CompanyProduct',
    seller: get(distributor, '_id') ? distributor : company,
    sellerCategory: get(distributor, '_id') ? 'Distributor' : 'Company',
    quantity: [
      {
        saleUnit: get(
          offerProduct,
          'buyerCategories[0].saleUnit.package.name',
          get(carton, 'package.name', 'كرتونة')
        ),
        quantity: countCarton
      },
      {
        saleUnit: get(
          offerProduct,
          'packageUnit.name',
          get(product, 'packageUnit.name', 'علبة')
        ),
        quantity: countPackage
      }
    ]
  };
  // console.log(body, '======> body body');
  props.setLoading(true);
  updateObject('buyers/addToCart', body)
    .then(() => {
      // props.updateUser();
      props.getCart();
      message.destroy();
      props.setLoading(false);
      message.success('!تم اضافة المنتج الي عربة التسوق');
    })
    .catch(/* (err) => console.log(err) */);
}
export function getHighestBuyerCategory(array) {
  if (!Array.isArray(array)) return array;
  const highest = array.sort(
    (a, b) => get(b, 'saleUnit.price') - get(a, 'saleUnit.price')
  );
  return highest[0];
}

function getDistributorInBuyerZone(productId, zone, distributorProducts) {
  const distributorProduct = distributorProducts.find(
    (product) =>
      String(get(product, 'companyProduct._id')) === String(productId) &&
      get(product, 'distributor.zones.zone', []).includes(zone)
  );

  if (distributorProduct) {
    const distributor = get(distributorProduct, 'distributor');
    return {
      distributor,
      distributorProduct
    };
  }
  return null;
}

export function getProductDistributor({
  productId,
  zone,
  distributorProducts
}) {
  const result = getDistributorInBuyerZone(
    productId,
    zone,
    distributorProducts
  );
  const distributor = get(result, 'distributor');
  const distributorProduct = get(result, 'distributorProduct');
  if (distributor && distributorProduct) {
    return {
      distributor,
      distributorProduct
    };
  }
  return null;
}

export function checkIfBuyerConnectedToSeller({
  distributor,
  buyer,
  setLoading
}) {
  if (setLoading) setLoading(true);
  const buyerDistributor = get(buyer, 'distributors', []).find(
    (buyerDistributorEntry) => {
      if (
        String(get(buyerDistributorEntry, 'distributor._id', '')) ===
        String(get(distributor, '_id'))
      ) {
        return true;
      }
      return false;
    }
  );
  if (buyerDistributor) {
    if (setLoading) setLoading(false);
    return true;
  }
  if (setLoading) setLoading(false);
  return false;
}

export function checkIfBuyerPendingToSeller({ distributor, buyer }) {
  const pendingDistributor = get(buyer, 'pendingDistributors', []).find(
    (pendingDistributorEntry) => {
      if (
        String(get(pendingDistributorEntry, 'distributor._id', '')) ===
        String(get(distributor, '_id'))
      ) {
        return true;
      }
      return false;
    }
  );
  if (pendingDistributor) return true;
  return false;
}

export function addSeller({ buyer, distributor, props }) {
  // if distributor
  const zone = get(buyer, 'address.zone._id');
  const buyerCategory = get(buyer, 'buyerCategory._id');
  if (get(distributor, '_id')) {
    updateObject('buyers/addDistributor', {
      distributor: get(distributor, '_id'),
      zone,
      buyerCategory
    }).then(() => {
      props.getGeneric('buyers');
      props.updateUser();
    });
  }
}

export function checkBuyerCategoryWithSeller({ distributor, buyer, company }) {
  if (get(distributor, '_id')) {
    const buyerDistributor = get(buyer, 'distributors', []).find(
      (buyerDistributorEntry) => {
        if (
          String(get(buyerDistributorEntry, 'distributor._id', '')) ===
          String(get(distributor, '_id'))
        ) {
          return buyerDistributorEntry;
        }
        return null;
      }
    );
    if (buyerDistributor)
      return get(
        buyerDistributor,
        'buyerCategory._id',
        get(buyerDistributor, 'buyerCategory')
      );
    return null;
  }
  const buyerCompany = get(buyer, 'companies', []).find((buyerCompanyEntry) => {
    if (
      String(get(buyerCompanyEntry, 'company._id', '')) ===
      String(get(company, '_id'))
    ) {
      return buyerCompanyEntry;
    }
    return null;
  });
  if (buyerCompany)
    return get(
      buyerCompany,
      'buyerCategory._id',
      get(buyerCompany, 'buyerCategory')
    );
  return null;
}
export const getSavedPrice = (product, setSaved, offer) => {
  if (get(offer, 'type') !== 3) {
    const buyerCategory = get(product, 'buyerCategories', []).filter((bc) => {
      return (
        offer.buyer_cat ===
        get(bc, 'buyerCategory._id', get(bc, 'buyerCategory'))
      );
    });
    const priceUnit =
      get(offer, 'optional_3') === 'carton'
        ? Number(get(buyerCategory[0], 'saleUnit.price'))
        : Number(get(buyerCategory[0], 'saleUnit.price')) /
          Number(get(buyerCategory[0], 'saleUnit.packageQuantity'));
    const offerPriceUnit =
      get(offer, 'optional_4') === 'carton'
        ? Number(get(buyerCategory[0], 'saleUnit.price'))
        : Number(get(buyerCategory[0], 'saleUnit.price')) /
          Number(get(buyerCategory[0], 'saleUnit.packageQuantity'));
    const offerPrice =
      get(offer, 'type') === 1
        ? priceUnit * Number(get(offer, 'optional_1'))
        : priceUnit *
          Number(get(offer, 'optional_1')) *
          (1 - Number(get(offer, 'optional_2')) / 100);
    const oldPrice =
      get(offer, 'type') === 1
        ? priceUnit * Number(get(offer, 'optional_1')) +
          offerPriceUnit * Number(get(offer, 'optional_2'))
        : priceUnit * Number(get(offer, 'optional_1'));
    const savedPrice = Number(oldPrice) - Number(offerPrice);
    setSaved(savedPrice);
  }
};
