import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Auth from './Auth'
import Common from './Common'
import Generic from './Generic'
import Cart from './Cart'
import Products from './Products'
import wishlist from './Wishlist'

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth: Auth,
  commonData: Common,
  main: Generic,
  cart: Cart,
  products: Products,
  wishlist
});

export default createRootReducer;
