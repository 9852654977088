import React from 'react';

const MinusSvg = () => (
  <svg
    width='1em'
    height='1em'
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 51.38 4.38'
  >
    <defs>
      <style dangerouslySetInnerHTML={{ __html: '.cls-1{fill:#4c7fba;}' }} />
    </defs>
    <title>mi2</title>
    <path
      className='cls-1'
      d='M-2.5,42.67H-40.87a2.19,2.19,0,0,0-2.19,2.19,2.19,2.19,0,0,0,2.19,2.19H-2.5Z'
      transform='translate(43.06 -42.67)'
    />
    <path
      className='cls-1'
      d='M6.13,42.67h-5v4.38h5a2.19,2.19,0,1,0,0-4.38Z'
      transform='translate(43.06 -42.67)'
    />
  </svg>
);

export default MinusSvg;
