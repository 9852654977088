/* eslint-disable import/extensions */
import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomDivider from '../elements/CustomDivider';
import CustomButton from '../elements/CustomButton';
import {
  productQuantityPricesTotal,
  updateObject
} from '../services/CommonServices';
import { updateUser } from '../appRedux/actions/Auth';

const displayTotal = (props, productsArray) => {
  let total = 0;
  productsArray.forEach((productEntry) => {
    total += parseFloat(productQuantityPricesTotal(productEntry, props));
  });
  return total;
};

const checkoutCart = (props) => {
  updateObject('buyers/checkout').then(() => {
    props.updateUser();
    props.history.push('/checkout');
  });
};

const CartDetails = (props) => (
  <Box>
    <CheckoutButton disabled={props.saving} onClick={() => checkoutCart(props)}>
      <ButtonTitle>المتابعة للدفع</ButtonTitle>
    </CheckoutButton>
    <Title>الاجمالي</Title>
    <CustomDivider top={'1.8vw'} bottom={'1.8vw'} color={'white'} />
    {Object.values(get(props, 'groupedProducts', {})).map(
      (productArray, index) => (
        <div>
          <Title>{`شحنة ${index + 1}`}</Title>
          <Text>{get(productArray[0], 'seller.name.ar', '')}</Text>
          <HorizontalTexts>
            <Text>إجمالي</Text>
            <Text style={{ direction: 'ltr' }}>
              {/* eslint-disable no-restricted-globals */
              !isNaN(displayTotal(props, productArray))
                ? parseFloat(displayTotal(props, productArray)).toFixed(2)
                : 'جاري الحساب ...'}
            </Text>
          </HorizontalTexts>
          <CustomDivider top={'1.8vw'} bottom={'1.8vw'} color={'white'} />
        </div>
      )
    )}

    <HorizontalTexts>
      <Title paddingTop={'1.1vw'}>إجمالي</Title>
      <Text style={{ direction: 'ltr' }}>
        {/* eslint-disable no-restricted-globals */
        !isNaN(displayTotal(props, get(props, 'products', [])))
          ? parseFloat(displayTotal(props, get(props, 'products', []))).toFixed(
              2
            )
          : 'جاري الحساب ...'}
      </Text>
    </HorizontalTexts>
  </Box>
);

const mapStateToProps = ({ main, auth }) => ({ main, auth });
export default connect(mapStateToProps, { updateUser })(
  withRouter(CartDetails)
);

const Box = styled.div`
  background-color: #11192d;
  color: white;
  padding: 3.66vw;
  text-align: right;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Title = styled.div`
  font-size: 15px;
  font-family: 'Cairo-Bold';
  padding-top: ${(props) => props.paddingTop || '0vw'};
  @media (max-width: 768px) {
    font-size: 2vw;
    font-family: 'Cairo-Bold';
    padding-top: 0vw;
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 14px;
  }
  font-weight: 800;
`;

const Text = styled.div`
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 1.9vw;
  }
  font-weight: 300;
  padding-top: 1.1vw;
  @media (max-width: 768px) {
    padding-top: 0vw;
    font-size: 16px;
    margin-top: 14px;
  }
`;

const HorizontalTexts = styled.div`
  display: flex;
  justify-content: space-between;
  direction: rtl;
  @media (max-width: 768px) {
    padding: 1vw 0vw;
  }
`;

const CheckoutButton = styled(CustomButton)`
  align-self: center;
  border-radius: 4.12vw;
  height: auto;
  @media (max-width: 768px) {
    padding: 0vw 2.8vw;
    width: 80%;
    padding: 10px;
  }

  @media (max-width: 767px) {
    width: 50%;
  }

  padding: 1vw 5vw;
  margin-bottom: 2.19vw;
`;
const ButtonTitle = styled.div`
  font-size: 1vw;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
