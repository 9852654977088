/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Icon, Input, Select, Button, message } from 'antd';
import styled from 'styled-components';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { updateUser } from '../appRedux/actions/Auth';
import { getGeneric } from '../appRedux/actions/Generic';
import DropdownSvg from '../icons/DropdownSvg.jsx';
import EditSvg from '../icons/EditSvg.jsx';
import { updateObject } from '../services/CommonServices';

const { Option } = Select;
const EgyptId = '5dc925810b3f4f2774834180';
const filterCities = (cities) => {
  return cities.filter(
    (city) => String(get(city, 'country._id', '')) === String(EgyptId)
  );
};
const filterDistricts = (selectedCity, districts) => {
  return get(districts, 'data', []).filter(
    (district) => String(get(district, 'city._id', '')) === String(selectedCity)
  );
};
const filterzones = (selectedDistrict, zones) => {
  return get(zones, 'data', []).filter(
    (zone) => String(get(zone, 'district._id', '')) === String(selectedDistrict)
  );
};
const handleSubmit = (props) => {
  const { form } = props;
  form.validateFields((err, fieldsValue) => {
    if (err) {
      message.warning('من فضلك أكمل بياناتك');
      return;
    }
    fieldsValue._id = get(props, 'auth.user._id');
    message.loading('', 0);
    const initialAddress = {
      country: get(props, 'auth.user.address.country._id'),
      city: get(props, 'auth.user.address.city._id'),
      district: get(props, 'auth.user.address.district._id'),
      zone: get(props, 'auth.user.address.zone._id'),
      street: get(props, 'auth.user.address.street'),
      buildingNumber: get(props, 'auth.user.address.buildingNumber')
    };
    fieldsValue.distributors = get(props, 'auth.user.distributors');
    fieldsValue.pendingDistributors = get(
      props,
      'auth.user.pendingDistributors'
    );
    fieldsValue.initialAddress = initialAddress;
    updateObject('buyers', fieldsValue)
      .then(() => {
        message.destroy();
        props.updateUser();
        message.success('تم تعديل البيانات بنجاح');
      })
      .catch(() => {
        message.warn('يوجد خطأ');
      });
  });
};
const ProfileForm = (props) => {
  const [editable, setEditable] = useState(false);
  const { getFieldDecorator, getFieldValue, setFieldsValue } = props.form;
  useEffect(() => {
    props.updateUser();
    props.getGeneric('countries');
    props.getGeneric('cities');
    props.getGeneric('districts');
    props.getGeneric('zones');
  }, []);

  return (
    <Container>
      <StyledForm>
        <Row noBorder>
          <Col lg={12} xs={2}>
            <StyledIcon onClick={() => setEditable(true)} component={EditSvg} />
          </Col>
          <Col lg={12} xs={10}>
            <Label>العنوان</Label>
          </Col>
        </Row>
        <Welcome>يمكنك تعديل العنوان الخاص بك في اي وقت</Welcome>
        <StyledRow>
          <Col lg={18} sm={18} md={18} xs={24}>
            {getFieldDecorator('address.city', {
              initialValue: get(props, 'auth.user.address.city._id', ''),
              rules: [
                {
                  required: true,
                  message: 'Please enter your city!'
                }
              ]
            })(
              <DropDown
                disabled={!editable}
                key={get(props, 'auth.user.address.city.name.ar')}
                showArrow={false}
                showSearch
                onChange={() =>
                  setFieldsValue({
                    'address.district': undefined,
                    'address.zone': undefined
                  })
                }
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder={
                  <div style={{ textAlign: 'right' }}>
                    {' المحافظة'}
                    <StyledIcon component={DropdownSvg} />{' '}
                  </div>
                }
              >
                {filterCities(get(props, 'main.cities', [])).map((city) => (
                  <Option
                    value={get(city, '_id')}
                    style={{ textAlign: 'right' }}
                  >
                    {get(city, 'name')}
                  </Option>
                ))}
              </DropDown>
            )}
          </Col>
          <Col lg={6} sm={6} md={6} xs={24}>
            <Label>المحافظة :</Label>
          </Col>
        </StyledRow>

        <StyledRow>
          <Col lg={18} sm={18} md={18} xs={24}>
            {getFieldDecorator('address.district', {
              initialValue: get(props, 'auth.user.address.district._id', ''),
              rules: [
                {
                  required: true,
                  message: 'Please enter your district!'
                }
              ]
            })(
              <DropDown
                disabled={!editable}
                key={get(props, 'auth.user.address.district.name.ar')}
                showArrow={false}
                showSearch
                onChange={() =>
                  setFieldsValue({
                    'address.zone': undefined
                  })
                }
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder={
                  <div style={{ textAlign: 'right' }}>
                    {'  القسم او المركز'}
                    <StyledIcon component={DropdownSvg} />{' '}
                  </div>
                }
              >
                {getFieldValue('address.city')
                  ? filterDistricts(
                      getFieldValue('address.city'),
                      get(props, 'main.districts', [])
                    ).map((district) => (
                      <Option
                        value={get(district, '_id')}
                        style={{ textAlign: 'right' }}
                      >
                        {get(district, 'name.ar')}
                      </Option>
                    ))
                  : ''}
              </DropDown>
            )}
          </Col>
          <Col lg={6} sm={6} md={6} xs={24}>
            <Label>القسم او المركز :</Label>
          </Col>
        </StyledRow>
        <StyledRow>
          <Col lg={18} sm={18} md={18} xs={24}>
            {getFieldDecorator('address.zone', {
              initialValue: get(props, 'auth.user.address.zone._id', ''),
              rules: [
                {
                  required: true,
                  message: 'Please enter your zone!'
                }
              ]
            })(
              <DropDown
                disabled={!editable}
                key={get(props, 'auth.user.address.zone.name.ar')}
                showArrow={false}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                placeholder={
                  <div style={{ textAlign: 'right' }}>
                    {' المنطقة'}
                    <StyledIcon component={DropdownSvg} />{' '}
                  </div>
                }
              >
                {getFieldValue('address.district')
                  ? filterzones(
                      getFieldValue('address.district'),
                      get(props, 'main.zones', [])
                    ).map((zone) => (
                      <Option
                        value={get(zone, '_id')}
                        style={{ textAlign: 'right' }}
                      >
                        {get(zone, 'name.ar')}
                      </Option>
                    ))
                  : ''}
              </DropDown>
            )}
          </Col>
          <Col lg={6} sm={6} md={6} xs={24}>
            <Label> المنطقة :</Label>
          </Col>
        </StyledRow>

        <StyledRow>
          <Col lg={18} sm={18} md={18} xs={24}>
            {getFieldDecorator('address.street', {
              initialValue: get(props, 'auth.user.address.street', '')
            })(<TextArea disabled={!editable} placeholder='الشارع' />)}
          </Col>
          <Col lg={6} sm={6} md={6} xs={24}>
            <Label>الشارع :</Label>
          </Col>
        </StyledRow>

        <StyledRow>
          <Col lg={18} sm={18} md={18} xs={24}>
            {getFieldDecorator('address.buildingNumber', {
              initialValue: get(props, 'auth.user.address.buildingNumber', '')
            })(<TextArea disabled={!editable} placeholder='رقم المبني' />)}
          </Col>
          <Col lg={6} sm={6} md={6} xs={24}>
            <Label>رقم المبني :</Label>
          </Col>
        </StyledRow>
        <StyledRow noBorder>
          <Col span={24}>
            {editable ? (
              <CustomButton
                onClick={() => {
                  handleSubmit(props);
                }}
              >
                <ButtonText>حفظ</ButtonText>
              </CustomButton>
            ) : (
              ''
            )}
          </Col>
        </StyledRow>
      </StyledForm>
    </Container>
  );
};

const WrappedNormalLoginForm = Form.create({ name: 'ProfileForm' })(
  ProfileForm
);

const mapStateToProps = ({ main, auth }) => ({ main, auth });

export default connect(mapStateToProps, { getGeneric, updateUser })(
  WrappedNormalLoginForm
);

const Container = styled.div`
  width: 100%;
  margin: 60px 0;

  .ant-input[disabled],
  input,
  .ant-select-selection,
  .ant-select-selection.ant-select-selection--single {
    padding-left: 30px;
    border: 2px solid rgb(151, 151, 151);
    height: 40px;
    font-size: 16px;
    border-radius: 0;
    @media (max-width: 414px) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 414px) {
    .anticon svg {
      display: inline-block;
      width: 20px;
      height: 20px;
    }
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
`;

const Label = styled.h1`
  margin-bottom: 0px;
  font-size: 18px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
`;

const TextArea = styled(Input)`
  width: 100%;
  /* height: 2.4vw; */
  margin-top: 0px;
  margin-bottom: 0px;
  border: solid 1px #979797;
  line-height: normal;
  direction: rtl;
  font-size: 1.3vw;
  @media (max-width: 768px) {
    font-size: 16px;
    height: 3.9vw;
  }
`;

const StyledIcon = styled(Icon)`
  float: left;
  font-size: 1vw;
  font-weight: normal;
  margin-top: 3px;
  cursor: pointer;
  path {
    fill: ${(props) => props.theme.primary};
  }

  @media (max-width: 767px) {
    float: right;
  }
`;
const DropDown = styled(Select)`
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  /* line-height: normal; */
  font-size: 1.3vw;
  @media (max-width: 768px) {
    font-size: 20px;
  }
  & .ant-select-selection {
    border-color: #979797;
    @media (max-width: 768px) {
      height: 4vw;
    }
  }
  & .ant-select-selection-selected-value {
    direction: rtl;
    float: right;
    font-size: 17px;
    line-height: normal;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
`;

const StyledRow = styled(Row)`
  width: 100%;
  ${(props) => (!props.noBorder ? 'border-bottom: solid 0.5px' : '')};
  padding-top: 2.5vw;
  ${(props) => (!props.noMargin ? 'padding-bottom: 2.5vw;' : '')};
`;
const Welcome = styled.div`
  font-size: 19px;
  font-family: 'Cairo-Light';
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  float: right;
  color: ${(props) => props.theme.primary};
  padding-bottom: 2.5vw;
`;
const CustomButton = styled(Button)`
  float: left;
  background-color: #4a7fbd;
  border-radius: 1vw;
  width: 100px;
  height: inherit;
  padding: 3px;
  box-shadow: none;
  @media (max-width: 768px) {
    padding: 14px;
  }

  @media (max-width: 767px) {
    padding: 8px;
  }
  float: left;
  background-color: ${(props) => props.theme.accent};
  border-radius: 1vw;
`;
const ButtonText = styled.div`
  font-size: 1.4vw;
  color: #fff;
  @media (max-width: 767px) {
    font-size: 15px;
  }
`;
