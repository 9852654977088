import React from 'react';
import 'antd/dist/antd.css';
import { ThemeProvider } from 'styled-components';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/extensions
import MainRoutes from './routes/MainRoutes';
import './App.css';
import './bootstrap.min.css';

// import Header from 'antd/lib/calendar/Header';
function App() {
  return (
    <div className='App'>
      <ThemeProvider
        theme={{
          primary: '#11192d',
          accent: '#4a7fbd',
          light: '#e1e5ea',
          borderRadius: 21
        }}
      >
        <MainRoutes />
      </ThemeProvider>
    </div>
  );
}

export default App;
