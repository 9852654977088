/* eslint-disable import/extensions */
import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import CustomDivider from '../elements/CustomDivider';

const Text = styled.div`
  font-size: 20px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: ${(props) => props.theme.primary};
  direction: rtl;
  padding: 3% 0;
  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;
const Divider = styled(CustomDivider)`
  opacity: 50;
  margin-bottom: 4vw;
`;
const TextSubtitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.primary};
  text-align: right;
  border-bottom: 0.01vw solid #d8d8d8;
  float: right;
`;

const Help = () => {
  return (
    <div>
      <ImageContainer>
        <GradientDiv></GradientDiv>

        <ProductImage src={require('../assets/images/AAA.png')} />
        <CustomRow>
          <div className='container'>
            <MoreBg>
              <StyledOffersTitle>تعرف عننا اكثر </StyledOffersTitle>
            </MoreBg>
          </div>
        </CustomRow>
      </ImageContainer>
      <Row type='flex' justify='center'>
        <Col xl={21} lg={21} md={21} sm={21} xs={21}>
          <Container>
            <Text>
              تعتبر ريبزون أقوى منصة للتداول التجاري الإلكتروني في مصر، حيث
              التواجد الأكبر لكبرى الشركات المنتجة للأغذية، والمشروبات، والسلع
              الإستهلاكية وأيضاً أكبر قطاع تجاري من تجارة الجملة وأكبر الموزعين
              في جميع محافظات جمهورية مصر العربية.
            </Text>
          </Container>
          <Divider />
          <Row>
            <Col span={24}>
              <TextSubtitle> رؤيتنا</TextSubtitle>
            </Col>
            <Col span={24}>
              <Text>
                تهدف رؤيتنا لتطوير عملية التداول التجاري بين قطاعات الإنتاج
                وقطاعات التسويق وتحقيق زيادة فرص المبيعات لضمان تحقيق أعلى معدل
                للأرباح التي تطمح لها جميع القطاعات التجارية، وضمان توفير الرؤية
                الشاملة التي تساعد كلاً من شركاء العمل على إتخاذ قرارت قائمة على
                تقارير المندوبين والعملاء بشكل فوري.
              </Text>
            </Col>
          </Row>
          <Row style={{ padding: '5% 0%' }}>
            <Col span={24}>
              <TextSubtitle> هدفنا</TextSubtitle>
            </Col>
            <Col span={24}>
              <Text>
                من خلال ريبزون مهمتنا هو الحرص على حصولك على أفضل أداة قائمة
                بذاتها لإدارة المبيعات والتسويق، لذلك قمنا بتنفيذ مميزات ووظائف
                متقدمة ومتطورة للعديد من العوامل المساعدة لإدارة الأعمال في منصة
                إدارة واحدة لكي تمنحك تحكمًا كاملاً في جميع المبيعات والتسويق
                والتوزيع وخدمات وملاحظات ما بعد البيع، كل هذه المميزات من خلال
                منصة التداول التجاري الأولى بمصر ريبزون.
              </Text>
              <Text>
                في غضون دقائق من التسجيل والإشتراك في ريبزون ، يمكن لشركاء العمل
                من قطاعات الإنتاج و ممثلين التوزيع والمندوبين إدخال أنشطتهم حيث
                يمكنك إدارة أعمالك برؤية كاملة مع العديد من الإمتيازات التي تهدف
                إلي زيادة المبيعات مع قراءة إحتاجات السوق بشكل إحترافي.
              </Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default Help;

const ImageContainer = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
  clear: both;
`;

const ProductImage = styled.img`
  height: 400px;
  width: 100%;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 150px;
  }

  @media (max-width: 414px) {
    height: 125px;
  }
`;

const CustomRow = styled.div`
  // width: 100%;
  // position: absolute;
  // top: 0;
  // text-align: center;
  // background: #11192d87;
  // padding: 14px;
  // box-sizing: border-box;
  // position: absolute;
  // width: 100%;
  // top: 50%;
  // left: 50%;
  // padding: 60px;
  // color: #fff;
  // -webkit-transform: translate(-50%, -50%);
  // transform: translate(-50%, -50%);
  // font-size: 45px;
`;

const StyledOffersTitle = styled.text`
  border-bottom: 1px solid white;
  font-size: 30px;

  @media (max-width: 414px) {
    font-size: 20px;
  }
`;

const GradientDiv = styled.div`
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.8;
  background-image: linear-gradient(
    rgb(17, 25, 45),
    rgba(17, 25, 45, 0.85) 30%,
    rgba(17, 25, 45, 0)
  );
`;

const MoreBg = styled.div`
  position: absolute;
  color: #fff;
  z-index: 9999;
  top: 0;
  margin: 0;
  position: absolute;
  top: 70%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 20px;
  padding: 50px;
  width: inherit;
  text-align: center;
  background: red;
  background-color: rgba(17, 25, 45, 0.55);
  /* opacity: 0.4; */
  background: rgba(17, 25, 45, 0.53);

  @media (max-width: 1024px) {
    padding: 20px;
    -webkit-transform: translate(-50%, -100%);
    top: 70%;
    transform: translate(-50%, -100%);
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    width: 90%;
    top: 70%;
  }
`;
const Container = styled.div`
  padding-top: 60px;
  @media (max-width: 1024px) {
    padding-top: 30px;
  }
`;
