/* eslint-disable import/extensions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Icon, Tooltip, Divider, Spin, message } from 'antd';
import styled from 'styled-components';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import SingleProductSlider from '../components/SingleProductSlider.jsx';
import ProductCounter from '../components/ProductCounter.jsx';
import CustomButton from '../elements/CustomButton.jsx';
import { setLoading } from '../appRedux/actions/Common';
import { updateUser } from '../appRedux/actions/Auth';
import { getCart } from '../appRedux/actions/Cart';
import { getGeneric } from '../appRedux/actions/Generic';
import {
  addToCart,
  checkIfBuyerConnectedToSeller,
  getObject,
  getHighestBuyerCategory
} from '../services/CommonServices';
import barcodeSvg from '../icons/BarcodeSvg.jsx';
import { getProductOffers } from '../appRedux/actions/Products';
import HandshakeModal from '../components/HandshakeModal.jsx';
import ProductOfferCard from '../components/ProductOfferCard.jsx';
import NoOffers from '../components/NoOffers.jsx';

const addSignleProductToCart = ({
  props,
  company,
  distributor,
  distributorProduct,
  countCarton,
  countPackage,
  companyProduct,
  carton
}) => {
  companyProduct.buyerCategories = companyProduct.chosenBuyerCategory;
  addToCart({
    props,
    company,
    distributor,
    distributorProduct,
    countCarton,
    countPackage,
    companyProduct,
    carton
  });
};
const SingleProduct = (props) => {
  const query = queryString.parse(props.location.search);
  const zone = get(props, 'auth.user.address.zone._id');
  const productId = get(query, 'product');
  const [countCarton, setCountCarton] = useState(0);
  const [countPackage, setCountPackage] = useState(0);
  const [handshake, setHandshake] = useState(false);
  const [distributor, setDistributor] = useState({});
  const [carton, setCarton] = useState({});
  const [productPackage, setPackage] = useState();
  const [packageProduct, setPackageProduct] = useState();
  const [unitPrice, setUnitPrice] = useState(0);
  const [buyerCategoryId, setBuyerCategoryId] = useState();
  const [noDistributor, setNoDistributor] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [companyProduct, setCompanyProduct] = useState({});
  const [company, setCompany] = useState(get(companyProduct, 'company', {}));
  const [loading, setLoading] = useState(false);
  const [buyer, setBuyer] = useState(get(props, 'auth.user', {}));
  const [NoBuyerCategory, setNoBuyerCategory] = useState(false);
  const [pricesLoaded, setPricesLoaded] = useState(false);
  const [connected, setConnected] = useState(false);
  useEffect(() => {
    setConnected(
      checkIfBuyerConnectedToSeller({ distributor, buyer, company, setLoading })
    );
  }, [buyer, distributor, company]);
  useEffect(() => {
    window.scrollTo(0, 0);
    props.getProductOffers({
      zone,
      product: productId,
      token: get(props, 'auth.token')
    });
    setLoading(true);
    getObject('companyProducts/productDistributorInZone', {
      _id: productId,
      zone
    })
      .then((res) => {
        if (!get(res, 'data.distributor')) setNoDistributor(true);
        setDistributor(get(res, 'data.distributor'));
        setPackageProduct(get(res, 'data.product'));
        setPricesLoaded(true);
      })
      .catch(() => {
        message.error('تعثر الاتصال بلانترنت');
      });
    setLoading(false);
  }, []);
  useEffect(() => {
    setLoading(true);
    if (fetched) {
      const { company } = companyProduct;
      if (Array.isArray(companyProduct.buyerCategories)) {
        const distributorsSelector = (props) =>
          get(props, 'auth.user.distributors', []);
        const disributorBuyerCatSelector = createSelector(
          distributorsSelector,
          (distributors) => {
            return get(
              distributors.find(
                (entry) =>
                  entry.distributor &&
                  entry.distributor._id === get(distributor, '_id')
              ),
              'buyerCategory'
            );
          }
        );
        const distBuyerCategory = disributorBuyerCatSelector(props);
        const productBuyerCategorySelector = (product) =>
          product.buyerCategories;

        const isConnected = checkIfBuyerConnectedToSeller({
          distributor,
          buyer,
          company,
          setLoading
        });
        const buyerCategorySelector = isConnected
          ? createSelector(productBuyerCategorySelector, (buyerCategories) => {
              const res = buyerCategories.find((b) => {
                return isEqual(
                  b.buyerCategory,
                  get(distBuyerCategory, '_id', distBuyerCategory)
                );
              });
              if (!res) setNoBuyerCategory(true);
              else setNoBuyerCategory(false);
              return res;
            })
          : createSelector(productBuyerCategorySelector, (buyerCategories) =>
              getHighestBuyerCategory(buyerCategories)
            );
        const updatedCompanyProduct = Object.assign({}, companyProduct);
        updatedCompanyProduct.chosenBuyerCategory = buyerCategorySelector(
          companyProduct
        );
        const finalCompanyProduct = JSON.parse(
          JSON.stringify(updatedCompanyProduct)
        );
        const buyerCategoryId = get(
          finalCompanyProduct,
          'chosenBuyerCategory.buyerCategory'
        );
        const carton = get(finalCompanyProduct, 'chosenBuyerCategory.saleUnit');
        const unitPrice =
          get(finalCompanyProduct, 'chosenBuyerCategory.saleUnit.price') /
          get(
            finalCompanyProduct,
            'chosenBuyerCategory.saleUnit.packageQuantity'
          );
        const companyObject = get(finalCompanyProduct, 'company');
        setCompany(companyObject);
        setCarton(carton);
        setBuyerCategoryId(buyerCategoryId);
        setUnitPrice(unitPrice);
        setCompanyProduct(finalCompanyProduct);
      }
    }
    setLoading(false);
  }, [distributor, fetched]);

  useEffect(() => {
    if (buyerCategoryId)
      setPackage(
        get(packageProduct, 'buyerCategories', []).find(
          (pp) => pp.buyerCategory._id === buyerCategoryId
        )?.saleUnit?.package
      );
  }, [packageProduct, buyerCategoryId]);

  useEffect(() => {
    setLoading(true);
    setBuyer(get(props, 'auth.user', {}));
    getObject('companyProducts/availableProducts', {
      query: { _id: productId || companyProduct._id },
      buyer: get(props, 'auth.user._id', ''),
      zone: get(props, 'auth.user.address.zone._id', ''),
      skip: 1
    })
      .then((res) => {
        setCompanyProduct(get(res.data, 'newSortedProducts[0]', {}));
        setCompany(get(res.data, 'newSortedProducts[0].company', {}));
        setFetched(true);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setFetched(true);
        message.error('تعثر الاتصال بلانترنت');
      });
  }, [props.auth]);

  return (
    <PaddingAround>
      {loading ? (
        <Spin />
      ) : (
        <>
          <Row>
            {
              // TODO Breadcrumbs
            }
          </Row>
          <Row>
            {
              // TODO Tags
            }
          </Row>
          <div className='container'>
            <Row type='flex' justify='center' gutter={46}>
              <RightCol lg={10} sm={0} md={0} xs={0}>
                <SingleProductSlider companyProduct={companyProduct} />
                <Row>
                  <ProductInfo companyProduct={companyProduct} />
                </Row>
              </RightCol>
              <RightCol lg={0} sm={24} md={24} xs={24}>
                <SingleProductSlider companyProduct={companyProduct} />
              </RightCol>
              <LeftCol lg={14} md={24} sm={24} xs={24}>
                <ProductName
                  companyProduct={companyProduct}
                  sellerName={get(distributor, 'name.ar')}
                  loading={loading}
                  isBranch={get(distributor, 'isBranch')}
                  noDistributor={noDistributor}
                />
                {!pricesLoaded ? (
                  <Spin />
                ) : (
                  <Row>
                    {NoBuyerCategory ? (
                      <TextContainer>غير متوفر لقطاعك</TextContainer>
                    ) : (
                      <div>
                        <Package
                          company={company}
                          distributor={distributor}
                          props={props}
                          countPackage={countPackage}
                          setCountPackage={setCountPackage}
                          companyProduct={companyProduct}
                          // distributorProduct={distributorProduct}
                          carton={carton}
                          unitPrice={unitPrice}
                          setHandshake={setHandshake}
                          connected={connected}
                          loading={loading}
                          setLoading={setLoading}
                          noDistributor={noDistributor}
                          NoBuyerCategory={NoBuyerCategory}
                        />

                        <Carton
                          company={company}
                          distributor={distributor}
                          props={props}
                          countCarton={countCarton}
                          setCountCarton={setCountCarton}
                          companyProduct={companyProduct}
                          productPackage={productPackage}
                          // distributorProduct={distributorProduct}
                          carton={carton}
                          setHandshake={setHandshake}
                          connected={connected}
                          buyerCategoryId={buyerCategoryId}
                          loading={loading}
                          setLoading={setLoading}
                          noDistributor={noDistributor}
                          NoBuyerCategory={NoBuyerCategory}
                        />
                      </div>
                    )}

                    <RightCol lg={0} md={24} sm={24} xs={24}>
                      <Row>
                        <ProductInfo companyProduct={companyProduct} />
                      </Row>
                    </RightCol>
                  </Row>
                )}
                <ProductDescritpion
                  {...props}
                  productOffers={props.products.productOffers}
                  distributor={distributor}
                  company={company}
                  setHandshake={setHandshake}
                  companyProduct={companyProduct}
                />
              </LeftCol>
            </Row>
          </div>

          <HandshakeModal
            visible={handshake && !connected}
            setVisible={setHandshake}
            distributor={distributor}
            company={company}
            zone={zone}
            buyer={buyer}
          />
        </>
      )}
    </PaddingAround>
  );
};

const mapStateToProps = ({ main, auth, cart, products }) => ({
  main,
  auth,
  cart,
  products
});
export default connect(mapStateToProps, {
  getProductOffers,
  updateUser,
  getGeneric,
  setLoading,
  getCart
})(SingleProduct);

const ProductName = ({
  companyProduct,
  sellerName,
  noDistributor,
  isBranch
}) => (
  <Row>
    <ProductNameContentHolder>
      <hr />
      <Col lg={12} sm={0} md={0} xs={0} />

      <Col
        style={{ paddingLeft: '1vw', marginBottom: '20px' }}
        lg={24}
        sm={24}
        md={24}
        xs={24}
      >
        <Tooltip placement='bottom' title={get(companyProduct, 'name.ar')}>
          <ProductNameText>{get(companyProduct, 'name.ar')}</ProductNameText>
        </Tooltip>
        <Row type='flex' justify='space-between'>
          <Col lg={12} sm={24} md={12} xs={24}>
            <LeftPadding>
              <SpaceBetween>
                <Text>إسم الشركة :</Text>
                <Link
                  to={`/company?company=${get(
                    companyProduct,
                    'company._id'
                  )}&page=1`}
                >
                  <Text>{get(companyProduct, 'company.name.ar')}</Text>
                </Link>
              </SpaceBetween>
            </LeftPadding>
          </Col>
          <Col lg={12} sm={24} md={12} xs={24}>
            <RightPadding>
              <SpaceBetween>
                <Text>{isBranch ? ` اسم الفرع` : `اسم الموزع `} :</Text>
                {!sellerName && !noDistributor ? (
                  <Spin />
                ) : (
                  <Text>{sellerName || '-'}</Text>
                )}
              </SpaceBetween>
            </RightPadding>
          </Col>
        </Row>
      </Col>
    </ProductNameContentHolder>
  </Row>
);

const Package = ({
  countPackage,
  setCountPackage,
  companyProduct,
  carton,
  props,
  company,
  distributor,
  distributorProduct,
  setHandshake,
  unitPrice,
  loading,
  setLoading,
  noDistributor,
  NoBuyerCategory
}) => {
  const packagePrice = unitPrice;

  return (
    <SmallLeftCol lg={12} sm={12} md={12} xs={12}>
      <div>
        <SpaceBetween>
          <TextBold>{get(companyProduct, 'packageUnit.name')}</TextBold>
          <Barcode component={barcodeSvg} />
        </SpaceBetween>

        <SpaceBetween>
          <Text>{get(companyProduct, 'packageUnit.name', 'علبة')}</Text>
          <Text style={{ direction: 'ltr' }}>
            {!packagePrice ? (
              <Spin />
            ) : (
              <Row>
                <Col span={8}>
                  {' '}
                  <EGP> {' جنيه'} </EGP>
                </Col>
                <Col span={16}>{packagePrice.toFixed(2)}</Col>
              </Row>
            )}
          </Text>
        </SpaceBetween>

        <ProductCounterHolder>
          <ProductCounter count={countPackage} setCount={setCountPackage} />
          <SingleAddBtn>
            <Row>
              {loading ? (
                <Spin />
              ) : (
                <CustomButton
                  disabled={
                    !!(!carton && companyProduct.buyerCategories === {}) ||
                    (!distributor && !noDistributor) ||
                    loading ||
                    NoBuyerCategory ||
                    !packagePrice
                  }
                  onClick={() => {
                    if (!props.auth.token) {
                      window.scrollTo(0, 0);
                      props.history.push('/login');
                    }
                    if (
                      checkIfBuyerConnectedToSeller({
                        distributor,
                        buyer: get(props, 'auth.user'),
                        company,
                        setLoading
                      })
                    ) {
                      if (countPackage === 0) return;
                      addSignleProductToCart({
                        props,
                        companyProduct,
                        company,
                        distributor,
                        distributorProduct,
                        countPackage,
                        carton
                      });
                      setCountPackage(0);
                    } else {
                      setHandshake(true);
                    }
                  }}
                  shape='round'
                >
                  <ButtonTitle>إضافة</ButtonTitle>
                </CustomButton>
              )}
            </Row>
          </SingleAddBtn>
        </ProductCounterHolder>
      </div>
    </SmallLeftCol>
  );
};

const Carton = ({
  countCarton,
  setCountCarton,
  companyProduct,
  carton,
  props,
  company,
  distributor,
  distributorProduct,
  setHandshake,
  loading,
  setLoading,
  noDistributor,
  NoBuyerCategory,
  productPackage
}) => {
  const cartonPrice = Number(get(carton, 'price', 0));
  return (
    <SmallRightCol span={12}>
      <div>
        <SpaceBetween>
          <TextBold>{get(productPackage, 'name', 'كرتونة')}</TextBold>
          <Barcode component={barcodeSvg} />
        </SpaceBetween>

        <SpaceBetween>
          <Text style={{ direction: 'ltr' }}>
            {`${get(carton, 'packageQuantity', '')} ${get(
              companyProduct,
              'packageUnit.name',
              ''
            )}`}
          </Text>
          <Text style={{ direction: 'ltr' }}>
            {' '}
            {!cartonPrice ? (
              <Spin />
            ) : (
              <Row>
                <Col span={8}>
                  {' '}
                  <EGP> {' جنيه'} </EGP>
                </Col>
                <Col span={16}>{cartonPrice.toFixed(2)}</Col>
              </Row>
            )}
          </Text>
        </SpaceBetween>
        <ProductCounterHolder>
          <ProductCounter count={countCarton} setCount={setCountCarton} />

          <SingleAddBtn>
            {loading ? (
              <Spin />
            ) : (
              <CustomButton
                disabled={
                  !!(!carton && companyProduct.buyerCategories === {}) ||
                  (!distributor && !noDistributor) ||
                  loading ||
                  NoBuyerCategory ||
                  !cartonPrice
                }
                onClick={() => {
                  if (!props.auth.token) {
                    window.scrollTo(0, 0);
                    props.history.push('/login');
                  }
                  if (
                    checkIfBuyerConnectedToSeller({
                      distributor,
                      buyer: get(props, 'auth.user'),
                      company,
                      setLoading
                    })
                  ) {
                    if (countCarton === 0) return;
                    addSignleProductToCart({
                      props,
                      company,
                      distributor,
                      distributorProduct,
                      countCarton,
                      companyProduct,
                      carton
                    });
                    setCountCarton(0);
                  } else {
                    setHandshake(true);
                  }
                }}
                shape='round'
              >
                <Row>
                  <ButtonTitle>إضافة</ButtonTitle>
                </Row>
              </CustomButton>
            )}
          </SingleAddBtn>
        </ProductCounterHolder>
      </div>
    </SmallRightCol>
  );
};

const ProductInfo = ({ companyProduct }) => (
  <InfoHolder>
    <Row>
      <SpaceAround>
        <Row></Row>
        <Col lg={12} xs={6}>
          <ProductInfoText>
            {get(companyProduct, 'country.name')}
          </ProductInfoText>
        </Col>
        <Col lg={12} xs={6}>
          <ProductInfoText>بلد المنشأ :</ProductInfoText>
        </Col>
        <hr />

        <Col lg={12} xs={6}>
          <ProductInfoText>
            {get(companyProduct, 'brand.name.ar')}
          </ProductInfoText>
        </Col>
        <Col lg={12} xs={6}>
          <ProductInfoText>الماركة :</ProductInfoText>
        </Col>
      </SpaceAround>

      <Col lg={12} xs={6}>
        <ProductInfoText>
          {get(companyProduct, 'packageUnit.name')}
        </ProductInfoText>
      </Col>
      <Col lg={12} xs={6}>
        <ProductInfoText>وحدة التعبئة :</ProductInfoText>
      </Col>
      <Col lg={24} xs={0}>
        <Divider />
      </Col>

      <Col lg={12} xs={6}>
        <ProductInfoText>
          {`${get(companyProduct, 'sizes.quantity')} ${get(
            companyProduct,
            'sizes.size.name.ar'
          )}`}
        </ProductInfoText>
      </Col>
      <Col lg={12} xs={6}>
        <ProductInfoText>الوزن :</ProductInfoText>
      </Col>
      <Col lg={24} xs={0}>
        <Divider />
      </Col>
      <Col lg={12} xs={6}>
        <ProductInfoText>
          {companyProduct.points || 0}
        </ProductInfoText>
      </Col>
      <Col lg={12} xs={6}>
        <ProductInfoText>النقاط :</ProductInfoText>
      </Col>
    </Row>
  </InfoHolder>
);

const ProductDescritpion = (props) => {
  const [index, setIndex] = useState(1);
  return (
    <SingleProductOfferHolder>
      <Row style={{ height: 'auto' }}>
        <Col
          span={24}
          style={{
            height: '3vw',
            alignItems: 'center',
            background: '#11192d',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Tab
            onClick={() => {
              setIndex(1);
            }}
          >
            العروض
          </Tab>
          {/* <Tab
          onClick={() => {
            setIndex(2);
          }}
        >
          معلومات
        </Tab>
        <Tab
          onClick={() => {
            setIndex(3);
          }}
        >
          معلومات
        </Tab> */}
        </Col>

        <Col style={{ marginTop: '20px' }} span={24}>
          {index === 1 ? (
            <Row>
              {props.productOffers.length ? (
                props.productOffers.map((offer, index) => (
                  <OfferCol
                    lg={8}
                    md={8}
                    sm={8}
                    xs={12}
                    index={index}
                    length={props.productOffers.length}
                  >
                    <ProductOfferCard
                      {...props}
                      offer={offer}
                      product={props.companyProduct}
                      distributor={props.distributor}
                      company={props.company}
                      setHandshake={props.setHandshake}
                    />
                  </OfferCol>
                ))
              ) : (
                <NoOffers singleProduct={true} />
              )}
            </Row>
          ) : index === 2 ? (
            <div>Hello</div>
          ) : (
            <div>last one</div>
          )}
        </Col>
      </Row>
    </SingleProductOfferHolder>
  );
};

const LeftCol = styled(Col)``;

const SmallLeftCol = styled(Col)`
  padding-right: 2.66vw;
`;

const SmallRightCol = styled(Col)`
  padding-left: 2.66vw;
  border-left: 0.036vw solid #11192d;
`;

const Text = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: #11192d;
  text-align: center;
  line-height: normal;
  padding: 10px 0;

  @media (max-width: 768px) {
    text-align: center;
    font-size: 18px;
    font-family: 'Cairo-Bold';
  }

  @media (max-width: 414px) {
    font-size: 15px;
    font-family: 'Cairo-Bold';
  }
`;

const TextBold = styled.div`
  font-size: 19px;
  font-weight: 800;
  color: #11192d;
  line-height: normal;
  padding: 10px 0;
  direction: rtl;

  @media (max-width: 414px) {
    font-size: 15px;
  }
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.073vw solid #11192d;
  @media (max-width: 768px) {
  }
`;

const SpaceAround = styled(Row)`
  border-bottom: 0.073vw solid #11192d;
  text-align: center;
  @media (min-width: 768px) {
    hr {
      overflow: hidden;
      height: inherit;
    }
  }
`;

const RightCol = styled(Col)`
  border-left: 0.036vw solid #11192d;
  @media (max-width: 414px) {
    border-left: none;
  }
`;

const Tab = styled(Button)`
  background-color: ${(props) => props.theme.primary};
  width: 100%;
  height: auto;
  color: white;
  border: none;
  font-weight: bold;
  font-size: 17px;
  padding: 11px;

  border-right: 0.073vw solid white;
  :last-child {
    border: none;
  }
  :hover,
  :focus,
  :active {
    background: #11192d;
    color: #4a7fbd;
    font-weight: 800;
  }
`;

const Barcode = styled(Icon)`
  font-size: 1.46vw;

  @media (max-width: 768px) {
    font-size: 30px;
  }

  @media (max-width: 414px) {
    font-size: 25px;
  }
`;
const ButtonTitle = styled.div`
  font-size: 14px;
  width: 100%;
`;

const PaddingAround = styled.div`
  margin: 60px 0;

  @media (max-width: 414px) {
    margin: 0;
  }
`;
const SingleAddBtn = styled.div`
  margin: 0 auto;
  display: block;
  text-align: center;
  width: 50%;
  button {
    width: 100%;
  }
`;

const OfferCol = styled(Col)`
  border-left: ${(props) =>
    props.index + 1 !== props.length ? `1px solid ${props.theme.primary}` : ''};
  float: right;
  margin-bottom: 2px;
  padding-right: 1vw;
`;

const ProductNameText = styled.h3`
  font-size: 19px;
  font-weight: 800;
  color: #11192d;
  line-height: normal;
  direction: rtl;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 20px;
    font-family: 'Cairo-Bold';
  }

  @media (max-width: 414px) {
    font-size: 17px;
    margin-top: 20px;
  }

  @media (max-width: 375px) {
    font-size: 16px;
  }
`;

const ProductCounterHolder = styled.div`
  margin-top: 10px;

  @media (max-width: 414px) {
    .ProductCounterHolder {
      width: 40px !important;
      padding: 0;
      height: 25px;
    }
  }
`;

const SingleProductOfferHolder = styled.div`
  margin: 40px 0;

  @media (max-width: 414px) {
    margin: 30px 0;
  }
`;

const ProductInfoText = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: #11192d;
  text-align: center;
  line-height: normal;
  padding: 10px 0;
  @media (max-width: 768px) {
    text-align: center;
    font-size: 18px;
    font-family: 'Cairo-Bold';
  }

  @media (max-width: 414px) {
    font-size: 13px;
  }
`;

const ProductNameContentHolder = styled.div`
  hr {
    display: none;
  }
  @media (max-width: 767px) {
    display: block;

    hr {
      margin: 30px 0;
      border-top: 2px solid #10182d;
    }
  }
`;

const InfoHolder = styled.div`
  margin: 30px 0;

  .ant-divider-horizontal,
  hr {
    margin: 0;

    background: #10182d;
  }
`;

const EGP = styled.div`
  margin-right: 8px;
`;

const TextContainer = styled.div`
  text-align: ${(props) => (props.left ? 'left' : 'right')};
  font-weight: ${(props) => props.weight || 'normal'};
  font-size: ${(props) => props.size || '1.46vw'};
  color: ${(props) => props.theme.primary};
  .anticon {
    font-size: 2.34vw;
    @media (max-width: 768px) {
      font-size: 5vw;
    }
    color: ${(props) => props.theme.accent};
  }
  @media (max-width: 414px) {
    font-size: 15px;
  }
  direction: rtl;
`;
const RightPadding = styled.div`
  @media (min-width: 768px) {
    padding-right: 2.66vw;
  }
`;
const LeftPadding = styled.div`
  @media (min-width: 768px) {
    padding-left: 2.66vw;
  }
`;
