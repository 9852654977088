import React, { useState } from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { productQuantityPricesTotal } from '../services/CommonServices';

const CheckoutSingleProduct = (props) => {
  const [companyProduct] = useState(
    get(
      props,
      'product.product.companyProduct',
      get(props, 'product.product', {})
    )
  );

  const total = productQuantityPricesTotal(
    get(props, 'product', {}),
    props,
    'checkout'
  );

  return (
    <ProductContainer>
      <ProductImage
        alt={'product'}
        src={get(
          companyProduct,
          'images[0]',
          require('../assets/images/repzone-placeholder.png')
        )}
      />

      <ProductInfoContainer>
        {companyProduct.offer && (
          <ProductName>{`${get(companyProduct, 'offer.name')}${
            companyProduct.addOnProduct ? ' - منتج هدية' : ''
          }`}</ProductName>
        )}
        <ProductName>{get(companyProduct, 'name.ar')}</ProductName>
        <Flex>
          <ProductQuantity>
            {`${get(props, 'product.quantity[0].quantity', 0)} ${get(
              props,
              'product.quantity[0].saleUnit',
              'كرتونة'
            )} - ${get(props, 'product.quantity[1].quantity', 0)} ${get(
              props,
              'product.quantity[1].saleUnit',
              'عبوة'
            )}`}
          </ProductQuantity>

          <Price>
            {/* eslint-disable no-restricted-globals */
            isNaN(Number(total).toFixed(2))
              ? 'جاري الحساب ...'
              : get(props, 'product.product.addOnProduct')
              ? Number(0).toFixed(2)
              : Number(total).toFixed(2)}
          </Price>
        </Flex>
      </ProductInfoContainer>
    </ProductContainer>
  );
};

export default CheckoutSingleProduct;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ProductContainer = styled.div`
  width: 100%;
  font-size: 1.7vw;
  padding: 0.73vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ProductImage = styled.img`
  width: 5.12vw;
  height: 5.12vw;
  @media (max-width: 768px) {
    width: 10vw;
    height: 10vw;
    margin-left: 20px;
  }
  object-fit: fill;
  margin-left: 1.4vw;
`;
const ProductInfoContainer = styled.div`
  text-align: right;
  width: 100%;
  color: ${(props) => props.theme.primary};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;
const ProductName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  margin-bottom: 4px;

  color: #11192d;
  font-family: 'Cairo-Bold';
`;

const Price = styled.div`
  font-size: 15px;
  color: #11192d;
  direction: ltr;
  margin-top: 4px;
  font-family: 'Cairo-SemiBold';
`;

const ProductQuantity = styled.div`
  font-size: 15px;
  margin-top: 4px;
  color: #11192d;
  font-family: 'Cairo-SemiBold';
`;
