import { all, call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import get from 'lodash/get';
import { getCartSuccess, showCartMessage } from '../actions/Cart';
import config from '../../config';
import { GET_CART } from '../../constants/ActionTypes';

const getCartRequest = async () => {
  const token = localStorage.getItem('token');
  return axios({
    method: 'get',
    url: `${config.url}buyers/getBuyer`,
    params: { token }
  });
};

function* getUserCart() {
  try {
    const user = yield call(getCartRequest);
    yield put(getCartSuccess(get(user, 'data.buyer.cart.products')));
  } catch (error) {
    yield put(showCartMessage(error));
  }
}

export default function* rootSaga() {
  yield all([yield takeEvery(GET_CART, getUserCart)]);
}
