/* eslint-disable no-undef */
/* eslint-disable import/newline-after-import */
/* eslint-disable import/no-duplicates */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Slider from 'react-animated-slider';
import '../assets/css/slider-style.css';
import '../assets/css/slider-animation.css';
import 'react-animated-slider/build/horizontal.css';
import ScrollAnimation from 'react-animate-on-scroll';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { getObject } from '../services/CommonServices';

const HomeSlider = () => {
  const [content, setContent] = React.useState([]);

  React.useEffect(() => {
    getObject('commercials/activecommercials', { update: true }).then((res) => {
      setContent(res.data);
    });
  }, []);

  return (
    <Container>
      <div className='container'>
        <Row className='Reverse' gutter={18}>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Row gutter={18}>
              <Col xs={12} sm={12} md={24} lg={24} xl={24}>
                <ShopBanner2>
                  <ScrollAnimation
                    animateIn='fadeInLeft'
                    animateOnce={false}
                    duration={1.4}
                  >
                    <a href='#'>
                      <ShopBannerTittle AlightText></ShopBannerTittle>
                      <a href='#'>
                        {' '}
                        <ProductImage
                          src={require('../assets/images/coffe-break-ad2.jpeg')}
                        />{' '}
                      </a>
                    </a>
                  </ScrollAnimation>
                </ShopBanner2>
              </Col>
              <Col xs={12} sm={12} md={24} lg={24} xl={24}>
                <ShopBanner2>
                  <ScrollAnimation
                    animateIn='fadeInUp'
                    animateOnce={true}
                    duration={2}
                  >
                    <a href='#'>
                      <ShopBannerTittle className='text_white'></ShopBannerTittle>
                      <a href='#'>
                        {' '}
                        <ProductImage
                          src={require('../assets/images/coffe-break-ad.jpeg')}
                        />{' '}
                      </a>
                    </a>
                  </ScrollAnimation>
                </ShopBanner2>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={12} md={18} lg={18} xl={18}>
            <HomeSliderHolder>
              <Slider
                autoplay={3000}
                infinite={true}
                className='slider-wrapper'
              >
                {content.map((item, index) => (
                  <a
                    href={item.url}
                    key={index}
                    className='slider-content'
                    style={{
                      background: `url('${item.image}') no-repeat center center`
                    }}
                  >
                    <div className='inner'>
                      <ScrollAnimation
                        animateIn='fadeInRight'
                        animateOnce={true}
                        duration={1.4}
                      >
                        <p> {item.subtitle || ''}</p>
                        <h1> {item.title}</h1>
                        <HomeSliderBtnHolder>
                          <Row></Row>
                        </HomeSliderBtnHolder>
                      </ScrollAnimation>
                    </div>
                  </a>
                ))}
              </Slider>
            </HomeSliderHolder>
          </Col>
        </Row>
      </div>
    </Container>
  );
};
export default HomeSlider;
const ProductImage = styled.img`
  width: 100%;
`;
const ShopBanner2 = styled.div`
  margin-bottom: 25px;
  height: 212px;
  overflow: hidden;
  overflow: hidden;
  a {
    position: relative;
    z-index: 9;
    height: 100%;
  }
  span {
    text-transform: uppercase;
    font-family: 'Cairo-Bold';
    font-size: 20px;
  }
`;
const ShopBannerTittle = styled.div`
  width: 100%;
  color: #fff;
  h6 {
    font-size: 20px;
    margin: 0;
    color: #fff;
    text-align: ${(props) => (props.AlightText ? 'Right' : 'left')};

    @media (max-width: 414px) {
      text-align: right;
      font-size: 18px;
    }

    @media (max-width: 375px) {
      font-size: 16px;
    }
  }
`;

const HomeSliderBtnHolder = styled.div`
  @media (max-width: 414px) {
    width: 50%;
    margin: 0 auto;
    padding: 0 20px;
    display: block;
  }

  @media (max-width: 375px) {
    width: 60%;
  }
`;

const HomeSliderHolder = styled.div`
  @media (max-width: 414px) {
    .slider-wrapper {
      position: relative;
      height: 30vh;
      overflow: hidden;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 414px) {
    .slide {
    }
  }
`;

const Container = styled.div`
  .Reverse {
    @media (max-width: 767px) {
      display: flex;
      flex: 0 1 auto;
      flex-direction: row;
      flex-wrap: wrap-reverse;
    }
  }

  .slide {
    background-size: cover !important;
  }
`;
