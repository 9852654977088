/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Pagination, message } from 'antd';
import styled from 'styled-components';
import { connect } from 'react-redux';
import get from 'lodash/get';
import ScrollAnimation from 'react-animate-on-scroll';
import CustomDivider from '../elements/CustomDivider';
import { getGeneric } from '../appRedux/actions/Generic';
import { updateUser } from '../appRedux/actions/Auth';
import {
  getOffers,
  getNonHandshakedOffers
} from '../appRedux/actions/Products';
import OfferPageCard from '../components/OfferPageCard';
import NoOffers from '../components/NoOffers';

const OffersBasedPage = (props) => {
  const [tab, setTab] = useState(1);
  const [offers, setOffers] = useState(
    get(props, 'products.offers.newOffers', [])
  );
  const [unHandshakedOffers, setUnHandshakedOffers] = useState(
    get(props, 'products.nonHandshakedOffers.newOffers', [])
  );
  const [loading, setLoading] = useState(false);
  const [unhandshakedloading, setUnhandhakedLoading] = useState(false);
  const [skip, setSkip] = useState(1);
  const [limit] = useState(8);
  const [skipUnhandshaked, setSkipUnhandshaked] = useState(1);
  const [limitUnhandshaked] = useState(8);
  const [offersCount, setOffersCount] = useState(0);
  const [unhandshakedOffersCount, setUnhandshakedOffersCount] = useState(0);
  const [mainLoader, setMainLoader] = useState(true);
  const handleFailure = () => {
    setMainLoader(false);
  };
  const handleSuccess = () => {
    setMainLoader(false);
  };
  useEffect(() => {
    props.updateUser({ onSuccess: handleSuccess, onFailure: handleFailure });
  }, []);
  useEffect(() => {
    if (!mainLoader) {
      window.scroll(0, 0);
      props.getOffers({
        zone: get(props, 'auth.user.address.zone._id', ''),
        token: get(props, 'auth.token'),
        skip,
        limit
      });
      if (get(props, 'auth.token'))
        props.getNonHandshakedOffers({
          zone: get(props, 'auth.user.address.zone._id', ''),
          token: get(props, 'auth.token'),
          skip: skipUnhandshaked,
          limit: limitUnhandshaked
        });
    }
  }, [mainLoader]);

  useEffect(() => {
    if (!mainLoader) {
      props.getOffers({
        zone: get(props, 'auth.user.address.zone._id', ''),
        token: get(props, 'auth.token'),
        skip,
        limit
      });
    }
  }, [props.auth.user, skip, mainLoader]);
  useEffect(() => {
    if (!mainLoader) {
      if (get(props, 'auth.token'))
        props.getNonHandshakedOffers({
          zone: get(props, 'auth.user.address.zone._id', ''),
          token: get(props, 'auth.token'),
          skip: skipUnhandshaked,
          limit: limitUnhandshaked
        });
    }
  }, [props.auth.user, skipUnhandshaked, mainLoader]);

  useEffect(() => {
    setOffers(get(props, 'products.offers.newOffers'));
    setOffersCount(get(props, 'products.offers.total'));
  }, [props.products.offers.newOffers]);

  useEffect(() => {
    setLoading(props.products.offersLoading);
  }, [props.products.offersLoading]);

  useEffect(() => {
    setUnHandshakedOffers(get(props, 'products.nonHandshakedOffers.newOffers'));
    setUnhandshakedOffersCount(
      get(props, 'products.nonHandshakedOffers.total')
    );
  }, [props.products.nonHandshakedOffers.newOffers]);

  useEffect(() => {
    setUnhandhakedLoading(props.products.nonHandshakedOffersLoading);
  }, [props.products.nonHandshakedOffersLoading]);

  const onChange = (page) => {
    setSkip(page);
  };
  const onUnhandshakedChange = (page) => {
    setSkipUnhandshaked(page);
  };
  if (mainLoader)
    return (
      <div style={{ padding: '50%' }}>
        <Spin />
      </div>
    );

  return (
    <React.Fragment>
      <ImageContainer>
        <GradientDiv></GradientDiv>

        <ProductImage src={require('../assets/images/AAA.png')} />
        <CustomRow>
          <div className='container'>
            <MoreBg>
              <StyledOffersTitle>العروض</StyledOffersTitle>
            </MoreBg>
          </div>
        </CustomRow>
      </ImageContainer>
      <SectionMargin>
        <div className='container'>
          {get(props, 'auth.token') && (
            <SwitchRow>
              <SwitchContainer>
                <SwitchOption
                  tab={tab}
                  index={1}
                  onClick={() => {
                    setTab(1);
                  }}
                >
                  عروض شركاتي
                </SwitchOption>
                <SwitchOption
                  tab={tab}
                  index={2}
                  onClick={() => {
                    setTab(2);
                  }}
                >
                  عروض أخرى
                </SwitchOption>
              </SwitchContainer>
            </SwitchRow>
          )}
          {tab === 1 ? (
            <>
              <Row gutter={46}>
                {!loading ? (
                  get(offers, 'length', 0) ? (
                    offers.map((offer, index) => {
                      const i = index + 1;
                      return (
                        <React.Fragment>
                          <ProductCol index={i} lg={6} md={12} sm={12} xs={12}>
                            <ScrollAnimation
                              animateIn='fadeInUp'
                              animateOnce={true}
                            >
                              <ProductContainer>
                                <OfferPageCard offer={offer} {...props} />
                              </ProductContainer>
                            </ScrollAnimation>
                          </ProductCol>

                          {i % 4 === 0 &&
                          index !== 0 &&
                          index !== get(offers, 'length', 0) - 1 ? (
                            <Col span={24}>
                              <CustomDivider />
                            </Col>
                          ) : (
                            ''
                          )}
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <NoOffers />
                  )
                ) : (
                  <Spin />
                )}
              </Row>
              <Row>
                <PaginationHolder>
                  <StyledPagination
                    style={{
                      direction: 'rtl',
                      textAlign: 'center',
                      margin: '30px 0'
                    }}
                    defaultCurrent={1}
                    total={offersCount}
                    pageSize={8}
                    current={Number(skip)}
                    onChange={onChange}
                  />
                </PaginationHolder>
              </Row>
            </>
          ) : (
            <>
              <Row gutter={46}>
                {!unhandshakedloading ? (
                  get(unHandshakedOffers, 'length', 0) ? (
                    unHandshakedOffers.map((offer, index) => {
                      const i = index + 1;
                      return (
                        <React.Fragment>
                          <ProductCol index={i} lg={6} md={12} sm={12} xs={12}>
                            <ScrollAnimation
                              animateIn='fadeInUp'
                              animateOnce={true}
                            >
                              <ProductContainer>
                                <OfferPageCard offer={offer} {...props} />
                              </ProductContainer>
                            </ScrollAnimation>
                          </ProductCol>

                          {i % 4 === 0 &&
                          index !== 0 &&
                          index !== get(unHandshakedOffers, 'length') - 1 ? (
                            <Col span={24}>
                              <CustomDivider />
                            </Col>
                          ) : (
                            ''
                          )}
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <NoOffers />
                  )
                ) : (
                  <Spin />
                )}
              </Row>
              <Row>
                <PaginationHolder>
                  <StyledPagination
                    style={{
                      direction: 'rtl',
                      textAlign: 'center',
                      margin: '30px 0'
                    }}
                    defaultCurrent={1}
                    total={unhandshakedOffersCount}
                    pageSize={8}
                    current={Number(skipUnhandshaked)}
                    onChange={onUnhandshakedChange}
                  />
                </PaginationHolder>
              </Row>
            </>
          )}
        </div>
      </SectionMargin>
    </React.Fragment>
  );
};
const mapStateToProps = ({ main, auth, products }) => ({
  main,
  auth,
  products
});
export default connect(mapStateToProps, {
  updateUser,
  getGeneric,
  getOffers,
  getNonHandshakedOffers
})(OffersBasedPage);
const ProductCol = styled(Col)`
  border-left: ${(props) =>
    props.index % 4 !== 0 ? `1px solid ${props.theme.primary}` : ''};
  float: right;
  margin-bottom: 2px;
`;
const ImageContainer = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
  clear: both;
`;

const SwitchRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  @media (max-width: 767px) {
    padding: 20px 0;
    display: inherit;
    text-align: center;
    margin: 0 auto;
    display: table;
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  background-color: #e2e5ea;
  width: 300px;
  height: 80px;
  border-radius: 20px;
  @media (max-width: 767px) {
    height: 40px;
  }
`;

const SwitchOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: ${(props) => (props.tab === props.index ? 'white' : 'black')};
  background-color: ${(props) =>
    props.tab === props.index ? '#42628d' : '#e2e5ea'};
  width: 150px;
  height: 80px;
  border-radius: 20px;

  @media (max-width: 767px) {
    height: 40px;
    font-size: 17px;
  }
`;

const ProductImage = styled.img`
  height: 300px;
  width: 100%;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 150px;
  }

  @media (max-width: 414px) {
    height: 125px;
  }
`;

const CustomRow = styled.div``;

const StyledOffersTitle = styled.text`
  border-bottom: 1px solid white;
  font-size: 30px;

  @media (max-width: 414px) {
    font-size: 20px;
  }
`;

const ProductContainer = styled.div`
  padding-bottom: 2.6vw;
`;

const GradientDiv = styled.div`
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.8;
  background-image: linear-gradient(
    rgb(17, 25, 45),
    rgba(17, 25, 45, 0.85) 30%,
    rgba(17, 25, 45, 0)
  );
`;

const MoreBg = styled.div`
  position: absolute;
  color: #fff;
  z-index: 9999;
  top: 0;
  margin: 0;
  position: absolute;
  top: 70%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 20px;
  padding: 50px;
  width: inherit;
  text-align: center;
  background: red;
  background-color: rgba(17, 25, 45, 0.55);
  background: rgba(17, 25, 45, 0.53);
  @media (max-width: 1024px) {
    padding: 20px;
    -webkit-transform: translate(-50%, -100%);
    top: 70%;
    transform: translate(-50%, -100%);
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    width: 90%;
    top: 70%;
  }
`;

const SectionMargin = styled.div`
  margin: 30px 0;
`;

const PaginationHolder = styled.div`
  margin: 50px 0;

  @media (max-width: 414px) {
    margin: 20px 0;
  }
`;

const StyledPagination = styled(Pagination)`
  .ant-pagination-item-active,
  .ant-pagination-item,
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-color: #10182d;
  }

  .ant-pagination-item-active a {
    color: #4a7fbd !important;
    font-weight: bold;
  }
  a.ant-pagination-item-link i.anticon.anticon-right svg {
    transform: rotate(-180deg);
  }
  li.ant-pagination-disabled.ant-pagination-prev svg {
    transform: rotate(180deg);
  }
  .ant-pagination-item a,
  .ant-pagination-item {
    color: #10182d;
  }

  .ant-pagination-item a :hover,
  .ant-pagination-item:hover {
    color: red !important;
    font-weight: bold;
  }

  .ant-pagination-item {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    margin-right: 0.58vw;
    outline: 0;
    font-size: 17px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
  }
  .ant-pagination-next {
    display: inline-block;
    min-width: 32px;
    line-height: 32px;
    margin-left: 10px;
    border-radius: 0.29vw;
  }
  .ant-pagination-prev {
    display: inline-block;
    height: 32px;
    margin-right: 10px;
    line-height: 32px;
    border-radius: 0.29vw;
  }
  .ant-pagination-item-link {
    font-size: 1vw;
    @media (max-width: 768px) {
      font-size: 20px;
    }
    @media (min-width: 2560px) {
      font-size: 0.5vw;
    }
  }
`;
