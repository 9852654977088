import React from 'react';

const PlusSvg = () => (
  <svg
    id='Layer_1'
    width='1em'
    height='1em'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 55.06 55.06'
  >
    <defs>
      <style dangerouslySetInnerHTML={{ __html: '.cls-1{fill:#4c7fba;}' }} />
    </defs>
    <title>plus2</title>
    <path
      className='cls-1'
      d='M-17.17,17.19a2.2,2.2,0,0,0-2.2,2.2V70.05a2.2,2.2,0,0,0,2.2,2.2,2.2,2.2,0,0,0,2.2-2.2V19.39A2.2,2.2,0,0,0-17.17,17.19Z'
      transform='translate(44.7 -17.19)'
    />
    <path
      className='cls-1'
      d='M8.16,42.52H-10.37a2.2,2.2,0,0,0-2.2,2.2,2.2,2.2,0,0,0,2.2,2.2H8.16a2.2,2.2,0,1,0,0-4.4Z'
      transform='translate(44.7 -17.19)'
    />
    <path
      className='cls-1'
      d='M-24.53,42.52h-18a2.2,2.2,0,0,0-2.2,2.2,2.2,2.2,0,0,0,2.2,2.2h18a2.2,2.2,0,0,0,2.2-2.2A2.2,2.2,0,0,0-24.53,42.52Z'
      transform='translate(44.7 -17.19)'
    />
  </svg>
);

export default PlusSvg;
