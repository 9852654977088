import React from 'react';

const barcodeSvg = () => (
  <svg
    height='1em'
    width='1em'
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 78.85 78.78'
  >
    <defs>
      <style dangerouslySetInnerHTML={{ __html: '.cls-1{fill:#4c7fba;}' }} />
    </defs>
    <title>barcode</title>
    <path
      className='cls-1'
      d='M391,268.35v21.38a1.55,1.55,0,0,0,1.55,1.55h21.38a1.55,1.55,0,0,0,1.55-1.55v-4.05a1.55,1.55,0,1,0-3.09,0v2.51H394.11V269.89H412.4v3.72a1.55,1.55,0,1,0,3.09,0v-5.27a1.55,1.55,0,0,0-1.55-1.55H392.56A1.55,1.55,0,0,0,391,268.35Z'
      transform='translate(-381.49 -257.47)'
    />
    <path
      className='cls-1'
      d='M400,274.29a1.55,1.55,0,0,0-1.55,1.55v6.42a1.55,1.55,0,0,0,1.55,1.55h6.42a1.55,1.55,0,0,0,1.55-1.55v-6.42a1.55,1.55,0,0,0-1.55-1.55Zm4.87,6.42h-3.32v-3.32h3.32Z'
      transform='translate(-381.49 -257.47)'
    />
    <path
      className='cls-1'
      d='M413.95,302.32H392.56a1.55,1.55,0,0,0-1.55,1.55v21.38a1.55,1.55,0,0,0,1.55,1.55h21.38a1.55,1.55,0,0,0,1.55-1.55V303.86A1.55,1.55,0,0,0,413.95,302.32ZM412.4,323.7H394.11V305.41H412.4Z'
      transform='translate(-381.49 -257.47)'
    />
    <path
      className='cls-1'
      d='M428.19,291.28h21.38a1.55,1.55,0,0,0,1.55-1.55V268.35a1.55,1.55,0,0,0-1.55-1.55H428.19a1.55,1.55,0,0,0-1.55,1.55v21.38A1.55,1.55,0,0,0,428.19,291.28Zm1.55-21.38H448v18.29H429.73Z'
      transform='translate(-381.49 -257.47)'
    />
    <path
      className='cls-1'
      d='M451.12,325.25V303.86a1.55,1.55,0,0,0-1.55-1.55H428.19a1.55,1.55,0,0,0-1.55,1.55v21.38a1.55,1.55,0,0,0,1.55,1.55h21.38A1.55,1.55,0,0,0,451.12,325.25ZM448,323.7H429.73V305.41H448Z'
      transform='translate(-381.49 -257.47)'
    />
    <path
      className='cls-1'
      d='M449.57,298.44a1.55,1.55,0,0,0,0-3.09H422.46V268.18a1.55,1.55,0,1,0-3.09,0v27.17H392.56a1.55,1.55,0,0,0,0,3.09h26.81v26.81a1.55,1.55,0,0,0,3.09,0V298.44Z'
      transform='translate(-381.49 -257.47)'
    />
    <path
      className='cls-1'
      d='M400,319.43h6.42a1.55,1.55,0,0,0,1.55-1.55v-6.42a1.55,1.55,0,0,0-1.55-1.55H400a1.55,1.55,0,0,0-1.55,1.55v6.42A1.55,1.55,0,0,0,400,319.43Zm1.55-6.42h3.32v3.32h-3.32Z'
      transform='translate(-381.49 -257.47)'
    />
    <path
      className='cls-1'
      d='M442.09,274.29h-6.42a1.55,1.55,0,0,0-1.55,1.55v6.42a1.55,1.55,0,0,0,1.55,1.55h6.42a1.55,1.55,0,0,0,1.55-1.55v-6.42A1.55,1.55,0,0,0,442.09,274.29Zm-1.55,6.42h-3.32v-3.32h3.32Z'
      transform='translate(-381.49 -257.47)'
    />
    <path
      className='cls-1'
      d='M435.67,319.43h6.42a1.55,1.55,0,0,0,1.55-1.55v-6.42a1.55,1.55,0,0,0-1.55-1.55h-6.42a1.55,1.55,0,0,0-1.55,1.55v6.42A1.55,1.55,0,0,0,435.67,319.43Zm1.55-6.42h3.32v3.32h-3.32Z'
      transform='translate(-381.49 -257.47)'
    />
    <path
      className='cls-1'
      d='M413.64,278.1l-.08,0a1.55,1.55,0,0,0,.35,3.05,1.53,1.53,0,0,0,.35,0l.08,0a1.55,1.55,0,0,0-.71-3Z'
      transform='translate(-381.49 -257.47)'
    />
    <path
      className='cls-1'
      d='M383,270.14a1.55,1.55,0,0,0,1.55-1.55v-8h7.73a1.55,1.55,0,1,0,0-3.09H383a1.55,1.55,0,0,0-1.55,1.55v9.57A1.55,1.55,0,0,0,383,270.14Z'
      transform='translate(-381.49 -257.47)'
    />
    <path
      className='cls-1'
      d='M458.8,257.47h-9.28a1.55,1.55,0,1,0,0,3.09h7.73v8a1.55,1.55,0,0,0,3.09,0V259A1.55,1.55,0,0,0,458.8,257.47Z'
      transform='translate(-381.49 -257.47)'
    />
    <path
      className='cls-1'
      d='M392.32,333.16h-7.73v-8a1.55,1.55,0,0,0-3.09,0v9.57a1.55,1.55,0,0,0,1.55,1.55h9.28a1.55,1.55,0,1,0,0-3.09Z'
      transform='translate(-381.49 -257.47)'
    />
    <path
      className='cls-1'
      d='M458.8,323.59a1.55,1.55,0,0,0-1.55,1.55v8h-7.73a1.55,1.55,0,1,0,0,3.09h9.28a1.55,1.55,0,0,0,1.55-1.55v-9.57A1.55,1.55,0,0,0,458.8,323.59Z'
      transform='translate(-381.49 -257.47)'
    />
  </svg>
);

export default barcodeSvg;
