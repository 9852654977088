import React, { useState } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { updateUser } from '../appRedux/actions/Auth';
import { getGeneric } from '../appRedux/actions/Generic';

const TabStyleLeft = { color: '#4a7fbd', fontWeight: 800 };

const MyConnections = (props) => {
  const [tab, setTab] = useState(0);

  return (
    <React.Fragment>
      <TabsArea>
        <TabsAreaRow>
          <Col span={12}>
            <Tab
              onClick={() => {
                setTab(1);
              }}
            >
              <div style={tab === 1 ? TabStyleLeft : undefined}>
                قيد الانتظار
              </div>
            </Tab>
          </Col>
          <Col span={12}>
            <Tab
              style={{ borderLeft: '1px solid' }}
              onClick={() => {
                setTab(0);
              }}
            >
              <div id='google' style={tab === 0 ? TabStyleLeft : undefined}>
                {' '}
                تمت
              </div>
            </Tab>
          </Col>
        </TabsAreaRow>
      </TabsArea>
      {tab === 0 ? (
        props.type === 'distributors' &&
        get(props.auth, 'user.distributors.length') ? (
          get(
            props.auth,
            props.type === 'distributors'
              ? 'user.distributors'
              : 'user.companies',
            []
          ).map((item) => (
            <MyConnectionsHolder>
              <Col lg={8} md={12} sm={12} xs={12}>
                <Text> القطاع :</Text>
                <TextBold>
                  {props.type === 'distributors'
                    ? get(item, 'buyerCategory.name.ar') || 'غير متوفر'
                    : get(item, 'buyerCategory.name.ar') || 'غير متوفر'}
                </TextBold>
              </Col>
              <Col lg={6} md={12} sm={12} xs={12} className='border-mobile'>
                <Text>المنطقة:</Text>
                <TextBold>{get(item, 'zone.name.ar') || 'غير متوفره'}</TextBold>
              </Col>
              <Col lg={6} md={12} sm={12} xs={12}>
                <Text>الاسم:</Text>
                <TextBold>
                  {props.type === 'distributors'
                    ? get(item, 'distributor.name.ar') || 'غير متوفر'
                    : get(item, 'company.name.ar') || 'غير متوفر'}
                </TextBold>
              </Col>
              <Col
                lg={4}
                md={12}
                sm={12}
                xs={12}
                style={{ borderLeft: 'solid 1px black' }}
              >
                <CustomImage
                  src={get(
                    item,
                    props.type === 'distributors'
                      ? 'distributor.images[0]'
                      : 'company.images[0]',
                    require('../assets/images/repzone-placeholder.png')
                  )}
                  alt=''
                />
              </Col>
            </MyConnectionsHolder>
          ))
        ) : (
          <EmptyText> لا يوجد موزعين </EmptyText>
        )
      ) : props.type === 'distributors' &&
        get(props.auth, 'user.pendingDistributors.length') ? (
        get(
          props.auth,
          props.type === 'distributors'
            ? 'user.pendingDistributors'
            : 'user.pendingCompanies',
          []
        ).map((item) => (
          <MyConnectionsHolder2>
            <Container>
              <Col lg={8} md={12} sm={12} xs={12}>
                <Text> رقم التيليفون:</Text>
                <TextBold>
                  {props.type === 'distributors'
                    ? get(item, 'distributor.phone') || 'غير متوفر'
                    : get(item, 'company.phone') || 'غير متوفر'}
                </TextBold>
              </Col>

              <Col lg={6} md={12} sm={12} xs={12} className='border-mobile'>
                <Text>المنطقة:</Text>
                <TextBold>{get(item, 'zone.name.ar') || 'غير متوفره'}</TextBold>
              </Col>
              <Col lg={6} md={12} sm={12} xs={12}>
                <Text>الاسم:</Text>
                <TextBold>
                  {props.type === 'distributors'
                    ? get(item, 'distributor.name.ar') || 'غير متوفر'
                    : get(item, 'company.name.ar') || 'غير متوفر'}
                </TextBold>
              </Col>
              <Col
                lg={4}
                md={12}
                sm={12}
                xs={12}
                style={{ borderLeft: 'solid 1px black' }}
              >
                <CustomImage
                  src={get(
                    item,
                    props.type === 'distributors'
                      ? 'distributor.images[0]'
                      : 'company.images[0]',
                    require('../assets/images/repzone-placeholder.png')
                  )}
                  alt=''
                />
              </Col>
            </Container>
          </MyConnectionsHolder2>
        ))
      ) : (
        <EmptyText> لا يوجد موزعين في قائمة الانتظار </EmptyText>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = ({ main, auth }) => ({ main, auth });
export default connect(mapStateToProps, { updateUser, getGeneric })(
  MyConnections
);
const CustomImage = styled.img`
  height: 70px;
  width: 70px;
  object-fit: contain;
  padding-left: 13px;
  margin-top: 20px;
  margin: 20px auto;
  display: block;
  text-align: center;

  @media (max-width: 768px) {
    margin: 15px auto;
    padding-left: 0;
    height: 50px;
    width: 50px;
  }
`;

const Container = styled.div``;

const EmptyText = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.primary};
  margin-bottom: 2vw;
  margin-top: 2vw;
  text-align: center;
`;

const Text = styled.div`
  font-size: 16px;
  font-family: 'Cairo-Light';
  font-weight: 300;
  color: #11192d;
  padding-bottom: 1vw;
  padding-top: 2vw;
  text-align: center;
`;

const TextBold = styled.div`
  font-size: 17px;
  font-weight: bold;
  color: #11192d;
  text-align: center;
  overflow-wrap: break-word;
  padding-bottom: 2vw;
`;
const TabsArea = styled.div`
  background-color: #11192d;
  padding: 10px;
  @media (max-width: 414px) {
    margin: 30px 0;
  }
`;
const Tab = styled.div`
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  color: #ffffff;
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer;
  overflow-wrap: break-word;

  @media (max-width: 767px) {
    font-size: 15px;
  }
`;

const MyConnectionsHolder = styled(Row)`
  @media (max-width: 768px) {
    .border-mobile {
      border-left: 1px solid #000;
    }
  }

  border: 1px solid #000;
  padding: 10px;
  display: inherit !important;
  flex-direction: row-reverse !important;

  @media only screen and (min-width: 767px) and (max-width: 768px) {
    display: inherit !important;
    -ms-flex-wrap: inherit !important;
    flex-direction: row-reverse !important;
  }
`;

const TabsAreaRow = styled(Row)`
  display: inherit !important;
  flex-wrap: inherit !important;
  display: inherit !important;
  flex-direction: inherit !important;
`;

const MyConnectionsHolder2 = styled(Row)`
  border: 1px solid #000;
  padding: 10px;
  display: inherit !important;
  -ms-flex-wrap: inherit !important;
  display: inherit !important;

  flex-direction: inherit !important;

  @media (max-width: 768px) {
    .border-mobile {
      border-left: 1px solid #000;
    }
  }
`;
