/* eslint-disable func-names */
/* eslint-disable import/extensions */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux';
import styled from 'styled-components';
import configureStore, { history } from '../appRedux/store';
import Header from '../elements/Header';
import NavBar from '../elements/NavBar';
import Register from '../pages/RegisterPage';
import Login from '../pages/LoginPage';
import CompanyDetailsView from '../pages/CompanyDetailsView';
import AllCompanies from '../pages/AllCompanies';
import Cart from '../pages/Cart';
import SingleProduct from '../pages/SingleProduct';
import Checkout from '../pages/Checkout';
import AboutUs from '../pages/AboutUs';
import Footer from '../elements/Footer';
import Profile from '../pages/Profile';
import LandingPage from '../pages/LandingPage';
import CategoryBased from '../pages/CategoryBased';
import ResetPass from '../pages/ResetPass';
import BrandBased from '../pages/BrandBased';
import FeaturedProductsPage from '../pages/FeaturedProductsPage';
import OffersBasedPage from '../pages/OffersBasedPage';
import MobileMenu from '../components/MobileMenu';
import Wishlist from '../pages/Wishlist';
import Help from '../pages/Help.jsx';

export const { store } = configureStore();
export const { persistor } = configureStore();
const token = localStorage.getItem('token');
const MainRoutes = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <div>
          <Header />
          <Body>
            <Webview>
              <NavBar
                src=''
                className={
                  window.location.pathname === '/' ? 'classnavbar' : ''
                }
              />
            </Webview>

            <MobileView>
              <MobileMenu />
            </MobileView>

            <Switch>
              <Route exact path='/' component={LandingPage} />
              <Route path='/allcompanies' component={AllCompanies} />
              <Route path='/company' component={CompanyDetailsView} />
              <Route path='/brand' component={BrandBased} />
              <Route path='/featured' component={FeaturedProductsPage} />
              <Route path='/offers' component={OffersBasedPage} />
              <Route path='/cart' component={Cart} />
              <Route path='/checkout' component={Checkout} />
              <Route path='/login' component={Login} />
              <Route path='/register' component={Register} />
              <Route path='/help' component={Help} />
              <Route path='/singleproduct' component={SingleProduct} />
              <Route path='/profile' component={Profile} />
              <Route path='/section' component={CategoryBased} />
              <Route path='/reset-password' component={ResetPass} />
              <Route path='/aboutus' component={AboutUs} />
              <Route
                path='/wishlist'
                render={(props) =>
                  token ? (
                    <Wishlist {...props} />
                  ) : (
                    <Redirect
                      to={{
                        pathname: '/login',
                        state: { from: props.location }
                      }}
                    />
                  )
                }
              />
              <Redirect to='/login' />
            </Switch>
          </Body>
          <Footer />
        </div>
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);
window.onpopstate = function() {
  window.location.reload();
};
const Body = styled.div`
  width: 100%;
`;

const MobileView = styled.div`
  display: none;
  direction: ltr;
  @media (max-width: 768px) {
    display: block;
  }
`;

const Webview = styled.div`
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`;

export default MainRoutes;
