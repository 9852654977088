import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

const StyledButton = styled(Button)`
clear:both;
    background-color:${(props) => props.theme.accent};
    color:white;
    height: ${(props) => props.height};
    width: ${(props) => props.width};
    @media (max-width: 768px) {
      height: ${(props) => props.smallHeight}; 
      width: ${(props) => props.smallWidth};
  }
    padding: 0 ${(props) => props.padding};
    ${(props) => (props.float ? `float:${props.float};` : '')}
    ${(props) => (props.height ? `height:${props.height};` : '')}
    ${(props) => (props.size ? `font-size:${props.size};` : '')}
    border: solid 0.15vw ${(props) => props.theme.accent};
    ${(props) => (props.transparent ? 'background-color:transparent;' : '')}
    font-weight:bold;
    &:hover{
        color:${(props) => props.theme.accent};
        border: solid 0.15vw ${(props) => props.theme.accent};
    }
`;

const CustomButton = (props) => {
  const onclick = props.onClick || (() => {});
  return (
    <StyledButton
      {...props}
      onClick={(e) => {
        e.target.blur();
        onclick(e);
      }}
    >
      {props.children}
    </StyledButton>
  );
};

export default CustomButton;
