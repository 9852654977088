import ImageGallery from 'react-image-gallery';
import React from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import { isEmpty, get } from 'lodash';

const logo = require('../assets/images/repzone-placeholder.png');

export default function SingleProductSlider(props) {
  let images = get(props, 'companyProduct.images', []);
  if (isEmpty(images)) {
    images = [logo];
  }
  return (
    <ImageGallery
      isRTL={true}
      showFullscreenButton={false}
      showPlayButton={false}
      showThumbnails={true}
      thumbnailPosition={'left'}
      renderThumbInner={(img) => (
        <img
          alt='product-thumb'
          style={{ height: '45px' }}
          src={img.thumbnail}
        />
      )}
      renderItem={(img) => (
        <img
          alt='product'
          style={{
            width: '100%',
            height: '500px',
            margin: 0,
            objectFit: 'contain'
          }}
          src={img.original}
        />
      )}
      items={images.map((img) => ({
        original: img,
        thumbnail: img
      }))}
    />
  );
}
