/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
import React from 'react';
import { Row, Col, Icon } from 'antd';
import styled from 'styled-components';
import CustomDivider from './CustomDivider';
import PhoneSvg from '../icons/PhoneSvg';
import MailSvg from '../icons/MailSvg';
import WebsiteSvg from '../icons/WebsiteSvg';

const FooterContainer = styled.div`
  @media (max-width: 414px) {
    em {
      display: inherit;
      width: 100%;
    }
  }
  background-color: ${(props) => props.theme.primary};
  width: 100%;
  color: white;

  padding-top: 1.5vw;

  font-weight: bold;
  margin-top: 1.5vw;
  direction: ltr;
  @media (max-width: 414px) {
    padding: 20px 0;
  }
`;
const FooterBody = styled.div`
  padding: 10px;
  color: white;
  text-align: right;
  @media (max-width: 414px) {
    text-align: center;
  }
  hr {
    background: grey;
    border-top: 1px solid grey;
    border-bottom: 0;
    margin: 20px 0;
  }
`;

const Copyright = styled.p`
  text-align: center;
  font-size: 90%;
  margin-bottom: 5vh;
  font-weight: bold;
  padding: 10px 0;
  @media (max-width: 414px) {
    margin-bottom: 0;
    line-height: 25px;
    text-align: center;
    padding: 0;
  }
  @media (max-width: 320px) {
    line-height: 20px;
    font-size: 75%;
  }
`;
const StyledRow = styled(Row)`
  margin-bottom: 4vmin;
`;
const FooterIcon = styled(Icon)`
  font-size: 120%;
  margin-left: 1vw;
  path {
    fill: white;
  }
`;
const FooterIconText = styled.p`
  display: inline-block;
  margin-bottom: 0;
  font-size: 16px;
  @media (max-width: 414px) {
    font-size: 15px;
  }
  font-weight: normal;
  margin: 20px 0;
  @media (max-width: 414px) {
    margin: 0;
  }
  a {
    color: #fff;
  }
  @media (max-width: 375px) {
    font-size: 14px;
  }
  @media (max-width: 320px) {
    font-size: 12px;
  }
`;
const FooterIconContainer = styled.div``;
const CardsContainer = styled.div`
  img {
    width: 100px;
    @media (max-width: 414px) {
      width: 75px;
    }
  }
  height: auto;
  display: flex;
  justify-content: center;

  @media (max-width: 414px) {
    padding: 0;
  }
`;
const FacebookImage = styled.img`
  width: 3vw;
  object-fit: contain;
  margin-right: 1.8vw;
`;
const Footer = () => (
  <FooterContainer>
    <div className='container'>
      <FooterBody>
        <CardsContainer>
          <Row gutter={48}>
            <Col span={12}>
              <img
                style={{ objectFit: 'contain' }}
                alt={'cards'}
                src={require('../assets/images/visa.png')}
              />
            </Col>
            <Col span={12}>
              <img
                style={{ objectFit: 'contain' }}
                alt={'cards'}
                src={require('../assets/images/Combined_Meeza_Sign.png')}
              />{' '}
            </Col>
          </Row>
        </CardsContainer>
        <StyledRow display='flex' justify='center'>
          <hr color={'grey'} />

          <Col xl={6} lg={6} md={6} sm={6} xs={8}>
            <FooterIconContainer>
              <FooterIconText>16785</FooterIconText>
              <FooterIcon color={'white'} component={PhoneSvg} />
            </FooterIconContainer>
          </Col>

          <Col xl={6} lg={6} md={6} sm={6} xs={10}>
            <FooterIconContainer>
              <FooterIconText>
                <a href='mailto:info@repzone.org?Subject=' target='_top'>
                  info@repzone.org
                </a>
              </FooterIconText>
              <FooterIcon color={'white'} component={MailSvg} />
            </FooterIconContainer>
          </Col>

          {/* <Col xl={6} lg={6} md={6} sm={6} xs={12}>
            <StyledCol>حول ريبزون</StyledCol>
            <StyledCol>معلومات عنا</StyledCol>
            <StyledCol>مسؤولية مشتركة</StyledCol>
            <StyledCol>موقع المستثمرين</StyledCol>
          </Col> */}
          <Col xl={6} lg={6} md={6} sm={6} xs={6}>
            <StyledCol>
              <a href='/aboutus'>حول ريبزون </a>
            </StyledCol>
          </Col>
        </StyledRow>

        <hr />
        <Copyright>
          كل الحقوق محفوظه لدي شركه ريبزون <em /> ويمنع تداول اي حقوق ملكيه ©
          2019
        </Copyright>
      </FooterBody>
    </div>
  </FooterContainer>
);

const StyledCol = styled.div`
  font-size: 16px;
  font-weight: normal;
  margin: 16px 0;
  text-align: right;
  @media (max-width: 414px) {
    text-align: center;
    margin: 0;
  }
  @media (max-width: 375px) {
    font-size: 14px;
  }
  a {
    color: #fff;
  }
`;

const SocialMedia = styled.div`
  border-right: 0.5px solid grey;
  margin-right: 1.8vw;
`;
export default Footer;
