/* eslint-disable import/extensions */
import React, { useState, useEffect } from 'react';
import { Modal, Row } from 'antd';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { updateUser } from '../appRedux/actions/Auth';
import { getGeneric } from '../appRedux/actions/Generic';
import OfferModalProduct from '../components/OfferModalProduct';

const OfferDetailsModal = (props) => {
  const [offer, setOffer] = useState(props.offer);
  const [product, setProduct] = useState(props.product);
  const [addOns, setAddOns] = useState(props.addOns);
  useEffect(() => {
    setOffer(props.offer);
  }, [props.offer]);

  useEffect(() => {
    setProduct(props.product);
  }, [props.product]);

  useEffect(() => {
    setAddOns(props.addOns);
  }, [props.addOns]);

  return (
    <CustomModal
      title={
        offer.type === 2
          ? 'عرض سعر'
          : offer.type === 1
          ? 'عرض كمية'
          : 'عرض هدايا'
      }
      visible={props.visible}
      onCancel={() => props.setVisible(false)}
      footer={null}
    >
      <Container>
        <Row>
          <OfferModalProduct
            offer={offer}
            product={product}
            quantityCarton={props.quantityCarton}
            quantityPackage={props.quantityPackage}
          />
        </Row>
        {addOns.length ? (
          <Row>
            <FreeProduct>المنتجات المجانية</FreeProduct>
            {addOns.map((product) => (
              <Row>
                <OfferModalProduct
                  offer={offer}
                  product={product}
                  quantityCarton={product.quantityCarton}
                  quantityPackage={product.quantityPackage}
                />
              </Row>
            ))}
          </Row>
        ) : null}
      </Container>
    </CustomModal>
  );
};

const mapStateToProps = ({ main, auth }) => ({ main, auth });
export default connect(mapStateToProps, { updateUser, getGeneric })(
  OfferDetailsModal
);

const CustomModal = styled(Modal)`
  .ant-modal-close-x {
    line-height: normal;
  }
  .ant-modal-title {
    font-size: 21px;
    @media (max-width: 414px) {
      font-size: 18px;
      font-family: 'Cairo-Bold';
    }
  }

  direction: rtl;

  .ant-modal-header {
    background: #e1e5ea;
  }

  .ant-modal-close {
    left: 0;
    right: inherit;
  }
`;

const Container = styled.div``;

const FreeProduct = styled.div`
  @media (max-width: 767px) {
    text-align: center;
    margin: 0px;
    font-size: 21px;
    color: #52c41a;
    margin-top: 20px;
  }
`;
