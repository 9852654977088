/* eslint-disable import/extensions */
import React, { Component } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { groupBy, get, findIndex, isEqual } from 'lodash';
import CartDetails from '../components/CartDetails';
import { getGeneric, updateGeneric } from '../appRedux/actions/Generic';
import { updateObject } from '../services/CommonServices';
import CartDrawer from '../components/CartDrawer';
import { updateUser } from '../appRedux/actions/Auth';
import { getCart } from '../appRedux/actions/Cart';
import CartEmpty from '../components/CartEmpty';
import CartProduct from '../components/CartProduct';
import CustomButton from '../elements/CustomButton';

class Cart extends Component {
  state = {
    visible: false,
    defaultCart: {},
    cart: {},
    buyerCategoryId: '',
    saving: false
  };

  SaveCart = () => {
    updateObject('buyers/saveCart', { cart: this.state.cart })
      .then(() => {
        // this.props.updateUser();
        this.setState({ saving: false });
        this.props.getCart();
      })
      .catch(() => {});

    this.onClose();
  };

  saveProductInCart = (product) => {
    const cart = Object.assign({}, get(this.state, 'cart', {}));
    for (let i = 0; i < cart.products.length; i += 1) {
      if (String(cart.products[i]._id) === String(product._id)) {
        /* eslint-disable no-restricted-globals */
        if (
          (Number(product.quantity[0].quantity) === 0 &&
            Number(product.quantity[1].quantity) === 0) ||
          (isNaN(Number(product.quantity[0].quantity)) &&
            Number(product.quantity[1].quantity) === 0) ||
          (isNaN(Number(product.quantity[1].quantity)) &&
            Number(product.quantity[0].quantity) === 0) ||
          (isNaN(Number(product.quantity[0].quantity)) &&
            isNaN(Number(product.quantity[1].quantity)))
        ) {
          this.deleteProduct(product);
          this.setState(cart);
          break;
        } else {
          cart.products[i] = product;
          this.setState(cart);
          break;
        }
      }
    }
  };

  showDrawer = () => {
    this.setState({
      visible: true,
      saving: true
    });
  };

  deleteProduct = (product) => {
    const cart = JSON.parse(JSON.stringify(get(this.state, 'cart', {})));
    const productIndex = findIndex(
      cart.products,
      (item) => item._id === product._id
    );
    cart.products.splice(productIndex, 1);
    this.setState(
      {
        cart
      },
      this.updateProductsAndOffers
    );
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };

  updateProductsAndOffers = () => {
    const offers = [];
    get(this.state, 'cart.products', []).forEach((product, index) => {
      if (get(product, 'product.offer')) {
        offers.push({ ...product, index });
      }
    });
    const companyProducts = [];
    get(this.state, 'cart.products', []).forEach((product, index) => {
      if (!get(product, 'product.offer')) {
        companyProducts.push({ ...product, index });
      }
    });
    this.setState({
      offers,
      companyProducts
    });
  };

  componentDidMount = (props) => {
    if (!props) this.props.getCart();
    const newProps = props || this.props;
    const offers = [];
    get(newProps, 'cart.products', []).forEach((product, index) => {
      if (get(product, 'product.offer')) {
        offers.push({ ...product, index });
      }
    });
    const companyProducts = [];
    get(newProps, 'cart.products', []).forEach((product, index) => {
      if (!get(product, 'product.offer')) {
        companyProducts.push({ ...product, index });
      }
    });
    this.setState({
      offers,
      companyProducts,
      cart: get(newProps, 'cart', {}),
      defaultCart: JSON.parse(JSON.stringify(get(newProps, 'cart', {}))),
      defaultOffers: JSON.parse(JSON.stringify(offers)),
      defaultCompanyProducts: JSON.parse(JSON.stringify(companyProducts))
    });
  };

  componentWillReceiveProps(props) {
    if (isEqual(get(props, 'cart', '--'), get(this.props, 'cart', '-'))) return;
    this.componentDidMount(props);
  }

  RestoreCart = () => {
    this.setState({
      offers: JSON.parse(JSON.stringify(this.state.defaultOffers)),
      companyProducts: JSON.parse(
        JSON.stringify(this.state.defaultCompanyProducts)
      ),
      cart: JSON.parse(JSON.stringify(this.state.defaultCart))
    });
  };

  render() {
    // const user = get(this.props, 'auth.user', {});

    const groupedProducts = groupBy(
      get(this.state.cart, 'products', []),
      'seller._id'
    );
    if (get(this.props, 'cart.products.length', 0) === 0) {
      return <CartEmpty history={this.props.history} />;
    }
    return (
      <CartHolder>
        <div className='container'>
          <Row gutter={26}>
            <Col
              lg={{ span: 8, offset: 0 }}
              md={{ span: 8, offset: 0 }}
              xs={{ span: 24, offset: 0 }}
            >
              <CartDetails
                saving={get(this.state, 'saving', false)}
                user={get(this.props, 'auth.user', {})}
                products={get(this.state.cart, 'products', [])}
                groupedProducts={groupedProducts}
              />
            </Col>
            <Col
              style={{
                padding: 0
              }}
              sm={16}
              md={16}
              lg={16}
              xl={16}
              xs={24}
            >
              {get(this.state, 'offers.length') ? (
                <MainBorder>
                  <MoreOffers>{' العروض'}</MoreOffers>
                </MainBorder>
              ) : null}
              <Row style={{ margin: 0 }} type='flex'>
                {get(this.state, 'offers', []).map((product, index) => {
                  const i = index + 1;
                  return (
                    <ProductContainer xs={12} sm={12} md={12} lg={8} xl={8}>
                      <React.Fragment>
                        <BordersLeft index={i}>
                          <BordersBottom index={i}>
                            <CartProduct
                              key={product._id}
                              cart={this.props.cart}
                              saveProductInCart={this.saveProductInCart}
                              deleteProduct={this.deleteProduct}
                              index={product.index}
                              showDrawer={this.showDrawer}
                              product={product}
                            />
                          </BordersBottom>
                        </BordersLeft>
                      </React.Fragment>
                    </ProductContainer>
                  );
                })}
              </Row>
              {get(this.state, 'companyProducts.length') ? (
                <MainBorder>
                  <MoreOffers>{' المنتجات'}</MoreOffers>
                </MainBorder>
              ) : null}
              <Row style={{ margin: 0 }} type='flex'>
                {get(this.state, 'companyProducts', []).map(
                  (product, index) => {
                    const i = index + 1;
                    return (
                      <ProductContainer xs={12} sm={12} md={12} lg={8} xl={8}>
                        <React.Fragment>
                          <BordersLeft index={i}>
                            <BordersBottom index={i}>
                              <CartProduct
                                key={product._id}
                                cart={this.props.cart}
                                saveProductInCart={this.saveProductInCart}
                                deleteProduct={this.deleteProduct}
                                index={product.index}
                                showDrawer={this.showDrawer}
                                product={product}
                              />
                            </BordersBottom>
                          </BordersLeft>
                        </React.Fragment>
                      </ProductContainer>
                    );
                  }
                )}
              </Row>
            </Col>
            <CheckoutButton onClick={() => this.props.history.goBack()}>
              <ButtonTitle>متابعة التسوق</ButtonTitle>
            </CheckoutButton>{' '}
            <CartDrawer
              visible={this.state.visible}
              onClose={this.onClose}
              SaveCart={this.SaveCart}
              RestoreCart={this.RestoreCart}
            />
          </Row>
        </div>
      </CartHolder>
    );
  }
}
const mapStateToProps = ({ main, auth, cart }) => ({ main, auth, cart });
export default connect(mapStateToProps, {
  getGeneric,
  updateGeneric,
  updateUser,
  getCart
})(Cart);
const ProductContainer = styled(Col)`
  padding: 1vw 0vw;
  @media (max-width: 768px) {
    padding-top: 3.2vw;
  }
  /* margin-top: 2.5%; */
`;
// const CartDivider = styled(CustomDivider)`
//   margin-left: auto;
//   margin-right: auto;
//   min-width: 90%;
//   width: 94%;
// `;
const BordersBottom = styled.div`
  border-bottom-color: grey;

  ${(props) => (props.index <= 3 ? 'border-bottom: 1px solid grey' : '')};
  width: 90%;
  margin: auto;
  padding-bottom: 10%;

  @media (max-width: 768px) {
    i {
      font-size: 16px !important;
    }

    svg {
      font-size: 20px !important;
    }
  }
`;
const BordersLeft = styled.div`
  border-left-color: grey;
  ${(props) => (props.index % 3 !== 0 ? 'border-left: 1px solid grey;  ' : '')};
  @media (max-width: 768px) {
    ${(props) =>
      props.index % 2 !== 0 ? 'border-left: 1px solid grey;  ' : ''};
  }
  height: 100%;
`;

const CartHolder = styled.div`
  margin: 60px 0;

  @media (max-width: 414px) {
    margin: 0px 0;
  }
`;

const MainBorder = styled.div`
  color: #11192d;
  background: #e1e5ea;
  padding: 20px 50px;
  @media (max-width: 414px) {
    padding: 20px 20px;
  }
`;

const MoreOffers = styled.div`
  text-align: right;
  font-size: 20px;
  color: #11192d;
  font-family: 'Cairo-Bold';

  @media (max-width: 414px) {
    font-size: 17px;
  }
  @media (max-width: 375px) {
    font-size: 16px;
  }
  @media (max-width: 360px) {
    font-size: 15px;
  }
`;
const CheckoutButton = styled(CustomButton)`
  align-self: flex-end;
  border-radius: 4.12vw;
  height: auto;
  @media (max-width: 768px) {
    padding: 0vw 2.8vw;
    width: 80%;
    padding: 10px;
  }

  @media (max-width: 767px) {
    width: 50%;
  }

  padding: 1vw 5vw;
`;
const ButtonTitle = styled.div`
  font-size: 1vw;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
