/* eslint-disable import/extensions */
import React, { useState, useEffect } from 'react';
import { Modal, Col } from 'antd';
import styled from 'styled-components';
import get from 'lodash/get';
import { connect } from 'react-redux';
import CustomButton from '../elements/CustomButton';
import {
  addSeller,
  checkIfBuyerPendingToSeller
} from '../services/CommonServices';
import { updateUser } from '../appRedux/actions/Auth';
import { getGeneric } from '../appRedux/actions/Generic';

const HandshakeModal = (props) => {
  const { visible, setVisible, distributor, company, buyer } = props;
  const zone = get(props, 'auth.user.address.zone');
  const [pending, setPending] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  useEffect(() => {
    if (requestSent) return;
    setPending(checkIfBuyerPendingToSeller({ distributor, buyer, company }));
  }, [requestSent, distributor, buyer, company]);

  return (
    <CustomModal
      title='لاتمام  عملية الشرا يجب اضافة بالموزع الخاص بمنطقتك'
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      {distributor ? (
        <Container>
          <Col lg={6} sm={6} md={6} xs={6}>
            <CustomImage
              src={get(
                distributor,
                'images[0]',
                require('../assets/images/repzone-placeholder.png')
              )}
              alt=''
            />
          </Col>
          <Col lg={8} sm={6} md={6} xs={18}>
            <Text>الاسم:</Text>
            <TextBold> {get(distributor, 'name.ar')} </TextBold>
          </Col>
          <Col lg={6} sm={6} md={6} xs={18}>
            <Text>المنطقة:</Text>
            <TextBold> {get(zone, 'name.ar')}</TextBold>
          </Col>
          <Col lg={4} sm={6} md={6} xs={6}>
            <StyledButton
              disabled={pending}
              onClick={() => {
                addSeller({ buyer, distributor, company, props });
                setPending(true);
                setRequestSent(true);
              }}
            >
              {pending ? 'جاري' : 'إضافة'}
            </StyledButton>
          </Col>
        </Container>
      ) : (
        <NoDistributorContainer>
          لا يوجد موزع بمنطقتك حالياً
        </NoDistributorContainer>
      )}
    </CustomModal>
  );
};

const mapStateToProps = ({ main, auth }) => ({ main, auth });
export default connect(mapStateToProps, { updateUser, getGeneric })(
  HandshakeModal
);

const CustomModal = styled(Modal)`
  direction: rtl;
  @media (max-width: 767px) {
    .ant-modal-body {
      height: 300px;
      border: 0;
      text-align: right;
    }
  }
  .ant-modal-close {
    left: 0;
    right: inherit;
  }
  .ant-modal-header {
    background: #e1e5ea;
  }

  .ant-modal-close {
    left: 0;
  }
`;

const CustomImage = styled.img`
  height: 80px;
  width: 80px;
  object-fit: contain;
`;

const Container = styled.div`
  border: 1px solid;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 767px) {
    display: inherit;
    border: 0;
  }
`;

const NoDistributorContainer = styled.div`
  border: 1px solid;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
`;

const StyledButton = styled(CustomButton)`
  height: unset;
  padding: 0px 20px;
  border-radius: 15px;
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: #11192d;
  @media (max-width: 767px) {
    margin-top: 10px;
    font-size: 16px;
  }
`;

const TextBold = styled.div`
  font-size: 17px;
  font-weight: bold;
  color: #11192d;
  @media (max-width: 767px) {
    margin-top: 15px;
  }
`;
