import * as React from 'react';

function RightArrowSvg(props) {
  return (
    <svg
      id='prefix__Layer_1'
      data-name='Layer 1'
      viewBox='0 0 65.88 36.1'
      {...props}
    >
      <defs>
        <style>{'.prefix__cls-1{fill:#132046}'}</style>
      </defs>
      <title>{'drop down menu2'}</title>
      <path
        className='prefix__cls-1'
        d='M438.53 298.81a3.13 3.13 0 00-.24-4.18 3.17 3.17 0 00-4.47 0l-13.12 13.11-27.54-27.54a3.16 3.16 0 10-4.47 4.47l29.78 29.78a3.17 3.17 0 004.47 0l15.35-15.35zM452.71 280.2a3.17 3.17 0 00-4.47 0l-8.7 8.77a3.14 3.14 0 00.63 4.25 3.17 3.17 0 004-.08l8.49-8.47a3.16 3.16 0 000-4.47z'
        transform='translate(-387.75 -279.27)'
      />
    </svg>
  );
}

export default RightArrowSvg;
