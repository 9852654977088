import React from 'react';
import { Drawer, Row, Col } from 'antd';
import styled from 'styled-components';
import CustomButton from '../elements/CustomButton.jsx';

const Text = styled.div`
  font-size: 1.4vw;
  @media (max-width: 768px) {
    font-size: 2.4vw;
  }

  @media (max-width: 767px) {
    font-size: 16px;
    margin-bottom: 4px;
  }

  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: ${(props) => props.theme.primary};
  direction: rtl;
`;

const ButtonText = styled.div`
  font-size: 1.4vw;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
const CartDrawer = (props) => (
  <div>
    <StyledDrawer
      placement='bottom'
      closable={false}
      onClose={props.onClose}
      visible={props.visible}
      mask={false}
      height={110}
    >
      <Row type='flex' justify='start' style={{ direction: 'rtl' }}>
        <Col offset={1} lg={7} xs={24}>
          <Text>هل تريد حفظ التغييرات في عربة التسوق؟</Text>
        </Col>
        <Col span={6} xs={24}>
          <Row type='flex' justify='start'>
            <Col span={6} xs={12}>
              {' '}
              <StyledButton
                height={'3vw'}
                width={'4vw'}
                smallHeight={'6vw'}
                smallWidth={'8vw'}
                style={{ lineHeight: '0.1vw' }}
                onClick={props.SaveCart}
              >
                <ButtonText> تعديل</ButtonText>
              </StyledButton>
            </Col>
            <Col primary span={6} xs={12}>
              <StyledButton
                height={'3vw'}
                width={'4vw'}
                smallHeight={'6vw'}
                smallWidth={'8vw'}
                style={{ lineHeight: '0.1vw' }}
                onClick={props.RestoreCart}
                transparent
              >
                <ButtonText> اعادة</ButtonText>
              </StyledButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledDrawer>
  </div>
);
const StyledDrawer = styled(Drawer)`
  .ant-drawer-wrapper-body {
    overflow: hidden;
  }
  @media (max-width: 1024px) {
    .ant-drawer-content-wrapper {
      height: 130px !important;
    }
  }
`;
const StyledButton = styled(CustomButton)`
  @media (max-width: 1024px) {
    width: 90%;
    margin: 10px 0;
    padding: 20px 0px;
  }
  margin: 0px;
  color: ${(props) => (props.transparent ? props.theme.accent : 'white')};
`;
export default CartDrawer;
