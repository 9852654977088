/* eslint-disable import/extensions */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Pagination, message } from 'antd';
import styled from 'styled-components';
import { connect } from 'react-redux';
import queryString from 'query-string';
import get from 'lodash/get';
import ScrollAnimation from 'react-animate-on-scroll';
import ShopProduct from '../components/ShopProduct';
import CustomDivider from '../elements/CustomDivider';
import { getGeneric } from '../appRedux/actions/Generic';
import { updateUser } from '../appRedux/actions/Auth';
import { getObject } from '../services/CommonServices';
import SideFiltersBreadCrumbs from '../components/SideFiltersBreadCrumbs';
import SideFilters from '../components/SideFilters';
import SideFiltersMobile from '../components/SideFiltersMobile';

const BrandBased = (props) => {
  const query = queryString.parse(props.location.search);

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredProductsCount, setFilteredProductsCount] = useState(0);
  const [infoLoaded, setInfoLoaded] = useState(false);
  const [page, setPage] = useState(query.page || 1);
  const [brand, setBrand] = useState(query.brand);
  const [internetError, setInternetError] = React.useState(false);
  const [companySections, setCompanySections] = useState([]);
  const [mainLoader, setMainLoader] = useState(true);

  const handleFailure = () => {
    setMainLoader(false);
  };
  const handleSuccess = () => {
    setMainLoader(false);
  };
  useEffect(() => {
    props.updateUser({ onSuccess: handleSuccess, onFailure: handleFailure });
  }, []);
  useEffect(() => {
    if (!mainLoader) {
      getObject('brands/brand', { _id: brand })
        .then((res) => {
          setBrand(res.data);
          setInfoLoaded(true);
          setCompanySections(get(res, 'data.company.section', []));
        })
        .catch((e) => {
          setInfoLoaded(true);
          setInternetError(true);
        });
    }
  }, [mainLoader]);

  React.useEffect(() => {
    if (internetError) message.error('تعثر الاتصال بالانترنت ,حدث الصفحة');
  }, [internetError]);

  const onChange = (val) => {
    query.page = val;
    const search = queryString.stringify(query);
    props.history.push({
      pathname: '',
      search
    });
    window.location.reload();
  };
  if (mainLoader)
    return (
      <div style={{ padding: '50%' }}>
        {' '}
        <Spin />{' '}
      </div>
    );

  if (!infoLoaded || !brand) {
    return <Spin />;
  }

  return (
    <React.Fragment>
      <ImageContainer>
        <GradientDiv></GradientDiv>
        <ProductImage src={require('../assets/images/AAA.png')} />
        <CustomRow>
          <div className='container'>
            <MoreBg>
              <text style={{ borderBottom: '1px solid white' }}>
                {get(brand, 'name.ar')}
              </text>
              <CompanyName>{get(brand, 'company.name.ar')}</CompanyName>
            </MoreBg>
          </div>
        </CustomRow>
      </ImageContainer>
      <SectionMargin>
        <div className='container'>
          <SideFiltersBreadCrumbs pathname={'brand'} {...props} />
          <div>
            <Row type='flex' justify='space-between' gutter={46}>
              <Col lg={0} md={0} sm={24} xs={24}>
                <SideFiltersMobile
                  page={page}
                  setPage={setPage}
                  setFilteredProducts={setFilteredProducts}
                  setFilteredProductsCount={setFilteredProductsCount}
                  setProductsLoaded={setLoading}
                  countRoute={'availableProducts'}
                  route={'availableProducts'}
                  query={query}
                  buyer={get(props, 'auth.user._id')}
                  zone={get(props, 'auth.user.address.zone._id')}
                  internetError={internetError}
                  setInternetError={setInternetError}
                  companySections={companySections}
                  brandView={true}
                />
              </Col>

              <Col lg={6} md={8} sm={0} xs={0}>
                <SideFilterHolder>
                  <ScrollAnimation animateIn='fadeInRight' animateOnce={true}>
                    <SideFilters
                      page={page}
                      setPage={setPage}
                      setFilteredProducts={setFilteredProducts}
                      setFilteredProductsCount={setFilteredProductsCount}
                      setProductsLoaded={setLoading}
                      countRoute={'availableProducts'}
                      route={'availableProducts'}
                      query={query}
                      buyer={get(props, 'auth.user._id')}
                      zone={get(props, 'auth.user.address.zone._id')}
                      internetError={internetError}
                      setInternetError={setInternetError}
                      companySections={companySections}
                      brandView={true}
                    />
                  </ScrollAnimation>
                </SideFilterHolder>
              </Col>

              <Col lg={16} md={16} sm={24} xs={24}>
                <FilterContent>
                  <Row gutter={46}>
                    {loading ? (
                      filteredProducts.length ? (
                        filteredProducts.map((product, index) => {
                          const i = index + 1;
                          return (
                            <React.Fragment>
                              <ProductCol xs={12} sm={12} md={12} lg={8}>
                                <ShopProduct
                                  key={product._id}
                                  type='brand'
                                  product={product}
                                />
                              </ProductCol>
                              {i % 3 === 0 && index !== 0 ? (
                                <StyledCol span={24}>
                                  <CustomDivider />
                                </StyledCol>
                              ) : (
                                ''
                              )}
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <NoProductsDiv>لا يوجد منتجات</NoProductsDiv>
                      )
                    ) : (
                      <Spin />
                    )}
                  </Row>
                </FilterContent>

                <Row>
                  <StyledPagination
                    style={{
                      direction: 'rtl',
                      textAlign: 'center',
                      margin: '30px 0'
                    }}
                    defaultCurrent={1}
                    total={filteredProductsCount}
                    pageSize={6}
                    current={Number(page)}
                    onChange={onChange}
                  />
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </SectionMargin>
    </React.Fragment>
  );
};
const mapStateToProps = ({ main, auth }) => ({ main, auth });
export default connect(mapStateToProps, { updateUser, getGeneric })(BrandBased);
const ProductCol = styled(Col)`
  &:not(:nth-child(4n + 0)) {
    border-right: 1px solid ${(props) => props.theme.primary};
  }
  float: right;
`;
const ImageContainer = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
  clear: both;
`;
const StyledCol = styled(Col)`
  @media (max-width: 768px) {
    height: 0px;
    width: 0px;
  }
`;
const NoProductsDiv = styled.div`
  width: 800px;
  height: 200px;
  text-align: center;
  font-size: 40px;
  @media (max-width: 767px) {
    width: inherit;
    height: inherit;
    font-size: 25px;
    margin: 30px 0;
  }
`;
const GradientDiv = styled.div`
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.8;
  background-image: linear-gradient(
    rgb(17, 25, 45),
    rgba(17, 25, 45, 0.85) 30%,
    rgba(17, 25, 45, 0)
  );
`;
const ProductImage = styled.img`
  height: 500px;
  width: 100%;
  object-fit: cover;
  @media (max-width: 768px) {
    height: 300px;
  }

  @media (max-width: 414px) {
    height: 250px;
  }
`;

const Container = styled.div``;
const CustomRow = styled.div`
  // width: 100%;
  // position: absolute;
  // top: 0;
  // text-align: center;
  // background: #11192d87;
  // padding: 14px;
  // box-sizing: border-box;
  // position: absolute;
  // width: 100%;
  // top: 50%;
  // left: 50%;
  // padding: 60px;
  // color: #fff;
  // -webkit-transform: translate(-50%, -50%);
  // transform: translate(-50%, -50%);
  // font-size: 45px;
`;
const StyledPagination = styled(Pagination)`
  .ant-pagination-item-active,
  .ant-pagination-item,
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-color: #10182d;
  }
  li.ant-pagination-disabled.ant-pagination-prev svg {
    transform: rotate(180deg);
  }
  a.ant-pagination-item-link i.anticon.anticon-right svg {
    transform: rotate(-180deg);
  }
  .ant-pagination-item-active a {
    color: #4a7fbd !important;
    font-weight: bold;
  }

  .ant-pagination-item a,
  .ant-pagination-item {
    color: #10182d;
  }

  .ant-pagination-item a :hover,
  .ant-pagination-item:hover {
    color: red !important;
    font-weight: bold;
  }

  .ant-pagination-item {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    margin-right: 0.58vw;
    outline: 0;
    font-size: 17px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
  }
  .ant-pagination-next {
    display: inline-block;
    min-width: 32px;
    line-height: 32px;
    margin-left: 10px;
    border-radius: 0.29vw;
  }
  .ant-pagination-prev {
    display: inline-block;
    height: 32px;
    margin-right: 10px;
    line-height: 32px;
    border-radius: 0.29vw;
  }
  .ant-pagination-item-link {
    font-size: 1vw;
    @media (max-width: 768px) {
      font-size: 20px;
    }
    @media (min-width: 2560px) {
      font-size: 0.5vw;
    }
  }
`;

// const CompanyRow = styled.div`
//   width: 20%;
//   margin-top: 4vw;
//   position: absolute;
//   top: 35vw;
//   text-align: center;
// `;
const MoreBg = styled.div`
  position: absolute;
  color: #fff;
  z-index: 9999;
  top: 0;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 20px;
  padding: 50px;
  width: inherit;
  text-align: center;
  background: red;
  background-color: rgba(17, 25, 45, 0.55);
  /* opacity: 0.4; */
  background: rgba(17, 25, 45, 0.53);

  @media (max-width: 1024px) {
    padding: 20px;
    -webkit-transform: translate(-50%, -100%);
    top: 100%;
    transform: translate(-50%, -100%);
  }

  @media (max-width: 414px) {
    padding: 20px;
    width: 90%;
    top: 60%;
  }
`;

const CompanyName = styled.div`
  font-size: 23px;
  margin-top: 6px;
  text-align: center;
`;

const SectionMargin = styled.div`
  margin: 30px 0;
`;

const SideFilterHolder = styled.div`
  @media (min-width: 767px) {
    height: 25vh;
    min-height: 500px;
    overflow-x: hidden;
    position: -webkit-sticky;
    position: sticky;
    top: 5%;
    transition: 0.4s;
  }
`;

const FilterContent = styled.div`
  position: relative;
`;
