/* eslint-disable import/extensions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Pagination, message } from 'antd';
import styled from 'styled-components';
import { connect } from 'react-redux';
import get from 'lodash/get';
import queryString from 'query-string';
import ShopProduct from '../components/ShopProduct.jsx';
import CustomDivider from '../elements/CustomDivider.jsx';
import CompanyInfo from '../components/CompanyInfo.jsx';
import { getGeneric } from '../appRedux/actions/Generic';
import { updateUser } from '../appRedux/actions/Auth';
import { getObject } from '../services/CommonServices';
import SideFilters from '../components/SideFilters.jsx';
import SideFiltersMobile from '../components/SideFiltersMobile';
import SideFiltersBreadCrumbs from '../components/SideFiltersBreadCrumbs.jsx';

const StyledSpin = styled(Spin)`
  align-items: center;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const CompanyDetailsViewHolder = styled.div``;

const CompanyDetailsView = (props) => {
  const query = queryString.parse(props.location.search);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filteredProductsCount, setFilteredProductsCount] = useState(0);
  const [productsLoaded, setProductsLoaded] = useState(false);
  const [infoLoaded, setInfoLoaded] = useState(false);
  const [page, setPage] = useState(query.page || 1);
  const [company, setCompany] = useState(
    get(props, 'location.state.company', {})
  );
  const [internetError, setInternetError] = React.useState(false);
  const [companySections, setCompanySections] = useState([]);
  const [mainLoader, setMainLoader] = useState(true);
  const handleFailure = () => {
    setMainLoader(false);
  };
  const handleSuccess = () => {
    setMainLoader(false);
  };
  useEffect(() => {
    props.updateUser({ onSuccess: handleSuccess, onFailure: handleFailure });
  }, []);
  useEffect(() => {
    if (!mainLoader) {
      getObject('companies/companyInfo', { _id: query.company })
        .then((res) => {
          setCompany(res.data);
          setInfoLoaded(true);
          setCompanySections(res.data.section);
        })
        .catch(() => {
          setInfoLoaded(true);
          setInternetError(true);
        });
    }
  }, [query.company, mainLoader]);
  useEffect(() => {
    if (internetError) message.error('تعثر الاتصال بالانترنت ,حدث الصفحة');
  }, [internetError]);

  const onChange = (val) => {
    query.page = val;
    const search = queryString.stringify(query);
    props.history.push({
      pathname: '',
      search
    });
    window.location.reload();
  };
  if (mainLoader)
    return (
      <div style={{ padding: '50%' }}>
        <Spin />
      </div>
    );

  return (
    !internetError && (
      <div>
        {infoLoaded ? (
          <React.Fragment>
            <ImageContainer>
              <GradientDiv></GradientDiv>
              <ProductImage
                src={get(
                  company,
                  'images[0]',
                  require('../assets/images/Juhayna2.jpg')
                )}
              />
            </ImageContainer>
            <CompanyDetailsViewHolder>
              <CustomRow>
                <div className='container'>
                  <MoreBg>
                    <CompanyInfo company={company} />
                  </MoreBg>
                </div>
              </CustomRow>
              <div className='container'>
                <SideFiltersBreadCrumbs pathname={'company'} {...props} />
                <div>
                  <Row type='flex' justify='space-between' gutter={24}>
                    <Col lg={0} md={0} xs={24}>
                      <SideFiltersMobile
                        page={page}
                        setPage={setPage}
                        setFilteredProducts={setFilteredProducts}
                        setFilteredProductsCount={setFilteredProductsCount}
                        setProductsLoaded={setProductsLoaded}
                        countRoute={'availableProducts'}
                        route={'availableProducts'}
                        companyView={true}
                        query={query}
                        buyer={get(props, 'auth.user._id')}
                        zone={get(props, 'auth.user.address.zone._id')}
                        internetError={internetError}
                        setInternetError={setInternetError}
                        companySections={companySections}
                      />
                    </Col>

                    <Col lg={6} md={8} xs={0}>
                      <StyledFilters
                        page={page}
                        setPage={setPage}
                        setFilteredProducts={setFilteredProducts}
                        setFilteredProductsCount={setFilteredProductsCount}
                        setProductsLoaded={setProductsLoaded}
                        countRoute={'availableProducts'}
                        route={'availableProducts'}
                        companyView={true}
                        query={query}
                        buyer={get(props, 'auth.user._id')}
                        zone={get(props, 'auth.user.address.zone._id')}
                        internetError={internetError}
                        setInternetError={setInternetError}
                        companySections={companySections}
                      />
                    </Col>
                    <Col lg={18} md={16} xs={24}>
                      <Row gutter={24}>
                        {productsLoaded ? (
                          filteredProducts.length ? (
                            filteredProducts.map((product, index) => {
                              const i = index + 1;
                              return (
                                <React.Fragment>
                                  <ProductCol
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={6}
                                    xl={8}
                                  >
                                    <ShopProduct
                                      key={product._id}
                                      product={product}
                                    />
                                  </ProductCol>
                                  {i % 3 === 0 && index !== 0 ? (
                                    <StyledCol
                                      xs={0}
                                      sm={0}
                                      md={24}
                                      lg={24}
                                      xl={24}
                                    >
                                      <CustomDivider />
                                    </StyledCol>
                                  ) : (
                                    ''
                                  )}
                                </React.Fragment>
                              );
                            })
                          ) : (
                            <NoProductsDiv>لا يوجد منتجات</NoProductsDiv>
                          )
                        ) : (
                          <Wrapper>
                            <StyledSpin />
                          </Wrapper>
                        )}
                      </Row>
                      <Row>
                        <PaginationHolder>
                          <StyledPagination
                            style={{
                              direction: 'rtl',
                              textAlign: 'center',
                              margin: '30px 0'
                            }}
                            defaultCurrent={1}
                            total={filteredProductsCount}
                            pageSize={6}
                            current={Number(page)}
                            onChange={onChange}
                          />
                        </PaginationHolder>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </CompanyDetailsViewHolder>
          </React.Fragment>
        ) : (
          <Spin />
        )}
      </div>
    )
  );
};
const mapStateToProps = ({ main, auth }) => ({ main, auth });
export default connect(mapStateToProps, { getGeneric, updateUser })(
  CompanyDetailsView
);

const StyledFilters = styled(SideFilters)``;

const ProductCol = styled(Col)`
  &:not(:nth-child(4n + 0)) {
    border-right: 1px solid ${(props) => props.theme.primary};
  }
  padding: 1vw 0vw;
  float: right;
`;
const ProductImage = styled.img`
  height: 500px;
  width: 100%;
  object-fit: stretch;
  @media (max-width: 768px) {
    height: 350px;
  }

  @media (max-width: 414px) {
    height: 350px;
  }
`;
const ImageContainer = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
  clear: both;
  overflow: hidden;
  z-index: 0;
`;
const StyledCol = styled(Col)`
  @media (max-width: 768px) {
    height: 0px;
    width: 0px;
    display: none !important;
  }
`;
const NoProductsDiv = styled.div`
  width: 800px;
  height: 200px;
  text-align: center;
  font-size: 40px;
  @media (max-width: 767px) {
    width: inherit;
    height: inherit;
    font-size: 25px;
    margin: 30px 0;
  }
`;
const GradientDiv = styled.div`
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.8;
`;
const StyledPagination = styled(Pagination)`
  .ant-pagination-item-active,
  .ant-pagination-item,
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-color: #10182d;
  }
  li.ant-pagination-disabled.ant-pagination-prev svg {
    transform: rotate(180deg);
  }
  a.ant-pagination-item-link i.anticon.anticon-right svg {
    transform: rotate(-180deg);
  }
  .ant-pagination-item-active a {
    color: #4a7fbd !important;
    font-weight: bold;
  }

  .ant-pagination-item a,
  .ant-pagination-item {
    color: #10182d;
  }

  .ant-pagination-item a :hover,
  .ant-pagination-item:hover {
    color: red !important;
    font-weight: bold;
  }

  .ant-pagination-item {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    margin-right: 0.58vw;
    outline: 0;
    font-size: 17px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
  }
  .ant-pagination-next {
    display: inline-block;
    min-width: 32px;
    line-height: 32px;
    margin-left: 10px;
    border-radius: 0.29vw;
  }
  .ant-pagination-prev {
    display: inline-block;
    height: 32px;
    margin-right: 10px;
    line-height: 32px;
    border-radius: 0.29vw;
  }
  .ant-pagination-item-link {
    font-size: 1vw;
    @media (max-width: 768px) {
      font-size: 20px;
    }
    @media (min-width: 2560px) {
      font-size: 0.5vw;
    }
  }
`;

const CustomRow = styled.div``;

const MoreBg = styled.div`
  background: #11192d;
  padding: 30px 0;
  margin-top: 30px;
  margin-bottom: 0;

  @media (max-width: 1024px) {
    padding: 20px;
  }

  @media (max-width: 414px) {
    padding: 15px;
    width: 100%;
  }
`;

const PaginationHolder = styled.div`
  margin: 50px 0;

  @media (max-width: 414px) {
    margin: 20px 0;
  }
`;
