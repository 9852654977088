import React from 'react';

const Cart = () => (
  <svg
    height='1em'
    width='1em'
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 99.79 99.79'
  >
    <defs>
      <style dangerouslySetInnerHTML={{ __html: '.cls-1{fill:#4c7fba;}' }} />
    </defs>
    <title>cart</title>
    <path
      className='cls-1'
      d='M421.4,247a49.9,49.9,0,1,0,49.9,49.9A50,50,0,0,0,421.4,247Zm0,95.39a45.5,45.5,0,1,1,45.5-45.5A45.55,45.55,0,0,1,421.4,342.37Z'
      transform='translate(-371.51 -246.97)'
    />
    <path
      className='cls-1'
      d='M444.32,304.87a1.54,1.54,0,0,0-.57-.11,1.7,1.7,0,0,0-1.57,1.11l-1,3H409.27L403.59,286h5.62a1.67,1.67,0,0,0,1.73-1.55,1.54,1.54,0,0,0-.07-.58,1.7,1.7,0,0,0-1.54-1.15H402.7l-2.08-8.44A1.7,1.7,0,0,0,399,273h-7.47a1.63,1.63,0,0,0,0,3.27h6.18l8.65,34.59a1.71,1.71,0,0,0,1.61,1.27h34.37a1.59,1.59,0,0,0,1.51-1.12l1.4-4.07A1.67,1.67,0,0,0,444.32,304.87Z'
      transform='translate(-371.51 -246.97)'
    />
    <path
      className='cls-1'
      d='M452.95,284.05l-.06-.42-.06-.07v-.09l-.11-.11v0l-.44-.43-.09,0-.13-.11H415.81a1.63,1.63,0,0,0-1.22.45,1.73,1.73,0,0,0-.54,1.13v.1a1.09,1.09,0,0,0,0,.47,1.62,1.62,0,0,0,1.56,1.11h33.45l-4.81,14.11a1.6,1.6,0,0,0,0,1.28,1.7,1.7,0,0,0,.9.85l.09,0a1.07,1.07,0,0,0,.45.1,1.59,1.59,0,0,0,1.51-1.12l5.54-16.4a1,1,0,0,0,.11-.49Z'
      transform='translate(-371.51 -246.97)'
    />
    <path
      className='cls-1'
      d='M427.39,301.58v-9a1.47,1.47,0,1,0-2.94,0v9a1.47,1.47,0,0,0,2.94,0Z'
      transform='translate(-371.51 -246.97)'
    />
    <path
      className='cls-1'
      d='M418.42,301.58v-9a1.47,1.47,0,1,0-2.94,0v9a1.47,1.47,0,1,0,2.94,0Z'
      transform='translate(-371.51 -246.97)'
    />
    <path
      className='cls-1'
      d='M435.6,301.58v-9a1.47,1.47,0,1,0-2.94,0v9a1.47,1.47,0,0,0,2.94,0Z'
      transform='translate(-371.51 -246.97)'
    />
    <path
      className='cls-1'
      d='M434.6,314.11a5.86,5.86,0,0,0-5.8,5.15,5.79,5.79,0,0,0,.2,2.33,5.83,5.83,0,0,0,11.43-1.67A5.82,5.82,0,0,0,434.6,314.11Zm0,9.32a3.49,3.49,0,0,1,0-7,3.49,3.49,0,0,1,0,7Z'
      transform='translate(-371.51 -246.97)'
    />
    <circle className='cls-1' cx='63.08' cy='72.96' r='1.76' />
    <path
      className='cls-1'
      d='M415.74,314.11a5.86,5.86,0,0,0-5.8,5.15,5.79,5.79,0,0,0,.2,2.33,5.83,5.83,0,0,0,11.43-1.67A5.82,5.82,0,0,0,415.74,314.11Zm0,9.32a3.49,3.49,0,1,1,0-7,3.49,3.49,0,0,1,0,7Z'
      transform='translate(-371.51 -246.97)'
    />
    <circle className='cls-1' cx='44.22' cy='72.96' r='1.76' />
  </svg>
);

export default Cart;
