// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

// Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

// Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';
export const UPDATE_USER = 'update_user';
export const UPDATE_USER_SUCCESS = 'update_user_success';

// Generic const
export const GET_GENERIC = 'GET_GENERIC';
export const GET_GENERIC_SUCCESS = 'GET_GENERIC_SUCCESS';
export const ADD_GENERIC = 'ADD_GENERIC';
export const UPDATE_GENERIC = 'UPDATE_GENERIC';
export const DELETE_GENERIC = 'DELETE_GENERIC';

// Settings const
export const SET_LOADING = 'SET_LOADING';

// Cart const
export const GET_CART = 'GET_CART';
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS';
export const EMPTY_CART = 'EMPTY_CART';

// Products const
export const GET_OFFERS = 'GET_OFFERS';
export const GET_OFFERS_SUCCESS = 'GET_OFFERS_SUCCESS';
export const GET_OFFERS_FAILURE = 'GET_OFFERS_FAILURE';
export const GET_NON_HANDSHAKED_OFFERS = 'GET_NON_HANDSHAKED_OFFERS';
export const GET_NON_HANDSHAKED_OFFERS_SUCCESS =
  'GET_NON_HANDSHAKED_OFFERS_SUCCESS';
export const GET_NON_HANDSHAKED_OFFERS_FAILURE =
  'GET_NON_HANDSHAKED_OFFERS_FAILURE';
export const GET_PRODUCT_OFFERS = 'GET_PRODUCT_OFFERS';
export const GET_PRODUCT_OFFERS_SUCCESS = 'GET_PRODUCT_OFFERS_SUCCESS';
export const GET_PRODUCT_OFFERS_FAILURE = 'GET_PRODUCT_OFFERS_FAILURE';

export const GET_WISHLIST = 'GET_WISHLIST';
export const GET_WISHLIST_SUCCESS = 'GET_WISHLIST_SUCCESS';
export const GET_WISHLIST_FAILURE = 'GET_WISHLIST_FAILURE';
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';

export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';
