/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */

import React, { Component } from 'react';

import { Row, Col, Icon, Input, Spin, Button, Divider } from 'antd';
import styled from 'styled-components';
import { get, debounce } from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { slide as Menu1 } from 'react-burger-menu';
import { getObject } from '../services/CommonServices';
import { updateUser } from '../appRedux/actions/Auth';
import { getGeneric } from '../appRedux/actions/Generic';

const { Search } = Input;

class MobileMenu extends Component {
  state = {
    companies: [],
    menuVisible: false,
    sections: [],
    loading: false,
    focusIndex: 0,
    searchOptions: false,
    searchType: 'companies'
  };

  handleSubmit = (sec) => {
    if (sec._id === 'offers') {
      this.props.history.push({
        pathname: '/offers'
      });
    } else {
      this.props.history.push({
        pathname: '/section',
        search: `sections=${sec._id}&sections=categories&sections=${sec.name.ar}&taxonomy=${sec._id}&type=categories&page=1`
      });
      window.location.reload();
    }
  };

  componentDidMount() {
    this.setState({ companies: [] });
    getObject('companyProducts/sections', [])
      .then((res) => {
        const result = res.data;
        result.push({ _id: 'offers', name: { ar: 'العروض' } });
        this.setState({ sections: result });
      })
      .catch(() => {});
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (this.state.companies.length > 0) {
        const company = this.state.companies[this.state.focusIndex];
        this.setState({ companies: [] });
        this.searchInput.input.state.value = '';
        if (this.state.searchType === 'brands') {
          this.props.history.push({
            pathname: '/brand',
            search: `brand=${company._id}&page=1`
          });
        } else {
          if (this.state.searchType === 'companyProducts') {
            this.props.history.push({
              pathname: '/singleproduct',
              search: `product=${company._id}`
            });
          } else
            this.props.history.push({
              pathname: '/company',
              search: `company=${company._id}&page=1`
            });
        }
      }
    } else if (e.keyCode === 38) {
      // up
      let { focusIndex } = this.state;
      if (focusIndex > 0) {
        focusIndex -= 1;
      }
      this.setState({ focusIndex });
    } else if (e.keyCode === 40) {
      // down
      let { focusIndex } = this.state;
      if (focusIndex < this.state.companies.length - 1) {
        focusIndex += 1;
      }
      this.setState({ focusIndex });
    }
  };

  searchCompanies = debounce((e) => {
    this.setState({ loading: true });
    if (e !== '') {
      getObject(`${this.state.searchType}/search`, {
        type: this.state.searchType.slice(0, -1),
        searchItem: e
      })
        .then((res) => {
          this.setState({ loading: false });
          this.setState({ companies: res.data });
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    } else {
      this.setState({ loading: false });
      this.setState({ companies: [] });
    }
  }, 500);

  render() {
    return (
      <div>
        <MobileMenuHolder>
          <div className='container'>
            <Row>
              <CartIconHolder xs={3} sm={3} md={2} lg={2}>
                {' '}
                <a href='/cart'>
                  <img src={require('../assets/images/cart.svg')} />
                  <StyledSpan>
                    {get(this.props, 'cart.products.length', 0)}
                  </StyledSpan>
                </a>
              </CartIconHolder>
              <Col span={21}>
                <LogoContainer>
                  <a href='/'>
                    <Logo
                      alt={'logo'}
                      src={require('../assets/images/logo-new.png')}
                    />
                  </a>
                </LogoContainer>
              </Col>
              <p> أقوى منصة للتداول التجاري </p>
            </Row>
            <Col span={24}>
              <SearchHolder>
                <StyledSearch
                  searchType={this.state.searchType}
                  // enterButton="Search"
                  placeholder=' '
                  onKeyDown={this.handleKeyDown}
                  ref={(ref) => {
                    this.searchInput = ref;
                  }}
                  id='search'
                  onChange={(e) => this.searchCompanies(e.target.value)}
                />
                {!this.state.searchType ? (
                  <StyledButton
                    onClick={() =>
                      this.setState({
                        searchOptions: !this.state.searchOptions
                      })
                    }
                  >
                    {/* <Customicons type='caret-down' /> */}
                    <img src={require('../assets/images/dropdown.svg')} />
                  </StyledButton>
                ) : (
                  <CustomButton
                    onClick={() =>
                      this.setState({
                        searchOptions: !this.state.searchOptions
                      })
                    }
                  >
                    <StyledText>
                      {this.state.searchType === 'companies'
                        ? 'شركات'
                        : this.state.searchType === 'companyProducts'
                        ? 'منتجات'
                        : 'ماركات'}
                      <img src={require('../assets/images/dropdown.svg')} />
                      <Divider type='vertical' />
                    </StyledText>
                  </CustomButton>
                )}
                {this.state.searchOptions ? (
                  <StyledSearchMenu>
                    <Col span={8}>
                      <SearchMenuItem
                        onClick={() => {
                          this.setState({
                            searchType: 'companies',
                            searchOptions: false
                          });
                        }}
                      >
                        شركات
                      </SearchMenuItem>
                    </Col>
                    <Col span={8}>
                      <SearchMenuItem
                        onClick={() => {
                          this.setState({
                            searchType: 'brands',
                            searchOptions: false
                          });
                        }}
                      >
                        ماركات
                      </SearchMenuItem>
                    </Col>
                    <Col span={8}>
                      <SearchMenuItem
                        onClick={() => {
                          this.setState({
                            searchType: 'companyProducts',
                            searchOptions: false
                          });
                        }}
                      >
                        منتجات
                      </SearchMenuItem>
                    </Col>
                  </StyledSearchMenu>
                ) : null}
                {this.state.companies.length > 0 || this.state.loading ? (
                  <SearchResult>
                    <Row>
                      {this.state.loading ? (
                        <Wrapper>
                          <StyledSpin />
                        </Wrapper>
                      ) : (
                        get(this.state, 'companies', []).map(
                          (company, index) => (
                            <Wrapper
                              key={index}
                              last={this.state.companies.length === index + 1}
                            >
                              <SearchItem
                                focused={this.state.focusIndex === index}
                                onClick={() => {
                                  if (this.state.searchType === 'brands') {
                                    this.props.history.push({
                                      pathname: '/brand',
                                      search: `brand=${company._id}&page=1`
                                    });
                                  } else {
                                    if (
                                      this.state.searchType ===
                                      'companyProducts'
                                    ) {
                                      this.props.history.push({
                                        pathname: '/singleproduct',
                                        search: `product=${company._id}`
                                      });
                                    } else {
                                      this.props.history.push({
                                        pathname: '/company',
                                        search: `company=${company._id}&page=1`
                                      });
                                    }
                                    this.setState({ companies: [] });
                                    this.searchInput.input.state.value = '';
                                    window.location.reload();
                                  }
                                }}
                              >
                                {get(company, 'name.ar')}
                              </SearchItem>
                            </Wrapper>
                          )
                        )
                      )}
                    </Row>
                  </SearchResult>
                ) : null}
              </SearchHolder>
            </Col>
            <Col span={24}>
              <FilterHolder
                onClick={() =>
                  this.setState({
                    menuVisible: !this.state.menuVisible
                  })
                }
              >
                <span> الأقسام</span>
              </FilterHolder>
              {this.state.menuVisible ? (
                <SectionMenu>
                  {get(this.state, 'sections').map((section) => (
                    <Row>
                      <CustomCol span={24}>
                        <SectionItem
                          onClick={() => {
                            this.handleSubmit(section);
                          }}
                        >
                          {get(section, 'name.ar')}
                        </SectionItem>
                      </CustomCol>
                    </Row>
                  ))}
                </SectionMenu>
              ) : null}{' '}
            </Col>
          </div>
        </MobileMenuHolder>
        <Menu1>
          <Bar>
            <LeftNavbar>
              <BorderBottomDiv>
                <a
                  href={!get(this.props, 'auth.token') ? '/login' : '/profile'}
                >
                  <img src={require('../assets/images/user-mobile.svg')} />

                  <Title>
                    {get(this.props, 'auth.token') ? 'حسابي' : 'الدخول'}
                  </Title>
                </a>
              </BorderBottomDiv>
              <hr />

              <BorderBottomDiv>
                <a href='/wishlist'>
                  <img src={require('../assets/images/wishlist-mobile.svg')} />
                </a>
                <Title> الامنيات</Title>
              </BorderBottomDiv>
              <hr />
              <BorderBottomDiv>
                <a href='/cart'>
                  <img src={require('../assets/images/cart-mobile.svg')} />
                  <Title> العربة </Title>
                </a>
              </BorderBottomDiv>

              <hr />
              <BorderBottomDiv>
                <a href='/offers'>
                  <img src={require('../assets/images/cart-mobile.svg')} />
                  <Title> العروض</Title>
                </a>
              </BorderBottomDiv>
            </LeftNavbar>
            <div>{/* <Col span={6}>
                </Col> */}</div>
          </Bar>
        </Menu1>
      </div>
    );
  }
}
const mapStateToProps = ({ main, auth, cart }) => ({ main, auth, cart });
export default connect(mapStateToProps, { updateUser, getGeneric })(
  withRouter(MobileMenu)
);

const StyledSpin = styled(Spin)`
  align-items: center;
`;

const Bar = styled.div``;

const Title = styled.div`
  text-align: center;
  font-size: 0.9vw;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  margin-top: 0.5vmin;
  margin-bottom: 0.5vmin;
  padding: 0px;
  font-size: 15px;
  font-family: 'Cairo-Bold';
  :hover {
    color: #fff;
    transition: 0.2s;
  }
`;

const BorderBottomDiv = styled.div`
  padding: 0px;
  display: block;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
  img {
    height: 40px;
    margin-bottom: 10px;
  }
`;

const StyledSearch = styled(Search)`
  width: 80%;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border: solid 2px #4a7fbd;
  height: 45px;
  @media (max-width: 768px) {
    width: 95%;
    margin-left: 14px;
  }
  & .ant-input {
    width: 80%;
    background-color: transparent;
    border-width: 0px;
    border-radius: 0;
    padding-left: 4vw;
    margin: auto;
    text-align: right;
    text-indent: 50px;
  }
  & .ant-input:placeholder-shown {
    font-size: 13px;
    align-items: center;
    text-overflow: ellipsis;
  }
  &.ant-input-affix-wrapper {
    border-left-width: 0px;
  }
`;
const StyledButton = styled(Button)`
  width: 10%;
  // background: #f8f8f8 !important;
  box-shadow: none !important;
  filter: none !important;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  border: solid 2px #4a7fbd;
  position: absolute;
  left: 0.1vh;
  color: ${(props) => (props.focused ? '#1890ff' : '#11192d')};
  &.ant-btn {
    justify-content: center;
    align-content: center;
    border-right-width: 0;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    font-size: 1vw;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    height: 45px;
    width: fit-content !important;
    margin: 0;
    :focus,
    :hover {
      border-color: #4a7fbd !important;
    }
  }
`;
const CustomButton = styled(Button)`
  width: 25%;
  position: absolute;
  left: 0.1vh;
  color: ${(props) => (props.focused ? '#fff' : '#11192d')};
  &.ant-btn {
    justify-content: center;
    align-content: center;
    padding-left: 0;
    padding-right: 0;
    background: transparent;
    font-size: 1vw;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    border: solid 2px #4a7fbd;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin: 0;
    height: 45px;
    border-right: 0;
  }
  width: fit-content !important;
`;
const StyledText = styled.text`
  font-size: 18px;
  margin-left: 15px;
  color: #4c7fba;

  img {
    height: 18px;
    margin-right: 0;
    margin-left: 10px;
  }
`;
const Logo = styled.img`
  height: 90px;
  @media (max-width: 767px) {
    margin-right: 50px;
  }
`;

const LogoContainer = styled.div`
  margin-bottom: 0;
  margin: 15px auto;
  margin-bottom: 5px;
  display: block;
  text-align: center;
`;

const SearchResult = styled.div`
  background-color: #e1e5ea;
  padding: 0;
  background-color: #fff;
  text-align: left;
  height: auto;
  width: 100%;
  margin: auto;
  padding: 20px;
  z-index: 99990;
`;
const StyledSearchMenu = styled.div`
  background-color: #4a7fbd;
  align-items: center;
  width: 100%;
  height: auto;
  z-index: 10;
  position: relative;
  right: 0;
  padding: 0;
  border-bottom: 0;
  margin-top: 10px;
  height: 40px;
  border-radius: 50px;
`;
const SearchMenuItem = styled.div`
  font-size: 16px;
  color: ${(props) => (props.focused ? '#fff' : '#fff')};
  text-align: center;
  margin: 0 0;
  padding: 7px;
  margin-bottom: 20px;

  cursor: pointer;
  :hover {
    font-family: 'Cairo-Bold';
    transition: 0.3s;
  }
  border-right: 1px solid white;
`;
const SectionMenu = styled.div`
  background-color: #fff;
  text-align: right;
  padding-top: 0.6vw;
  padding-right: 3vw;
  padding-left: 3vw;
  width: 100%;
  z-index: 99990;
  height: 200px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #7099c8;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #7099c8;
    outline: 1px solid #7099c8;
  }
`;
const SearchItem = styled.a`
  height: auto;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => (props.focused ? '#4c81bf' : '#11192d')};
  text-align: left;
`;
const CustomCol = styled(Col)`
  text-align: right;
  padding-bottom: 0.6vw;
  margin-bottom: 0.6vw;
`;
const SectionItem = styled.a`
  font-size: 15px;
  font-style: normal;
  font-stretch: normal;

  color: #11192d;
  text-align: right;
  padding: 10px;
  width: 100%;
  border-bottom: 1px solid #4a7fbd;
  display: inline-block;
`;
const Wrapper = styled.div`
  padding-bottom: 0.8vw;
  padding-top: 1.3vw;
  padding-left: 2.19vw;
  ${(props) => (!props.last ? 'border-bottom: solid 0.1vw #11192d;' : '')};
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid;
`;

const LeftNavbar = styled.div`
  hr {
    padding: 1px 23px;
    background: #ffffff4a;
    border: 0;
    border-radius: 0;
    margin: 20px;
    height: 1px;
  }
`;
const SearchHolder = styled.div`
  margin-top: 15px;
  .ant-divider,
  .ant-divider-vertical {
    position: relative;
    top: -0.06em;
    display: inline-block;
    width: 1px;
    height: 24px;
    margin: 0 8px;
    vertical-align: middle;
    background: #4a7fbd;
    margin-left: 25px;
  }
`;

const FilterHolder = styled.div`
  margin: 20px 0;
  background: #4a7fbd;
  color: #fff;
  width: 100%;
  text-align: center;
  border-radius: 30px;
  padding: 6px;
  img {
    height: 40px;
    margin: 20px 0;
  }

  span {
    text-align: center;
    color: #fff;
    padding: 0px;
    border-bottom: 1px solid;
    font-size: 15px;
    font-family: 'Cairo-Bold';
    padding-bottom: 20px;
    margin: 0 20px;

    :hover {
      color: #fff;
      transition: 0.4s;
    }
  }
`;
const CartIconHolder = styled(Col)`
  padding-top: 12%;
  @media (min-width: 768px) {
    padding-top: 4%;
  }
`;
const MobileMenuHolder = styled.div`
  p {
    text-align: center;
    margin: 0;
    padding: 0;
    color: #4a7fbd;
    font-size: 13px;
    margin-bottom: 10px;
  }
`;

const StyledSpan = styled.span`
  background-color: red;
  color: white;
  padding: 1px 3px;
  font-size: 12px;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 40%;
  text-align: center;
  position: absolute; /* Position the badge within the relatively positioned button */
  right: 0;

  @media (min-width: 768px) {
    top: 25%;
    font-size: 12px;
    border-radius: 12px;
    width: 25px;
    height: 25px;
  }
`;
