/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Collapse, Icon, Checkbox, Radio, Tooltip, Spin } from 'antd';
import styled from 'styled-components';
import queryString from 'query-string';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import { getObject } from '../services/CommonServices';
import CustomCollapse from '../elements/CustomCollapse.jsx';
import DropdownSvg from '../icons/DropdownSvg.jsx';

const { Panel } = Collapse;

const customPanelStyle = {
  background: 'white',
  borderRadius: 0,
  padding: 0
};
const SideFilters = (props) => {
  const [options, setOptions] = React.useState([]);
  const [query, setQuery] = React.useState(props.query);
  const [currentFilter, setCurrentFilter] = React.useState(
    props.query.type || 'sections'
  );
  const [currentFilterValue, setCurrentFilterValue] = React.useState(
    props.query.taxonomy
  );
  const [endOfFilter, setEndOfFilter] = React.useState(false);
  const [endOfFilterParent, setEndOfFilterParent] = React.useState('');
  const [endOfFilterType, setEndOfFilterType] = React.useState('subType');
  const [selectedCheckBoxes, setSelectedCheckBoxes] = React.useState(
    props.query.selected || []
  );
  const [brands, setBrands] = React.useState([]);
  const [selectedBrands, setSelectedBrands] = React.useState(
    props.query.selectedBrands || []
  );
  const [brandsLoading, setBrandsLoading] = React.useState(false);

  // const [noBrands, setNoBrands] = React.useState(false);
  const nextFilterMap = {
    sections: 'categories',
    categories: 'subCategories',
    subCategories: 'types',
    types: 'subTypes'
  };
  const parameterMap = {
    categories: 'section',
    subCategories: 'category',
    types: 'subCategory',
    subTypes: 'type'
  };
  const arabicMap = {
    sections: 'الفئات',
    categories: 'الأقسام',
    subCategories: 'القسم الفرعي',
    types: 'النوع',
    subTypes: 'النوع الفرعي'
  };
  const currentFilterArabicName = arabicMap[currentFilter];

  React.useEffect(() => {
    if (!nextFilterMap[currentFilter]) {
      setEndOfFilterType('subType');
      setEndOfFilter(true);
    }
  }, [props.query]);

  React.useEffect(() => {
    if (!props.brandView) {
      setBrandsLoading(true);
      const params = {
        _id: props.query.company,
        section: props.sectionView && get(props, 'query.sections[0]')
      };
      getObject(`brands/FiltersBrands`, params)
        .then((res) => {
          setBrands(res.data);
          setBrandsLoading(false);
        })
        .catch(() => {
          setBrandsLoading(false);
        });
    }
  }, [props.companySections]);

  React.useEffect(() => {
    // this condition is for getting current company sections , so we don't have to fetch all sections
    if (currentFilter === 'sections' && !props.featured) {
      setOptions(props.companySections);
    } else {
      if (!endOfFilter) {
        const route = currentFilter;
        const params = currentFilterValue
          ? { [parameterMap[currentFilter]]: currentFilterValue }
          : {};
        getObject(`companyProducts/${route}`, params)
          .then((res) => {
            if (res.data.length === 0) setEndOfFilter(true);
            else setOptions(res.data);
          })
          .catch(() => {});
      }
    }
  }, [props.companySections]);

  React.useEffect(() => {
    let query = queryString.parse(props.location.search);
    let queryKey;
    let queryValue;
    const { selectedBrands } = query;
    if (endOfFilter || currentFilter === 'subTypes') {
      queryKey = 'subType';
      queryValue = Array.isArray(currentFilterValue)
        ? currentFilterValue
        : [currentFilterValue];
      if (!query.selected) queryValue = options.map((o) => o._id);
    } else {
      queryKey = parameterMap[currentFilter];
      queryValue = Array.isArray(query.taxonomy)
        ? query.taxonomy
        : [query.taxonomy];
    }
    if (query.selected) {
      queryValue = Array.isArray(selectedCheckBoxes)
        ? selectedCheckBoxes
        : [selectedCheckBoxes];
    }

    query = { [queryKey]: queryValue };
    delete query.taxonomy;
    const route = props.countRoute;
    getObject(`companyProducts/${route}`, {
      buyer: props.buyer,
      count: true,
      zone: props.zone,
      query,
      featured: props.featured ? true : undefined,
      company: props.query.company,
      brand: (props.query.brand || []).concat(selectedBrands || []),
      isDeleted: false
    }).then((response) => {
      props.setFilteredProductsCount(get(response, 'data.total', 0));
    });
  }, [query, props.query.company, options, props.zone]);

  React.useEffect(() => {
    let query = queryString.parse(props.location.search);
    const { selectedBrands } = query;
    let queryKey;
    let queryValue;

    if (endOfFilter || currentFilter === 'subTypes') {
      queryKey = 'subType';
      queryValue = Array.isArray(currentFilterValue)
        ? currentFilterValue
        : [currentFilterValue];
      queryKey = 'subType';
      if (!query.selected) queryValue = options.map((o) => o._id);
    } else {
      queryKey = parameterMap[currentFilter];
      queryValue = Array.isArray(query.taxonomy)
        ? query.taxonomy
        : [query.taxonomy];
    }
    if (query.selected) {
      queryValue = Array.isArray(selectedCheckBoxes)
        ? selectedCheckBoxes
        : [selectedCheckBoxes];
    }
    query = { [queryKey]: queryValue };
    delete query.taxonomy;
    const { route } = props;
    getObject(`companyProducts/${route}`, {
      buyer: get(props, 'buyer'),
      zone: get(props, 'zone'),
      company: props.query.company,
      brand: (props.query.brand || []).concat(selectedBrands || []),
      featured: props.featured ? true : undefined,
      query,
      skip: Number(props.page),
      limit: 6
    })
      .then((response) => {
        props.setFilteredProducts(get(response, 'data.newSortedProducts', []));
        props.setProductsLoaded(true);
      })
      .catch(() => {
        props.setProductsLoaded(true);
        if (!props.internetError) props.setInternetError(true);
      });
  }, [query, props.page, props.query.company, options, props.zone]);

  function updateUrl(newQuery) {
    switch (currentFilter) {
      case 'sections':
        ['categories', 'subCategories', 'types'].forEach(
          (taxonomy) => delete newQuery[taxonomy]
        );
        break;
      case 'categories':
        newQuery.sections = props.query.sections;
        ['subCategories', 'types'].forEach(
          (taxonomy) => delete newQuery[taxonomy]
        );
        break;
      case 'subCategories':
        newQuery.sections = props.query.sections;
        newQuery.categories = props.query.categories;
        delete newQuery.types;
        break;
      case 'types':
        newQuery.sections = props.query.sections;
        newQuery.categories = props.query.categories;
        newQuery.subCategories = props.query.subCategories;
        break;
      default:
        break;
    }
    return newQuery;
  }

  function onChange(checkedValue, title) {
    props.setPage(1);
    let newQuery = {};
    let array = [];
    let selected = [];
    if (endOfFilter) {
      if (Array.isArray(currentFilterValue)) array = currentFilterValue;
      else {
        array = [];
        setEndOfFilterParent(currentFilterValue);
      }
      if (checkedValue.target.checked) {
        array.push(checkedValue.target.value);
        selected = [...array];
        setCurrentFilterValue(array);
        newQuery = {
          taxonomy: array,
          type: nextFilterMap[currentFilter] || currentFilter,
          company: props.query.company,
          isDeleted: false,
          featured: props.featured ? true : undefined,
          brand: props.query.brand,
          page: 1
        };
      } else {
        const index = array.indexOf(checkedValue.target.value);
        const splicedCount = index === -1 ? 0 : 1;
        array.splice(index, splicedCount);
        selected = [...array];
        const filter =
          array.length === 0
            ? endOfFilterType
            : nextFilterMap[currentFilter] || currentFilter;
        array = array.length === 0 ? endOfFilterParent : array;
        setCurrentFilterValue(array);
        newQuery = {
          taxonomy: array,
          type: filter,
          isDeleted: false,
          company: props.query.company,
          featured: props.featured ? true : undefined,
          brand: props.query.brand,
          page: 1
        };
      }
      setSelectedCheckBoxes(array);
    } else {
      setCurrentFilterValue(checkedValue.target.value);
      setCurrentFilter(nextFilterMap[currentFilter]);
      newQuery = {
        taxonomy: [checkedValue.target.value],
        type: nextFilterMap[currentFilter],
        isDeleted: false,
        company: props.query.company,
        featured: props.featured ? true : undefined,
        brand: props.query.brand,
        page: 1
      };
    }
    setQuery(newQuery);

    if (!endOfFilter) {
      delete newQuery.selected;
      newQuery = updateUrl(newQuery);
      newQuery[currentFilter] = [
        checkedValue.target.value,
        nextFilterMap[currentFilter],
        title
      ];
      const search = queryString.stringify(newQuery);
      props.history.push({
        pathname: props.companyView
          ? '/company'
          : props.sectionView
          ? '/section'
          : props.featured
          ? '/featured'
          : '/brand',
        search
      });
      window.location.reload();
    } else {
      const newQuery = Object.assign({}, props.query);
      newQuery.selected = selected;
      const search = queryString.stringify(newQuery);
      props.history.push({
        pathname: props.companyView
          ? '/company'
          : props.sectionView
          ? '/section'
          : props.featured
          ? '/featured'
          : '/brand',
        search
      });
    }
  }
  const selectedChecks = Array.isArray(selectedCheckBoxes)
    ? selectedCheckBoxes
    : [selectedCheckBoxes];

  const selectBrand = (value) => {
    const selected = [...[selectedBrands].flat()];
    const index = selected.findIndex((e) => e === value);
    if (index === -1) selected.push(value);
    else {
      selected.splice(index, 1);
    }
    setSelectedBrands(selected);
    const newQuery = queryString.parse(props.location.search);
    newQuery.selectedBrands = selected;
    const search = queryString.stringify(newQuery);
    props.history.push({
      pathname: props.location.path,
      search
    });
    window.location.reload();
  };
  return (
    <React.Fragment>
      <CustomCollapse
        style={{ padding: 0 }}
        accordion
        expandIcon={({ isActive }) => (
          <StyledIcon
            style={
              isActive
                ? { transform: 'rotateZ(180deg)', bottom: '30%' }
                : undefined
            }
            component={DropdownSvg}
          />
        )}
        defaultActiveKey={['1']}
      >
        {
          <Panel
            header={currentFilterArabicName}
            key='1'
            style={customPanelStyle}
          >
            <Checklist>
              {' '}
              <div style={{ width: '100%', padding: '0' }}>
                {options.map((entry, index) => (
                  <Box>
                    <ChecklistItem notlast={index !== options.length - 1}>
                      {endOfFilter ? (
                        <StyledCheckbox
                          key={get(entry, '_id')}
                          className={get(entry, '_id')}
                          value={get(entry, '_id')}
                          defaultChecked={
                            !!selectedChecks.find(
                              (s) => s === get(entry, '_id')
                            )
                          }
                          onChange={(checkedValues) =>
                            onChange(checkedValues, get(entry, 'name.ar', ''))
                          }
                        >
                          {get(entry, 'name.ar', '').length > 22 ? (
                            <Tooltip
                              placement='bottom'
                              title={get(entry, 'name.ar', '')}
                            >
                              {`${get(entry, 'name.ar', '').substring(
                                0,
                                22
                              )}...`}
                            </Tooltip>
                          ) : (
                            get(entry, 'name.ar', '')
                          )}
                        </StyledCheckbox>
                      ) : (
                        <StyledRadioButton
                          key={get(entry, '_id')}
                          className={get(entry, '_id')}
                          value={get(entry, '_id')}
                          size={'large'}
                          onChange={(checkedValues) =>
                            onChange(checkedValues, get(entry, 'name.ar', ''))
                          }
                        >
                          {get(entry, 'name.ar', '').length > 22 ? (
                            <Tooltip
                              placement='bottom'
                              title={get(entry, 'name.ar', '')}
                            >
                              {`${get(entry, 'name.ar', '').substring(
                                0,
                                22
                              )}...`}
                            </Tooltip>
                          ) : (
                            get(entry, 'name.ar', '')
                          )}
                        </StyledRadioButton>
                      )}
                      {/* </a> */}
                    </ChecklistItem>
                    <br style={{ display: 'contents' }} />
                  </Box>
                ))}
              </div>
            </Checklist>
          </Panel>
        }
      </CustomCollapse>
      <CustomCollapse
        style={{ padding: 0 }}
        accordion
        expandIcon={({ isActive }) => (
          <StyledIcon
            style={
              isActive
                ? { transform: 'rotateZ(180deg)', bottom: '30%' }
                : undefined
            }
            component={DropdownSvg}
          />
        )}
        defaultActiveKey={['1']}
      >
        {!props.brandView && (
          <Panel header={'الماركة'} key='1' style={customPanelStyle}>
            <Checklist>
              {' '}
              <div style={{ width: '100%', padding: '0' }}>
                {!brandsLoading ? (
                  brands.map((entry, index) => (
                    <Box>
                      <ChecklistItem notlast={index !== brands.length - 1}>
                        {
                          <StyledCheckbox
                            key={get(entry, '_id')}
                            className={get(entry, '_id')}
                            value={get(entry, '_id')}
                            defaultChecked={
                              !![selectedBrands]
                                .flat()
                                .find((s) => s === get(entry, '_id'))
                            }
                            onChange={
                              (e) => selectBrand(e.target.value)
                              // onChange(checkedValues, get(entry, 'name.ar', ''))
                            }
                          >
                            {get(entry, 'name.ar', '').length > 22 ? (
                              <Tooltip
                                placement='bottom'
                                title={get(entry, 'name.ar', '')}
                              >
                                {`${get(entry, 'name.ar', '').substring(
                                  0,
                                  22
                                )}...`}
                              </Tooltip>
                            ) : (
                              get(entry, 'name.ar', '')
                            )}
                          </StyledCheckbox>
                        }
                      </ChecklistItem>
                      <br style={{ display: 'contents' }} />
                    </Box>
                  ))
                ) : (
                  <Spin />
                )}
              </div>
            </Checklist>
          </Panel>
        )}
      </CustomCollapse>
    </React.Fragment>
  );
};
const StyledIcon = styled(Icon)`
  &.ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    font-size: 2vw;
  }
  font-weight: normal;
  path {
    width: 2vw;
    height: 2vw;
    @media (max-width: 768px) {
      width: 4vw;
      height: 4vw;
    }
    fill: ${(props) => props.theme.primary};
  }
`;

const Checklist = styled.div`
  width: 100%;
  background-color: #e1e5ea;
  overflow: auto;
  max-height: 600px;
  padding-top: 10%;
  @media (max-width: 767px) {
    padding: 10px 0;
  }
`;
const ChecklistItem = styled.div`
  ${(props) => (props.notlast ? `border-bottom: solid 0.5px #11192d;` : ``)}
  margin: auto;
  width: 75%;
  height: 2.9vw;
  @media (max-width: 768px) {
    height: 30px;
    font-family: 'Cairo-Bold';
  }
`;
const StyledRadioButton = styled(Radio)`
  float: right;
  height: 1.7vw;
  &.ant-radio-wrapper {
    color: #11192d;
    font-size: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: right;
    line-height: unset;
    @media (max-width: 414px) {
      font-size: 15px;
    }
  }
  .ant-radio {
    line-height: unset;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  float: right;
  height: 1.7vw;
  &.ant-checkbox-wrapper {
    color: #11192d;
    font-size: 18px;
    line-height: unset;
    text-align: right;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
`;
const Box = styled.div`
  height: auto;
  padding: 1vw 0vw;
  @media (max-width: 768px) {
    padding: 10px 0;
  }
`;

export default withRouter(SideFilters);
