/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'antd';
import ScrollAnimation from 'react-animate-on-scroll';

export default function BannerAds() {
  return (
    <React.Fragment>
      <BannerAdsHolder>
        <div class='container'>
          <Row gutter={32}>
            <a
              href={`https://repzone.app/company?company=5f53b4b36462a26b956fcfce&page=1`}
              key={1}
              className='ad'
            >
              <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                <SingleBanner>
                  <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                    <ProductImage
                      src={require('../assets/images/habba-habba-ad.jpeg')}
                    />
                  </ScrollAnimation>
                </SingleBanner>
              </Col>
            </a>
            <a
              href={`https://repzone.app/company?company=5f53b4b36462a26b956fcfce&page=1`}
              key={1}
              className='ad'
            >
              <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                <SingleBanner>
                  <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                    <ProductImage
                      src={require('../assets/images/habba-habba-ad2.jpeg')}
                    />
                  </ScrollAnimation>
                </SingleBanner>
              </Col>
            </a>
            <a
              href={`https://repzone.app/company?company=5f53b4b36462a26b956fcfce&page=1`}
              key={1}
              className='ad'
            >
              <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                <SingleBanner>
                  <ScrollAnimation animateIn='fadeInDown' animateOnce={true}>
                    <ProductImage
                      src={require('../assets/images/habba-habba-ad3.jpeg')}
                    />
                  </ScrollAnimation>
                </SingleBanner>
              </Col>
            </a>
            <a
              href={`https://repzone.app/company?company=5f53b4b36462a26b956fcfce&page=1`}
              key={1}
              className='ad'
            >
              <Col xs={12} sm={6} md={6} lg={6} xl={6}>
                <SingleBanner>
                  <ScrollAnimation animateIn='fadeInDown' animateOnce={true}>
                    <ProductImage
                      src={require('../assets/images/habba-habba-ad4.jpeg')}
                    />
                  </ScrollAnimation>
                </SingleBanner>
              </Col>
            </a>
          </Row>
        </div>
      </BannerAdsHolder>
    </React.Fragment>
  );
}

const SingleBanner = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
`;
const BannerAdsHolder = styled.div`
  margin: 40px 0;
  @media (max-width: 414px) {
    margin: 20px 0;
  }
`;

const ProductImage = styled.img`
  transition: all 0.3s ease-in-out;
  width: 100%;
  object-fit: contain;
`;
