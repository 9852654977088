/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Icon, Row, Col, Tabs, Collapse, Spin } from 'antd';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { createSelector } from 'reselect';
import ScrollAnimation from 'react-animate-on-scroll';
import CustomButton from '../elements/CustomButton.jsx';
import CustomDivider from '../elements/CustomDivider.jsx';
import { setLoading } from '../appRedux/actions/Common';
import HandshakeSvg from '../icons/HandshakeSvg.jsx';
import MinusSvg from '../icons/MinusSvg.jsx';
import PlusSvg from '../icons/PlusSvg.jsx';
import {
  getObject,
  addToCart,
  checkIfBuyerConnectedToSeller,
  getHighestBuyerCategory
} from '../services/CommonServices';
import HandshakeModal from './HandshakeModal.jsx';
import { updateUser } from '../appRedux/actions/Auth';
import { getCart } from '../appRedux/actions/Cart';
import { getGeneric } from '../appRedux/actions/Generic';
import {
  addToWishlist,
  removeFromWishlist
} from '../appRedux/actions/Wishlist';

const { TabPane } = Tabs;
const { Panel } = Collapse;
const decrementCount = (prev) => {
  if (prev === 0) {
    return 0;
  }
  return prev - 1;
};

const ShopCollapse = ({ setCount, count, panel }) => (
  <StyledCollapse activeKey={[`${panel}`]}>
    <Panel showArrow={false} key={'true'}>
      <Row gutter={24} justify='center'>
        <Col span={8}>
          <StyledCustomButton
            style={{ lineHeight: '0.1vw' }}
            height={'2.17vw'}
            width={'2.78vw'}
            float={'left'}
            block
            onClick={() => {
              setCount(decrementCount);
            }}
            transparent
            shape='round'
          >
            <StyledIcon component={MinusSvg} />
          </StyledCustomButton>
        </Col>
        <Col span={8}>
          <Count>{count}</Count>
        </Col>
        <Col span={8}>
          <StyledCustomButton
            style={{ lineHeight: '0.1vw' }}
            height={'2.17vw'}
            width={'2.78vw'}
            float={'left'}
            onClick={() => {
              setCount((prev) => prev + 1);
            }}
            block
            transparent
            shape='round'
          >
            <StyledIcon component={PlusSvg} />
          </StyledCustomButton>
        </Col>
      </Row>
    </Panel>
  </StyledCollapse>
);

const WishlistProduct = (props) => {
  const [panel, setPanel] = useState(false);
  const [tabID, setTabID] = useState('2');
  const [loading, setLoading] = useState(true);
  const [countCarton, setCountCarton] = useState(0);
  const [countPackage, setCountPackage] = useState(0);
  const [connected, setConnected] = useState(false);
  const [handshake, setHandshake] = useState(false);
  const [carton, setCarton] = useState({});
  const [noDistributor, setNoDistributor] = useState(false);
  const [NoBuyerCategory, setNoBuyerCategory] = useState(false);
  const [buyerCategoryId] = useState();
  const [productDetails, setProductDetails] = useState();
  const companyProduct = get(productDetails, 'product');
  const distributor = get(productDetails, 'distributor');

  const buyer = get(props, 'auth.user', {});
  const zone = get(buyer, 'address.zone', {});
  const distributorProduct = { ...companyProduct };
  const company = get(
    distributorProduct,
    'company',
    get(companyProduct, 'company', '-')
  );
  const companyName = get(company, 'name.ar');
  const sellerName = get(distributor, 'name.ar', get(company, 'name.ar'));
  const isFavorite = get(props, 'wishlist.products').find(
    (entry) => entry === props.product
  );
  const updateProductPriceCategory = (
    props,
    companyProduct,
    distributor,
    isConnected
  ) => {
    const distributorsSelector = (props) =>
      get(props, 'auth.user.distributors', []);

    const disributorBuyerCatSelector = createSelector(
      distributorsSelector,
      (distributors) => {
        return get(
          distributors.find(
            (entry) =>
              entry.distributor &&
              entry.distributor._id === get(distributor, '_id')
          ),
          'buyerCategory._id'
        );
      }
    );
    const distBuyerCategory = disributorBuyerCatSelector(props);
    const productBuyerCategorySelector = () =>
      get(companyProduct, 'buyerCategories');
    const buyerCategorySelector = isConnected
      ? createSelector(productBuyerCategorySelector, (buyerCategories) => {
          const res = buyerCategories.find(
            (b) => b.buyerCategory._id === distBuyerCategory
          );
          if (!res) setNoBuyerCategory(true);
          return res;
        })
      : createSelector(productBuyerCategorySelector, (buyerCategories) =>
          getHighestBuyerCategory(buyerCategories)
        );
    const updatedCompanyProduct = Object.assign({}, companyProduct);
    updatedCompanyProduct.buyerCategories = buyerCategorySelector(
      companyProduct
    );
    const finalCompanyProduct = JSON.parse(
      JSON.stringify(updatedCompanyProduct)
    );
    setProductDetails({
      ...productDetails,
      product: finalCompanyProduct,
      distributor
    });
  };

  useEffect(() => {
    setLoading(true);
    getObject('companyProducts/productDistributorInZone', {
      _id: get(props, 'product'),
      zone: get(props, 'auth.user.address.zone._id'),
      select: 'distributor company name buyerCategories packageUnit images'
    }).then((res) => {
      if (!get(res, 'data.distributor')) setNoDistributor(true);
      setProductDetails({
        product: get(res, 'data.product'),
        distributor: get(res, 'data.distributor')
      });
      const isConnected = checkIfBuyerConnectedToSeller({
        distributor: get(res, 'data.distributor'),
        buyer,
        setLoading
      });
      setConnected(isConnected);
      updateProductPriceCategory(
        props,
        get(res, 'data.product'),
        get(res, 'data.distributor'),
        isConnected
      );
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    const carton = get(companyProduct, 'buyerCategories.saleUnit.price', '-');
    setTabID('2');
    setPanel(false);
    setCountCarton(0);
    setCountPackage(0);
    setCarton(carton);
    setLoading(false);
  }, [
    props.auth.pendingCompanies,
    props.auth.companies,
    props.auth.user,
    props.auth.distributors,
    props.auth.PendingDistributors,
    props.main.distributorProducts,
    props.product,
    companyProduct
  ]);
  const manageFavorites = () => {
    if (!isFavorite)
      props.addToWishlist({
        productType: 'wishlist.products',
        value: props.product
      });
    else
      props.removeFromWishlist({
        productType: 'wishlist.products',
        value: props.product
      });
  };
  return (
    <React.Fragment>
      <ScrollAnimation animateIn='fadeInDown' animateOnce={true} delay={100}>
        <ProductContainer>
          <Row>
            <Col span={12}>
              <TextContainer left>
                {connected && <Icon component={HandshakeSvg} />}
              </TextContainer>
            </Col>
            <Col span={12}>
              <TextContainer right>
                <Icon
                  style={{ color: '#4a7fbd' }}
                  onClick={() => manageFavorites()}
                  theme={isFavorite ? 'filled' : 'outlined'}
                  twoToneColor={isFavorite ? '#4a7fbd' : '#e1e5ea'}
                  type={'star'}
                />
              </TextContainer>
            </Col>
          </Row>
          <ImageContainer>
            <Link
              to={{
                pathname: `/singleproduct`,
                search: `product=${get(props, 'product')}&zone=${get(
                  props,
                  'auth.user.address.zone._id'
                )}`,
                state: {
                  distributorProduct,
                  companyProduct,
                  distributor,
                  zone: get(props, 'auth.user.address.zone._id')
                }
              }}
            >
              <div>
                <img
                  alt={'product'}
                  src={get(
                    companyProduct,
                    'images[0]',
                    require('../assets/images/repzone-placeholder.png')
                  )}
                  loading={!companyProduct}
                />
                {!companyProduct && (
                  <center>
                    <StyledSpinner
                      type='loading'
                      style={{ fontSize: '30px', color: '#4a7fbd' }}
                    />
                  </center>
                )}
              </div>
            </Link>
          </ImageContainer>
          {companyProduct ? (
            <ProductInfoContainer>
              <Link
                to={{
                  pathname: '/singleproduct',
                  search: `product=${get(props, 'product')}&zone=${get(
                    props,
                    'auth.user.address.zone._id'
                  )}`,
                  state: {
                    distributorProduct,
                    companyProduct,
                    distributor,
                    zone: get(props, 'auth.user.address.zone._id')
                  }
                }}
              >
                <ProductName
                  title={get(
                    distributorProduct.companyProduct,
                    'name.ar',
                    get(companyProduct, 'name.ar', '--')
                  )}
                >
                  {get(
                    distributorProduct.companyProduct,
                    'name.ar',
                    get(companyProduct, 'name.ar', '--')
                  )}
                </ProductName>
              </Link>
              <ProductName>
                {' '}
                <p> موزع :{` ${sellerName}`} </p>
              </ProductName>
              {props.type === 'section' ? (
                <ProductName>
                  <p> شركة :{` ${companyName}`} </p>
                </ProductName>
              ) : null}
            </ProductInfoContainer>
          ) : (
            <center>
              <Icon style={{ color: '#4a7fbd' }} type='loading' />{' '}
            </center>
          )}
          <CustomDivider bottom={'0'} />
          <PriceContainer>
            {NoBuyerCategory ? (
              <TextContainer>غير متوفر لقطاعك</TextContainer>
            ) : (
              <Row>
                <Col span={12}>
                  <TextContainer size={'1.5vw'} weight={'800'} left>
                    <div style={{ direction: 'ltr' }}>
                      {String(tabID) === '2'
                        ? Number(
                            get(
                              companyProduct,
                              'buyerCategories.saleUnit.price',
                              '0'
                            )
                          ).toFixed(2)
                        : (
                            Number(
                              get(
                                companyProduct,
                                'buyerCategories.saleUnit.price',
                                '0'
                              )
                            ) /
                            Number(
                              get(
                                companyProduct,
                                'buyerCategories.saleUnit.packageQuantity',
                                '1'
                              )
                            )
                          ).toFixed(2)}
                    </div>
                  </TextContainer>
                  {/* <TextContainer size={'20px'} weight={'800'} left>
              ج.م
            </TextContainer> */}
                </Col>
                <Col span={12}>
                  <TextContainer size={'1.5vw'} weight={'800'} right>
                    {String(tabID) === '2'
                      ? get(
                          companyProduct,
                          'buyerCategories.saleUnit.packageQuantity',
                          '--'
                        ) + get(companyProduct, 'packageUnit.name', 'وحدة')
                      : get(companyProduct, 'packageUnit.name', 'وحدة')}
                  </TextContainer>
                </Col>
              </Row>
            )}
          </PriceContainer>
          <BlueContainer>
            {loading ? (
              <Spin />
            ) : (
              <>
                <StyledTabs
                  onChange={(key) => setTabID(key)}
                  defaultActiveKey={tabID}
                  activeKey={tabID}
                >
                  <StyledTabPane
                    tab={
                      <ButtonTitle>
                        {get(companyProduct, 'packageUnit.name', 'وحدة')}
                      </ButtonTitle>
                    }
                    key='1'
                  >
                    <ShopCollapse
                      count={countPackage}
                      setCount={setCountPackage}
                      panel={panel}
                    />
                  </StyledTabPane>
                  <StyledTabPane
                    tab={
                      <ButtonTitle>
                        {get(
                          buyerCategoryId,
                          'saleUnit.package.name',
                          get(
                            companyProduct,
                            'buyerCategories.saleUnit.package.name',
                            'كرتونة'
                          )
                        )}
                      </ButtonTitle>
                    }
                    key='2'
                  >
                    <ShopCollapse
                      count={countCarton}
                      setCount={setCountCarton}
                      panel={panel}
                    />
                  </StyledTabPane>
                </StyledTabs>
                <ShopAddBtn>
                  <Row>
                    <CustomButton
                      // style={{ lineHeight: '0.1vw' }}
                      disabled={
                        loading ||
                        NoBuyerCategory ||
                        !!(!carton && buyerCategoryId) ||
                        get(props, 'commonData.loading', false) ||
                        (!distributor && !noDistributor)
                      }
                      onClick={() => {
                        if (!props.auth.token) {
                          window.scrollTo(0, 0);
                          props.history.push('/login');
                        }
                        if (connected) {
                          if (panel) {
                            addToCart({
                              props,
                              company,
                              distributor,
                              distributorProduct,
                              companyProduct,
                              countCarton,
                              countPackage,
                              carton
                            });
                          }
                          setPanel((prev) => !prev);
                          setCountCarton(0);
                          setCountPackage(0);
                        } else {
                          setHandshake(true);
                        }
                      }}
                      shape='round'
                    >
                      {loading ? (
                        <Spin />
                      ) : (
                        <AddButtonTitle>إضافة</AddButtonTitle>
                      )}
                    </CustomButton>
                  </Row>
                </ShopAddBtn>
              </>
            )}
          </BlueContainer>
          <HandshakeModal
            visible={handshake && !connected}
            setVisible={setHandshake}
            distributor={distributor}
            company={company}
            zone={zone}
            buyer={buyer}
          />
        </ProductContainer>
      </ScrollAnimation>
    </React.Fragment>
  );
};
const mapStateToProps = ({ main, auth, commonData, cart, wishlist }) => ({
  main,
  auth,
  commonData,
  cart,
  wishlist
});
export default connect(mapStateToProps, {
  updateUser,
  getGeneric,
  setLoading,
  getCart,
  addToWishlist,
  removeFromWishlist
})(withRouter(WishlistProduct));

const TextContainer = styled.div`
  text-align: ${(props) => (props.left ? 'left' : 'right')};
  font-weight: ${(props) => props.weight || 'normal'};
  font-size: ${(props) => props.size || '1.46vw'};
  color: ${(props) => props.theme.primary};
  .anticon {
    font-size: 2.34vw;
    @media (max-width: 768px) {
      font-size: 5vw;
    }
    color: ${(props) => props.theme.accent};
  }
  @media (max-width: 414px) {
    font-size: 15px;
  }
  direction: rtl;
`;
const ProductContainer = styled.div`
  width: 100%;
  height: 100%;
  font-size: 1.2vw;
  @media (max-width: 768px) {
    font-size: 2.4vw;
  }
  direction: ltr;
`;
const ImageContainer = styled.div`
  width: 100%;
  margin-top: 3.66vw;
  margin-bottom: 1.32vw;
  img {
    width: 100%;
    height: 13.7vw;
    object-fit: contain;
    ${({ loading }) => loading && `opacity: 0.8;`};
    @media (max-width: 414px) {
      height: 140px;
    }
  }
`;
const ProductInfoContainer = styled.div`
  text-align: right;
  color: ${(props) => props.theme.primary};
  font-size: 1.2vw;
  @media (max-width: 768px) {
    font-size: 20px;
  }
  font-weight: 300;
  a {
    color: #4a7fbd;
  }
`;
const BlueContainer = styled.div`
  background-color: ${(props) => props.theme.primary};
  text-align: center;
  padding: 14px;
  @media (max-width: 768px) {
    padding: 6px 0;
    margin-bottom: 20px;
  }
`;
const PriceContainer = styled.div`
  padding: 0.73vw 1.2vw;
  @media (max-width: 414px) {
    padding: 5px;
  }
`;
const StyledTabs = styled(Tabs)`
  .ant-tabs-bar {
    border-bottom: 0;
    margin: 0 0 1.17vw 0;
  }
  .ant-tabs-nav .ant-tabs-tab {
    padding: 0.87vw 1.17vw;
  }
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: white;
  }
  .ant-tabs-ink-bar {
    background-color: white;
    width: auto !important;
  }
  .ant-tabs-tab {
    color: white;
    font-weight: 100;
    /* width: 6.7vw; */
    margin-right: 1.3vw;
    border-bottom: 2px solid grey;
  }
  .ant-tabs-tab-active {
    font-weight: bold;
  }
`;
const ProductName = styled.div`
  font-weight: bold;
  font-size: 17px;

  p {
    font-weight: normal;
    margin: 0;
  }
  overflow: hidden;
  width: 100%;
  direction: rtl;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 768px) {
    margin-bottom: 5px;
    font-size: 15px;
    overflow: hidden;
  }
`;
const StyledCollapse = styled(Collapse)`
  border: 0;
  background: transparent;
  &.ant-collapse > .ant-collapse-item {
    border: 0;
  }
  .ant-collapse-header {
    display: none;
  }
  .ant-collapse-content {
    background: transparent;
    border: 0;
    color: white;
  }
  .ant-collapse-content-box {
    font-size: 2.34vw;
    color: white;
    padding: 1.17vw;
  }
`;
const Count = styled.p`
  margin-bottom: 0px;
  font-size: 20px;
  line-height: normal;
  margin-top: 4px;
  font-weight: 800;
`;
const StyledTabPane = styled(TabPane)`
  font-size: 1.2vw;
`;
const AddButtonTitle = styled.div`
  font-size: 1.2vw;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
const ButtonTitle = styled.div`
  font-size: 1.2vw;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
const StyledIcon = styled(Icon)`
  font-size: 1vw;
  @media (max-width: 768px) {
    font-size: 3vw;
  }
`;
const StyledCustomButton = styled(CustomButton)`
  @media (max-width: 768px) {
    height: 30px;
    width: 35px;
    max-width: 100%;
  }
`;

const ShopAddBtn = styled.div`
  margin: 0 auto;
  display: block;
  text-align: center;
  width: 50%;

  button {
    width: 100%;
  }
`;

const StyledSpinner = styled(Icon)`
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%);
`;
