import React from 'react';

const MailSvg = () => (
  <svg
    width='1em'
    height='1em'
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 99.79 99.79'
  >
    <defs>
      <style dangerouslySetInnerHTML={{ __html: '.cls-1{fill:#4c7fba;}' }} />
    </defs>
    <title>mail</title>
    <path
      className='cls-1'
      d='M421.84,247a49.9,49.9,0,1,0,49.9,49.9A50,50,0,0,0,421.84,247Zm0,95.39a45.5,45.5,0,1,1,45.5-45.5A45.55,45.55,0,0,1,421.84,342.37Z'
      transform='translate(-371.95 -246.97)'
    />
    <path
      className='cls-1'
      d='M447.43,274.38h-37.8a1.64,1.64,0,1,0,0,3.28h37.8a.25.25,0,0,1,.25.25v37.9a.25.25,0,0,1-.25.25H396.26a.25.25,0,0,1-.25-.25v-37.9a.25.25,0,0,1,.25-.25h6.57a1.64,1.64,0,1,0,0-3.28h-6.57a3.54,3.54,0,0,0-3.54,3.54v37.9a3.54,3.54,0,0,0,3.54,3.54h51.17a3.54,3.54,0,0,0,3.54-3.54v-37.9A3.54,3.54,0,0,0,447.43,274.38Z'
      transform='translate(-371.95 -246.97)'
    />
    <path
      className='cls-1'
      d='M421.84,300.15a4.07,4.07,0,0,1-2.62-1L399.4,282.54a1.49,1.49,0,0,1-.53-1,1.49,1.49,0,0,1,1.35-1.61h.13a1.49,1.49,0,0,1,1,.35l19.83,16.66a1.09,1.09,0,0,0,1.4,0l19.83-16.66a1.49,1.49,0,1,1,1.92,2.28L424.46,299.2A4.07,4.07,0,0,1,421.84,300.15Z'
      transform='translate(-371.95 -246.97)'
    />
    <path
      className='cls-1'
      d='M399.7,280.85a.86.86,0,0,0,.11,1.21l19.83,16.66a3.43,3.43,0,0,0,4.42,0l19.83-16.66a.86.86,0,0,0-1.11-1.32L423,297.4a1.72,1.72,0,0,1-2.21,0l-19.83-16.66a.86.86,0,0,0-1.21.11Z'
      transform='translate(-371.95 -246.97)'
    />
    <path
      className='cls-1'
      d='M400.34,313.85a1.49,1.49,0,0,1-1.07-2.53l12-12.88a1.49,1.49,0,1,1,2.17,2l-12,12.88A1.48,1.48,0,0,1,400.34,313.85Z'
      transform='translate(-371.95 -246.97)'
    />
    <path
      className='cls-1'
      d='M411.76,298.86l-12,12.89a.86.86,0,1,0,1.26,1.17L413,300a.86.86,0,1,0-1.26-1.17Z'
      transform='translate(-371.95 -246.97)'
    />
    <path
      className='cls-1'
      d='M443.32,313.82a1.47,1.47,0,0,1-1.07-.45l-12-12.9a1.49,1.49,0,0,1,2.18-2l12,12.89a1.5,1.5,0,0,1-.09,2.09,1.48,1.48,0,0,1-1,.4Z'
      transform='translate(-371.95 -246.97)'
    />
    <path
      className='cls-1'
      d='M430.71,298.82a.86.86,0,0,0,0,1.21l12,12.89a.86.86,0,0,0,1.26-1.17l-12-12.89A.86.86,0,0,0,430.71,298.82Z'
      transform='translate(-371.95 -246.97)'
    />
  </svg>
);

export default MailSvg;
