/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createSelector } from 'reselect';
import { setLoading } from '../appRedux/actions/Common';
import {
  checkIfBuyerConnectedToSeller,
  getHighestBuyerCategory
} from '../services/CommonServices';
import { updateUser } from '../appRedux/actions/Auth';
import { getGeneric } from '../appRedux/actions/Generic';

const LandingPageProduct = (props) => {
  const [companyProduct, setCompanyProduct] = useState(props.product);
  const distributorProduct = props.product;
  const distributor = {
    _id: get(distributorProduct, 'distributor[0]._id[0]', '-'),
    name: { ar: get(distributorProduct, 'distributor[0].name[0].ar') }
  };
  const company = get(
    distributorProduct,
    'company',
    get(distributorProduct, 'companyProduct.company', '-')
  );
  let isConnected = false;
  if (props.auth.user) {
    isConnected = checkIfBuyerConnectedToSeller({
      distributor,
      buyer: props.auth.user,
      company
    });
  }
  useEffect(() => {
    setCompanyProduct(props.product);
    if (Array.isArray(props.product.buyerCategories)) {
      const distributorsSelector = (props) =>
        get(props, 'auth.user.distributors', []);
      const companiesSelector = (props) =>
        get(props, 'auth.user.companies', []);

      const disributorBuyerCatSelector = createSelector(
        distributorsSelector,
        (distributors) => {
          return get(
            distributors.find(
              (entry) =>
                entry.distributor && entry.distributor._id === distributor._id
            ),
            'buyerCategory._id'
          );
        }
      );
      const companyBuyerCatSelector = createSelector(
        companiesSelector,
        (companies) => {
          return get(
            companies.find((entry) => entry.company._id === company._id),
            'buyerCategory._id'
          );
        }
      );
      const distBuyerCategory = disributorBuyerCatSelector(props);
      const companyBuyerCategory = companyBuyerCatSelector(props);
      const productBuyerCategorySelector = (companyProduct) =>
        companyProduct.buyerCategories;
      const buyerCategorySelector = isConnected
        ? createSelector(productBuyerCategorySelector, (buyerCategories) =>
            buyerCategories.find(
              (b) =>
                b.buyerCategory === (distBuyerCategory || companyBuyerCategory)
            )
          )
        : createSelector(productBuyerCategorySelector, (buyerCategories) =>
            getHighestBuyerCategory(buyerCategories)
          );
      const updatedCompanyProduct = Object.assign({}, props.product);
      updatedCompanyProduct.buyerCategories = buyerCategorySelector(
        props.product
      );
      const finalCompanyProduct = JSON.parse(
        JSON.stringify(updatedCompanyProduct)
      );
      setCompanyProduct(finalCompanyProduct);
    }
  }, [props.product]);
  return (
    <React.Fragment>
      <ProductContainer>
        <ImageContainer>
          <Link
            to={{
              pathname: `/singleproduct`,
              search: `product=${get(companyProduct, '_id')}&zone=${get(
                props,
                'auth.user.address.zone._id'
              )}`,
              state: {
                distributorProduct,
                companyProduct,
                distributor,
                zone: get(props, 'auth.user.address.zone._id')
              }
            }}
          >
            <img
              alt={'product'}
              src={get(
                companyProduct,
                'images[0]',
                require('../assets/images/repzone-placeholder.png')
              )}
            />
          </Link>
        </ImageContainer>
        <Link
          to={{
            pathname: '/singleproduct',
            search: `product=${get(companyProduct, '_id')}&zone=${get(
              props,
              'auth.user.address.zone._id'
            )}&company=${get(companyProduct, 'company._id')}`,
            state: {
              distributorProduct,
              companyProduct,
              distributor,
              zone: get(props, 'auth.user.address.zone._id')
            }
          }}
        >
          <ProductNameHolder>
            {get(
              distributorProduct.companyProduct,
              'name.ar',
              get(companyProduct, 'name.ar', '--')
            )}
          </ProductNameHolder>
        </Link>
      </ProductContainer>
    </React.Fragment>
  );
};
const mapStateToProps = ({ main, auth, commonData }) => ({
  main,
  auth,
  commonData
});
export default connect(mapStateToProps, { updateUser, getGeneric, setLoading })(
  LandingPageProduct
);

const ProductNameHolder = styled.div`
  color: #11192d;
  font-size: 17px;
  padding: 30px 0;
  /* height: 90px; */
  font-family: 'Cairo-Light';
  text-align: center;

  @media (max-width: 414px) {
    padding: 20px 0;
  }
`;

const ProductContainer = styled.div`
  width: 100%;
  direction: rtl;
  margin: 40px 0;
  height: 280px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  background: #fff;
  padding: 12px;
  @media (max-width: 414px) {
    height: 220px;
  }
  @media (min-width: 1600px) {
    height: 300px;
  }
  :hover {
    transition: 0.3s;
  }
`;
const ImageContainer = styled.div`
  width: 100%;
  img {
    width: 100%;
    height: 13.76vw;
    object-fit: contain;
    background: white;
    @media (max-width: 414px) {
      height: 140px;
    }
  }
`;
