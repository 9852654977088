import { all, call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import get from 'lodash/get';
import { message } from 'antd';
import { getWishlistSuccess, showWishlistMessage } from '../actions/Wishlist';
import config from '../../config';
import {
  GET_WISHLIST,
  ADD_TO_WISHLIST,
  REMOVE_FROM_WISHLIST
} from '../../constants/ActionTypes';
import { fetchError } from '../actions/Common';

const getWishlistRequest = async () => {
  const token = localStorage.getItem('token');
  return axios({
    method: 'get',
    url: `${config.url}buyers/getBuyer`,
    params: { token }
  });
};

function* getUserWishlist() {
  try {
    const user = yield call(getWishlistRequest);
    yield put(getWishlistSuccess(get(user, 'data.buyer.wishlist')));
  } catch (error) {
    yield put(showWishlistMessage(error));
  }
}
const addToWishlistRequest = async (payload) => {
  return axios({
    method: 'PUT',
    url: `${config.url}buyers/addToWishlist`,
    data: payload,
    params: {
      token: localStorage.getItem('token')
    }
  });
};
function* addToWishlist(action) {
  try {
    // message.loading('جار الاضافة', 0);
    const response = yield call(addToWishlistRequest, action.payload);
    yield put(getWishlistSuccess(get(response, 'data.wishlist')));
    message.destroy();
    message.success(get(response, 'data.message'));
  } catch (error) {
    message.destroy();
    message.error(get(error, 'response.data.error', 'حدث خطأ!'));
    yield put(fetchError(error));
  }
}
const removeFromWishlistRequest = async (payload) => {
  return axios({
    method: 'PUT',
    url: `${config.url}buyers/removeFromWishlist`,
    data: payload,
    params: {
      token: localStorage.getItem('token')
    }
  });
};
function* removeFromWishlist(action) {
  try {
    message.loading('جاري ..', 0);
    const response = yield call(removeFromWishlistRequest, action.payload);
    yield put(getWishlistSuccess(get(response, 'data.wishlist')));
    message.destroy();
    message.success(get(response, 'data.message'));
  } catch (error) {
    message.destroy();
    message.error(get(error, 'response.data.error', 'حدث خطأ!'));
    yield put(fetchError(error));
  }
}
export default function* rootSaga() {
  yield all([yield takeEvery(GET_WISHLIST, getUserWishlist)]);
  yield all([yield takeEvery(ADD_TO_WISHLIST, addToWishlist)]);
  yield all([yield takeEvery(REMOVE_FROM_WISHLIST, removeFromWishlist)]);
}
