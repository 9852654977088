/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import CheckoutShipment from './CheckoutShipment';
import { getObject } from '../services/CommonServices';

const CheckoutProducts = (props) => {
  const [conversionRate, setConversionRate] = useState();
  const [tempOrders, setTempOrders] = useState(
    get(props, 'tempOrder.orders', [])
  );

  useEffect(() => {
    getObject('settings').then((res) => {
      setConversionRate(get(res, 'data.settings[0].conversionRate'));
    });
  }, []);

  useEffect(() => {
    props.setTempOrders(tempOrders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempOrders]);

  return tempOrders.map((order, orderIndex) => (
    <CheckoutShipment
      order={order}
      orderIndex={orderIndex}
      conversionRate={conversionRate}
      {...props}
      setTempOrders={setTempOrders}
      tempOrders={tempOrders}
    />
  ));
};
export default CheckoutProducts;
