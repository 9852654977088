import React from 'react';

const DropdownSvg = () => (
  <svg
    width='1em'
    height='1em'
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 99.79 99.79'
  >
    <defs>
      <style dangerouslySetInnerHTML={{ __html: '.cls-1{fill:#4c7fba;}' }} />
    </defs>
    <title>drop down menu</title>
    <path
      className='cls-1'
      d='M421.47,346.64a49.9,49.9,0,1,1,49.9-49.9A50,50,0,0,1,421.47,346.64Zm0-95.39a45.5,45.5,0,1,0,45.5,45.5A45.55,45.55,0,0,0,421.47,251.25Z'
      transform='translate(-371.57 -246.85)'
    />
    <path
      className='cls-1'
      d='M421.47,345.94a49.2,49.2,0,1,1,49.2-49.2A49.25,49.25,0,0,1,421.47,345.94Zm0-95.39a46.2,46.2,0,1,0,46.2,46.2A46.25,46.25,0,0,0,421.47,250.55Z'
      transform='translate(-371.57 -246.85)'
    />
    <path
      className='cls-1'
      d='M439.3,300.37a3.13,3.13,0,0,0-.24-4.18,3.17,3.17,0,0,0-4.47,0h0L421.47,309.3l-27.54-27.54a3.16,3.16,0,1,0-4.47,4.47L419.23,316a3.17,3.17,0,0,0,4.47,0l15.35-15.35h0Z'
      transform='translate(-371.57 -246.85)'
    />
    <path
      className='cls-1'
      d='M453.48,281.76a3.17,3.17,0,0,0-4.47,0l-8.7,8.77a3.14,3.14,0,0,0,.63,4.25,3.17,3.17,0,0,0,4-.08l8.49-8.47a3.16,3.16,0,0,0,0-4.47Z'
      transform='translate(-371.57 -246.85)'
    />
  </svg>
);

export default DropdownSvg;
