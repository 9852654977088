import {
  GET_GENERIC,
  ADD_GENERIC,
  UPDATE_GENERIC,
  DELETE_GENERIC
} from '../../constants/ActionTypes';

export const getGeneric = (type) => ({
  type: GET_GENERIC,
  payload: { type }
});

export const addGeneric = (payload) => ({
  type: ADD_GENERIC,
  payload
});

export const updateGeneric = (payload) => ({
  type: UPDATE_GENERIC,
  payload
});

export const deleteGeneric = (payload) => ({
  type: DELETE_GENERIC,
  payload
});
