/* eslint-disable import/extensions */
import React from 'react';
import { Icon } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import CustomButton from '../elements/CustomButton';
import Cart from '../icons/CartSvg';

const Text = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.primary};
  margin-bottom: 2vw;
  margin-top: 2vw;
  text-align: center;
`;
const StyledIcon = styled(Icon)`
  font-size: 12.4vw;
  & .cls-1 {
    fill: ${(props) => props.theme.primary};
  }
  @media (max-width: 767px) {
    font-size: 60px;
    margin: 10px 0;
  }
`;
const StyledLink = styled(Link)``;
const StyledButton = styled(CustomButton)`
  border-radius: 57px;
  width: 100%;
  padding: 15px 30px;
  height: inherit;
  font-size: 17px;

  @media (max-width: 767px) {
    padding: 10px 20px;
    font-size: 14px;
  }
  :hover {
    border: 1px solid #4a7fbd;
    color: #4a7fbd;
    background: #fff;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3vw;
  padding-bottom: 3vw;
`;
const NoOffers = (props) => (
  <Container>
    {!props.singleProduct && <StyledIcon component={Cart} />}
    <Text>
      {props.singleProduct
        ? 'لا يوجد عروض على هذا المنتج حالياً'
        : 'لا يوجد عروض حالياً'}
    </Text>
    {!props.singleProduct && (
      <StyledLink to='/'>
        <StyledButton>متابعة التسوق</StyledButton>
      </StyledLink>
    )}
  </Container>
);

export default NoOffers;
