import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import get from 'lodash/get';

const CompanyInfo = (props) => (
  <React.Fragment>
    <Row gutter={24} type='flex' justify='center'>
      <Col lg={4} md={4} sm={24} xs={24}>
        <LogoContainer>
          <Logo
            alt='logo'
            src={get(
              props,
              'company.images[0]',
              require('../assets/images/repzone-placeholder2.png')
            )}
          />
        </LogoContainer>
      </Col>

      <Col
        lg={{ span: 10, offset: 0 }}
        md={{ span: 10, offset: 0 }}
        sm={{ span: 24, offset: 0 }}
        xs={{ span: 24, offset: 0 }}
      >
        <TextContainer>
          <Text>مناطق البيع : مصر</Text>
          <Text>
            {`${get(props, 'company.address[0].buildingNumber', '')},${get(
              props,
              'company.address[0].zone.name.ar',
              ''
            )},${get(props, 'company.address[0].district.name.ar', '')} , ${get(
              props,
              'company.address[0].city.name',
              ''
            )},${get(props, 'company.address[0].country.name', '')} `}
          </Text>
        </TextContainer>
      </Col>

      <Col lg={10} md={10} sm={24} xs={24}>
        <TextContainer>
          <Text>اسم الشركة : {get(props, 'company.name.ar')}</Text>
          <Text>رقم التليفون : {get(props, 'company.phone[0]', '--')}</Text>
        </TextContainer>
      </Col>
    </Row>
  </React.Fragment>
);
export default CompanyInfo;

const Text = styled.div`
  font-size: 1.4vw;
  color: #fff;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 414px) {
    font-size: 16px;
    padding: 16px;
    text-align: center;
  }

  ${(props) => (!props.last ? 'border-bottom:solid 1px #ffffff;' : '')}
  text-align:right;
  padding-top: 0.73vw;
  padding-bottom: 0.73vw;
  font-weight: bold;
  margin-left: 30px;
  @media (max-width: 414px) {
    margin-left: 0;
  }
`;
const TextContainer = styled.div``;
const Logo = styled.img`
  width: 100%;
  height: 75px;
  object-fit: contain;
  margin: auto;
`;
const LogoContainer = styled.div`
  width: 100%;
  max-height: 75px;
`;
