/* eslint-disable import/extensions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react';
import { Row, message, Spin, Icon, Button, Col } from 'antd';
import styled from 'styled-components';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setLoading } from '../appRedux/actions/Common';
import { updateUser } from '../appRedux/actions/Auth';
import { getCart } from '../appRedux/actions/Cart';
import { getGeneric } from '../appRedux/actions/Generic';
import CustomButton from '../elements/CustomButton.jsx';
import OfferDetailsModal from './OfferDetailsModal.jsx';
import HandshakeModal from './HandshakeModal.jsx';
import {
  getObject,
  addToCart,
  checkBuyerCategoryWithSeller,
  getSavedPrice
} from '../services/CommonServices';

const OfferPageCard = (props) => {
  const [offer, setOffer] = useState(props.offer);
  const [info, setInfo] = useState('');
  const [visible, setVisible] = useState(false);
  const [offerProduct, setOfferProduct] = useState();
  const [quantityCarton, setQuantityCarton] = useState(0);
  const [quantityPackage, setQuantityPackage] = useState(0);
  const [addOns, setAddOns] = useState([]);
  const [addOnObjects, setAddOnObjects] = useState([]);
  const [distributor, setDistributor] = useState();
  const [company, setCompany] = useState();
  const [handshake, setHandshake] = useState(false);
  const [buyer, setBuyer] = useState(get(props, 'auth.user', {}));
  const [zone, setZone] = useState(get(props, 'auth.user.address.zone', {}));
  const [loading, setLoading] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [saved, setSaved] = useState(1);

  useEffect(() => {
    setLoading(true);
    setLoadingDetails(true);
    setOffer(props.offer);
    if (get(props, 'offer.optional_3') === 'carton') {
      setQuantityCarton(get(props, 'offer.optional_1'));
    } else {
      setQuantityPackage(get(props, 'offer.optional_1'));
    }
    getObject('companyProducts/product', {
      _id: get(props, 'offer.product_id')
    }).then((res) => {
      setLoadingDetails(false);
      setOfferProduct(res.data);
      getSavedPrice(res.data, setSaved, props.offer);
    });
    if (get(props, 'auth.user')) {
      getObject('companyProducts/productDistributorInZone', {
        _id: get(props, 'offer.product_id'),
        zone: get(props, 'auth.user.address.zone._id')
      }).then((res) => {
        setLoading(false);
        setDistributor(
          get(res, 'data.distributor.distributor', get(res, 'data.distributor'))
        );
        setCompany(get(res, 'data.company'));
      });
    } else {
      setLoading(false);
    }
    if (offer.bundles) {
      const products = offer.bundles.map((bundle) => bundle.addOnProduct);
      getObject('companyProducts/offerAddons', {
        products
      }).then((res) => {
        setAddOns(res.data);
      });
    }
    if (get(props, 'offer.type') === 3) {
      setInfo('واحصل على منتجات هدية');
    } else if (get(props, 'offer.type') === 1) {
      const quantityInfo = ` وفر ${saved.toFixed(2)} جنيه`;
      setInfo(quantityInfo);
    } else {
      const discountInfo = `خصم ${get(props, 'offer.optional_2', 0)}%`;
      setInfo(discountInfo);
    }
  }, [props.offer, saved]);
  useEffect(() => {
    setBuyer(get(props, 'auth.user', {}));
    setZone(get(props, 'auth.user.address.zone', {}));
  }, [props.auth]);
  useEffect(() => {
    if (addOns.length) {
      const addOnObjects = [];
      // eslint-disable-next-line array-callback-return
      addOns.map((addOn) => {
        // eslint-disable-next-line array-callback-return
        offer.bundles.map((bundle) => {
          if (bundle.addOnProduct === addOn._id) {
            const addOnObject = {
              addOnProduct: true,
              _id: addOn._id,
              name: { ar: addOn.name.ar },
              images: addOn.images,
              quantityCarton: bundle.package === 'carton' ? bundle.quantity : 0,
              quantityPackage: bundle.package === 'unit' ? bundle.quantity : 0,
              buyerCategories: addOn.buyerCategories,
              packageUnit: addOn.packageUnit
            };
            addOnObjects.push(addOnObject);
          }
        });
      });
      setAddOnObjects(addOnObjects);
    }
  }, [addOns]);
  const addOfferToCart = () => {
    if (!props.auth.token) {
      window.scrollTo(0, 0);
      props.history.push('/login');
    } else {
      const buyerCategory = checkBuyerCategoryWithSeller({
        distributor,
        buyer,
        company
      });
      const offerBuyerCategory = offer.buyer_cat;
      if (!buyerCategory) {
        setHandshake(true);
      } else {
        if (buyerCategory !== offerBuyerCategory) {
          message.warn(' هذا العرض غير متوفر لقطاع سعرك ! ');
        } else {
          addToCart({
            props,
            companyProduct: offer,
            offer,
            company: company._id,
            distributor,
            distributorProduct: null,
            countPackage: undefined,
            countCarton: 1,
            carton: undefined,
            offerProduct
          });
        }
      }
    }
  };

  return (
    <React.Fragment>
      <OfferDetailsModal
        offer={offer}
        visible={visible}
        setVisible={setVisible}
        product={offerProduct}
        quantityCarton={quantityCarton}
        quantityPackage={quantityPackage}
        addOns={addOnObjects}
      />
      <HandshakeModal
        visible={handshake}
        setVisible={setHandshake}
        distributor={distributor}
        company={company}
        zone={zone}
        buyer={buyer}
      />
      <ProductContainer>
        <LabelContainer>
          <img alt={'product'} src={require('../assets/images/label 2.svg')} />
          <LabelText>{info}</LabelText>
        </LabelContainer>
        <ImageContainer>
          <Link
            to={{
              pathname: `/singleproduct`,
              search: `product=${get(offer, 'product_id')}&zone=${get(
                props,
                'auth.user.address.zone._id'
              )}`,
              state: {
                productId: get(offer, 'product_id'),
                zone: get(props, 'auth.user.address.zone._id')
              }
            }}
          >
            <img
              alt={'product'}
              src={get(
                offer,
                'images[0]',
                get(
                  offerProduct,
                  'images.0',
                  require('../assets/images/repzone-placeholder.png')
                )
              )}
            />
          </Link>
        </ImageContainer>
        {/* <ProductInfoContainer> */}
        <Link
          to={{
            pathname: '/singleproduct',
            search: `product=${get(offer, 'product_id')}&zone=${get(
              props,
              'auth.user.address.zone._id'
            )}`,
            state: {
              productId: get(offer, 'product_id'),
              zone: get(props, 'auth.user.address.zone._id')
            }
          }}
        >
          <div>{get(offer, 'name')}</div>
        </Link>
        <Row>
          <OfferDetails>
            <a onClick={() => setVisible(true)} disabled={loadingDetails}>
              تفاصيل العرض
            </a>
          </OfferDetails>
        </Row>
        <Row>
          <OfferButtonHolder>
            <Row gutter={8}>
              <Col span={4}>
                <Button shape='circle'>
                  <Icon style={{ color: '#4a7fbd' }} type='star' />
                </Button>
              </Col>
              <Col span={20}>
                <OfferButton
                  disabled={loading}
                  onClick={() => {
                    addOfferToCart();
                  }}
                >
                  <OfferButtonText>
                    {loading ? <Spin /> : ' احصل على العرض'}{' '}
                  </OfferButtonText>
                </OfferButton>
              </Col>
            </Row>
          </OfferButtonHolder>
        </Row>
      </ProductContainer>
    </React.Fragment>
  );
};
const mapStateToProps = ({ main, auth, commonData }) => ({
  main,
  auth,
  commonData
});
export default connect(mapStateToProps, {
  updateUser,
  getGeneric,
  setLoading,
  getCart
})(OfferPageCard);

const ProductContainer = styled.div`
  width: 100%;
  height: fit-content;
  font-size: 20px;
  direction: ltr;

  @media (max-width: 414px) {
    /* height: 200px; */
    height: fit-content;
  }

  a {
    text-align: center;
    color: #10182d;
    @media (max-width: 414px) {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      direction: rtl;
      font-size: 17px;
    }
  }
`;
const ImageContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 18px;
  img {
    width: 100%;
    height: 130px;
    object-fit: contain;
    @media (max-width: 414px) {
      height: 100px;
    }
  }
  a {
    font-family: 'Cairo-Bold';
  }
`;

const LabelContainer = styled.div`
  width: 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
`;

const LabelText = styled.div`
  font-size: 17px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  direction: rtl;
  color: #fff;
  font-weight: bold;
  width: 100%;
  text-align: right;
  padding: 25px;

  @media (max-width: 414px) {
    font-size: 11px;
    line-height: normal;
    padding: 11px;
  }
`;

const OfferButton = styled(CustomButton)`
  border-radius: 50px;
  font-size: 14px;
  width: 100%;
`;

const OfferButtonText = styled.div`
  font-weight: bold;
  border-radius: 50px;
  font-size: 12px;
  width: 100%;
`;
const OfferButtonHolder = styled.div`
  margin: 10px auto;
  display: block;
  width: 50%;
  @media (max-width: 414px) {
    width: 100%;
  }
`;

const OfferDetails = styled.div`
  text-align: center;
  a {
    color: #4a7fbd;
    font-size: 15px;
  }
`;
