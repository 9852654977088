import { all, call, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { push } from 'connected-react-router'
import { message } from 'antd'
import get from 'lodash/get'
import { fetchError } from '../actions/Common'
import config from '../../config'
import {
  GET_GENERIC,
  GET_GENERIC_SUCCESS,
  ADD_GENERIC,
  UPDATE_GENERIC,
  DELETE_GENERIC
} from '../../constants/ActionTypes'
import { toSingular } from '../../services/CommonServices'

const genericAddApi = async (payload) =>
  axios({
    method: 'POST',
    url: config.url + payload.type,
    data: payload.value,
    params: {
      token: localStorage.getItem('token')
    }
  })

const genericGetApi = async (type) =>
  axios
    .get(config.url + type, {
      params: {
        token: localStorage.getItem('token')
      }
    })
    .then((response) => response.data)

const genericUpdateApi = async ({ type, value, _id }) =>
  axios({
    method: 'PUT',
    url: config.url + type,
    data: value,
    params: {
      _id,
      token: localStorage.getItem('token')
    }
  })

const genericDeleteApi = async ({ type, _id }) =>
  axios({
    method: 'DELETE',
    url: `${config.url + type}/${_id}`,
    params: {
      token: localStorage.getItem('token')
    }
  })

function* genericAdd(action) {
  try {
    message.loading('', 0)
    yield call(genericAddApi, action.payload)
    message.destroy()
    message.success(action.payload.message)
  } catch (error) {
    message.destroy()
    message.error(get(error, 'response.data.error', 'حدث خطأ خلال الأضافة!'))
    yield put(fetchError(error))
  }
}

function* genericUpdate(action) {
  try {
    message.loading('', 0)
    yield call(genericUpdateApi, action.payload)
    message.destroy()
    if (get(action, 'payload.value.isDeleted')) {
      message.success(`${toSingular(action.payload.type)} تم المسح بنجاح!`)
    } else {
      message.success(`${toSingular(action.payload.type)} تم التعدبل بنجاح`)
    }
    if (action.payload.refreshRoute !== '')
      yield put(push(`/list/${action.payload.type}`))
  } catch (error) {
    message.destroy()
    message.error(get(error, 'response.data.error', 'حدث خطأ!'))
    yield put(fetchError(error))
  }
}

function* genericDelete(action) {
  try {
    message.loading('', 0)
    yield call(genericDeleteApi, action.payload)
    message.destroy()
    message.success(`${toSingular(action.payload.type)} تم المسح بنجاح!`)
    yield put(
      push({
        pathname: `/delete/${action.payload.type}`,
        state: {
          isDeleted: true
        }
      })
    )
  } catch (error) {
    message.destroy()
    message.error(get(error, 'response.data.error', 'حدث خطأ!'))
    yield put(fetchError(error))
  }
}

function* genericGet(action) {
  const { type } = action.payload
  try {
    const fetchedData = yield call(genericGetApi, type)
    yield put({
      type: GET_GENERIC_SUCCESS,
      payload: {
        [type]: fetchedData,
        type
      }
    })
  } catch (error) {
    yield put(fetchError(error))
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(GET_GENERIC, genericGet),
    yield takeEvery(ADD_GENERIC, genericAdd),
    yield takeEvery(UPDATE_GENERIC, genericUpdate),
    yield takeEvery(DELETE_GENERIC, genericDelete)
  ])
}
