/* eslint-disable import/extensions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Icon, Row, Col } from 'antd';
import get from 'lodash/get';
import { connect } from 'react-redux';
import CustomButton from '../elements/CustomButton';
import CustomDivider from '../elements/CustomDivider';
import MinusSvg from '../icons/MinusSvg';
import PlusSvg from '../icons/PlusSvg';
import {
  getUnitPrice,
  productQuantityPricesTotal,
  getPackage,
  getBuyerCategoryId,
  getNewPrice
} from '../services/CommonServices';
import CancelSvg from '../icons/CancelSvg';
import { getGeneric, updateGeneric } from '../appRedux/actions/Generic';
import { updateUser } from '../appRedux/actions/Auth';

const decrementCount = (prev) => {
  if (prev === 0) {
    return 0;
  }
  return prev - 1;
};
const updateProductCartonQ = (count, props) => {
  const product = Object.assign({}, get(props, 'product', {}));
  product.quantity[0].quantity = Number(count);
  props.saveProductInCart(product);
  return product;
};

const updateProductPackageQ = (count, props) => {
  const product = Object.assign({}, get(props, 'product', {}));
  product.quantity[1].quantity = Number(count);
  props.saveProductInCart(product);
  return product;
};
const CartCounter = (setCount, count, props, updateProduct) => (
  <CounterRow>
    <Col span={6}>
      <CustomButton
        style={{ lineHeight: '0.1vw' }}
        height={'2.17vw'}
        width={'3.7vw'}
        float={'left'}
        block
        onClick={() => {
          setCount(decrementCount);
          const productCount = count === 0 ? count : Number(count) - 1;
          updateProduct(productCount, props);
          if (count !== 0) {
            props.showDrawer();
          }
        }}
        transparent
        shape='round'
      >
        <Icon style={{ fontSize: '1.25vw' }} component={MinusSvg} />
      </CustomButton>
    </Col>
    <Col span={12}>
      <Count>{count}</Count>
    </Col>
    <Col span={6}>
      <CustomButton
        style={{ lineHeight: '0.1vw' }}
        height={'2.17vw'}
        width={'3.7vw'}
        float={'left'}
        onClick={() => {
          setCount((prev) => prev + 1);
          updateProduct(Number(count) + 1, props);
          props.showDrawer();
        }}
        block
        transparent
        shape='round'
      >
        <Icon style={{ fontSize: '1.25vw' }} component={PlusSvg} />
      </CustomButton>
    </Col>
  </CounterRow>
);
const CartProduct = (props) => {
  const [countCarton, setCountCarton] = useState(
    Number(get(props, 'product.quantity[0].quantity', 0))
  );
  const [offerProduct, setOfferProduct] = useState();
  const [countPackage, setCountPackage] = useState(
    Number(get(props, 'product.quantity[1].quantity', 0))
  );
  const [buyerCategoryId, setBuyerCategoryId] = useState(
    get(props, 'product.buyerCategory', get(props, 'product,buyer_cat'))
  );
  const [companyProduct, setCompanyProduct] = useState(
    get(
      props,
      'product.product.companyProduct',
      get(props, 'product.product', {})
    )
  );

  useEffect(() => {
    setCountCarton(Number(get(props, 'product.quantity[0].quantity', 0)));
    setCountPackage(Number(get(props, 'product.quantity[1].quantity', 0)));
    const companyProduct = get(
      props,
      'product.product.companyProduct',
      get(props, 'product.product', {})
    );
    setCompanyProduct(companyProduct);
    if (
      get(props, 'product.product.offer') &&
      get(props, 'product.product.offer.type') !== 3
    ) {
      setOfferProduct(get(props, 'product.product.offerProduct'));
    }
  }, [props.product]);

  useEffect(() => {
    const buyerCategoryId =
      getBuyerCategoryId(
        get(props, 'product.seller', {}),
        get(props, 'auth.user', {})
      ) || get(props, 'product.buyer_cat');
    setBuyerCategoryId(buyerCategoryId);
  }, [props.auth]);

  let total = productQuantityPricesTotal(
    get(props, 'product', {}),
    buyerCategoryId
  );
  if (get(props, 'product.product.offer')) {
    if (get(props, 'product.product.offer.type') === 3) {
      total = get(props, 'product.product.offer.optional_2') * countCarton;
    } else if (get(props, 'product.product.offer.type') === 2) {
      total = getNewPrice(
        get(props, 'product.product.offer.product_id'),
        get(props, 'product.product.offer.buyer_cat'),
        [],
        get(props, 'product.product.offer.optional_1'),
        get(props, 'product.product.offer.optional_3'),
        get(props, 'product.product.offer.optional_2'),
        countCarton,
        offerProduct
      );
    } else {
      total = getNewPrice(
        get(props, 'product.product.offer.product_id'),
        get(props, 'product.product.offer.buyer_cat'),
        [],
        get(props, 'product.product.offer.optional_1'),
        get(props, 'product.product.offer.optional_3'),
        0,
        countCarton,
        offerProduct
      );
    }
  }
  const carton = getPackage(companyProduct, buyerCategoryId);
  const unitPrice = getUnitPrice(companyProduct, buyerCategoryId);
  const productsInPackage = Number(get(carton, 'packageQuantity', 0));

  return (
    <ProductContainer>
      <Row>
        <Col span={12}>
          <TextContainer left>
            <StyledIcon
              onClick={() => {
                props.showDrawer();
                props.deleteProduct(props.product);
              }}
              style={{ fontSize: '2vw' }}
              component={CancelSvg}
            />
          </TextContainer>
        </Col>
      </Row>
      <ImageContainer>
        <img
          alt={'product'}
          src={get(
            companyProduct,
            'images[0]',
            require('../assets/images/repzone-placeholder.png')
          )}
        />
      </ImageContainer>
      <ProductInfoContainer>
        <ProductName>{get(companyProduct, 'name.ar')}</ProductName>
      </ProductInfoContainer>
      <CustomDivider top={'10px'} bottom={'10px'} />
      <PriceContainer>
        <Row>
          <Col lg={12} md={12} xs={24}>
            <TextContainer weight={'400'} left>
              <Row>
                <Col
                  lg={{ span: 20, offset: 2 }}
                  md={{ span: 24, offset: 0 }}
                  xs={{ span: 24, offset: 0 }}
                >
                  <h5> جم </h5>{' '}
                  <h6>
                    {get(props, 'product.product.offer')
                      ? get(props, 'product.product.offer.type') === 3
                        ? parseFloat(
                            props.product.product.offer.optional_2
                          ).toFixed(2)
                        : get(props, 'product.product.offer.type') === 2
                        ? parseFloat(
                            getNewPrice(
                              get(props, 'product.product.offer.product_id'),
                              get(props, 'product.product.offer.buyer_cat'),
                              [],
                              get(props, 'product.product.offer.optional_1'),
                              get(props, 'product.product.offer.optional_3'),
                              get(props, 'product.product.offer.optional_2'),
                              1,
                              offerProduct
                            )
                          ).toFixed(2)
                        : parseFloat(
                            getNewPrice(
                              get(props, 'product.product.offer.product_id'),
                              get(props, 'product.product.offer.buyer_cat'),
                              get(props, 'main.companyProducts'),
                              get(props, 'product.product.offer.optional_1'),
                              get(props, 'product.product.offer.optional_3'),
                              0,
                              1,
                              offerProduct
                            )
                          ).toFixed(2)
                      : parseFloat(get(carton, 'price', 0)).toFixed(2)}
                  </h6>
                </Col>
              </Row>
            </TextContainer>
          </Col>
          <Col lg={12} md={12} xs={24}>
            <TextContainer weight={'400'} right>
              {get(props, 'product.product.offer')
                ? 'عرض'
                : `${get(
                    props,
                    'product.quantity[0].saleUnit',
                    'كرتونة'
                  )} - ${productsInPackage} ${get(
                    props,
                    'product.quantity[1].saleUnit',
                    'عبوة'
                  )}`}
            </TextContainer>
          </Col>
        </Row>
      </PriceContainer>
      {CartCounter(setCountCarton, countCarton, props, updateProductCartonQ)}
      {!get(props, 'product.product.offer') && (
        <div>
          <CustomDivider top={'5px'} bottom={'5px'} />
          <PriceContainer>
            <Row>
              <Col lg={12} md={12} xs={24}>
                <TextContainer weight={'400'} left>
                  <Row>
                    <Col lg={24} md={12} xs={24}>
                      <h5> جم </h5> {parseFloat(unitPrice).toFixed(2)}
                    </Col>
                  </Row>
                </TextContainer>
              </Col>
              <Col lg={12} md={12} xs={24}>
                <TextContainer weight={'400'} right>
                  {get(props, 'product.quantity[1].saleUnit', 'عبوة')}
                </TextContainer>
              </Col>
            </Row>
          </PriceContainer>
          {CartCounter(
            setCountPackage,
            countPackage,
            props,
            updateProductPackageQ
          )}
        </div>
      )}

      <GreyContainer>
        <div style={{ marginRight: '5px' }}> جم </div>
        <div>{parseFloat(total).toFixed(2)}</div>
      </GreyContainer>
    </ProductContainer>
  );
};

const mapStateToProps = ({ main, auth }) => ({ main, auth });
export default connect(mapStateToProps, {
  getGeneric,
  updateUser,
  updateGeneric
})(CartProduct);

const TextContainer = styled.div`
  text-align: ${(props) => (props.left ? 'left' : 'right')};
  font-weight: ${(props) => props.weight || 'normal'};
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 14px;
    font-weight: bold;
  }
  @media (max-width: 414px) {
    font-weight: bold;
    text-align: center;
  }

  color: ${(props) => props.theme.primary};
  .anticon {
    font-size: 3vw;
    color: ${(props) => props.theme.accent};
  }
  h5,
  h6 {
    display: inline-block;
    margin: 0;

    font-size: 14px;

    @media (max-width: 768px) {
      font-weight: bold;
    }
  }
`;
const ProductContainer = styled.div`
  font-size: 1.8vw;
  direction: ltr;
`;
const ImageContainer = styled.div`
  width: 100%;
  margin-top: 0.733vw;
  @media (max-width: 768px) {
    margin-top: 1.5vw;
  }
  img {
    width: 100%;
    height: 8vw;
    object-fit: contain;
    @media (max-width: 768px) {
      height: 100px;
    }
  }
`;
const ProductInfoContainer = styled.div`
  text-align: center;
  color: ${(props) => props.theme.primary};
  font-size: 1.17vw;
  @media (max-width: 768px) {
    font-size: 2.3vw;
  }
  font-weight: 400;
`;
const PriceContainer = styled.div``;
const ProductName = styled.div`
  direction: rtl;
  overflow: hidden;
  width: 100%;
  font-family: 'Cairo-Bold';
  margin: 1.3vw 0px;
  font-size: 17px;

  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  direction: rtl;
  margin-bottom: 1.17vw;
  overflow: hidden;

  :hover {
    text-overflow: inherit;
    white-space: normal;
  }
  @media (max-width: 768px) {
    margin: 2.6vw 0px;
    font-size: 15px;
  }
`;

const CounterRow = styled(Row)`
  margin: 0.95vw 0px;
  @media (max-width: 768px) {
    margin: 2vw 0px;
    button {
      width: 40px;
      height: 20px;
      font-size: 22px;
      line-height: normal;
      margin-top: 2px;
    }
  }
`;
const GreyContainer = styled.div`
  background-color: ${(props) => props.theme.light};
  font-size: 18px;
  @media (max-width: 768px) {
    font-size: 17px;
  }
  @media (max-width: 414px) {
    font-size: 16px;
  }
  color: ${(props) => props.theme.primary};
  font-weight: 800;
  display: flex;
  justify-content: center;
  padding: 0.6vw 0px;
`;

const Count = styled.p`
  color: ${(props) => props.theme.accent};
  margin-bottom: 0px;
  font-size: 2vw;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 3.8vw;
  }
  font-weight: 800;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
