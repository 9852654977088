/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
import React, { Component } from 'react';
import {
  Row,
  Col,
  Input,
  Select,
  Icon,
  Form,
  message,
  Modal,
  Button
} from 'antd';
import styled from 'styled-components';
import { get } from 'lodash';
import { connect } from 'react-redux';
import randomstring from 'randomstring';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';
import config from '../config';
import CustomDivider from '../elements/CustomDivider';
import CustomButton from '../elements/CustomButton';
import CustomRectangle from '../elements/CustomRectangle';
import DropdownSvg from '../icons/DropdownSvg';
import UploadDisplay from '../components/UploadDisplay';
import {
  getGeneric,
  addGeneric,
  updateGeneric
} from '../appRedux/actions/Generic';
import {
  postObject,
  getObject,
  showNotification
} from '../services/CommonServices';

const { Option } = Select;
const s3Url = 'https://repzone-static.s3.amazonaws.com/';
const uploadOptions = {
  server: config.url.slice(0, -1),
  signingUrlWithCredential: true,
  s3path: `buyers/${randomstring.generate(7)}/`,
  autoUpload: true
};
const EgyptId = '5dc925810b3f4f2774834180';
class RegisterPage extends Component {
  state = {
    logo: '',
    sendcodeModal: false,
    values: {},
    valCode: '',
    buyerTypes: [
      { _id: 'كشك', name: 'كشك' },
      { _id: 'سوبر ماركت', name: 'سوبر ماركت' },
      { _id: 'سلاسل', name: 'سلاسل' },
      { _id: 'مطاعم', name: 'مطاعم' },
      { _id: 'تاجر جملة', name: 'تاجر جملة' },
      { _id: 'تاجر قطاعي', name: 'تاجر قطاعي' },
      { _id: 'بازار', name: 'بازار' },
      { _id: 'محمصة', name: 'محمصة' },
      { _id: 'كوفي شوب', name: 'كوفي شوب' },
      { _id: 'مطعم', name: 'مطعم' }
    ]
  };

  componentDidMount() {
    this.props.getGeneric('cities');
    this.props.getGeneric('districts');
    this.props.getGeneric('zones');
  }

  filterCities = () =>
    get(this.props, 'cities', []).filter(
      (city) => String(get(city, 'country._id', '')) === String(EgyptId)
    );

  filterDistricts = (selectedCity) => {
    return get(this.props, 'districts.data', []).filter(
      (district) =>
        String(get(district, 'city._id', '')) === String(selectedCity)
    );
  };

  filterzones = (selectedDistrict) => {
    return get(this.props, 'zones.data', []).filter(
      (zone) =>
        String(get(zone, 'district._id', '')) === String(selectedDistrict)
    );
  };

  handleFinishedUpload = (info, setFieldsValue) => {
    setFieldsValue({ logo: info.fileUrl });
    this.setState({ logo: info.fileUrl });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, fieldsValue) => {
      if (err) {
        return;
      }
      if (String(fieldsValue.password) !== String(fieldsValue.rePassword)) {
        message.error('كلمتا السر غير متطابقتين');
        return;
      }
      fieldsValue.logo = this.state.logo;
      fieldsValue.logo = this.state.logo;
      getObject('buyers/verifyuser', {
        phone: fieldsValue.accountPhoneNumber
      }).then((response) => {
        if (response.data.find) {
          message.error('هذا المستخدم موجود بالفعل ');
        } else {
          this.setState({ values: fieldsValue, sendcodeModal: true });
          postObject('buyers/sendpin', {
            phone: fieldsValue.accountPhoneNumber
          }).then((response) => {
            this.setState({ pinId: response.data.message.pinId });
          });
        }
      });
    });
  };

  handleVerifyPin = () => {
    postObject('buyers/verifypin', {
      pinId: this.state.pinId,
      pin: this.state.valCode
    }).then((response) => {
      if (response.data.success === true) {
        this.setState({ sendcodeModal: false });
        showNotification(
          response.data.message,
          <Icon type='smile' twoToneColor='#52c41a' theme='twoTone' />
        );
        postObject('buyers', this.state.values)
          .then(() => {
            message.success('! تم التسجيل');
            this.props.history.push('/login');
          })
          .catch(() => {
            message.destroy();
            message.warn('يوجد خطأ');
          });
      } else {
        showNotification(
          response.data.message,
          <Icon type='frown' twoToneColor='#eb2f96' theme='twoTone' />
        );
        // return;
      }
    });
  };

  handleResendCode = () => {
    postObject('buyers/resendpin', {
      pinId: this.state.pinId
    })
      .then((response) => {
        if (response.data.success === true) {
          showNotification(
            response.data.message,
            <Icon type='smile' twoToneColor='#52c41a' theme='twoTone' />
          );
        } else {
          showNotification(
            response.data.message,
            <Icon type='frown' twoToneColor='#eb2f96' theme='twoTone' />
          );
        }
      })
      .catch(() => {});
  };

  handleCancel = () => {
    this.setState({
      sendcodeModal: false,
      valCode: ''
    });
  };

  render() {
    const {
      getFieldDecorator,
      getFieldValue,
      setFieldsValue
    } = this.props.form;

    return (
      <Container>
        <div className='container'>
          <Row>
            <Col lg={{ span: 16, offset: 4 }} xs={{ span: 24, offset: 0 }}>
              <Registerholder style={{ background: 'white' }}>
                {' '}
                <Row>
                  <Row>
                    <span>
                      {' '}
                      <p>هل لديك حساب؟</p>
                    </span>
                    <span>
                      {' '}
                      <a href='/login'>تسجيل الدخول</a>
                    </span>
                  </Row>
                </Row>
                <CustomDivider />
              </Registerholder>
              <Documents>بيانات المتجر :</Documents>

              <RegisterPanel>
                <Row type='flex' justify='center' align='middle'>
                  <Col lg={6} sm={6} md={6} xs={24}>
                    <Label>الاسم :</Label>
                  </Col>
                  <Col lg={18} sm={18} md={18} xs={24}>
                    {getFieldDecorator('name.ar', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter your name!'
                        }
                      ]
                    })(<TextArea placeholder='الاسم ' />)}
                  </Col>
                </Row>
                <CustomDivider />

                <Row type='flex' justify='center' align='middle'>
                  <Col lg={6} sm={6} md={6} xs={24}>
                    <Label>البريد الإلكتروني :</Label>
                  </Col>
                  <Col lg={18} sm={18} md={18} xs={24}>
                    {getFieldDecorator('email', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter your email!'
                        }
                      ]
                    })(<TextArea placeholder='البريد الإلكتروني' />)}
                  </Col>
                </Row>
                <CustomDivider />

                <Row type='flex' justify='center' align='middle'>
                  <Col lg={6} sm={6} md={6} xs={24}>
                    <Label>تاكيد البريد الإلكتروني :</Label>
                  </Col>
                  <Col lg={18} sm={18} md={18} xs={24}>
                    {getFieldDecorator('reEmail', {
                      rules: [
                        {
                          required: true,
                          message: 'Please re-enter your email!'
                        }
                      ]
                    })(<TextArea placeholder='البريد الإلكتروني' />)}
                  </Col>
                </Row>
                <CustomDivider />

                <Row type='flex' justify='center' align='middle'>
                  <Col lg={6} sm={6} md={6} xs={24}>
                    <Label>كلمة السر :</Label>
                  </Col>
                  <Col lg={18} sm={18} md={18} xs={24}>
                    {getFieldDecorator('password', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter your password!'
                        }
                      ]
                    })(
                      <TextArea
                        prefix={<Icon type='lock' />}
                        type='password'
                        placeholder='كلمة السر'
                      />
                    )}
                  </Col>
                </Row>
                <CustomDivider />

                <Row type='flex' justify='center' align='middle'>
                  <Col lg={6} sm={6} md={6} xs={24}>
                    <Label> تاكيد كلمة السر :</Label>
                  </Col>
                  <Col lg={18} sm={18} md={18} xs={24}>
                    {getFieldDecorator('rePassword', {
                      rules: [
                        {
                          required: true,
                          message: 'Please re-enter your password!'
                        }
                      ]
                    })(
                      <TextArea
                        prefix={<Icon type='lock' />}
                        type='password'
                        placeholder='كلمة السر'
                      />
                    )}
                  </Col>
                </Row>
                <CustomDivider />

                <Row type='flex' justify='center' align='middle'>
                  <Col lg={6} sm={6} md={6} xs={24}>
                    <Label>نشاط المنشأة :</Label>
                  </Col>
                  <Col lg={18} sm={18} md={18} xs={24}>
                    {getFieldDecorator('activity', {
                      rules: [
                        {
                          required: true,
                          message: 'Please enter your institution type!'
                        }
                      ]
                    })(
                      <DropDown
                        mode={'multiple'}
                        showArrow={false}
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder={
                          <div style={{ textAlign: 'right' }}>
                            {' نشاط المنشأة'}
                            <StyledIcon component={DropdownSvg} />{' '}
                          </div>
                        }
                      >
                        {get(this.state, 'buyerTypes', []).map((type) => (
                          <Option
                            value={get(type, '_id')}
                            style={{ textAlign: 'right' }}
                          >
                            {get(type, 'name')}
                          </Option>
                        ))}
                      </DropDown>
                    )}
                  </Col>
                </Row>
                <CustomDivider />
                <Row type='flex' justify='center' align='middle'>
                  <Col lg={6} sm={6} md={6} xs={24}>
                    <Label> مدير المنشأة :</Label>
                  </Col>
                  <Col lg={18} sm={18} md={18} xs={24}>
                    {getFieldDecorator('manager_name.ar', {
                      rules: [
                        {
                          required: true,
                          message: "Please enter your manager's name!"
                        }
                      ]
                    })(<TextArea placeholder='مدير المنشأة' />)}
                  </Col>
                </Row>
                <CustomDivider />
                <Row type='flex' justify='center' align='middle'>
                  <Col lg={6} sm={6} md={6} xs={24}>
                    <Label>رقم مدير المنشأة :</Label>
                  </Col>
                  <Col lg={18} sm={18} md={18} xs={24}>
                    {getFieldDecorator('accountPhoneNumber', {
                      rules: [
                        {
                          required: true,
                          message: "Please enter your manager's number!"
                        }
                      ]
                    })(<TextArea placeholder=' رقم مدير المنشأة' />)}
                  </Col>
                </Row>
                <CustomDivider />
                <Row type='flex' justify='center' align='middle'>
                  <Col lg={6} sm={6} md={6} xs={24}>
                    <Label>رقم التليفون الأرضي :</Label>
                  </Col>
                  <Col lg={18} sm={18} md={18} xs={24}>
                    {getFieldDecorator(
                      'phone',
                      {}
                    )(<TextArea placeholder=' رقم التليفون الأرضي' />)}
                  </Col>
                </Row>
                <CustomDivider />

                <Row
                  style={{ width: '100%', paddingBottom: '1.5vw' }}
                  type='flex'
                >
                  <Col lg={5} sm={5} md={5} xs={24}>
                    <Label>صوره اللوجو :</Label>
                  </Col>
                  <Col lg={10} sm={10} md={10} xs={24}>
                    <Wrapper>
                      <StyledDropzone
                        onFinish={(info) =>
                          this.handleFinishedUpload(info, setFieldsValue)
                        }
                        s3Url={s3Url}
                        maxSize={1024 * 1024 * 5}
                        upload={uploadOptions}
                      >
                        <UploadDisplay image={get(this.state, 'logo', '')} />
                      </StyledDropzone>
                    </Wrapper>
                  </Col>
                </Row>
              </RegisterPanel>
              <Documents> بيانات العنوان :</Documents>

              <RegisterPanel>
                <Row type='flex' justify='center' align='middle'>
                  <Col lg={6} sm={6} md={6} xs={24}>
                    <Label>المحافظة :</Label>
                  </Col>

                  <Col lg={18} sm={18} md={18} xs={24}>
                    {getFieldDecorator(
                      'address.city',
                      {}
                    )(
                      <DropDown
                        showArrow={false}
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder={
                          <div style={{ textAlign: 'right' }}>
                            {' المحافظة'}
                            <StyledIcon component={DropdownSvg} />{' '}
                          </div>
                        }
                      >
                        {this.filterCities().map((city) => (
                          <Option
                            value={get(city, '_id')}
                            style={{ textAlign: 'right' }}
                          >
                            {get(city, 'name')}
                          </Option>
                        ))}
                      </DropDown>
                    )}
                  </Col>
                </Row>
                <CustomDivider />

                <Row type='flex' justify='center' align='middle'>
                  <Col lg={6} sm={6} md={6} xs={24}>
                    <Label>القسم او المركز :</Label>
                  </Col>
                  <Col lg={18} sm={18} md={18} xs={24}>
                    {getFieldDecorator(
                      'address.district',
                      {}
                    )(
                      <DropDown
                        showArrow={false}
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder={
                          <div style={{ textAlign: 'right' }}>
                            {'  القسم او المركز'}
                            <StyledIcon component={DropdownSvg} />{' '}
                          </div>
                        }
                      >
                        {getFieldValue('address.city')
                          ? this.filterDistricts(
                              getFieldValue('address.city')
                            ).map((district) => (
                              <Option
                                value={get(district, '_id')}
                                style={{ textAlign: 'right' }}
                              >
                                {get(district, 'name.ar')}
                              </Option>
                            ))
                          : ''}
                      </DropDown>
                    )}
                  </Col>
                </Row>
                <CustomDivider />

                <Row type='flex' justify='center' align='middle'>
                  <Col lg={6} sm={6} md={6} xs={24}>
                    <Label> المنطقة :</Label>
                  </Col>
                  <Col lg={18} sm={18} md={18} xs={24}>
                    {getFieldDecorator(
                      'address.zone',
                      {}
                    )(
                      <DropDown
                        showArrow={false}
                        showSearch
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder={
                          <div style={{ textAlign: 'right' }}>
                            {' المنطقة'}
                            <StyledIcon component={DropdownSvg} />{' '}
                          </div>
                        }
                      >
                        {getFieldValue('address.district')
                          ? this.filterzones(
                              getFieldValue('address.district')
                            ).map((zone) => (
                              <Option
                                value={get(zone, '_id')}
                                style={{ textAlign: 'right' }}
                              >
                                {get(zone, 'name.ar')}
                              </Option>
                            ))
                          : ''}
                      </DropDown>
                    )}
                  </Col>
                </Row>
                <CustomDivider />

                <Row type='flex' justify='center' align='middle'>
                  <Col lg={6} sm={6} md={6} xs={24}>
                    <Label>الشارع :</Label>
                  </Col>
                  <Col lg={18} sm={18} md={18} xs={24}>
                    {getFieldDecorator('address.street')(
                      <TextArea placeholder='الشارع' />
                    )}
                  </Col>
                </Row>
                <CustomDivider />

                <Row type='flex' justify='center' align='middle'>
                  <Col lg={6} sm={6} md={6} xs={24}>
                    <Label>رقم المبني :</Label>
                  </Col>
                  <Col lg={18} sm={18} md={18} xs={24}>
                    {getFieldDecorator('address.buildingNumber')(
                      <TextArea placeholder='رقم المبني' />
                    )}
                  </Col>
                </Row>
                <CenterButton>
                  <RegisterButton
                    className='submit'
                    onClick={this.handleSubmit}
                  >
                    {' '}
                    سجل
                  </RegisterButton>
                </CenterButton>

                <Text>
                  **من خلال تقديم طلبك ، فإنك توافق على الشروط والأحكام وسياسات
                  الخصوصية والإرجاع الخاصة بنا. أنت توافق أيضًا على تخزين بعض
                  بياناتك بواسطة ريبزون ، والتي يمكن استخدامها لجعل تجارب التسوق
                  المستقبلية أفضل لك.{' '}
                </Text>
                <RegisterModal
                  title='Verification'
                  maskClosable={false}
                  visible={this.state.sendcodeModal}
                  footer={null}
                  onCancel={this.handleCancel}
                >
                  <Text>لقد تم ارسال الكود الي الرقم</Text>
                  <Text>
                    {'+2'.concat(this.state.values.accountPhoneNumber)}
                  </Text>
                  <Input
                    placeholder='ادخل كود التحقق'
                    onChange={(e) => this.setState({ valCode: e.target.value })}
                    value={this.state.valCode}
                  />
                  <Button
                    type='link'
                    onClick={this.handleResendCode}
                    // disabled={closeTime}
                  >
                    لم يصلك الكود قم باعادة الارسال
                  </Button>
                  <br />
                  <ConfirmButton onClick={this.handleVerifyPin}>
                    تأكيد
                  </ConfirmButton>
                </RegisterModal>
              </RegisterPanel>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}
const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(
  RegisterPage
);

const mapStateToProps = ({ main }) => main;
export default connect(mapStateToProps, {
  addGeneric,
  getGeneric,
  updateGeneric
})(WrappedNormalLoginForm);

const StyledIcon = styled(Icon)`
  float: left;
  font-weight: normal;
  margin-top: 3px;
  path {
    fill: ${(props) => props.theme.primary};
  }
`;
const Container = styled.div`
  .ant-input-affix-wrapper .ant-input:not(:first-child),
  .ant-input:placeholder-shown,
  .ant-select-selection--single {
    padding-left: 30px;
    border: 2px solid rgb(151, 151, 151);
    height: 40px !important;
    border-radius: 0;
  }
  .ant-input {
    border: 2px solid rgb(151, 151, 151);
    border-radius: 0;
    height: 40px !important;
  }
  .ant-select-selection--multiple .ant-select-selection__rendered {
    border: 2px solid rgb(151, 151, 151);
  }
  .ant-select-selection__rendered {
    border: 0;
  }
  .ant-select-no-arrow .ant-select-selection__rendered {
    margin-right: 0;
    margin-left: 0;
  }
  .ant-input:focus {
    border: 2px solid rgb(151, 151, 151);
    height: 40px !important;
  }
`;

const RegisterPanel = styled(Container)`
  background-color: white;
  padding: 7.5vh 2.5vw 0px 2.5vw;
  width: 100%;
  @media (max-width: 414px) {
    padding: 20px 0;
  }
`;

const DropDown = styled(Select)`
  direction: rtl !important;
  width: 100%;
  height: 40px;
  text-align: right !important;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: normal;
  & .ant-select-selection-selected-value {
    direction: rtl;
    float: right;
    margin-right: 10px;
  }
`;
const Label = styled.h1`
  font-size: 21px;
  margin: 0;
  line-height: normal;
  @media (max-width: 414px) {
    margin-bottom: 15px;
    text-align: right;
    font-size: 19px;
  }
`;
const TextArea = styled(Input)`
  .ant-input-affix-wrapper .ant-input {
    width: 100%;
    height: 40px;
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: normal;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(151, 151, 151);
    border-image: initial;
  }
`;
const Documents = styled.h1`
  color: #11192d;
  text-align: right;
  background: #e1e5ea;
  padding: 20px;
  font-size: 24px;
  font-family: 'Cairo-Bold';
  margin-bottom: 30px;
  @media (max-width: 414px) {
    margin-bottom: 10px;
    text-align: right;
    padding: 10px;
    margin: 30px 0;
    font-size: 22px;
  }
`;

const RegisterButton = styled(CustomButton)`
  border-radius: 30px;
  clear: both;
  background-color: #4a7fbd;
  color: white;
  padding: 0;
  border: solid 0.15vw #4a7fbd;
  font-weight: bold;
  padding: 11px;
  height: inherit;
  width: 300px;
  margin-top: 20px;
  font-weight: bold;
  font-size: 15px;
  font-family: Cairo-Bold;
  margin: 40px 0;
`;
const ConfirmButton = styled(CustomButton)`
  border-radius: 30px;
  font-size: 1.3vw;
  width: 124px;
  font-size: 17px;
  padding: 0px 0;
  height: 40px;
`;
const Text = styled.div`
  text-align: right;
  font-size: 17px;
  margin: 0px 0;
  clear: both;
  color: #10182d;
  margin-bottom: 5px;
`;
const LoginButton = styled(CustomButton)`
  text-align: right;
  color: #4a7fbd;
  font-size: 1.7vw;
  background-color: white;
  border-color: white;
`;
const Wrapper = styled.div`
  padding-left: 1vw;
  padding-right: 1vw;
`;
const StyledDropzone = styled(DropzoneS3Uploader)`
  border: 2px solid #f1f4f5 !important;
  width: 100px !important;
  max-width: 100%;
  height: 100px !important;
  margin: 0 auto;
`;

const Registerholder = styled.div`
  text-align: right;

  a {
    font-size: 17px;
    font-weight: bold;
    color: #4a7fbd;
    margin-right: 10px;
  }
  text-align: center;
  p {
    display: inline-block;
    font-size: 22px;
  }
`;

const CenterButton = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
`;

const RegisterModal = styled(Modal)`
  .ant-modal-body {
    height: 260px;
    padding: 15px;
  }
  input.ant-input {
    text-align: right;
  }

  .ant-input:placeholder-shown {
    text-align: right;
    text-overflow: ellipsis;
    height: 40px !important;
    margin-top: 10px;
    border: 2px solid rgb(151, 151, 151);
    border-radius: 0;
  }
`;
