/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Row, Col, message } from 'antd';
import { connect } from 'react-redux';
import { get } from 'lodash';

import {
  getObject,
  addToCart,
  checkBuyerCategoryWithSeller,
  getSavedPrice
} from '../services/CommonServices';
import { setLoading } from '../appRedux/actions/Common';
import { updateUser } from '../appRedux/actions/Auth';
import { getCart } from '../appRedux/actions/Cart';
import { getGeneric } from '../appRedux/actions/Generic';
import OfferDetailsModal from './OfferDetailsModal.jsx';
import HandshakeModal from './HandshakeModal.jsx';

const LandingPageOfferCard = (props) => {
  const [title, setTitle] = useState(get(props, 'offer.name'));
  const [images, setImages] = useState(get(props, 'offer.images'));
  const [info, setInfo] = useState('');
  const [offer, setOffer] = useState(props.offer);
  const [visible, setVisible] = useState(false);
  const [offerProduct, setOfferProduct] = useState();
  const [quantityCarton, setQuantityCarton] = useState(0);
  const [quantityPackage, setQuantityPackage] = useState(0);
  const [addOns, setAddOns] = useState([]);
  const [addOnObjects, setAddOnObjects] = useState([]);
  const [distributor, setDistributor] = useState();
  const [company, setCompany] = useState();
  const [handshake, setHandshake] = useState(false);
  const [buyer, setBuyer] = useState(get(props, 'auth.user', {}));
  const [zone, setZone] = useState(get(props, 'auth.user.address.zone', {}));
  const [loading, setLoading] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [saved, setSaved] = useState(1);
  useEffect(() => {
    setLoading(true);
    setLoadingDetails(true);
    setOffer(props.offer);
    setTitle(get(props, 'offer.name'));
    setImages(get(props, 'offer.images'));
    if (get(props, 'offer.optional_3') === 'carton') {
      setQuantityCarton(get(props, 'offer.optional_1'));
    } else {
      setQuantityPackage(get(props, 'offer.optional_1'));
    }
    getObject('companyProducts/product', {
      _id: get(props, 'offer.product_id')
    }).then((res) => {
      setLoadingDetails(false);
      setOfferProduct(res.data);
      getSavedPrice(res.data, setSaved, props.offer);
    });
    if (get(props, 'auth.user')) {
      getObject('companyProducts/productDistributorInZone', {
        _id: get(props, 'offer.product_id'),
        zone: get(props, 'auth.user.address.zone._id')
      }).then((res) => {
        setDistributor(
          get(res, 'data.distributor.distributor', get(res, 'data.distributor'))
        );
        setCompany(get(res, 'data.company'));
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    if (offer.bundles) {
      const products = offer.bundles.map((bundle) => bundle.addOnProduct);
      getObject('companyProducts/offerAddons', {
        products
      }).then((res) => {
        setAddOns(res.data);
      });
    }
    if (get(props, 'offer.type') === 3) {
      setInfo('واحصل على منتجات هدية');
    } else if (get(props, 'offer.type') === 1) {
      const quantityInfo = ` وفر ${saved.toFixed(2)} جنيه`;
      setInfo(quantityInfo);
    } else {
      const discountInfo = `خصم ${get(props, 'offer.optional_2', 0)}%`;
      setInfo(discountInfo);
    }
  }, [props.offer, saved]);

  useEffect(() => {
    setBuyer(get(props, 'auth.user', {}));
    setZone(get(props, 'auth.user.address.zone', {}));
  }, [props.auth]);

  useEffect(() => {
    if (addOns.length) {
      const addOnObjects = [];
      // eslint-disable-next-line array-callback-return
      addOns.map((addOn) => {
        // eslint-disable-next-line array-callback-return
        get(offer, 'bundles', []).map((bundle) => {
          if (bundle.addOnProduct === addOn._id) {
            const addOnObject = {
              addOnProduct: true,
              _id: addOn._id,
              name: { ar: addOn.name.ar },
              images: addOn.images,
              quantityCarton: bundle.package === 'carton' ? bundle.quantity : 0,
              quantityPackage: bundle.package === 'unit' ? bundle.quantity : 0,
              buyerCategories: addOn.buyerCategories,
              packageUnit: addOn.packageUnit
            };
            addOnObjects.push(addOnObject);
          }
        });
      });
      setAddOnObjects(addOnObjects);
    }
  }, [addOns]);
  const addOfferToCart = () => {
    if (!props.auth.token) {
      window.scrollTo(0, 0);
      props.history.push('/login');
    } else {
      const buyerCategory = checkBuyerCategoryWithSeller({
        distributor,
        buyer,
        company
      });
      const offerBuyerCategory = offer.buyer_cat;
      if (!buyerCategory) {
        setHandshake(true);
      } else {
        if (buyerCategory !== offerBuyerCategory) {
          message.warn(' هذا العرض غير متوفر لقطاع سعرك ! ');
        } else {
          addToCart({
            props,
            companyProduct: offer,
            offer,
            company: company._id,
            distributor,
            distributorProduct: null,
            countPackage: undefined,
            countCarton: 1,
            carton: undefined,
            offerProduct
          });
        }
      }
    }
  };

  return (
    <React.Fragment>
      <OfferDetailsModal
        offer={offer}
        visible={visible}
        setVisible={setVisible}
        product={offerProduct}
        quantityCarton={quantityCarton}
        quantityPackage={quantityPackage}
        addOns={addOnObjects}
      />
      <HandshakeModal
        visible={handshake}
        setVisible={setHandshake}
        distributor={distributor}
        company={company}
        zone={zone}
        buyer={buyer}
      />

      <OfferItem>
        <Row>
          <Col span={14}>
            <Link
              to={{
                pathname: `/singleproduct`,
                search: `product=${get(props, 'offer.product_id')}&zone=${get(
                  props,
                  'auth.user.address.zone._id'
                )}`,
                state: {
                  productId: get(props, 'offer.product_id'),
                  zone: get(props, 'auth.user.address.zone._id')
                }
              }}
            >
              <OfferText> {` ${title} `}</OfferText>
            </Link>
            <OfferInfo> {` ${info} `}</OfferInfo>
            <OfferAnchor onClick={() => addOfferToCart()} disabled={loading}>
              {' '}
              اشتري الان{' '}
            </OfferAnchor>

            <Offerdetail>
              <a onClick={() => setVisible(true)} disabled={loadingDetails}>
                تفاصيل العرض
              </a>
            </Offerdetail>
          </Col>
          <Col span={10}>
            <OfferImage
              src={get(
                images,
                '0',
                get(
                  offerProduct,
                  'images.0',
                  require('../assets/images/repzone-placeholder.png')
                )
              )}
            />
          </Col>
        </Row>
      </OfferItem>
    </React.Fragment>
  );
};
const mapStateToProps = ({ main, auth, commonData }) => ({
  main,
  auth,
  commonData
});
export default connect(mapStateToProps, {
  updateUser,
  getGeneric,
  setLoading,
  getCart
})(LandingPageOfferCard);

const OfferImage = styled.img`
  width: 100%;
  height: 85px;
  object-fit: contain;
`;

const OfferText = styled.text`
  font-size: 17px;
  font-family: 'Cairo-Bold';
  display: block;
  color: #10182d;
  text-align: right;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: rtl;
`;

const OfferInfo = styled.text`
  font-size: 15px;
  font-family: 'Cairo-Bold';
  display: block;
  color: #ffffff;
  text-align: right;
  margin-top: 6px;
  background: #7099c8;
  border-radius: 10px;
  padding: 1px;
  padding: 3px 11px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
`;

const OfferAnchor = styled.a`
  font-size: 14px;
  color: #10182d;
  border-radius: 50px;
  margin-left: 20px;
  margin-top: 19px;
  float: left;
`;

const OfferItem = styled.div`
  margin: 30px 0;
  border: 1px solid #10182d8a;
  padding: 15px 0;
  background: #fff;
  position: relative;
  @media (max-width: 414px) {
    border: 0;
  }

  .ribbon {
    margin: 10px 0;
  }
  .ribbon4 {
    font-weight: bold;
    border-radius: 6px;
    background: #4b80be;
    padding: 4px 10px;
    box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.3);
    left: 0;
    top: 0;
    color: #fff;
    float: right;
    font-family: 'Cairo-Bold';
    margin-top: 19px;
  }
  .ribbon4:before,
  .ribbon4:after {
    content: '';
    position: absolute;
  }
  .ribbon4:before {
    height: 100%;
    top: 0;
    left: -6.5px;
    padding: 0 0 7px;
    background: inherit;
    border-radius: 5px 0 0 5px;
  }
  .ribbon4:after {
    width: 5px;
    height: 5px;
    bottom: -5px;
    left: -4.5px;
    // background: lightblue;
    border-radius: 5px 0 0 5px;
  }

  @media (min-width: 500px) {
    .ribbons-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .ribbon {
    }
  }
`;

const Offerdetail = styled.div`
  margin-top: 20px;
  text-align: right;
  a {
    color: #10182d;
    font-family: 'Cairo-SemiBold';
  }
`;
