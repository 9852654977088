/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable import/extensions */
import React from 'react';
import { Row, Col, Input, message, Icon, Form, Modal, Button } from 'antd';
import styled from 'styled-components';
import CustomDivider from '../elements/CustomDivider';
import CustomButton from '../elements/CustomButton';
import {
  postObject,
  getObject,
  showNotification
} from '../services/CommonServices';

class ResetPass extends React.Component {
  state = {
    phone_number_login: '',
    password_login: '',
    phone_number_signup: '',
    password_signup1: '',
    password_signup2: '',
    sendcodeModal: false,
    values: {}
  };

  handleLogin(e, form) {
    e.preventDefault();
    form.validateFields((err, fieldsValue) => {
      // if (err) {
      //   return;
      // }
      // if (String(fieldsValue.password) !== String(fieldsValue.rePassword)) {
      //   message.error("Passwords don't match");
      //   return;
      // }
      getObject('buyers/verifyuser', {
        phone: fieldsValue.accountPhoneNumber
      }).then((response) => {
        if (response.data.find) {
          this.setState({ values: fieldsValue, sendcodeModal: true });
          postObject('buyers/sendpin', {
            phone: fieldsValue.accountPhoneNumber
          }).then((response) => {
            this.setState({ pinId: response.data.message.pinId });
          });
        } else {
          message.error('لا يوجد مستخدم');
        }
      });
    });
  }

  handleVerifyPin = () => {
    postObject('buyers/verifypin', {
      pinId: this.state.pinId,
      pin: this.state.valCode
    }).then((response) => {
      if (response.data.success === true) {
        showNotification(
          `${response.data.message}   `,
          <Icon type='smile' twoToneColor='#52c41a' theme='twoTone' />
        );
        postObject('buyers/reset', {
          phone: this.state.values.accountPhoneNumber,
          password: this.state.values.password
        })
          .then((response) => {
            showNotification(
              `${response.data.message}   `,
              <Icon type='smile' twoToneColor='#52c41a' theme='twoTone' />
            );
            this.props.history.push('/login');
          })
          .catch((error) => {
            showNotification(
              `${error.data.message}   `,
              <Icon type='frown' twoToneColor='#eb2f96' theme='twoTone' />
            );
          });
      } else {
        showNotification(
          `${response.data.message}   `,
          <Icon type='frown' twoToneColor='#eb2f96' theme='twoTone' />
        );
      }
    });
  };

  handleResendCode = () => {
    postObject('buyers/resendpin', {
      pinId: this.state.pinId
    })
      .then((response) => {
        if (response.data.success === true) {
          showNotification(
            `${response.data.message}   `,
            <Icon type='smile' twoToneColor='#52c41a' theme='twoTone' />
          );
        } else {
          showNotification(
            `${response.data.message}   `,
            <Icon type='frown' twoToneColor='#eb2f96' theme='twoTone' />
          );
        }
      })
      .catch((error) => {});
  };

  handleCancel = (e) => {
    this.setState({
      sendcodeModal: false,
      valCode: ''
    });
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;

    return (
      //   <div className='Login Reset'>
      //     <div className='login-area gray-bg'>
      //       <div className='container'>
      //         <Row>
      //           <Col mdOffset={3} md={6}>
      //             <div className='login-content mb-30'>
      //               <h2 className='login-title'>Reset Password</h2>
      //               <form onSubmit={this.handleLogin.bind(this)}>
      //                 <label>Phone Number</label>
      //                 <Input
      //                   type='text'
      //                   onChange={(e) => this.setState({ phone: e.target.value })}
      //                   placeholder='Enter your phone number'
      //                 />
      //                 <label>Password</label>
      //                 <Input
      //                   type='password'
      //                   onChange={(e) =>
      //                     this.setState({ password_1: e.target.value })
      //                   }
      //                   placeholder='******'
      //                 />
      //                 <label>Confirm Password</label>
      //                 <Input
      //                   type='password'
      //                   onChange={(e) =>
      //                     this.setState({ password_2: e.target.value })
      //                   }
      //                   placeholder='******'
      //                 />
      //                 <input
      //                   className='login-sub main-btn'
      //                   type='submit'
      //                   value='Login'
      //                 />
      //               </form>
      //             </div>
      //           </Col>
      //         </Row>
      //       </div>
      //     </div>
      //   </div>
      <form>
        <Container>
          <div className='container'>
            <Row>
              <Col lg={{ span: 16, offset: 4 }} xs={{ span: 24, offset: 0 }}>
                <RestHolder style={{ background: 'white' }}>
                  <Row>
                    <span>
                      {' '}
                      <p>ليس لديك حساب؟</p>
                    </span>
                    <span>
                      {' '}
                      <a href='/register'>سجل الآن</a>
                    </span>
                  </Row>
                  <CustomDivider />
                </RestHolder>

                <Documents>البيانات :</Documents>

                <LoginPanel onSubmit={(e) => this.handleLogin(e, form)}>
                  <Row
                    style={{ width: '100%' }}
                    type='flex'
                    justify='center'
                    align='middle'
                  >
                    <Col lg={6} sm={6} md={6} xs={24}>
                      <Label>رقم الموبايل :</Label>
                    </Col>
                    <Col lg={18} sm={18} md={18} xs={24}>
                      {getFieldDecorator('accountPhoneNumber', {
                        rules: [
                          {
                            required: true,
                            message: 'من فضلك ادخل رقم الموبايل'
                          }
                        ]
                      })(
                        <TextArea
                          // autocomplete='new-phone'
                          prefix={<Icon type='user' />}
                          type='text'
                          onChange={(e) =>
                            this.setState({ phone: e.target.value })
                          }
                          placeholder='ادخل رقم الهاتف'
                        />
                      )}
                    </Col>
                  </Row>

                  <CustomDivider />

                  <Row
                    style={{ width: '100%' }}
                    type='flex'
                    justify='center'
                    align='middle'
                  >
                    <Col lg={6} sm={6} md={6} xs={24}>
                      <Label>كلمة السر :</Label>
                    </Col>
                    <Col lg={18} sm={18} md={18} xs={24}>
                      {getFieldDecorator('password', {
                        rules: [
                          {
                            required: true,
                            message: 'Please enter your password!'
                          }
                        ]
                      })(
                        <TextArea
                          autocomplete='new-password'
                          prefix={<Icon type='lock' />}
                          type='password'
                          placeholder='كلمة السر'
                        />
                      )}
                    </Col>
                  </Row>
                  <CustomDivider />

                  <Row
                    style={{ width: '100%' }}
                    type='flex'
                    justify='center'
                    align='middle'
                  >
                    <Col lg={6} sm={6} md={6} xs={24}>
                      <Label> تاكيد كلمة السر :</Label>
                    </Col>
                    <Col lg={18} sm={18} md={18} xs={24}>
                      {getFieldDecorator('rePassword', {
                        rules: [
                          {
                            required: true,
                            message: 'Please re-enter your password!'
                          }
                        ]
                      })(
                        <TextArea
                          autocomplete='new-password'
                          prefix={<Icon type='lock' />}
                          type='password'
                          placeholder='كلمة السر'
                        />
                      )}
                    </Col>
                  </Row>
                  <CustomDivider />
                  <CenterButton>
                    <LoginButton type='primary' htmlType='submit'>
                      تسجيل الدخول
                    </LoginButton>
                  </CenterButton>

                  <Text>
                    **من خلال تقديم طلبك ، فإنك توافق على الشروط والأحكام
                    وسياسات الخصوصية والإرجاع الخاصة بنا. أنت توافق أيضًا على
                    تخزين بعض بياناتك بواسطة ريبزون ، والتي يمكن استخدامها لجعل
                    تجارب التسوق المستقبلية أفضل لك.{' '}
                  </Text>
                </LoginPanel>
                <RestPassModal
                  title='Verification'
                  maskClosable={false}
                  visible={this.state.sendcodeModal}
                  footer={null}
                  onCancel={this.handleCancel}
                >
                  <Text>لقد تم ارسال الكود الي الرقم</Text>
                  <Text>{`+2${this.state.values.accountPhoneNumber}`}</Text>
                  <Input
                    placeholder='ادخل كود التحقق'
                    onChange={(e) => this.setState({ valCode: e.target.value })}
                    value={this.state.valCode}
                  />
                  <Row>
                    <Button
                      type='link'
                      onClick={this.handleResendCode}
                      // disabled={closeTime}
                    >
                      لم يصلك الكود قم باعادة الارسال
                    </Button>
                    <br />
                    <ConfirmButton onClick={this.handleVerifyPin}>
                      تأكيد
                    </ConfirmButton>
                  </Row>
                </RestPassModal>
              </Col>
            </Row>
          </div>
        </Container>
      </form>
    );
  }
}
const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(ResetPass);

export default WrappedNormalLoginForm;

const Container = styled.div`
  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 30px;
    border: 2px solid rgb(151, 151, 151);
    height: 40px;
    border-radius: 0;
  }
  input.ant-input {
    text-align: right;
  }
`;

const LoginPanel = styled.form`
  background-color: white;
  width: 100%;
  a {
    margin: 30px 0;
    font-size: 23px;
    color: #4a7fbd;
  }
  input.ant-input {
    border: 2px solid rgb(151, 151, 151);
    padding: 21px;
    text-align: right;
  }
`;

const Label = styled.h1`
  font-size: 21px;
  margin: 0;
  line-height: normal;
  @media (max-width: 414px) {
    margin-bottom: 15px;
    text-align: right;
    font-size: 19px;
  }
`;

const TextArea = styled(Input)`
  .ant-input-affix-wrapper .ant-input {
    width: 100%;
    height: 40px;
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: normal;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(151, 151, 151);
    border-image: initial;
  }
`;

const Documents = styled.h1`
  color: #11192d;
  text-align: right;
  background: #e1e5ea;
  /* padding-right: 2vw; */
  padding: 20px;
  font-family: 'Cairo-Bold';
  margin-bottom: 30px;
  font-size: 24px;
  @media (max-width: 414px) {
    margin-bottom: 10px;
    text-align: right;
    padding: 10px;
    margin: 30px 0;
    font-size: 22px;
  }
`;

const LoginButton = styled(Button)`
  border-radius: 30px;
  clear: both;
  background-color: #4a7fbd;
  color: white;
  padding: 0;
  border: solid 0.15vw #4a7fbd;
  font-weight: bold;
  padding: 11px;
  height: inherit;
  width: 260px;
  margin-top: 20px;
  font-weight: bold;
  font-size: 15px;
  font-family: Cairo-Bold;
`;

const Text = styled.div`
  text-align: center;
  font-size: 16px;
  margin: 5px 0;
  clear: both;
  color: #10182d;
`;
const RegisterButton = styled(CustomButton)`
  text-align: right;
  color: #4a7fbd;
  font-size: 1.7vw;
  background-color: white;
  border-color: white;
`;
const ConfirmButton = styled(CustomButton)`
  border-radius: 30px;
  font-size: 1.3vw;
  width: 124px;
  font-size: 17px;
  padding: 0px 0;
  height: 40px;
`;

const CenterButton = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
`;

const RestHolder = styled.div`
  text-align: right;

  a {
    font-size: 17px;
    font-weight: bold;
    color: #4a7fbd;
    margin-right: 10px;
  }
  text-align: center;
  p {
    display: inline-block;
    font-size: 22px;
  }
`;
const RestPassModal = styled(Modal)`
  input.ant-input {
    text-align: right;
    padding: 20px;
  }
  input.ant-input {
    text-align: right;
  }
  .ant-input:placeholder-shown {
    text-align: right;
    text-overflow: ellipsis;
    height: 40px !important;
    margin-top: 10px;
    border: 2px solid rgb(151, 151, 151);
    border-radius: 0;
  }
`;
