import {
  GET_CART,
  GET_CART_SUCCESS,
  SHOW_MESSAGE,
  EMPTY_CART
} from '../../constants/ActionTypes';

export const getCart = () => ({
  type: GET_CART
});

export const getCartSuccess = (cart) => ({
  type: GET_CART_SUCCESS,
  payload: cart
});
export const emptyCart = () => ({
  type: EMPTY_CART
});

export const showCartMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};
