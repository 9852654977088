/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import queryString from 'query-string';
import BreadCrumbs from '../elements/BreadCrumbs.jsx';

const SideFiltersBreadCrumbs = (props) => {
  const query = queryString.parse(props.location.search);
  const linksData = [
    query.sections,
    query.categories,
    query.subCategories,
    query.types,
    query.subTypes
  ];
  const [links, setlinks] = React.useState([]);
  const [linkTitles, setlinkTitles] = React.useState([]);
  const temp1 = [];
  const temp2 = [];

  React.useEffect(() => {
    let copyQuery = {};
    for (let index = 0; index < linksData.length; index += 1) {
      let element = linksData[index];

      if (element) {
        copyQuery = Object.assign({}, query);
        delete copyQuery.selected;
        [copyQuery.taxonomy, copyQuery.type] = element;
        element = queryString.stringify(copyQuery);
      }
      temp1[index] = element;
      if (element && query.type === linksData[index][1]) break;
    }
    copyQuery = {};
    copyQuery.type = 'sections';
    delete copyQuery.selected;
    copyQuery.taxonomy = query.taxonomy;
    copyQuery.brand = query.brand;
    copyQuery.page = query.page;
    copyQuery.company = query.company;
    copyQuery.featured = query.featured ? true : undefined;
    if (query.company || query.brand || query.featured)
      temp1.unshift(queryString.stringify(copyQuery));
    setlinks(temp1);

    for (let index = 0; index < linksData.length; index += 1) {
      let element = linksData[index];

      if (element) {
        // eslint-disable-next-line prefer-destructuring
        element = element[2];
      }
      temp2[index] = element;
      if (element && query.type === linksData[index][1]) break;
    }
    if (query.company || query.brand || query.featured)
      temp2.unshift('الرئيسية');
    setlinkTitles(temp2);
  }, []);

  return <BreadCrumbs {...props} links={links} linkTitles={linkTitles} />;
};
export default SideFiltersBreadCrumbs;
