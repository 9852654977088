/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Row, Col, Icon } from 'antd';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { IconContext } from 'react-icons/lib';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { getGeneric, updateGeneric } from '../appRedux/actions/Generic';
import { updateUser, userSignOut } from '../appRedux/actions/Auth';
import { emptyCart } from '../appRedux/actions/Cart';
import { getObject } from '../services/CommonServices';

const HeaderLeft = styled.div`
  float: left;
  margin-top: 2px;
  img {
    height: 29px;
    margin-right: 20px;
    object-fit: cover;
  }
  span {
    color: #fff;
    font-size: 14px;
    @media (max-width: 414px) {
      font-size: 12px;
    }
  }
`;
const HeaderRight = styled.div`
  float: right;
  @media (max-width: 414px) {
    display: none;
  }
  img {
    height: 20px;
    margin: 4px 0px;
    margin-left: 15px;
  }
`;
const StyledHeader = styled.div`
  @media (max-width: 414px) {
    display: none;
  }
  width: 100%;
  background-color: #11192d;
  padding: 5px 0;

  svg {
    fill: #fff;
    color: #fff;
    font-size: 23px;
    margin-left: 5px;
    border-radius: 50px;
    padding: 4px;
    margin-right: 5px;
  }
`;
const Title = styled.span`
  color: #ffffff;
  line-height: 30px;
`;

const IconContainer = styled.div`
  display: inline-flex;
  margin-left: 10px;
  a {
    color: #ffff;
  }
`;

const HeaderPhone = styled.div`
  line-height: 30px;
  a {
    color: #fff;
  }
`;

const NavIcon = styled.span`
  margin-left: 2rem;
  display: inline-flex;
  cursor: pointer;
`;
const CloseIcon = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  margin-right: auto;
`;

const SidebarNav = styled.nav`
  background: #fff;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  border: solid #11192d;
  border: 0px 2px;
  border-width: thin medium;
  position: fixed;
  top: 0;
  right: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  transition: 350ms;
  z-index: 100000;
  overflow-x: hidden;
  overflow-y: auto;
  svg {
    fill: #11192d;
    color: #11192d;
  }
`;

const HelloUser = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2px;
  padding: 2px;
  border-bottom: ${({ user }) => (user ? '1px solid #11192d;' : '')};
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const SidebarLink = styled(Link)`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;

  &:hover {
    background: #d3d3d3;
    border-left: 4px solid #505050;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
  color: #11192d;
  display: inline-block;
`;

const SubMenuText = styled.span`
  margin-left: 16px;
  color: #11192d;
  display: inline-block;
  margin: 5px;
  border: 2px solid #d3d3d3;
  border-radius: 3px;
  padding: 3px 3px;
  background: #d3d3d3;
`;

const BuyerName = styled.div`
  font-size: 25px;
  @media (max-width: 768px) {
    font-size: 19px;
  }
  display: flex;

  justify-content: center;
  margin-right: 23px;

  a {
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #11192d;
  }
`;

const SubMenuDiv = styled.div`
  display: flex;
  padding: 1px;
  color: #11192d;
  display: inline-block;
  // justify-content: center;
  margin-right: 25px;
  &:hover {
    cursor: pointer;
  }
`;

const LoginHolder = styled.div`
  margin-right: auto;
  margin-bottom: auto;

  justify-content: center;
  a {
    font-size: 12px;
    font-weight: bold;
    color: #4a7fbd;
    margin-right: 10px;
  }
  text-align: center;
  p {
    display: inline-block;
    font-size: 17px;
  }
`;
const Header = (props) => {
  const history = useHistory();

  const SidebarData = [
    {
      title: 'بياناتى',
      path: '/profile',
      icon: <AiIcons.AiFillIdcard />
    },
    {
      title: 'طلباتى',
      path: '/profile',
      icon: <FaIcons.FaCartPlus />
    },
    {
      title: 'الشركات',
      path: '/allcompanies',
      icon: <AiIcons.AiFillHome />
    }
  ];
  const [sidebar, setSidebar] = useState(false);
  const [subMenu, setSubMenu] = useState(false);
  const [mainLoader, setMainLoader] = useState(true);

  const showSidebar = () => setSidebar(!sidebar);
  const distributorsSelector = (props) =>
    get(props, 'auth.user.distributors', []);

  return (
    <React.Fragment>
      <StyledHeader>
        <div className='container'>
          <Row>
            <Col lg={14} md={14} xs={24} sm={14}>
              <HeaderLeft>
                <span> احصل علي التطبيق</span>
                <span>
                  <a href='#'>
                    <img src={require('../assets/images/appstore.png')} />
                  </a>
                </span>
                <span>
                  <a href='#'>
                    <img src={require('../assets/images/gplay.png')} />
                  </a>
                </span>
              </HeaderLeft>
            </Col>
            <Col lg={10} md={24} xs={24} sm={10}>
              <HeaderRight>
                <IconContainer>
                  <IconContext.Provider value={{ color: '#11192d' }}>
                    <NavIcon>
                      <img
                        src={require('../assets/icons/bars-solid.svg')}
                        onClick={showSidebar}
                      />
                    </NavIcon>

                    <SidebarNav sidebar={sidebar}>
                      <SidebarWrap>
                        <HelloUser user={get(props, 'auth.token')}>
                          {get(props, 'auth.token') ? (
                            <div>
                              <span>مرحبا</span>
                              <BuyerName>
                                <a href='/profile'>
                                  {get(props, 'auth.user.name.ar', '')}
                                </a>
                              </BuyerName>
                            </div>
                          ) : (
                            ''
                          )}

                          <CloseIcon to='#'>
                            <FaIcons.FaWindowClose
                              size={25}
                              style={{ borderRadius: '8px' }}
                              onClick={showSidebar}
                            ></FaIcons.FaWindowClose>
                          </CloseIcon>
                        </HelloUser>

                        {get(props, 'auth.token')
                          ? SidebarData.map((item, index) => {
                              return (
                                <SidebarLink
                                  item={item}
                                  key={index}
                                  to={item.path}
                                  onClick={() => setSidebar(!sidebar)}
                                >
                                  <div>
                                    {item.icon}
                                    <SidebarLabel>{item.title}</SidebarLabel>
                                  </div>
                                </SidebarLink>
                              );
                            })
                          : ''}
                        {get(props, 'auth.token') ? (
                          <SidebarLink
                            onClick={(e) => {
                              e.preventDefault();
                              setSubMenu(!subMenu);
                            }}
                          >
                            <div>
                              <FaIcons.FaBuilding />
                              <SidebarLabel>شركاتى</SidebarLabel>
                            </div>
                          </SidebarLink>
                        ) : (
                          <LoginHolder>
                            <p>ليس لديك حساب؟</p>
                            <a href='/register'>سجل الآن</a>
                          </LoginHolder>
                        )}
                        {subMenu === true ? (
                          <>
                            <SubMenuDiv>
                              <>
                                {distributorsSelector !== '' &&
                                get(props, 'auth.token')
                                  ? distributorsSelector(props).map(
                                      (company, index) => {
                                        return (
                                          <SubMenuText
                                            onClick={() => setSidebar(!sidebar)}
                                            key={index}
                                          >
                                            <Link
                                              style={{
                                                color: '#11192d',
                                                marginLeft: 10
                                              }}
                                              to={{
                                                pathname: '/company',
                                                search: `company=${company.distributor.company}`
                                              }}
                                            >
                                              {
                                                company.distributor.companyName
                                                  .ar
                                              }
                                            </Link>
                                          </SubMenuText>
                                        );
                                      }
                                    )
                                  : ''}
                              </>
                            </SubMenuDiv>
                          </>
                        ) : (
                          ''
                        )}

                        <SidebarLink onClick={() => setSidebar(!sidebar)}>
                          <div>
                            {get(props, 'auth.token') ? (
                              <FaIcons.FaSignOutAlt />
                            ) : (
                              <FaIcons.FaSignInAlt />
                            )}

                            <SidebarLabel
                              onClick={() => {
                                props.userSignOut({ history });
                                props.emptyCart();
                              }}
                            >
                              {get(props, 'auth.user.name.ar', '')
                                ? 'تسجيل  الخروج'
                                : 'تسجيل الدخول '}
                            </SidebarLabel>
                          </div>
                        </SidebarLink>
                      </SidebarWrap>
                    </SidebarNav>
                  </IconContext.Provider>
                </IconContainer>
                <Title>
                  <IconContainer>
                    <img src={require('../assets/images/mail.svg')} />
                    <span>
                      <a href='mailto:  info@repzone.org'> info@repzone.org</a>
                    </span>
                  </IconContainer>
                </Title>
                <Title>
                  <IconContainer>
                    <img src={require('../assets/images/phone.svg')} />

                    <HeaderPhone>
                      <span>
                        <a href='tel:16785'>16785</a>{' '}
                      </span>
                    </HeaderPhone>
                  </IconContainer>
                </Title>
              </HeaderRight>
            </Col>
          </Row>
        </div>
      </StyledHeader>
    </React.Fragment>
  );
};

const mapStateToProps = ({ main, auth, cart }) => ({ main, auth, cart });
export default connect(mapStateToProps, {
  getGeneric,
  updateGeneric,
  updateUser,
  userSignOut,
  emptyCart
})(Header);
