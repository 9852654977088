/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
import React from 'react';
import { Icon, Row, Col } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Cart from '../icons/CartSvg';

const Text = styled.div`
  font-size: 1.5vw;
  @media (max-width: 768px) {
    font-size: 16px;
  }

  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.primary};
  margin-bottom: 2vw;
  margin-top: 2vw;
`;
const StyledIcon = styled(Icon)`
  font-size: 12.4vw;
  @media (max-width: 767px) {
    font-size: 80px;
    margin-top: 30px;
  }
  & .cls-1 {
    fill: ${(props) => props.theme.primary};
  }
`;

const ButtonText = styled.div`
  background: #4a7fbd;
  width: 50%;
  margin: 0 auto;
  display: block;
  border-radius: 50px;
  padding: 13px;
  font-size: 18px;
  font-family: 'Cairo-Bold';
  transition: 0.3s;
  text-align: center;
  color: #fff;
  @media (max-width: 767px) {
    width: 100%;
    margin-top: 20px;

    padding: 7px;
    font-size: 16px;
  }
  :hover {
    background: #fff;
    color: #4a7fbd;
    border: 1px solid #4a7fbd;
  }
`;
const CartEmptyHolder = styled.div`
  padding-top: 3vw;
  text-align: center;
  padding-bottom: 3vw;
  @media (max-width: 767px) {
    margin: 50px 0;
  }
`;

const CartEmprtyHolder = styled.div``;
const CartEmpty = (props) => (
  <CartEmptyHolder>
    <div className='container'>
      <Row>
        <Col span={12} offset={6}>
          <StyledIcon component={Cart} />
          <Text>عربة التسوق فارغة</Text>
          <CartEmprtyHolder>
            <div onClick={() => props.history.goBack()}>
              <ButtonText> متابعة التسوق</ButtonText>
            </div>
          </CartEmprtyHolder>
        </Col>
      </Row>
    </div>
  </CartEmptyHolder>
);

export default CartEmpty;
