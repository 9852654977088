import { all } from 'redux-saga/effects';
import authSagas from './Auth';
import genericSaga from './Generic';
import cartSaga from './Cart';
import productsSaga from './Products';
import wishlistSaga from './Wishlist';

export default function* rootSaga() {
  yield all([
    authSagas(),
    genericSaga(),
    cartSaga(),
    productsSaga(),
    wishlistSaga()
  ]);
}
