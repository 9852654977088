const config = {};
if (process.env.NODE_ENV === 'development') {
  // config.url = 'https://api.dev.repzone.qpix.io/';
  config.url = 'http://localhost:8000/';
} else {
  config.url = 'https://api.dev.repzone.qpix.io/';
  // config.url = 'https://api.repzone.qpix.io/';
}

export default config;
