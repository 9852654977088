import React from 'react';

const CancelSvg = () => (
  <svg
    height='1em'
    width='1em'
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 99.75 99.75'
  >
    <defs>
      <style dangerouslySetInnerHTML={{ __html: '.cls-1{fill:#4c7fba;}' }} />
    </defs>
    <title>cancel</title>
    <path
      className='cls-1'
      d='M385.2,261.32a49.9,49.9,0,1,0,70.56.53A50,50,0,0,0,385.2,261.32Zm66.94,68a45.5,45.5,0,1,1,.49-64.34A45.55,45.55,0,0,1,452.15,329.28Z'
      transform='translate(-370.34 -247)'
    />
    <path
      className='cls-1'
      d='M400.79,277.15a2.2,2.2,0,0,0,0,3.11l35.55,36.09a2.2,2.2,0,1,0,3.13-3.09L403.9,277.18A2.2,2.2,0,0,0,400.79,277.15Z'
      transform='translate(-370.34 -247)'
    />
    <path
      className='cls-1'
      d='M436.62,277.42l-13.2,13a2.2,2.2,0,0,0,3.09,3.13l13.2-13a2.2,2.2,0,1,0-3.09-3.13Z'
      transform='translate(-370.34 -247)'
    />
    <path
      className='cls-1'
      d='M413.32,300.37,400.52,313a2.2,2.2,0,0,0,3.09,3.13l12.8-12.61a2.2,2.2,0,0,0-3.09-3.13Z'
      transform='translate(-370.34 -247)'
    />
  </svg>
);

export default CancelSvg;
