import React from 'react';

const CarSvg = () => (
  <svg
    height='1em'
    width='1em'
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 92.03 62'
  >
    <defs>
      <style dangerouslySetInnerHTML={{ __html: '.cls-1{fill:#11192d;}' }} />
    </defs>
    <title>car</title>
    <path
      className='cls-1'
      d='M380.13,266.63a2,2,0,0,0-1.81,2v15.09a2,2,0,1,0,4,.06s0,0,0-.06V270.63h53.5v37.25a2,2,0,1,0,4,.06s0,0,0-.06V268.63a2,2,0,0,0-2-2H380.13Zm64.47,13.09a2,2,0,0,0-.06,4h8.87s.11.06.41.31a10.18,10.18,0,0,1,1.5,1.75,42.76,42.76,0,0,1,3.5,6.38,72.52,72.52,0,0,1,2.81,7.16,26.55,26.55,0,0,1,1.09,4.31v4.19h-2.06a2,2,0,0,0,.22,4h1.85v5.66h-1.87a2,2,0,0,0-.06,4h3.93a2,2,0,0,0,2-2V303.63a18.7,18.7,0,0,0-1.28-5.56,76.57,76.57,0,0,0-3-7.56,45.46,45.46,0,0,0-3.84-7,13.66,13.66,0,0,0-2.16-2.5,4.38,4.38,0,0,0-2.94-1.28Zm.72,7.38a2,2,0,0,0-2,2h0V301a2,2,0,0,0,2,2H457a2,2,0,0,0,.06-4h-9.74v-9.87a2,2,0,0,0-2-2Zm-68.59,1.16a2,2,0,1,0-.06,4h16.71a2,2,0,0,0,.06-4H376.72Zm5.25,6.53a2,2,0,1,0-.06,4h11.46a2,2,0,0,0,.06-4H382Zm3.75,6.53a2,2,0,0,0,.22,4h7.44a2,2,0,0,0,.06-4h-7.71Zm-5.44,6.5a2,2,0,0,0-2,2h0v9.63a2,2,0,0,0,2,2h9.09A10,10,0,0,0,409,318.59a10,10,0,0,0-19.94-1.12h-6.75v-7.62a2,2,0,0,0-2-2Zm65.28.81a10.07,10.07,0,0,0-10,8.84H414.28a2,2,0,0,0,.19,4h21.47a10,10,0,0,0,19.63-2.87A10,10,0,0,0,445.56,308.63Zm-46.53,4a6,6,0,0,1,0,12,6,6,0,1,1,0-12Zm46.53,0a6,6,0,0,1,0,12,6,6,0,1,1,0-12Zm-46.56,3a3,3,0,1,0,3,3A3,3,0,0,0,399,315.59Zm46.53,0a3,3,0,1,0,3,3A3,3,0,0,0,445.53,315.59Z'
      transform='translate(-374.69 -266.62)'
    />
  </svg>
);

export default CarSvg;
