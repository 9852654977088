import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { get } from 'lodash';
import { Col, Row } from 'antd';
import { withRouter } from 'react-router-dom';
import WishlistProduct from '../components/WishlistProduct.jsx';
import WishlistEmpty from '../components/WishlistEmpty.jsx';

const Wishlist = (props) => {
  if (
    !get(props, 'wishlist.offers.length') &&
    !get(props, 'wishlist.products.length')
  )
    return <WishlistEmpty {...props} />;

  return (
    <div>
      <Holder>
        <div className='container'>
          <Row gutter={26}>
            <Col
              style={{
                padding: 0
              }}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              xs={24}
            >
              {get(props, 'wishlist.offers.length') > 0 && (
                <MainBorder>
                  <MoreOffers>{' العروض'}</MoreOffers>
                </MainBorder>
              )}

              <Row style={{ margin: 0 }} type='flex'>
                {get(props, 'wishlist.offers', []).map((product, index) => {
                  const i = index + 1;
                  return (
                    <ProductContainer xs={12} sm={12} md={12} lg={6} xl={6}>
                      <React.Fragment>
                        <BordersLeft index={i}>
                          <BordersBottom index={i}>
                            <WishlistProduct key={product} product={product} />
                          </BordersBottom>
                        </BordersLeft>
                      </React.Fragment>
                    </ProductContainer>
                  );
                })}
              </Row>
              {get(props, 'wishlist.products.length') > 0 && (
                <MainBorder>
                  <MoreOffers>{' المنتجات'}</MoreOffers>
                </MainBorder>
              )}
              <Row style={{ margin: 0 }} type='flex'>
                {get(props, 'wishlist.products', []).map((product, index) => {
                  const i = index + 1;
                  return (
                    <ProductContainer xs={12} sm={12} md={12} lg={6} xl={6}>
                      <React.Fragment>
                        <BordersLeft index={i}>
                          <BordersBottom index={i}>
                            <WishlistProduct key={product} product={product} />
                          </BordersBottom>
                        </BordersLeft>
                      </React.Fragment>
                    </ProductContainer>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </div>
      </Holder>
    </div>
  );
};
const mapStateToProps = ({ wishlist }) => ({ wishlist });
export default connect(mapStateToProps, {})(withRouter(Wishlist));

const MoreOffers = styled.div`
  text-align: right;
  font-size: 20px;
  color: #11192d;
  font-family: 'Cairo-Bold';

  @media (max-width: 414px) {
    font-size: 17px;
  }
  @media (max-width: 375px) {
    font-size: 16px;
  }
  @media (max-width: 360px) {
    font-size: 15px;
  }
`;
const MainBorder = styled.div`
  color: #11192d;
  background: #e1e5ea;
  padding: 20px 50px;
  @media (max-width: 414px) {
    padding: 20px 20px;
  }
`;

const Holder = styled.div`
  margin: 60px 0;

  @media (max-width: 414px) {
    margin: 0px 0;
  }
`;

const BordersLeft = styled.div`
  border-left-color: grey;
  ${(props) => (props.index % 4 !== 0 ? 'border-left: 1px solid grey;  ' : '')};
  @media (max-width: 768px) {
    ${(props) =>
      props.index % 2 !== 0 ? 'border-left: 1px solid grey;  ' : ''};
  }
  height: 100%;
`;

const BordersBottom = styled.div`
  border-bottom-color: grey;

  ${(props) => (props.index <= 4 ? 'border-bottom: 1px solid grey' : '')};
  width: 90%;
  margin: auto;
  padding-bottom: 10%;

  @media (max-width: 768px) {
    i {
      font-size: 16px !important;
    }

    svg {
      font-size: 20px !important;
    }
  }
`;
const ProductContainer = styled(Col)`
  padding: 1vw 0vw;
  @media (max-width: 768px) {
    padding-top: 3.2vw;
  }
  /* margin-top: 2.5%; */
`;
