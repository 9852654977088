import React from 'react';

const PhoneSvg = () => (
  <svg
    width='1em'
    height='1em'
    id='Layer_1'
    data-name='Layer 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 99.79 99.79'
  >
    <defs>
      <style dangerouslySetInnerHTML={{ __html: '.cls-1{fill:#4c7fba;}' }} />
    </defs>
    <title>phone</title>
    <path
      className='cls-1'
      d='M421.83,247a49.9,49.9,0,1,0,49.9,49.9A50,50,0,0,0,421.83,247Zm0,95.39a45.5,45.5,0,1,1,45.5-45.5A45.55,45.55,0,0,1,421.83,342.37Z'
      transform='translate(-371.93 -246.97)'
    />
    <path
      className='cls-1'
      d='M447.51,309.69,439.38,304a7,7,0,0,0-9.72,1.73l-1.49,2.13c-1-.71-2.09-1.52-3.18-2.39h0a3.54,3.54,0,0,0-.92-.2,2,2,0,0,0-1.31,3.43l.07.06a54.76,54.76,0,0,0,4.81,3.45,2,2,0,0,0,2.65-.54l2.58-3.7a3.13,3.13,0,0,1,2.56-1.27,3,3,0,0,1,1.71.52l8.13,5.68a3,3,0,0,1,1.18,3.42,8.15,8.15,0,0,1-.66,1.38l-.7,1a5.14,5.14,0,0,1-.44.49,8.11,8.11,0,0,1-3.71,2.06,12.84,12.84,0,0,1-3.3.4c-7.78,0-18-5.36-26.6-14-10-10-15.61-22.32-13.59-29.9a8.1,8.1,0,0,1,2.06-3.71c.15-.15.32-.31.42-.39l1-.7a8,8,0,0,1,1.47-.71,2.67,2.67,0,0,1,.93-.17,3.06,3.06,0,0,1,2.49,1.34l5.68,8.12a3.06,3.06,0,0,1-.76,4.27l-3.7,2.58a2,2,0,0,0-.54,2.65A70,70,0,0,0,416,302.67c.79.79,2.39,2.29,2.39,2.29a2,2,0,0,0,2.24-3.26h0c-.63-.59-1.25-1.19-1.86-1.8a71.29,71.29,0,0,1-8-9.38L413,289a7,7,0,0,0,1.73-9.72L409,271.19a7,7,0,0,0-5.7-3,6.6,6.6,0,0,0-2.29.41,12,12,0,0,0-2.27,1.12l-1.15.81a9.37,9.37,0,0,0-.86.76,12,12,0,0,0-3.07,5.47c-2.4,9,3.47,22.54,14.6,33.68,9.34,9.34,20.59,15.14,29.37,15.14A16.75,16.75,0,0,0,442,325a12,12,0,0,0,5.47-3.07,9.28,9.28,0,0,0,.82-.93l.81-1.16a12,12,0,0,0,1.06-2.19A6.88,6.88,0,0,0,447.51,309.69Z'
      transform='translate(-371.93 -246.97)'
    />
  </svg>
);

export default PhoneSvg;
