/* eslint-disable no-unused-vars */
/* eslint-disable import/no-duplicates */
/* eslint-disable import/extensions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Row, Col, Icon, Divider } from 'antd';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import Slider1 from 'react-slick';
import Slider2 from 'react-slick';
import Slider3 from 'react-slick';
import ScrollAnimation from 'react-animate-on-scroll';
import LandingPageHeader from '../components/LandingPageHeader';
import LandingPageOfferCard from '../components/LandingPageOfferCard';
import LandingPageProduct from '../components/LandingPageProduct';
import DropdownSvg from '../icons/DropdownSvg';
import { updateUser } from '../appRedux/actions/Auth';
import { getCart } from '../appRedux/actions/Cart';
import { getWishlist } from '../appRedux/actions/Wishlist';
import { getGeneric } from '../appRedux/actions/Generic';
import {
  getOffers,
  getNonHandshakedOffers
} from '../appRedux/actions/Products';
import { getObject } from '../services/CommonServices';
import BannerAds from '../components/BannerAds';

const LandingPage = (props) => {
  const [featuredProducts, setFeaturedProducts] = useState();
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buyer, setBuyer] = useState(get(props, 'auth.user._id'));
  const [zone, setZone] = useState(get(props, 'auth.user.address.zone._id'));

  useEffect(() => {
    if (get(props, 'auth.token')) {
      props.updateUser();
      props.getCart();
      props.getWishlist();
    }
    getObject('companyProducts/availableProducts', {
      update: true,
      featured: true,
      buyer,
      zone,
      skip: 1
    }).then((res) => setFeaturedProducts(res.data.newSortedProducts));
    props.getOffers({
      zone: get(props, 'auth.user.address.zone._id', ''),
      token: get(props, 'auth.token'),
      skip: 1,
      limit: 3
    });
    if (get(props, 'auth.token'))
      props.getNonHandshakedOffers({
        zone: get(props, 'auth.user.address.zone._id', ''),
        token: get(props, 'auth.token'),
        skip: 1,
        limit: 3
      });
    // .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setBuyer(get(props, 'auth.user._id'));
    setZone(get(props, 'auth.user.address.zone._id'));
  }, [props.auth]);

  useEffect(() => {
    getObject('companyProducts/availableProducts', {
      update: true,
      featured: true,
      buyer,
      zone,
      skip: 1
    }).then((res) => setFeaturedProducts(res.data.newSortedProducts));
    props.getOffers({
      zone: get(props, 'auth.user.address.zone._id', ''),
      token: get(props, 'auth.token'),
      skip: 1,
      limit: 3
    });
    if (get(props, 'auth.token'))
      props.getNonHandshakedOffers({
        zone: get(props, 'auth.user.address.zone._id', ''),
        token: get(props, 'auth.token'),
        skip: 1,
        limit: 3
      });
  }, [zone, buyer]);

  useEffect(() => {
    const offers = [
      ...get(props, 'products.offers.newOffers', []),
      ...get(props, 'products.nonHandshakedOffers.newOffers', [])
    ];
    setOffers(offers);
  }, [props.products.offers]);
  useEffect(() => {
    const offers = [
      ...get(props, 'products.offers.newOffers', []),
      ...get(props, 'products.nonHandshakedOffers.newOffers', [])
    ];
    setOffers(offers);
  }, [props.products.nonHandshakedOffers]);
  const settings = {
    infinite: false,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 300,
          autoplay: false
        }
      }
    ]
  };

  const settings2 = {
    dots: false,
    infinite: false,
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
          speed: 300,
          autoplay: false
        }
      }
    ]
  };
  const settings3 = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    centerPadding: '40px',
    rows: 2,
    slidesPerRow: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
          rows: 1,
          slidesPerRow: 1,
          speed: 300,
          autoplay: false
        }
      }
    ]
  };

  return (
    <React.Fragment>
      <LandingPageHeader />
      {get(offers, 'length', 0) && !loading && (
        <>
          <OfferHolder>
            <div className='container'>
              <MainBorder>
                <Row>
                  <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                    <Link
                      to={{
                        pathname: '/offers'
                      }}
                    >
                      <FeatureProductMore>
                        {' '}
                        <img
                          src={require('../assets/images/leftarrow.svg')}
                        />{' '}
                        <h3> المزيد </h3>
                        <Divider type='vertical' />
                      </FeatureProductMore>
                    </Link>
                  </Col>
                  <Col xs={12} sm={16} md={18} lg={18} xl={18}>
                    <MoreOffers>{' العروض'}</MoreOffers>
                  </Col>
                </Row>
              </MainBorder>
            </div>
            <div className='container'>
              <Offerbg>
                <ScrollAnimation
                  animateIn='fadeInDown'
                  animateOnce={true}
                  duration={2.4}
                >
                  <Slider1 {...settings}>
                    {offers.map((offer) => (
                      <LandingPageOfferCard offer={offer} {...props} />
                    ))}
                  </Slider1>
                </ScrollAnimation>
              </Offerbg>
            </div>
          </OfferHolder>
        </>
      )}
      <div className='container'>
        <FeatureProductHolder>
          <Row>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
              <Link
                to={{
                  pathname: '/featured',
                  search: `featured=true`
                }}
              >
                <FeatureProductMore>
                  {' '}
                  <img src={require('../assets/images/leftarrow.svg')} />{' '}
                  <h3> المزيد </h3>
                  <Divider type='vertical' />
                </FeatureProductMore>
              </Link>{' '}
            </Col>
            <Col xs={12} sm={16} md={18} lg={18} xl={18}>
              <FeatureProducTitle> المنتجات المتميزة</FeatureProducTitle>
            </Col>
          </Row>
        </FeatureProductHolder>
        <HomeProducts>
          <ScrollAnimation
            animateIn='fadeInLeft'
            animateOnce={true}
            duration={2.4}
          >
            {featuredProducts && !loading ? (
              <Slider2 {...settings2}>
                {featuredProducts.map((product, index) => (
                  <LandingPageProduct product={product} />
                ))}
              </Slider2>
            ) : null}
          </ScrollAnimation>
        </HomeProducts>
      </div>
      <BannerAds />
      <div className='container'>
        <FeatureProductHolder>
          <Row>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
              <Link
                to={{
                  pathname: '/featured',
                  search: `featured=true`
                }}
              >
                <FeatureProductMore>
                  {' '}
                  <img src={require('../assets/images/leftarrow.svg')} />{' '}
                  <h3> المزيد </h3>
                  <Divider type='vertical' />
                </FeatureProductMore>
              </Link>{' '}
            </Col>
            <Col xs={12} sm={16} md={18} lg={18} xl={18}>
              <FeatureProducTitle> المنتجات الاكثر مبيعا</FeatureProducTitle>
            </Col>
          </Row>
        </FeatureProductHolder>
      </div>
      <div className='container'>
        <FeatureProductBg>
          <HomeProducts>
            <ScrollAnimation
              animateIn='fadeInLeft'
              animateOnce={true}
              duration={2.4}
            >
              {featuredProducts && !loading ? (
                <Slider3 {...settings3}>
                  {featuredProducts.map((product, index) => (
                    <LandingPageProduct product={product} />
                  ))}
                </Slider3>
              ) : null}
            </ScrollAnimation>
          </HomeProducts>
        </FeatureProductBg>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ main, auth, cart, products }) => ({
  main,
  auth,
  cart,
  products
});
export default connect(mapStateToProps, {
  updateUser,
  getGeneric,
  getCart,
  getOffers,
  getNonHandshakedOffers,
  getWishlist
})(LandingPage);

const FeatureProductHolder = styled.div`
  width: 100%;
  font-family: 'Cairo-bold';
  font-size: 1.5vmax;
  color: #11192d;
  //border-bottom: 1px solid #ddd;
  background: #e1e5ea;
  padding: 20px 50px;
  @media (max-width: 414px) {
    padding: 20px 20px;
  }
`;
const StyledIcon = styled(Icon)``;
const ProductCol = styled.div``;

const BordersBottom = styled.div`
  // border-bottom-color: ${(props) => props.theme.light};

  ${(props) => (props.index <= 4 ? 'border-bottom: 1px solid ' : '')};
  width: 90%;
  margin: auto;
  box-shadow: 0 0 7px rgba(0,0,0,0.1);

  /* position: absolute; */
`;
const BordersLeft = styled.div`
  // border-left-color: ${(props) => props.theme.light};
  // ${(props) => (props.index % 4 !== 0 ? 'border-left:;  ' : '')};
  // height: 100%;
`;
const StyledCol = styled(Col)`
  border: 1px solid black;
  height: 29vh;
  /* width: 28.0012vw; */
  /* margin-right: 2vw; */
`;

const MoreOffers = styled.div`
  text-align: right;
  font-size: 20px;
  color: #11192d;
  font-family: 'Cairo-Bold';

  @media (max-width: 414px) {
    font-size: 17px;
  }
  @media (max-width: 375px) {
    font-size: 16px;
  }
  @media (max-width: 360px) {
    font-size: 15px;
  }
`;
const OfferHolder = styled.div`
  .slick-slide {
    padding: 0px 10px;
  }

  .slick-next:before,
  .slick-prev:before {
    color: #191919;
  }

  .slick-slide {
    padding: 0px 10px;
  }

  .slick-prev:before {
    content: '\f104';
    font: normal normal normal 20px/1 FontAwesome;
    font-size: 40px;
  }
  .slick-next:before {
    content: '\f105';
    font: normal normal normal 20px/1 FontAwesome;
    font-size: 40px;
  }
  .slick-prev {
    left: -50px;
    z-index: 99;
  }
  .slick-next {
    right: -50px;
  }

  @media (max-width: 414px) {
    .slick-prev {
      left: -15px;
    }
    .slick-next {
      right: -15px;
    }
  }
`;
const Offerbg = styled.div`
  border: 1px solid #ffffff94;
  margin: 40px 0;

  @media (max-width: 414px) {
    margin: 20px 0;
  }
`;

const FeatureProducTitle = styled.h3`
  text-align: right;
  color: #10182d;
  font-size: 20px;
  margin: 0;
  @media (max-width: 414px) {
    font-size: 17px;
  }
  @media (max-width: 375px) {
    font-size: 16px;
  }
  @media (max-width: 360px) {
    font-size: 15px;
  }
`;
const FeatureProductMore = styled.div`
  text-align: left;
  font-family: 'Cairo-Bold';
  float: left;
  color: #11192d;
  cursor: pointer;
  img {
    float: left;
    width: 25px;
    height: 25px;
    margin-right: 20px;
    transform: rotate(90deg);
    transition: 0.4s;
    :hover {
      transform: rotate(-90deg);
    }
  }
  h3 {
    font-size: 18px;
    display: inline-block;
    margin: 0;
    @media (max-width: 414px) {
      font-size: 17px;
    }
    @media (max-width: 375px) {
      font-size: 16px;
    }
    @media (max-width: 360px) {
      font-size: 15px;
    }
    :hover {
      color: #4a7fbd;
    }
  }

  .ant-divider,
  .ant-divider-vertical {
    width: 1px;
    height: 30px;
    margin-left: 50px;
    background: #11192d;

    @media (max-width: 767px) {
      display: none;
    }
  }
`;
const FeatureProductBg = styled.div``;
const MainBorder = styled.div`
  color: #11192d;
  background: #e1e5ea;
  padding: 20px 50px;
  @media (max-width: 414px) {
    padding: 20px 20px;
  }
`;

const HomeProducts = styled.div`
  a .div {
    color: #292b2c;
    background-color: red !important;
  }
  margin: 0px 0;
  .slick-next:before,
  .slick-prev:before {
    color: #191919;
  }

  .slick-slide {
    padding: 0px 10px;
  }

  .slick-prev:before {
    content: '\f104';
    font: normal normal normal 20px/1 FontAwesome;
    font-size: 40px;
  }
  .slick-next:before {
    content: '\f105';
    font: normal normal normal 20px/1 FontAwesome;
    font-size: 40px;
  }
  .slick-prev {
    left: -50px;
    z-index: 99;
  }
  .slick-next {
    right: -50px;
  }

  @media (max-width: 414px) {
    .slick-prev {
      left: -5px;
      width: 40px;
      height: 40px;
      z-index: 9999;
      border-radius: 0;
      top: 40%;
      text-align: center;
      margin: 0 auto;
    }
    .slick-next {
      right: -5px;
      width: 40px;
      height: 40px;
      z-index: 9999;
      border-radius: 0;
      top: 40%;
      text-align: center;
      margin: 0 auto;
    }
  }
`;
