/* eslint-disable import/extensions */
import React from 'react';
import { Icon, Row, Col } from 'antd';
import styled from 'styled-components';
import MinusSvg from '../icons/MinusSvg';
import PlusSvg from '../icons/PlusSvg';
import CustomButton from '../elements/CustomButton';
import Count from '../elements/Count';

const decrementCount = (prev) => {
  if (prev === 0) {
    return 0;
  }
  return prev - 1;
};

const CartCounter = ({ setCount, count }) => (
  <Row type='flex' style={{ alignItems: 'center' }}>
    <Col span={6}>
      <CustomButton
        className='ProductCounterHolder'
        style={{ lineHeight: '0.1vw' }}
        height={'2.56vw'}
        width={'3.66vw'}
        onClick={() => {
          setCount((prev) => prev + 1);
        }}
        block
        transparent
        shape='round'
      >
        <StyledIcon style={{ fontSize: '17px' }} component={PlusSvg} />
      </CustomButton>
    </Col>
    <Col span={12}>
      <Count>{count}</Count>
    </Col>
    <Col span={6}>
      <CustomButton
        className='ProductCounterHolder'
        style={{ lineHeight: '0.1vw' }}
        height={'2.56vw'}
        width={'3.66vw'}
        block
        onClick={() => {
          setCount(decrementCount);
        }}
        transparent
        shape='round'
      >
        <StyledIcon style={{ fontSize: '17px' }} component={MinusSvg} />
      </CustomButton>
    </Col>
  </Row>
);

const StyledIcon = styled(Icon)``;
export default CartCounter;
