/* eslint-disable import/extensions */
import React, { useState, useEffect } from 'react';
import { Row, Col, Pagination, Spin, message } from 'antd';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import ScrollAnimation from 'react-animate-on-scroll';
import { postObject } from '../services/CommonServices';
import { getGeneric, updateGeneric } from '../appRedux/actions/Generic';
import { updateUser, userSignOut } from '../appRedux/actions/Auth';
import CustomDivider from '../elements/CustomDivider';

const Card = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 7px;
`;

const CardBody = styled.div`
  box-sizing: border-box;
  position: relative;
  background-color: white;
  width: 280px;
  min-height: 230px;
  margin: 0px 30px;
  padding: 15px 30px;
  justify-content: center;
  box-shadow: 0px 1px 3px 2px rgba(150, 146, 146, 0.7);
`;

const CompanyName = styled.h3`
  color: rgb(75, 75, 75);
  font-family: sans-serif;
  font-size: medium;
  margin: 20px 0px;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
const SubTitle = styled.span`
  color: rgb(119, 119, 119);
  font-family: 'Roboto';
  font-size: small;
  margin: 20px 0px;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
const CardImg = styled.span`
  display: inline-flex;
  align-items: center;
  vertical-align: bottom;
`;
const Img = styled.img`
  width: 150px;
  height: 150px;
  margin: 0px 30px;
`;

const AllCompanies = (props) => {
  const [mainLoader, setMainLoader] = useState(true);
  const [infoLoaded, setInfoLoaded] = useState(false);
  const [internetError, setInternetError] = useState(false);
  const [skip, setSkip] = useState(1);
  const [limit] = useState(8);
  const handleFailure = () => {
    setMainLoader(false);
  };
  const handleSuccess = () => {
    setMainLoader(false);
  };

  const [companiesCount, setCompaniesCount] = useState(0);

  const [allCompanies, setallCompanies] = useState();

  const onChange = (page) => {
    setSkip(page);
  };

  useEffect(() => {
    props.updateUser({ onSuccess: handleSuccess, onFailure: handleFailure });
  }, [props]);

  useEffect(() => {
    if (!mainLoader) {
      postObject('companies/getCompanies', { limit, skip })
        .then((res) => {
          setallCompanies(res.data.data);
          setCompaniesCount(res.data.count);
          setInfoLoaded(true);
        })
        .catch(() => {
          setInfoLoaded(true);
          setInternetError(true);
        });
    }
  }, [mainLoader, skip, companiesCount, limit]);

  useEffect(() => {
    if (internetError) message.error('تعثر الاتصال بالانترنت ,حدث الصفحة');
  }, [internetError]);

  return (
    !internetError && (
      <>
        <Row gutter={25}>
          {infoLoaded ? (
            companiesCount ? (
              allCompanies.map((company, index) => {
                const i = index + 1;
                return (
                  <React.Fragment>
                    <ProductCol index={i} lg={6} md={12} sm={12} xs={12}>
                      <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                        <Card key={i}>
                          <CardBody>
                            <CardImg>
                              <Img src={get(company, 'images[0]')} />
                            </CardImg>
                            <CompanyName>{get(company, 'name.ar')}</CompanyName>
                            <SubTitle>
                              التليفون: {get(company, 'phone')}{' '}
                            </SubTitle>
                            <Link
                              to={{
                                pathname: '/company',
                                search: `company=${company._id}&page=1`
                              }}
                            >
                              مزيد من التفاصيل
                            </Link>
                          </CardBody>
                        </Card>
                      </ScrollAnimation>
                    </ProductCol>
                    {i % 4 === 0 &&
                    index !== 0 &&
                    index !== get(allCompanies, 'length', 0) - 1 ? (
                      <Col span={24}>
                        <CustomDivider />
                      </Col>
                    ) : (
                      ''
                    )}
                  </React.Fragment>
                );
              })
            ) : (
              <NoCompaniesDiv>لا يوجد شركات</NoCompaniesDiv>
            )
          ) : (
            <Wrapper>
              <StyledSpin />
            </Wrapper>
          )}
        </Row>
        <Row>
          <PaginationHolder>
            <StyledPagination
              style={{
                direction: 'rtl',
                textAlign: 'center',
                margin: '30px 0'
              }}
              defaultCurrent={1}
              total={companiesCount}
              pageSize={8}
              current={Number(skip)}
              onChange={onChange}
            />
          </PaginationHolder>
        </Row>
      </>
    )
  );
};

const mapStateToProps = ({ main, auth, allCompanies, products }) => ({
  main,
  auth,
  allCompanies,
  products
});
export default connect(mapStateToProps, {
  getGeneric,
  updateGeneric,
  updateUser,
  userSignOut
})(AllCompanies);

const StyledPagination = styled(Pagination)`
  .ant-pagination-item-active,
  .ant-pagination-item,
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-color: #10182d;
  }

  .ant-pagination-item-active a {
    color: #4a7fbd !important;
    font-weight: bold;
  }
  a.ant-pagination-item-link i.anticon.anticon-right svg {
    transform: rotate(-180deg);
  }
  li.ant-pagination-disabled.ant-pagination-prev svg {
    transform: rotate(180deg);
  }
  .ant-pagination-item a,
  .ant-pagination-item {
    color: #10182d;
  }

  .ant-pagination-item a :hover,
  .ant-pagination-item:hover {
    color: red !important;
    font-weight: bold;
  }

  .ant-pagination-item {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    margin-right: 0.58vw;
    outline: 0;
    font-size: 17px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
  }
  .ant-pagination-next {
    display: inline-block;
    min-width: 32px;
    line-height: 32px;
    margin-left: 10px;
    border-radius: 0.29vw;
  }
  .ant-pagination-prev {
    display: inline-block;
    height: 32px;
    margin-right: 10px;
    line-height: 32px;
    border-radius: 0.29vw;
  }
  .ant-pagination-item-link {
    font-size: 1vw;
    @media (max-width: 768px) {
      font-size: 20px;
    }
    @media (min-width: 2560px) {
      font-size: 0.5vw;
    }
  }
`;

const ProductCol = styled(Col)`
  // &:not(:nth-child(4n + 0)) {
  //   border-right: 1px solid ${(props) => props.theme.primary};
  // }
  padding: 1vw 0vw;
  float: right;
`;

const PaginationHolder = styled.div`
  margin: 50px 0;

  @media (max-width: 414px) {
    margin: 20px 0;
  }
`;

const NoCompaniesDiv = styled.div`
  width: 800px;
  height: 200px;
  text-align: center;
  font-size: 40px;
  @media (max-width: 767px) {
    width: inherit;
    height: inherit;
    font-size: 25px;
    margin: 30px 0;
  }
`;

const StyledSpin = styled(Spin)`
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;
