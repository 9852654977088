/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { Col } from 'antd';

const OfferModalProduct = (props) => {
  const [price, setPrice] = useState(0);
  const [oldPrice, setOldPrice] = useState(0);
  useEffect(() => {
    if (get(props, 'offer.type') !== 3) {
      const buyerCategory = get(props, 'product.buyerCategories', []).filter(
        (bc) => {
          return (
            props.offer.buyer_cat ===
            get(bc, 'buyerCategory._id', get(bc, 'buyerCategory'))
          );
        }
      );
      const priceUnit =
        get(props, 'offer.optional_3') === 'carton'
          ? Number(get(buyerCategory[0], 'saleUnit.price'))
          : Number(get(buyerCategory[0], 'saleUnit.price')) /
            Number(get(buyerCategory[0], 'saleUnit.packageQuantity'));
      const offerPriceUnit =
        get(props, 'offer.optional_4') === 'carton'
          ? Number(get(buyerCategory[0], 'saleUnit.price'))
          : Number(get(buyerCategory[0], 'saleUnit.price')) /
            Number(get(buyerCategory[0], 'saleUnit.packageQuantity'));
      const offerPrice =
        get(props, 'offer.type') === 1
          ? priceUnit * Number(get(props, 'offer.optional_1'))
          : priceUnit *
            Number(get(props, 'offer.optional_1')) *
            (1 - Number(get(props, 'offer.optional_2')) / 100);
      const oldPrice =
        get(props, 'offer.type') === 1
          ? priceUnit * Number(get(props, 'offer.optional_1')) +
            offerPriceUnit * Number(get(props, 'offer.optional_2'))
          : priceUnit * Number(get(props, 'offer.optional_1'));
      setOldPrice(oldPrice);
      setPrice(offerPrice);
    } else {
      setPrice(get(props, 'offer.optional_2'));
    }
  }, [props.product]);
  return (
    <ProductContainer>
      <Col md={12} lg={12} xs={24}>
        <ProductImage
          alt={'product'}
          src={get(
            props,
            'product.images[0]',
            require('../assets/images/repzone-placeholder.png')
          )}
        />
      </Col>
      <Col md={12} lg={12} xs={24}>
        <ProductInfoContainer>
          {get(props, 'product.addOnProduct') && (
            <ProductName>{'منتج هدية'}</ProductName>
          )}
          <ProductName>{get(props, 'product.name.ar')}</ProductName>
          <Flex>
            <ProductQuantity>
              {`${get(props, 'quantityCarton', 0)} ${get(
                props,
                'product.buyerCategories.0.saleUnit.package.name',
                'كرتونة'
              )} - ${get(props, 'quantityPackage', 0)} ${get(
                props,
                'product.packageUnit.name',
                'عبوة'
              )}`}
            </ProductQuantity>
            {(get(props, 'offer.type') === 1 ||
              get(props, 'offer.type') === 2) && (
              <DescriptionTag>
                {get(props, 'offer.type') === 1
                  ? get(props, 'offer.optional_4') === 'carton'
                    ? `+ ${get(props, 'offer.optional_2')} كراتين مجاناً`
                    : `+ ${get(props, 'offer.optional_2')} عبوات مجاناً `
                  : `خصم ${get(props, 'offer.optional_2')} بالمئة `}
              </DescriptionTag>
            )}
          </Flex>
          <PriceContainer>
            <PriceTag>
              {get(props, 'product.addOnProduct')
                ? 'مجاني'
                : ` ${Number(price).toFixed(2)} جم `}
            </PriceTag>
            {get(props, 'offer.type') !== 3 && (
              <OldPriceTagText>بدلاً من </OldPriceTagText>
            )}
            {get(props, 'offer.type') !== 3 && (
              <OldPriceTag>{`${oldPrice.toFixed(2)} جم`}</OldPriceTag>
            )}
          </PriceContainer>
        </ProductInfoContainer>
        <div>
          {get(props, 'offer.type') !== 3 && (
            <DiscountTag>{` وفر ${(oldPrice - price).toFixed(
              2
            )} جنيه`}</DiscountTag>
          )}
        </div>
      </Col>
    </ProductContainer>
  );
};

export default OfferModalProduct;

const Flex = styled.div`
  display: flex;
  @media (max-width: 414px) {
    display: inherit;
  }
`;
const ProductContainer = styled.div`
  width: 100%;
  font-size: 1.7vw;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 414px) {
    display: inherit;
    align-items: inherit;
  }
`;
const ProductImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: contain;
  padding-left: 20px;
  border-left: 1px solid;

  @media (max-width: 414px) {
    height: 200px;
    object-fit: contain;
    margin-left: 0;
    border-left: inherit;
    margin: 0 auto;
    display: block;
  }
`;
const ProductInfoContainer = styled.div`
  text-align: right;
  width: 100%;
  color: ${(props) => props.theme.primary};
  margin-right: 20px;
  @media (max-width: 767px) {
    text-align: center;
    margin-right: 0;
  }
`;
const ProductName = styled.div`
  overflow: hidden;
  font-size: 17px;
  color: #11192d;
  margin-bottom: 10px;
  font-family: 'Cairo-Bold';
  text-align: right;
  @media (max-width: 767px) {
    text-align: center;
  }
`;

const DescriptionTag = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-weight: bold;
  color: #11192d;
  margin-right: 6px;
`;

const PriceTag = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
  color: #557fb8;
`;

const DiscountTag = styled.div`
  font-size: 18px;
  color: black;
  border: 2px solid yellowgreen;
  border-radius: 10px;
  width: 50%;
  text-align: center;
  margin-right: 25%;
  @media (max-width: 767px) {
    margin-top: 20px;
  }
`;

const OldPriceTag = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
  color: red;
  text-decoration: line-through;
`;
const OldPriceTagText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
  color: #807f80;
  margin: 0;
`;
const PriceContainer = styled.div`
  margin-top: 10px;
`;

const ProductQuantity = styled.div`
  font-size: 16px;
  color: #11192d;
`;
