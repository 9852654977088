/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
import React from 'react';
import { Row, Col, Form, Icon, Input, Button } from 'antd';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CustomDivider from '../elements/CustomDivider';
import CustomButton from '../elements/CustomButton';
import { userSignIn } from '../appRedux/actions/Auth';
import { getCart } from '../appRedux/actions/Cart';
import { handleLogin } from '../services/CommonServices';
import { getWishlist } from '../appRedux/actions/Wishlist';

const LoginPage = (props) => {
  const { form, userSignIn } = props;
  const { getFieldDecorator } = form;

  return (
    <Container>
      <div className='container'>
        <Row>
          <Col lg={{ span: 16, offset: 4 }} xs={{ span: 24, offset: 0 }}>
            <LoginHolder style={{ background: 'white' }}>
              <Row>
                <span>
                  {' '}
                  <p>ليس لديك حساب؟</p>
                </span>
                <span>
                  {' '}
                  <a href='/register'>سجل الآن</a>
                </span>
              </Row>
              <CustomDivider />
            </LoginHolder>
            <LoginHolder>
              <Documents>البيانات :</Documents>

              <LoginPanel
                onSubmit={async (e) => {
                  await handleLogin({ e, form, userSignIn });
                  props.getCart();
                  props.getWishlist();
                }}
              >
                <Row type='flex' justify='center'>
                  <Col lg={6} sm={6} md={6} xs={24}>
                    <Label>رقم الموبايل :</Label>
                  </Col>
                  <Col lg={18} sm={18} md={18} xs={24}>
                    {getFieldDecorator('accountPhoneNumber', {
                      rules: [
                        { required: true, message: 'من فضلك ادخل رقم الموبايل' }
                      ]
                    })(
                      <TextArea
                        prefix={<Icon type='user' />}
                        placeholder='رقم الموبايل'
                      />
                    )}
                  </Col>
                </Row>

                <CustomDivider />

                <Row type='flex' justify='center' align='middle'>
                  <Col lg={6} sm={6} md={6} xs={24}>
                    <Label> كلمة السر :</Label>
                  </Col>

                  <Col lg={18} sm={18} md={18} xs={24}>
                    {getFieldDecorator('password', {
                      rules: [
                        { required: true, message: 'من فضلك ادخل كلمة السر' }
                      ]
                    })(
                      <TextArea
                        prefix={<Icon type='lock' />}
                        type='password'
                        placeholder='كلمة السر'
                      />
                    )}
                  </Col>
                </Row>
                <NewPassword>
                  <Link to='/reset-password'> تريد كلمة سر جديدة؟ </Link>
                </NewPassword>

                <CenterButton>
                  <LoginButton type='primary' htmlType='submit'>
                    تسجيل الدخول
                  </LoginButton>
                </CenterButton>
                <Text>
                  **من خلال تقديم طلبك ، فإنك توافق على الشروط والأحكام وسياسات
                  الخصوصية والإرجاع الخاصة بنا. أنت توافق أيضًا على تخزين بعض
                  بياناتك بواسطة ريبزون ، والتي يمكن استخدامها لجعل تجارب التسوق
                  المستقبلية أفضل لك.{' '}
                </Text>
              </LoginPanel>
            </LoginHolder>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(LoginPage);

const mapStateToProps = ({ main, cart }) => ({
  main,
  cart
});

export default connect(mapStateToProps, { userSignIn, getCart, getWishlist })(
  WrappedNormalLoginForm
);

const Container = styled.div`
  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 30px;
    border: 2px solid rgb(151, 151, 151);
    height: 42px;
    border-radius: 0;
  }
`;

const LoginPanel = styled.form`
  background-color: white;
  width: 100%;
  a {
    margin: 30px 0;
    font-size: 23px;
    color: #4a7fbd;
  }
`;

const Label = styled.h1`
  font-size: 21px;
  margin: 0;
  line-height: normal;
  @media (max-width: 414px) {
    margin-bottom: 15px;
    text-align: right;
    font-size: 19px;
  }
`;

const TextArea = styled(Input)`
  .ant-input-affix-wrapper .ant-input {
    width: 100%;
    height: 40px;
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: normal;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(151, 151, 151);
    border-image: initial;
  }
`;

const Documents = styled.h1`
  color: #11192d;
  text-align: right;
  background: #e1e5ea;
  /* padding-right: 2vw; */
  padding: 20px;
  font-size: 24px;
  font-family: 'Cairo-Bold';
  margin-bottom: 30px;
  @media (max-width: 414px) {
    margin-bottom: 10px;
    text-align: right;
    padding: 10px;
    margin: 30px 0;
    font-size: 22px;
  }
`;

const LoginButton = styled(Button)`
  border-radius: 30px;
  clear: both;
  background-color: #4a7fbd;
  color: white;
  padding: 0;
  border: solid 0.15vw #4a7fbd;
  font-weight: bold;
  padding: 11px;
  height: inherit;
  width: 260px;
  margin-top: 20px;
  font-weight: bold;
  font-size: 15px;
  font-family: Cairo-Bold;
`;

const Text = styled.div`
  text-align: center;
  font-size: 19px;
  margin: 35px 0;
  clear: both;
  color: #10182d;
`;
const RegisterButton = styled(CustomButton)`
  text-align: right;
  color: #4a7fbd;
  font-size: 1.7vw;
  background-color: white;
  border-color: white;
  margin-right: 10px;

  :hover {
    border: none;
    transition: none;
    box-shadow: none;
  }
`;

const LoginHolder = styled.div`
  text-align: right;

  a {
    font-size: 17px;
    font-weight: bold;
    color: #4a7fbd;
    margin-right: 10px;
  }
  text-align: center;
  p {
    display: inline-block;
    font-size: 22px;
  }
`;

const CenterButton = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
`;

const NewPassword = styled.div`
  margin: 20px 0;
`;
