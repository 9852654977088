/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
import React from 'react';
import HomeSlider from '../components/HomeSlider';

export default function LandingPageHeader() {
  return (
    <React.Fragment>
      <div>
        <HomeSlider />
      </div>
    </React.Fragment>
  );
}
